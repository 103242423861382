import { Box } from '@mui/material';

import TextButton from '../../ui/Button/TextButton';

import {
    clientAuthorizationSchema as authorizationSchema,
    clientResetPasswordSchema as resetPasswordSchema,
} from '../../../settings/formSchema';

const RedirectButtonGroup = ({ changeFormContent, showForm, dictionary }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2.25,
                justifyContent: 'space-between',
                order: 10,
                width: '100%',
            }}
        >
            {(showForm === 'initialRegistration' ||
                showForm === 'registration' ||
                showForm === 'resetPassword') && (
                <TextButton
                    title={dictionary?.enter}
                    buttonHandler={() => {
                        changeFormContent(
                            'authorization',
                            dictionary?.authorization,
                            dictionary?.enter_to_start,
                            dictionary?.enter,
                            authorizationSchema
                        );
                    }}
                />
            )}
            {(showForm === 'authorization' || showForm === 'resetPassword') && (
                <TextButton
                    title={dictionary?.registration}
                    buttonHandler={() => {
                        changeFormContent(
                            'initialRegistration',
                            dictionary?.registration,
                            dictionary?.choose_role,
                            ''
                        );
                    }}
                />
            )}
            {showForm !== 'resetPassword' && (
                <TextButton
                    title={dictionary?.restore_password}
                    buttonHandler={() => {
                        changeFormContent(
                            'resetPassword',
                            dictionary?.restore_password_title,
                            dictionary?.restore_password_text,
                            dictionary?.send,
                            resetPasswordSchema
                        );
                    }}
                />
            )}
        </Box>
    );
};

export default RedirectButtonGroup;
