import { useContext } from 'react';
import {
    useChangeNotificationStatusMutation,
    useDeleteNotificationMutation,
} from '../../../api/adminPartApi/apiNotificationsSlice';

import { TableBody, TableRow, TableCell, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as PublishIcon } from '../../../assets/icons/publish.svg';
import { ReactComponent as HideIcon } from '../../../assets/icons/show.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setNotificationsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { dateConverter } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const NotificationsList = ({
    notificationsList,
    notificationsSuccess,
    changeContentHandler,
    paginationData,
}) => {
    const { navigate, dictionary, language } = useContext(PageContext);

    const [changeNotificationStatus] = useChangeNotificationStatusMutation();
    const [deleteNotification] = useDeleteNotificationMutation();

    const changeStatusHandler = (id, action) => {
        changeNotificationStatus({ id, action })
            .unwrap()
            .catch((error) => console.log(error));
    };

    const deleteNotificationHandler = (id) => {
        deleteNotification(id)
            .unwrap()
            .catch((error) => console.log(error));
    };

    return (
        <>
            <MyToolbar
                filter={true}
                moveToLeft={true}
                buttonSx={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}
                buttonClass={'btn--small'}
                paginationData={paginationData}
                buttonHandler={() => {
                    changeContentHandler('createNotification');
                    navigate('/admin/notifications#create');
                }}
            />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {notificationsList.length === 0 && notificationsSuccess && (
                        <EmptyListAlertText />
                    )}
                    {notificationsList.length > 0 &&
                        notificationsList.map(({ id, name, published_at, views, status }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell>{name?.[language]}</TableCell>
                                    <TableCell>
                                        <Typography>
                                            {published_at ? dateConverter(published_at) : '-'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{views ? views : '-'}</Typography>
                                    </TableCell>
                                    <TableCell className="action">
                                        <MyTooltip
                                            position={'top'}
                                            title={
                                                status === 'draft' || status === 'hidden'
                                                    ? dictionary?.publish
                                                    : dictionary?.hide
                                            }
                                        >
                                            {status === 'draft' || status === 'hidden' ? (
                                                <PublishIcon
                                                    onClick={() =>
                                                        changeStatusHandler(id, 'publish')
                                                    }
                                                />
                                            ) : (
                                                <HideIcon
                                                    onClick={() =>
                                                        changeStatusHandler(id, 'unpublish')
                                                    }
                                                />
                                            )}
                                        </MyTooltip>
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <EditIcon
                                                onClick={() => {
                                                    changeContentHandler(
                                                        'editNotification',
                                                        id,
                                                        true
                                                    );
                                                    navigate(
                                                        `/admin/notifications#notification_${id}`
                                                    );
                                                }}
                                            />
                                        </MyTooltip>
                                        <MyTooltip position={'top'} title={dictionary?.delete}>
                                            <DeleteIcon
                                                onClick={() => deleteNotificationHandler(id)}
                                            />
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Notifications'} />
        </>
    );
};

export default NotificationsList;
