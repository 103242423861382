import { useContext, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { FlexBox } from '../../../mui/theme';

import Counter from '../../../components/ui/Counter/Counter';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';

import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { dateConverter } from '../../../helpers/functions';

import '/node_modules/flag-icons/css/flag-icons.min.css';

const DialoguesList = ({
  type,
  dialogues,
  dialogueId,
  setDialogueId,
  connectToDialogHandler,
  setSkipVisitorData,
  visitorNewMessagesCount,
  activeDialogueList,
  clearViews,
}) => {
  const { navigate, dictionary, inDialogue } = useContext(PageContext);
  const { dialogueMessagesArray, setDialogueMessagesArray, hash } =
    useContext(PageContainerContext);

  useEffect(() => {
    if (hash.includes('#dialogue') && dialogueId) {
      const id = hash.slice(hash.indexOf('_') + 1);

      const isExist = dialogueMessagesArray.some(
        (item) => item.dialog.id === id
      );

      if (dialogueMessagesArray.length !== 0 && isExist) {
        setDialogueMessagesArray([
          ...dialogueMessagesArray.filter((item) => item.dialog.id !== id),
        ]);
      }
    }
  }, [hash, dialogueId, dialogueMessagesArray]);

  useEffect(() => {
    if (
      inDialogue &&
      dialogueId &&
      type === 'active' &&
      visitorNewMessagesCount
    ) {
      clearViews(dialogueId, dialogues);
    }
  }, [inDialogue, dialogueId, visitorNewMessagesCount, type]);

  const chooseDialogueHandler = (id) => {
    if (dialogueId === id) {
      setDialogueId('');

      // if (window.location.hash) {
      //     window.history.replaceState('', document.title, window.location.pathname);
      // }

      // needed to remove the hash from PopupMessage useEffect. Otherwise, it will contain information about
      // the dialogue that was clicked
      if (type === 'active') {
        navigate('/dialogues/active');
        setSkipVisitorData(true);
      } else {
        navigate('/dialogues/waiting');
      }
    } else {
      setDialogueId(id);

      if (type === 'active') {
        navigate(`/dialogues/active#dialogue_${id}`);
        clearViews(id, dialogues);
      } else {
        navigate(`/dialogues/waiting#dialogue_${id}`);
      }
    }
  };

  const swapTimeAndDate = (last_message_at) => {
    return dateConverter(last_message_at).split(',').reverse().join(' ');
  };

  return (
    <>
      {dialogues.map(
        ({
          id,
          fullname,
          site,
          new_messages_count,
          code,
          city,
          isIncognito,
          last_message_at,
          last_message_text,
          last_message_is_file,
          created_at,
        }) => {
          return (
            <FlexBox
              className={`row-20 ${dialogueId === id && 'active'}`}
              sx={{
                padding: '10px 16px',
                alignItems: 'center',
                justifyContent: 'space-between',

                backgroundColor: 'basicColor.color100',
                borderRadius: 1.5,
                transition: 'color 250ms, background-color 250ms',
                cursor: 'pointer',
                '& .MuiTypography-root': {
                  fontSize: 12,
                },
                '& .fi': {
                  width: 21,
                },
                '& .MuiTypography-root.user-info': {
                  fontSize: 14,
                },
                '&:not(:last-of-type)': {
                  marginBottom: 1.5,
                },
                '& svg': {
                  marginRight: 1.75,
                },
                '&:hover, &.active': {
                  backgroundColor: 'secondaryColor.main',
                  '.MuiTypography-root': {
                    color: 'basicColor.colorWhite',
                  },
                  '.counter': {
                    backgroundColor: 'basicColor.colorWhite',
                    color: 'secondaryColor.main',
                  },
                  'svg path': {
                    fill: '#ffffff',
                  },
                },
              }}
              key={id}
              onClick={(e) => {
                if (!e.target.closest('svg')) {
                  chooseDialogueHandler(id);
                }
              }}
            >
              <FlexBox
                className="col-6"
                sx={{
                  maxWidth: type === 'waiting' ? 370 : 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                <FlexBox className="row-10" alignItems="center">
                  {!isIncognito && (
                    <span
                      className={`fi ${
                        code ? 'fi-' + code?.toLowerCase() : ''
                      }`}
                    ></span>
                  )}
                  <Typography
                    className="user-info"
                    sx={{
                      fontWeight: 600,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    variant="body2"
                  >
                    {city || ''} {fullname}
                  </Typography>
                  <Typography sx={{ marginLeft: 'auto' }} variant="body2">
                    {created_at && <TimeOnline createdAt={created_at} />}
                  </Typography>
                </FlexBox>
                <FlexBox justifyContent="space-between">
                  <Typography
                    sx={{
                      lineHeight: 1.667,
                      color: 'secondaryColor.main',
                    }}
                  >
                    {site?.domain}
                  </Typography>
                  <Typography sx={{ lineHeight: 1.667 }}>
                    {swapTimeAndDate(last_message_at)}
                  </Typography>
                </FlexBox>
                <Typography
                  sx={{
                    display: 'flex',
                    gap: 1,
                    lineHeight: 1.667,
                    '& *': {
                      margin: 0,
                      fontSize: 12,
                    },
                    '& span :not(:first-child), &  li:not(:first-of-type)': {
                      display: 'none',
                    },
                    '& span, & span p:first-of-type': {
                      overflow: 'hidden',
                    },
                    '& ol': {
                      paddingLeft: 1.5,
                    },
                    '& span p:first-of-type, & li': {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  {last_message_is_file ? (
                    dictionary?.file
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{ __html: last_message_text }}
                    ></span>
                  )}
                </Typography>
              </FlexBox>
              <FlexBox>
                {type === 'waiting' && (
                  <MyTooltip position={'top'} title={dictionary?.connect}>
                    <AddIcon
                      className="icon"
                      onClick={(e) => {
                        console.log(1313, id);
                        if (e.target.closest('svg')) {
                          connectToDialogHandler(
                            id,
                            activeDialogueList,
                            dialogues
                          );
                        }
                      }}
                    />
                  </MyTooltip>
                )}
                <Counter>{new_messages_count}</Counter>
              </FlexBox>
            </FlexBox>
          );
        }
      )}
    </>
  );
};

export default DialoguesList;

const TimeOnline = ({ createdAt }) => {
  const date = new Date();
  const timestamp = Math.round(date.getTime() / 1000);
  const MINUTES = 60;

  const [time, setTime] = useState(
    Math.floor((timestamp - createdAt) / MINUTES)
  );

  useEffect(() => {
    const interval = setInterval(() => setTime((prev) => prev + 1), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{time}&nbsp;min</>;
};
