import { apiSlice } from '../apiSlice';

const apiCategoriesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: (query) =>
                `admin/support/categories${query.limit}${query.page}${query.sortBy}${query.sortOrder}`,
            providesTags: ['Categories'],
        }),
        changeCategorieActivity: builder.mutation({
            query: (categorie) => ({
                url: `admin/support/categories/${categorie.id}/${categorie.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Categories'],
        }),
        createCategorie: builder.mutation({
            query: (categorie) => ({
                url: 'admin/support/categories',
                method: 'POST',
                body: categorie,
            }),
            invalidatesTags: ['Categories'],
        }),
        getCategorie: builder.query({
            query: (id) => ({
                url: `admin/support/categories/${id}`,
            }),
            providesTags: ['Categories'],
        }),
        editCategorie: builder.mutation({
            query: (categorie) => ({
                url: `admin/support/categories/${categorie.id}`,
                method: 'PUT',
                body: categorie.body,
            }),
            invalidatesTags: ['Categories'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCategoriesQuery,
    useChangeCategorieActivityMutation,
    useCreateCategorieMutation,
    useGetCategorieQuery,
    useEditCategorieMutation,
} = apiCategoriesSlice;
