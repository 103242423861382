export const basic = {
    100: '#f5f6fa',
    200: '#e8f0f6',
    300: '#e7e8ec',
    400: '#e3e6f0',
    500: '#dfe2ee',
    600: '#aab1c1',
    700: '#9197a5',
    800: '#5e6470',
    900: '#595f6b',
    main: '#363f48',
    white: '#ffffff',
    black: '#000000',
    disabled: '#d1e2ed',
};

export const secondary = {
    main: '#196ca7',
    light: '#2383c8',
};

export const accent = {
    first: '#6cb351',
    firstLight: '#8dcc74',
    second: '#fd4d5c',
    secondLight: '#f78e98',
    third: '#4789b9',
    thirdLight: '#75aed7',
    fourth: '#ff932f',
    fifth: '#327cb1',
};

export const ACCEPTED_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain'
];

export const disallowedKeys = [
    'Backspace',
    'Delete',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Control',
    'Alt',
    'Shift',
    'Meta',
    'Tab',
    'CapsLock',
    'Escape',
    'Enter',
    'Home',
    'End',
    'PageUp',
    'PageDown',
    'Insert',
    'ContextMenu',
    'ScrollLock',
    'Pause',
    'NumLock',
    'PrintScreen',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12',
];