import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import {
  setToken,
  setLogin,
  setRole,
  setIndexPath,
} from '../../reducers/authorizationSlice';

import { Typography } from '@mui/material';

import FormContainer from './FormContainer';
import PasswordInput from './FormInputs/PasswordInput';
import GeneralInput from './FormInputs/GeneralInput';
import Title from '../ui/Title/Title';

import { ReactComponent as Logo } from '../../assets/logo-en.svg';

import { usePassAuthorizationMutation } from '../../api/apiAuthorizationSlice';

import { adminAuthorizationSchema as schema } from '../../settings/formSchema';

import { PageContext } from '../../pages/Page';

const AdminPartAuthForm = ({ dictionary }) => {
  const { setServerValidationError, alertMessageContent } =
    useContext(PageContext);

  const [checkPass, { isFetching, isLoading }] = usePassAuthorizationMutation();

  const dispatch = useDispatch();

  const formSubmitHandler = useCallback((data) => {
    checkPass({ data, path: 'admin' })
      .unwrap()
      .then((res) => {
        if (res?.data?.success) {
          dispatch(setToken(res?.data?.token));
          dispatch(setLogin(res?.data?.user?.login));
          dispatch(setRole(res?.data?.user?.role));
          dispatch(setIndexPath('admin'));
        } else {
          switch (res?.message) {
            case 'Login failed.':
              setServerValidationError(['login', 'password']);
              alertMessageContent(dictionary?.wrong_login, 'error');
              break;
            default:
              alertMessageContent(dictionary?.something_go_wrong, 'error');
          }
        }
      })
      .catch((error) => {
        switch (error?.data?.message) {
          case 'User is blocked':
            setServerValidationError(['login']);
            alertMessageContent(dictionary?.account_blocked, 'error');
            break;
          default:
            alertMessageContent(dictionary?.something_go_wrong, 'error');
        }
      });
  }, []);

  return (
    <FormContainer
      schema={schema}
      submitButtonTitle={dictionary?.enter}
      submitButtonClass={'btn--primary btn--big'}
      formClass={'form--main'}
      formSubmitHandler={formSubmitHandler}
      isLoading={isLoading}
      isFetching={isFetching}
    >
      <Logo className="logo" />
      <Title heading="h3" title={dictionary?.authorization} />
      <Typography variant="body1">
        {dictionary?.hello} <br /> {dictionary?.enter_to_start}
      </Typography>
      <GeneralInput fieldName={'login'} label={dictionary?.login} />
      <PasswordInput fieldName={'password'} label={dictionary?.password} />
    </FormContainer>
  );
};

export default AdminPartAuthForm;
