import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl } from '@mui/material';
import Switcher from './SwitcherTemplate';

const ExpandedSwitcher = ({ fieldName, label, checked, setChecked, receivedValue }) => {
    const { control, setValue } = useFormContext();

    useEffect(() => {
        if (receivedValue !== undefined) {
            if (typeof checked === 'object') {
                setChecked({
                    ...checked,
                    [fieldName]: receivedValue,
                });
                setValue(fieldName, receivedValue);
            } else {
                setChecked(receivedValue);
                setValue(fieldName, receivedValue);
            }
        }
    }, [receivedValue]);
    const handleChange = () => {
        if (typeof checked === 'object') {
            setChecked({ ...checked, [fieldName]: !checked[fieldName] });
            setValue(fieldName, !checked[fieldName]);
        } else {
            setChecked(!checked);
            setValue(fieldName, !checked);
        }
    };

    return (
        <FormControl>
            <Controller
                name={fieldName}
                control={control}
                render={({ field: { ref } }) => (
                    <Switcher
                        label={label}
                        checked={typeof checked === 'object' ? checked[fieldName] : checked}
                        onClick={() => handleChange()}
                        inputRef={ref}
                    />
                )}
            ></Controller>
        </FormControl>
    );
};

export default ExpandedSwitcher;
