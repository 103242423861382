import { apiSlice } from '../apiSlice';

const apiUsersSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (query = '') => ({
                url: `/admin/users${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.search}`,
            }),
            providesTags: ['Users'],
        }),
        changeUserStatus: builder.mutation({
            query: (user) => ({
                url: `admin/users/${user.id}/${user.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Users'],
        }),
        createUser: builder.mutation({
            query: (user) => ({
                url: 'admin/users',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['Users'],
        }),
        getUser: builder.query({
            query: (id) => ({
                url: `admin/users/${id}`,
            }),
            providesTags: ['Users'],
        }),
        editUser: builder.mutation({
            query: (user) => ({
                url: `admin/users/${user.id}`,
                method: 'PUT',
                body: user.body,
            }),
            invalidatesTags: ['Users'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetUsersQuery,
    useChangeUserStatusMutation,
    useCreateUserMutation,
    useGetUserQuery,
    useEditUserMutation,
} = apiUsersSlice;
