import { useContext, useLayoutEffect, useEffect } from 'react';
import { useGetSiteQuery } from '../../../../api/clientPartApi/apiSitesSlice';

import Section from '../../../../layouts/Section/Section';
import SitesForm from './SitesForm';

import { getFromLocalStorage } from '../../../../helpers/functions';

import { PageContext } from '../../../Page';
import { PageContainerContext } from '../../../PageContainer';

const Site = () => {
    const { dictionary } = useContext(PageContext);
    const { content, changeContentHandler, selectedElementId } = useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (!content && getFromLocalStorage('site')?.id) {
            changeContentHandler('editSite');
        } else if (!content && !getFromLocalStorage('site')?.id) {
            changeContentHandler('createSite');
        }
    }, [content]);

    function beforeUnloadHandler(e) {
        e.preventDefault();
        e.returnValue = '';
    }

    useEffect(() => {
        if (content === 'editSite') {
            window.addEventListener('beforeunload', beforeUnloadHandler, true);

            return () => window.removeEventListener('beforeunload', beforeUnloadHandler, true);
        }
    }, [content]);

    const {
        data: selectedSite,
        isLoading: selectedSiteLoading,
        isFetching: selectedSiteFetching,
        isSuccess: selectedSiteSuccess,
        isError: selectedSiteFetchingError,
        error: selectedSiteError,
    } = useGetSiteQuery(
        content === 'editSite' ? selectedElementId : ''
        //  {
        //     skip: !skip,
        // }
    );

    const site = selectedSiteSuccess ? selectedSite?.data : '';

    return (
        <>
            <Section
                key={site.id === selectedElementId}
                isLoading={selectedSiteLoading}
                isFetching={selectedSiteFetching}
                isError={selectedSiteFetchingError}
                error={selectedSiteError}
                title={content === 'createSite' ? dictionary?.create_site : dictionary?.edit_site}
                sx={{ maxWidth: 730 }}
                content={content}
            >
                <SitesForm content={content} site={site} />
            </Section>
        </>
    );
};

export default Site;
