import { useContext } from 'react';
import { useChangeCategorieActivityMutation } from '../../../api/adminPartApi/apiCategoriesSlice';

import { TableBody, TableRow, TableCell } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as TurnOnIcon } from '../../../assets/icons/turn-on.svg';
import { ReactComponent as TurnOffIcon } from '../../../assets/icons/turn-off.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setCategoriesTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const CategoriesList = ({
    categoriesList,
    categoriesSuccess,
    changeContentHandler,
    paginationData,
}) => {
    const { navigate, dictionary, language } = useContext(PageContext);

    const [changeCategorieActivity] = useChangeCategorieActivityMutation();

    const changeActivityHandler = (id, action) => {
        changeCategorieActivity({ id, action }).unwrap();
    };

    return (
        <>
            <MyToolbar
                paginationData={paginationData}
                buttonHandler={() => {
                    changeContentHandler('createCategorie', '', '', 'support');
                    navigate('/admin/support/categories#create');
                }}
            />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {categoriesList.length === 0 && categoriesSuccess && <EmptyListAlertText />}
                    {categoriesList.length > 0 &&
                        categoriesList.map(({ id, name, active }, index) => {
                            return (
                                <TableRow className="table-main__row" key={id + index}>
                                    <TableCell>{name?.[language]}</TableCell>
                                    <TableCell className="action">
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <EditIcon
                                                onClick={() => {
                                                    changeContentHandler(
                                                        'editCategorie',
                                                        id,
                                                        true,
                                                        'support'
                                                    );
                                                    navigate(
                                                        `/admin/support/categories#categorie_${id}`
                                                    );
                                                }}
                                            />
                                        </MyTooltip>
                                        <MyTooltip
                                            position={'top'}
                                            title={
                                                active ? dictionary?.turn_off : dictionary?.turn_on
                                            }
                                        >
                                            {active ? (
                                                <TurnOffIcon
                                                    onClick={() =>
                                                        changeActivityHandler(id, 'disable')
                                                    }
                                                />
                                            ) : (
                                                <TurnOnIcon
                                                    onClick={() =>
                                                        changeActivityHandler(id, 'enable')
                                                    }
                                                />
                                            )}
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Categories'} />
        </>
    );
};

export default CategoriesList;
