import { useCallback, useContext } from 'react';

import {
    useCreateUserMutation,
    useEditUserMutation,
} from '../../../api/adminPartApi/apiUsersSlice';

import FormContainer from '../../../components/Form/FormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import PasswordInput from '../../../components/Form/FormInputs/PasswordInput';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';

import { setAdminPartRoleSelect as setOptions } from '../../../data/formSelectData';
import { createUserSchema, editUserSchema } from '../../../settings/formSchema';

import { PageContext } from '../../Page';

const UsersForm = ({ content, user }) => {
    const { alertMessageContent, setServerValidationError, dictionary } = useContext(PageContext);

    const [
        createUser,
        {
            isLoading: createdUserLoading,
            isFetching: createdUserFetching,
            isSuccess: createdUserSuccess,
        },
    ] = useCreateUserMutation();

    const [editUser, { isLoading: editedUserLoading, isFetching: editedUserFetching }] =
        useEditUserMutation();

    const showServerError = (error) => {
        if (error) {
            setServerValidationError(['login']);
            alertMessageContent(dictionary?.user_login_exists, 'error');
        } else {
            alertMessageContent(dictionary?.something_go_wrong, 'error');
        }
    };

    const formSubmitHandler = useCallback(
        (data) => {
            const userData = {
                login: data.login,
                password: data.password,
                role: data.role,
            };

            if (content === 'createUser') {
                createUser(userData)
                    .unwrap()
                    .then((res) => alertMessageContent(dictionary?.user_created, 'success'))
                    .catch((error) => {
                        showServerError(error?.data?.errors?.login);
                    });
            } else {
                editUser({
                    id: user.id,
                    body: {
                        ...userData,
                        password: data.editedPassword ? data.editedPassword : null,
                    },
                })
                    .unwrap()
                    .then((res) => alertMessageContent(dictionary?.user_edited, 'success'))
                    .catch((error) => showServerError(error?.data?.errors?.login));
            }
        },
        [user]
    );

    return (
        <>
            <FormContainer
                schema={user ? editUserSchema : createUserSchema}
                submitButtonTitle={user ? dictionary?.save : dictionary?.create}
                submitButtonClass={'btn--primary'}
                formSubmitHandler={formSubmitHandler}
                cleareForm={createdUserSuccess}
                isLoading={createdUserLoading || editedUserLoading}
                isFetching={createdUserFetching || editedUserFetching}
            >
                <GeneralInput
                    fieldName={'login'}
                    label={dictionary?.login}
                    receivedValue={user?.login}
                />
                <PasswordInput
                    fieldName={user ? 'editedPassword' : 'password'}
                    label={dictionary?.password}
                />
                <SelectWithFormContext
                    fieldName={'role'}
                    label={dictionary?.role}
                    labelId={'roleSelect'}
                    receivedValue={user?.role}
                    options={setOptions(dictionary)}
                />
            </FormContainer>
        </>
    );
};

export default UsersForm;
