import { useState, useContext, useLayoutEffect } from 'react';
import { useGetPaymentHistoryQuery } from '../../../api/clientPartApi/apiPaymentHistorySlice';

import { v4 as uuidv4 } from 'uuid';

import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FlexBox } from '../../../mui/theme';

import Section from '../../../layouts/Section/Section';
import PaymentAccordion from './PaymentAccordion';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { setPaymendHistoryTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { dateConverter, setFunctionalItemValue } from '../../../helpers/functions';

const PaymentHistory = () => {
    const { dictionary, language } = useContext(PageContext);
    const { createSlug, queryParams, content, changeContentHandler, pathname } =
        useContext(PageContainerContext);

    const [expanded, setExpanded] = useState(false);

    useLayoutEffect(() => {
        if (pathname === '/payment' && content !== 'payment') {
            changeContentHandler('payment');
        }
    }, [pathname, content]);

    const {
        data: paymentHistory,
        isLoading: paymentHistoryLoading,
        isFetching: paymentHistoryFetching,
        isSuccess: paymentHistorySuccess,
        isError: paymentHistoryFetchingError,
        error: paymentHistoryError,
    } = useGetPaymentHistoryQuery({
        page: createSlug('page'),
        sortBy: createSlug('sort_by'),
        sortOrder: createSlug('sort_order'),
        rangeStart: createSlug('range_start'),
        rangeEnd: createSlug('range_end'),
    });

    const paymentHistoryList = paymentHistory?.data?.payments?.data || [];
    const paymentHistoryListPagination = paymentHistory?.data?.payments || [];
    const paymentsTotal = paymentHistory?.data?.payments?.payments_total || '';

    const tableBodyStyles = {
        '& .MuiTableRow-root:not(:last-of-type)': {
            marginBottom: 1.25,
        },
        '& .MuiTableCell-root:nth-of-type(1)': {
            flex: '1 1 auto',
        },
        '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3)': {
            maxWidth: 80,
            width: '100%',
        },
    };

    return (
        <Section
            isLoading={paymentHistoryLoading}
            isFetching={paymentHistoryFetching}
            isError={paymentHistoryFetchingError}
            error={paymentHistoryError}
            title={dictionary?.payment_history}
            sx={{ maxWidth: 920 }}
        >
            <MyToolbar filter={true} changeContentButton={false} />
            {queryParams.has('range_start') && paymentHistoryList.length > 0 && (
                <FlexBox className="row-10" sx={{ marginBottom: 3.75 }}>
                    <Typography>{dictionary?.total_for_period}</Typography>
                    <Typography fontWeight={500} color="secondaryColor.main">
                        {paymentsTotal} UAH
                    </Typography>
                </FlexBox>
            )}
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {paymentHistoryList.length === 0 && paymentHistorySuccess && (
                        <EmptyListAlertText />
                    )}
                    {paymentHistoryList.length > 0 &&
                        paymentHistoryList.map(({ id, date, functional }) => {
                            return (
                                <TableRow
                                    className="table-main__row"
                                    sx={{ verticalAlign: 'top' }}
                                    key={id}
                                >
                                    <TableCell sx={{ width: '16.31%' }}>
                                        {dateConverter(date, 'date')}
                                    </TableCell>
                                    <TableCell sx={{ width: '83.69%' }}>
                                        <PaymentAccordion
                                            id={id}
                                            functional={functional}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                        >
                                            <Table>
                                                <TableBody sx={{ ...tableBodyStyles }}>
                                                    {functional.length > 0 &&
                                                        functional.map(
                                                            ({ name, price, type, value }) => {
                                                                return (
                                                                    <TableRow
                                                                        className="table-main__row--flex"
                                                                        key={uuidv4()}
                                                                        sx={{
                                                                            '& .MuiTableCell-root:not(:first-of-type)':
                                                                                {
                                                                                    color: 'secondaryColor.main',
                                                                                },
                                                                        }}
                                                                    >
                                                                        <TableCell>
                                                                            {name?.[language]}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                justifyContent:
                                                                                    'flex-start',
                                                                            }}
                                                                        >
                                                                            {setFunctionalItemValue(
                                                                                value,
                                                                                type,
                                                                                dictionary
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                fontWeight: 500,
                                                                                justifyContent:
                                                                                    'flex-end',
                                                                            }}
                                                                        >
                                                                            {price} UAH
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </PaymentAccordion>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paymentHistoryListPagination} invalidateTag={'Payment History'} />
        </Section>
    );
};

export default PaymentHistory;
