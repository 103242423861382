export const setSoundData = (dictionary) => {
    return [
        {
            id: 'sound1',
            title: dictionary?.new_appeal,
            description: dictionary?.new_appeal_sound,
        },
        {
            id: 'sound2',
            title: dictionary?.accepted_appeal,
            description: dictionary?.accepted_appeal_sound,
        },
        {
            id: 'sound3',
            title: dictionary?.new_dialogue_message,
            description: dictionary?.new_dialogue_message_sound,
        },
        {
            id: 'sound4',
            title: dictionary?.new_visitor,
            description: dictionary?.new_visitor_sound,
        },
        {
            id: 'sound5',
            title: dictionary?.visitor_close_dialogue,
            description: dictionary?.visitor_close_dialogue_sound,
        },
        {
            id: 'sound6',
            title: dictionary?.support_notification,
            description: dictionary?.support_notification_sound,
        },
    ];
};
