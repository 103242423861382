import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: 'cors',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authorizationSlice?.token;

            if (token) {
                headers.set('Authorization', 'Bearer ' + token);
            }

            headers.set('Cache-control', 'no-cache');
            headers.set('Accept', 'application/json');
            return headers;
        },
    }),
    tagTypes: [
        'Users',
        'Functional',
        'Clients',
        'Notifications',
        'Categories',
        'Appeals',
        'Tariff',
        'Payment History',
        'Personal Information',
        'Channels',
        'Employees',
        'Visitors',
        'Dialogues',
        'Dialogues Tags',
        'Commands',
        'Commands Categories',
        'Answers',
    ],
    endpoints: () => ({}),
});
