import { useContext } from 'react';

import EmployeesList from '../../clientPartPages/Employees/EmployeesList';
import ClientData from './ClientData';

import { PageContainerContext } from '../../PageContainer';

const ClientEmployees = ({
    clientId,
    employee,
    employees,
    employeesList,
    paginationData,
    employeesSuccess,
    copy,
    copiedText,
    actionType,
}) => {
    const { hash } = useContext(PageContainerContext);

    return (
        <>
            {!hash && (
                <EmployeesList
                    userRole={'sadmin'}
                    employeeAdminId={clientId}
                    employees={employees}
                    employeesList={employeesList}
                    paginationData={paginationData}
                    employeesSuccess={employeesSuccess}
                />
            )}
            {hash && (
                <ClientData
                    employeeAdminId={clientId}
                    client={employee}
                    copy={copy}
                    copiedText={copiedText}
                    actionType={actionType}
                />
            )}
        </>
    );
};

export default ClientEmployees;
