import { useContext } from 'react';
import { useGetCurrentDialogueTagsQuery } from '../../../../api/clientPartApi/apiDialoguesSlice';

import MyButton from '../../../../components/ui/Button/MyButton';
import MyTooltip from '../../../../components/ui/Tooltip/MyTooltip';
import BlockVisitorPopper from './BlockVisitorPopper';
import PassDialogPopper from './PassDialogPopper';
import AddTagPopper from './AddTagPopper';
import { FlexBox } from '../../../../mui/theme';

import { ReactComponent as ResolvedIcon } from '../../../../assets/icons/confirmed.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add.svg';
import { ReactComponent as ContactsRequestIcon } from '../../../../assets/icons/contacts-request.svg';

import { PageContext } from '../../../Page';

const ChatToolbar = ({
    blackList,
    dialogueId,
    dialogueStatus,
    visitorStatus,
    changeTags,
    alertMessageContent,
    showVisitorData,
    setShowVisitorData,
    skipVisitorData,
    setSkipVisitorData,
    passDialogueHandler,
    connectToDialogHandler,
    changeDialogueStatusHandler,
    changeVisitorStatusHandler,
    selectedDialogueSuccess,
    visitorContactsRequest,
    sendForm,
}) => {
    const { role, setAlertMessage, dictionary } = useContext(PageContext);

    const { data: tags, isSuccess: tagsSuccess } = useGetCurrentDialogueTagsQuery(dialogueId, {
        skip: dialogueStatus !== 'unresolved',
    });

    const tagsList = tagsSuccess ? tags?.data?.tags?.data?.map(({ name }) => name) : [];

    const visitorContactsRequestHandler = () => {
        setAlertMessage({});

        visitorContactsRequest(dialogueId)
            .unwrap()
            .then((res) => {
                alertMessageContent(dictionary?.contact_information_success, 'success');
            })
            .catch((error) => {
                alertMessageContent(dictionary?.contact_information_error, 'error');
            });
    };

    return (
        <>
            <FlexBox className="row-20" justifyContent="space-between" sx={{ marginBottom: 2.5 }}>
                <MyButton
                    title={dictionary?.visitor}
                    buttonClasses={'btn--dialogues-small'}
                    buttonHandler={() => {
                        setAlertMessage({});
                        setShowVisitorData(!showVisitorData);
                        setSkipVisitorData(!skipVisitorData);
                    }}
                />
                <FlexBox
                    className="row-10"
                    sx={{
                        '& svg': {
                            width: 40,
                            height: 40,
                            padding: 1.125,

                            backgroundColor: 'basicColor.color100',
                            borderRadius: 1.25,

                            cursor: 'pointer',
                            transition: ' background-color 250ms',
                            opacity: dialogueStatus === 'unresolved' ? '1' : 0.4,
                            pointerEvents: dialogueStatus === 'unresolved' ? 'all' : 'none',

                            '&:hover': {
                                backgroundColor: 'basicColor.color200',
                            },
                        },
                        '& svg.request': {
                            opacity: sendForm && dialogueStatus === 'unresolved' ? '1' : 0.4,
                            pointerEvents:
                                sendForm && dialogueStatus === 'unresolved' ? 'all' : 'none',
                        },
                        '& svg:first-of-type': {
                            padding: 0.85,
                        },
                        '& svg:first-of-type path': {
                            fill: '#6CB351',
                        },
                    }}
                >
                    <MyTooltip position={'top'} title={dictionary?.resolved}>
                        <ResolvedIcon onClick={() => changeDialogueStatusHandler()} />
                    </MyTooltip>
                    <PassDialogPopper
                        dialogueId={dialogueId}
                        dialogueStatus={dialogueStatus}
                        passDialogueHandler={passDialogueHandler}
                    />
                    {role !== 'op' && (
                        <MyTooltip position={'top'} title={dictionary?.connect}>
                            <AddIcon onClick={() => connectToDialogHandler(dialogueId)} />
                        </MyTooltip>
                    )}
                    <AddTagPopper
                        dialogueId={dialogueId}
                        dialogueStatus={dialogueStatus}
                        changeTags={changeTags}
                        setAlertMessage={setAlertMessage}
                        alertMessageContent={alertMessageContent}
                        tagsList={tagsList}
                    />
                    <MyTooltip position={'top'} title={dictionary?.contact_information_request}>
                        <ContactsRequestIcon
                            className="request"
                            onClick={() => visitorContactsRequestHandler()}
                        />
                    </MyTooltip>
                    {blackList && selectedDialogueSuccess && (
                        <BlockVisitorPopper
                            visitorStatus={visitorStatus}
                            changeVisitorStatusHandler={changeVisitorStatusHandler}
                        />
                    )}
                </FlexBox>
            </FlexBox>
        </>
    );
};

export default ChatToolbar;
