import { useContext } from 'react';
import { useChangeFunctionalStatusMutation } from '../../../api/adminPartApi/apiFunctionalSlice';

import { TableBody, TableRow, TableCell, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyToolbar from '../../../components/Toolbar/MyToolbar';

import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg';
import { ReactComponent as UnarchiveIcon } from '../../../assets/icons/unarchive.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setStatusLabel } from '../../../helpers/functions';

import { setFunctionalTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const FunctionalList = ({ functionalList, changeContentHandler, paginationData }) => {
    const { navigate, dictionary, language } = useContext(PageContext);

    const [changeFunctionalStatus] = useChangeFunctionalStatusMutation();

    const changeStatusHandler = (id, action) => {
        changeFunctionalStatus({ id, action }).unwrap();
    };

    return (
        <>
            <MyToolbar paginationData={paginationData} changeContentButton={false} />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {functionalList.map(({ id, name, status }) => {
                        return (
                            <TableRow className="table-main__row" key={id}>
                                <TableCell>{name?.[language]}</TableCell>
                                <TableCell className="status">
                                    <Typography className={status}>
                                        {setStatusLabel(status, dictionary)}
                                    </Typography>
                                </TableCell>
                                <TableCell className="action">
                                    <MyTooltip position={'top'} title={dictionary?.edit}>
                                        <EditIcon
                                            onClick={() => {
                                                changeContentHandler('editFunctional', id, true);
                                                navigate(`/admin/functional#functional_${id}`);
                                            }}
                                        />
                                    </MyTooltip>
                                    <MyTooltip
                                        position={'top'}
                                        title={
                                            status === 'archived'
                                                ? dictionary?.unarchive
                                                : dictionary?.archive
                                        }
                                    >
                                        {status === 'archived' ? (
                                            <UnarchiveIcon
                                                onClick={() => changeStatusHandler(id, 'unarchive')}
                                            />
                                        ) : (
                                            <ArchiveIcon
                                                onClick={() => changeStatusHandler(id, 'archive')}
                                            />
                                        )}
                                    </MyTooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Functional'} />
        </>
    );
};

export default FunctionalList;
