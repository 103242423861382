import { useContext, useLayoutEffect } from 'react';
import { useGetUsersQuery, useGetUserQuery } from '../../../api/adminPartApi/apiUsersSlice';

import Section from '../../../layouts/Section/Section';
import UsersForm from './UsersForm';
import UsersList from './UsersList';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Users = () => {
    const { dictionary } = useContext(PageContext);
    const { createSlug, content, changeContentHandler, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (hash === '#create' && content !== 'createUser') {
            changeContentHandler('createUser');
        } else if (hash.includes('#user') && content !== 'editUser') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('editUser', id, true);
        } else if (!hash && content !== 'usersList') {
            changeContentHandler('usersList');
        }
    }, [hash]);

    const {
        data: users,
        isLoading: usersLoading,
        isFetching: usersFetching,
        isSuccess: usersSuccess,
        isError: usersFetchingError,
        error: usersError,
    } = useGetUsersQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q'),
        },
        { skip }
    );

    const {
        data: selectedUser,
        isLoading: selectedUserLoading,
        isFetching: selectedUserFetching,
        isError: selectedUserFetchingError,
    } = useGetUserQuery(content === 'editUser' ? selectedElementId : '', {
        skip: content !== 'editUser',
    });

    const usersList = users?.data?.users?.data || [];
    const user = selectedUser?.data?.user || '';
    const usersListPaginationData = users?.data?.users || {};

    return (
        <Section
            isLoading={usersLoading || selectedUserLoading}
            isFetching={usersFetching || selectedUserFetching}
            isError={usersFetchingError || selectedUserFetchingError}
            error={usersError}
            title={
                content === 'usersList'
                    ? dictionary?.users_title_1
                    : content === 'createUser'
                    ? dictionary?.users_title_2
                    : dictionary?.users_title_3
            }
            content={content}
            sx={{ maxWidth: content === 'usersList' ? 920 : 540 }}
        >
            {(!content || content === 'usersList') && (
                <UsersList
                    usersList={usersList}
                    usersSuccess={usersSuccess}
                    changeContentHandler={changeContentHandler}
                    paginationData={usersListPaginationData}
                />
            )}
            {(content === 'createUser' || content === 'editUser') && (
                <UsersForm content={content} user={content === 'editUser' ? user : ''} />
            )}
        </Section>
    );
};

export default Users;
