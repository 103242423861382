import { Box, Button, TextField, Typography } from '@mui/material';

const ChangeValueInput = ({
    label,
    id,
    maxValue,
    minValue,
    step = 1,
    units = '',
    value,
    setValue,
    objectWithStates,
}) => {
    const textFieldStyles = {
        '& .MuiFormLabel-root': {
            left: '50%',

            fontSize: 12,
            lineHeight: 1.333,

            transform: 'translate(-50%, -2px) ',
        },
        '& .MuiInputBase-root': {
            pointerEvents: 'none',
        },
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
        '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'basicColor.color700',
            borderRadius: 0,
            borderLeft: 0,
            borderRight: 0,
        },
        '& fieldset': {
            top: 0,
        },
        '&:hover fieldset': {
            borderColor: '#9197a5!important',
        },
        '& legend': {
            display: 'none',
        },
    };

    const boxStyles = {
        display: 'flex',
        maxWidth: 300,
        '& button': {
            minWidth: 40,
            width: 40,
            height: 40,

            fontWeight: 400,
            fontSize: 26,

            alignItems: 'center',
            color: 'basicColor.colorWhite',

            '&:hover': {
                backgroundColor: 'secondaryColor.light',
            },
        },
        '& .MuiTypography-root': {
            fontSize: 26,
            lineHeight: 1,
        },
    };

    const incrementValue = () => {
        if (value === maxValue) {
            return;
        }

        if (objectWithStates) {
            setValue({ ...objectWithStates, [id]: value + step });
        } else {
            setValue((prev) => prev + step);
        }
    };

    const decrementValue = () => {
        if (value === minValue) {
            return;
        }

        if (objectWithStates) {
            setValue({ ...objectWithStates, [id]: value - step });
        } else {
            setValue((prev) => prev - step);
        }
    };

    // const changeValueHandler = (value) => {
    //     if (value > maxValue || value < initialValue || isNaN(value)) {
    //         return;
    //     }
    //     setValue(Number(value));
    // };

    return (
        <Box sx={{ ...boxStyles }}>
            <Button
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => decrementValue()}
                disabled={value === minValue ? true : false}
            >
                <Typography
                    sx={{
                        position: 'relative',
                        top: -1,
                    }}
                >
                    -
                </Typography>
            </Button>
            <TextField
                sx={{ ...textFieldStyles }}
                id={id}
                label={label}
                value={value + units || ''}
                // onChange={(e) => changeValueHandler(e.target.value)}
                focused
            />
            <Button
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => incrementValue()}
                disabled={value === maxValue ? true : false}
            >
                <Typography>+</Typography>
            </Button>
        </Box>
    );
};

export default ChangeValueInput;
