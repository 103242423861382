import { useContext } from 'react';

import { Typography } from '@mui/material';
import { FlexBox } from '../../../mui/theme';

import MultiplePhoneSelect from '../../../components/Form/FormSelect/PhoneSelect/MultiplePhoneSelect';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import { PageContext } from '../../Page';

const MAX_PHONES_VALUE = 5;

const VisitorPhonesForm = ({
    phones,
    setPhones,
    phonesValidationErrors,
    setPhonesValidationErrors,
    dropdownWidth,
    showErrors,
    receivedValue,
    isSubmited,
}) => {
    const { dictionary } = useContext(PageContext);
    const objToArray = (obj) => Object.assign([], Object.values(obj));

    const flexBoxStyles = {
        borderRadius: 1.25,
        transition: 'background-color 250ms',
        cursor: 'pointer',
    };

    const addPhoneHandler = (index) => {
        if (objToArray(phones).length < MAX_PHONES_VALUE) {
            const newPhone = {
                phone: '',
                phone_data: {},
            };
            setPhones({ ...phones, [index + 1]: newPhone });
        } else return;
    };

    const removePhoneHandler = (deletedPhoneIndex) => {
        let newPhones = { ...phones };
        delete newPhones[deletedPhoneIndex];

        setPhones({ ...objToArray(newPhones) });
        setPhonesValidationErrors({
            ...phonesValidationErrors,
            [`phone${deletedPhoneIndex + 1}`]: false,
        });
    };

    const renderIcons = (index) => {
        return (
            <>
                {index > 0 && (
                    <FlexBox
                        sx={{
                            ...flexBoxStyles,
                            padding: 1.125,
                            backgroundColor: 'colorAccent.secondLight',

                            '& svg path': {
                                fill: '#ffffff',
                            },
                            '&:hover': {
                                backgroundColor: 'colorAccent.second',
                            },
                        }}
                        onClick={() => removePhoneHandler(index)}
                    >
                        <DeleteIcon />
                    </FlexBox>
                )}
                {objToArray(phones).length === index + 1 &&
                    objToArray(phones).length < MAX_PHONES_VALUE && (
                        <FlexBox
                            sx={{
                                ...flexBoxStyles,
                                alignItems: 'center',
                                padding: '9px 11px',
                                backgroundColor: 'basicColor.color500',
                                pointerEvents: objToArray(phonesValidationErrors).some(
                                    (error) => error
                                )
                                    ? 'none'
                                    : 'all',
                                opacity: objToArray(phonesValidationErrors).some((error) => error)
                                    ? 0.5
                                    : 1,
                                '& svg': {
                                    width: 18,
                                    height: 18,
                                },
                                '& svg path': {
                                    fill: '#363f48',
                                },
                                '&:hover': {
                                    backgroundColor: 'secondaryColor.main',
                                    'svg path': {
                                        fill: '#ffffff',
                                    },
                                },
                            }}
                            onClick={() => addPhoneHandler(index)}
                        >
                            <AddIcon />
                        </FlexBox>
                    )}
            </>
        );
    };

    return (
        <FlexBox className="col-20">
            <Typography variant="body3" sx={{ color: 'secondaryColor.main' }}>
                {dictionary?.phones}
            </Typography>
            {objToArray(phones).map(({ phone }, index) => {
                return (
                    <FlexBox className="row-10" key={index}>
                        <MultiplePhoneSelect
                            fieldName={`phone${index + 1}`}
                            dropdownWidth={dropdownWidth}
                            receivedValue={
                                receivedValue.length !== 0 && receivedValue[index]?.phone
                                    ? receivedValue[index]?.phone
                                    : ''
                            }
                            phones={phones}
                            setPhones={setPhones}
                            index={index}
                            phonesValidationErrors={phonesValidationErrors}
                            setPhonesValidationErrors={setPhonesValidationErrors}
                            showErrors={showErrors}
                            isSubmited={isSubmited}
                        />
                        {renderIcons(index)}
                    </FlexBox>
                );
            })}
        </FlexBox>
    );
};

export default VisitorPhonesForm;
