import { useCallback, useState, useEffect, useContext } from 'react';
import { useEditVisitorMutation } from '../../../api/clientPartApi/apiVisitosSlice';

import { Typography } from '@mui/material';
import { FlexBox } from '../../../mui/theme';

import FormContainer from '../../../components/Form/FormContainer';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';
import VisitorPhonesForm from './VisitorPhonesForm';
import MyButton from '../../../components/ui/Button/MyButton';

import { editVisitorSchema } from '../../../settings/formSchema';

import { PageContext } from '../../Page';

const EditVisitor = ({ visitor, block, changeContentHandler }) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    const [phones, setPhones] = useState({
        0: {
            phone: '',
            phone_data: {},
        },
    });
    const [phonesValidationErrors, setPhonesValidationErrors] = useState({
        phone1: false,
        phone2: false,
        phone3: false,
        phone4: false,
        phone5: false,
    });
    const [showErrors, setShowErrors] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);

    const [editVisitor, { isLoading: editedVisitorLoading, isFetching: editedVisitorFetching }] =
        useEditVisitorMutation();

    useEffect(() => {
        if (visitor) {
            if (visitor?.phones?.length !== 0) {
                setPhones({ ...visitor?.phones });
            } else {
                setPhones({
                    0: {
                        phone: '',
                        phone_data: {},
                    },
                });
            }
        }
    }, [visitor]);

    const formSubmitHandler = useCallback(
        (data) => {
            const phonesErrorsArray = Object.assign([], Object.values(phonesValidationErrors));
            let phonesValidationValue = phonesErrorsArray.every((error) => !error);
            const phonesArray = Object.assign([], Object.values(phones));

            // if first phone is empty in dialogues and he is the only one - pass the validation
            if (
                phonesArray.length === 1 &&
                (!phonesArray[0].phone ||
                    phonesArray[0].phone === phonesArray[0].phone_data?.country?.dialCode)
            ) {
                phonesValidationValue = true;
            }

            setShowErrors(true);

            const editedVisitor = {
                surname: data.surname,
                name: data.name,
                patronymic: data.patronymic,
                email: data.email,
                phones:
                    phonesArray[0].phone &&
                    phonesArray[0].phone !== phonesArray[0].phone_data?.country?.dialCode
                        ? phonesArray
                        : null,
                messengers: {
                    telegram: data?.telegram || null,
                    viber: data?.viber || null,
                    messenger: data.messenger || null,
                },
            };

            if (phonesValidationValue) {
                setShowErrors(false);

                editVisitor({ id: visitor.id, body: editedVisitor })
                    .unwrap()
                    .then((res) => {
                        if (block === 'dialogues') {
                            changeContentHandler('viewVisitor', dictionary?.visitor);
                        }
                        setIsSubmited(false);
                        alertMessageContent(dictionary?.visitor_edited, 'success');
                    })
                    .catch((error) => {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    });
            }
        },
        [phones, visitor, phonesValidationErrors, showErrors]
    );

    return (
        <FormContainer
            // key={visitor}
            schema={editVisitorSchema}
            submitButtonTitle={dictionary?.save}
            submitButtonClass={block === 'dialogues' ? 'btn--dialogues-big' : 'btn--primary'}
            submitButtonHandler={() => setIsSubmited(true)}
            useButtonHandler={true}
            formClass={block === 'dialogues' ? 'form--dialogues' : ''}
            formInnerSx={{ gap: 3.75 }}
            formSubmitHandler={formSubmitHandler}
            spinnerSx={
                block === 'dialogues'
                    ? { height: 'calc(100% + 164px)', top: -134, borderRadius: 0 }
                    : {}
            }
            isLoading={editedVisitorLoading}
            isFetching={editedVisitorFetching}
        >
            <FlexBox className="col-20">
                <GeneralInput
                    fieldName={'surname'}
                    label={dictionary?.surname}
                    receivedValue={visitor?.surname}
                />
                <GeneralInput
                    fieldName={'name'}
                    label={dictionary?.first_name}
                    receivedValue={visitor?.name}
                />
                <GeneralInput
                    fieldName={'patronymic'}
                    label={dictionary?.patronymic}
                    receivedValue={visitor?.patronymic}
                />
                <GeneralInput fieldName={'email'} label={'E-mail'} receivedValue={visitor?.email} />
            </FlexBox>
            <VisitorPhonesForm
                visitor={visitor}
                phones={phones}
                setPhones={setPhones}
                phonesValidationErrors={phonesValidationErrors}
                setPhonesValidationErrors={setPhonesValidationErrors}
                dropdownWidth={block === 'dialogues' ? 290 : 380}
                showErrors={showErrors}
                receivedValue={visitor?.phones ? visitor?.phones : []}
                isSubmited={isSubmited}
            />
            <FlexBox className="col-20">
                <Typography variant="body3" sx={{ color: 'secondaryColor.main' }}>
                    {dictionary?.messengers}
                </Typography>
                <GeneralInput
                    fieldName={'telegram'}
                    label={dictionary?.telegram}
                    receivedValue={visitor?.messengers?.telegram}
                />
                <GeneralInput
                    fieldName={'viber'}
                    label={dictionary?.viber}
                    receivedValue={visitor?.messengers?.viber}
                />
                <GeneralInput
                    fieldName={'messenger'}
                    label={dictionary?.messenger}
                    receivedValue={visitor?.messengers?.messenger}
                />
            </FlexBox>
            {block === 'dialogues' && (
                <MyButton
                    title={dictionary?.cancel_form}
                    buttonClasses={'btn--dialogues-big btn--cancel'}
                    buttonHandler={() => changeContentHandler('viewVisitor', dictionary?.visitor)}
                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                />
            )}
        </FormContainer>
    );
};

export default EditVisitor;
