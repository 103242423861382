import { useState, useContext } from 'react';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import { Table, TableBody, TableRow, TableCell, Box, FormControl } from '@mui/material';

import { useLocation } from 'react-router-dom';

import Title from '../../../components/ui/Title/Title';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyLink from '../../../components/ui/Link/MyLink';
import BaseSelect from '../../../components/Form/FormSelect/Select/BaseSelect';
import { FlexBox } from '../../../mui/theme';

import { Chat } from '../../../components/Chat/Chat';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';

import { setAdminPartAppealStatusSelect as setOptions } from '../../../data/formSelectData';

import { setStatusLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const ViewAppeal = ({ id, appeal, appealCategory, changeStatusHandler }) => {
    const { alertMessageContent, clientCheck, dictionary, language } = useContext(PageContext);

    const { subject, fullname, status, id: clientId, role } = appeal;

    const domain = 'https://chat.dev.stgstudio.com';

    const [statusValue, setStatusValue] = useState('');
    const [copiedText, copy] = useCopyToClipboard();

    const location = useLocation();

    const newStatusOptions = setOptions(dictionary).slice(1, 4);

    const tableBodyStyles = {
        '& .MuiTableRow-root': {
            gap: 1.25,
        },
        '& .MuiTableRow-root:not(:last-of-type)': {
            marginBottom: 1.25,
        },
        '& .MuiTableRow-root:last-of-type': {
            marginBottom: 2.5,
        },
        '& .MuiTableCell-root': {
            '& .copy-icon': {
                cursor: 'pointer',
            },
        },
        '& .MuiTableCell-root:nth-of-type(1)': {
            marginRight: 2,
            minWidth: 55,

            fontWeight: 700,
        },
    };

    const MenuProps = {
        PaperProps: {
            sx: {
                paddingTop: 1.25,
                border: 'none',
                overflow: 'visible',
                '& .MuiList-root': {
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.10)',
                    borderRadius: 2,
                    padding: 1.5,
                },
                '& .MuiMenuItem-root': {
                    fontWeight: 600,
                    justifyContent: 'center',
                    borderRadius: 1.25,
                },
                '& .MuiMenuItem-root:not(:last-of-type)': {
                    marginBottom: 0.75,
                },
            },
        },
    };

    const roleByPath = location.pathname.includes('admin') ? 'admin' : 'client';

    return (
        <>
            {status && !clientCheck && (
                <FlexBox
                    className="row-20"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: 2.5 }}
                >
                    <Title heading="h3" title={appealCategory?.name?.[language]} />
                    <FormControl
                        sx={{
                            width: 180,
                            backgroundColor: 'basicColor.color100',
                            borderRadius: 1.25,
                            '& .MuiInputBase-root': {
                                fontWeight: 600,
                            },
                            '& fieldset': {
                                border: 'none!important',
                            },

                            '& legend': {
                                display: 'none',
                            },
                        }}
                    >
                        <BaseSelect
                            selectSx={{
                                '& .MuiSelect-select .notranslate::after':
                                    status === 'new'
                                        ? {
                                              content: `"${dictionary?.new}"`,
                                              display: 'inline-flex',
                                          }
                                        : {},
                            }}
                            label={'status'}
                            labelId={'statusSelect'}
                            value={!statusValue && status !== 'new' ? status : statusValue}
                            MenuProps={MenuProps}
                            onChangeHandler={(e) => {
                                setStatusValue(e.target.value);
                                changeStatusHandler(e.target.value);
                            }}
                            options={newStatusOptions}
                        />
                    </FormControl>
                </FlexBox>
            )}
            <Table className="table-main--small">
                <TableBody sx={{ ...tableBodyStyles }}>
                    <TableRow className="table-main__row--flex">
                        <TableCell>ID:</TableCell>
                        <TableCell>{id}</TableCell>
                        <TableCell>
                            <MyTooltip position={'top'} title={dictionary?.copy}>
                                <CopyIcon
                                    className="copy-icon"
                                    onClick={() => {
                                        if (copiedText === undefined) {
                                            copy(id);
                                            alertMessageContent(dictionary?.data_copied, 'success');
                                        }
                                    }}
                                />
                            </MyTooltip>
                        </TableCell>
                    </TableRow>
                    {!clientCheck && (
                        <>
                            <TableRow className="table-main__row--flex">
                                <TableCell>{dictionary?.subject}:</TableCell>
                                <TableCell>{subject}</TableCell>
                            </TableRow>
                            <TableRow className="table-main__row--flex">
                                <TableCell>{dictionary?.client}:</TableCell>
                                {fullname && (
                                    <TableCell sx={{ color: 'secondaryColor.main' }}>
                                        <MyLink
                                            link={`${domain}/admin/clients/client_${role}-${clientId}?tab=0`}
                                            text={fullname}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        </>
                    )}
                    {clientCheck && (
                        <TableRow className="table-main__row--flex">
                            <TableCell>Статус:</TableCell>
                            <TableCell>{setStatusLabel(status, dictionary)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Box
                sx={{
                    height: 640,
                    borderColor: '#D1E2ED',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: 1.25,
                }}
            >
                <Chat key={id} chatId={id} role={roleByPath} type="support" />
            </Box>
        </>
    );
};

export default ViewAppeal;
