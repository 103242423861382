import { useCallback, useEffect, useLayoutEffect, useContext, useState } from 'react';
import {
    useCreateNotificationMutation,
    useEditNotificationMutation,
} from '../../../api/adminPartApi/apiNotificationsSlice';
import { useGetAllFunctionalQuery } from '../../../api/adminPartApi/apiFunctionalSlice';

import { Box } from '@mui/system';

import FormContainer from '../../../components/Form/FormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ExpandedSwitcher from '../../../components/ui/Switcher/ExpandedSwitcher';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';
import LanguageTabs from '../../../components/Tabs/LanguageTabs';
import TextEditorWithFormContext from '../../../components/TextEditor/TextEditorWithFormContext';

import { setRoleSelect as setOptions } from '../../../data/formSelectData';
import { createNotoficationSchema as schema } from '../../../settings/formSchema';

import { setSelectLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const NotificationsForm = ({
    content,
    notification,
    selectedNotificationSuccess,
    formSwitcherValue,
    setFormSwitcherValue,
}) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    const [selectedFunctionalId, setSelectedFunctionalId] = useState('');

    const switcherData = [
        { id: 'evaluation_switcher', label: dictionary?.evaluation },
        { id: 'feedback_switcher', label: dictionary?.comments },
    ];

    useLayoutEffect(() => {
        if (notification) {
            setFormSwitcherValue({
                evaluation_switcher: notification?.evaluation,
                feedback_switcher: notification?.feedback,
            });
            if (!notification?.functional?.id) {
                setSelectedFunctionalId('allFunctional');
            }
        } else {
            setFormSwitcherValue({
                evaluation_switcher: false,
                feedback_switcher: false,
            });
        }
    }, [notification]);

    const {
        data: functional,
        isLoading: functionalLoading,
        isFetching: functionalFetching,
        isSuccess: functionalSuccess,
        isError: functionalFetchingError,
    } = useGetAllFunctionalQuery();

    const [
        createNotification,
        {
            isLoading: createdNotificationLoading,
            isFetching: createdNotificationFetching,
            isSuccess: createdNotificationSuccess,
        },
    ] = useCreateNotificationMutation();

    const [
        editNotification,
        { isLoading: editedNotificationLoading, isFetching: editedNotificationFetching },
    ] = useEditNotificationMutation();

    useEffect(() => {
        if (selectedNotificationSuccess && functionalSuccess) {
            setFormSwitcherValue({
                evaluation_switcher: notification?.evaluation,
                feedback_switcher: notification?.feedback,
            });
        }

        if (createdNotificationSuccess) {
            setFormSwitcherValue({
                evaluation_switcher: false,
                feedback_switcher: false,
            });
        }
    }, [functionalSuccess, selectedNotificationSuccess, createdNotificationSuccess, notification]);

    const formSubmitHandler = useCallback(
        (data) => {
            const setSelectedFunctional = () => {
                if (data?.functional === 'allFunctional') {
                    return {};
                } else {
                    return {
                        functional: {
                            id: data?.functional,
                            value: data?.functionalValue,
                        },
                    };
                }
            };

            const notificationData = {
                name: {
                    uk: data?.title_uk,
                    en: data?.title_en,
                    ru: data?.title_ru,
                },
                content: {
                    uk: data?.editor_uk,
                    en: data?.editor_en,
                    ru: data?.editor_ru,
                },
                role: data.role,
                evaluation: data?.evaluation_switcher || false,
                feedback: data?.feedback_switcher || false,
                ...setSelectedFunctional(),
            };

            if (content === 'createNotification') {
                createNotification(notificationData)
                    .unwrap()
                    .then((res) => {
                        alertMessageContent(dictionary?.notification_created, 'success');
                        setSelectedFunctionalId('');
                    })
                    .catch((error) => {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    });
            } else {
                editNotification({ id: notification?.id, body: notificationData })
                    .unwrap()
                    .then((res) => alertMessageContent(dictionary?.notification_edited, 'success'))
                    .catch((error) => {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    });
            }
        },
        [notification]
    );

    const setFunctionalSelect = (functional) => {
        return [
            {
                id: 'allFunctional',
                name: {
                    en: 'All functional',
                    ru: 'Весь функционал',
                    uk: 'Весь функціонал',
                },
            },
            ...functional,
        ];
    };

    const setReceivedValueOfFunctional = (notification) => {
        if (notification?.functional?.id) {
            return notification?.functional?.id;
        } else if (
            Array.isArray(notification?.functional) &&
            notification?.functional.length === 0
        ) {
            return 'allFunctional';
        } else {
            return '';
        }
    };

    return (
        <FormContainer
            schema={schema}
            submitButtonTitle={notification ? dictionary?.save : dictionary?.create}
            submitButtonClass={'btn--primary'}
            formSubmitHandler={formSubmitHandler}
            cleareForm={createdNotificationSuccess}
            isLoading={createdNotificationLoading || editedNotificationLoading}
            isFetching={createdNotificationFetching || editedNotificationFetching}
            useValidationErrors={true}
        >
            <LanguageTabs
                tabContent={'notificationsMultiLanguageFields'}
                receivedValue={notification}
                fieldNamePrefix={['title_', 'editor_']}
            />
            <SelectWithFormContext
                fieldName={'functional'}
                label={setSelectLabel(
                    functionalFetching,
                    functionalLoading,
                    functionalFetchingError,
                    dictionary?.functional,
                    dictionary
                )}
                labelId={'functionalSelect'}
                options={functionalSuccess ? setFunctionalSelect(functional?.data) : ''}
                receivedValue={functionalSuccess ? setReceivedValueOfFunctional(notification) : ''}
                useSelectedValueImmediatly={true}
                setSelectedValue={setSelectedFunctionalId}
            />
            <GeneralInput
                fieldName={'functionalValue'}
                label={dictionary?.value}
                receivedValue={
                    selectedFunctionalId === 'allFunctional' ? '' : notification?.functional?.value
                }
                type={'number'}
                disabled={selectedFunctionalId === 'allFunctional'}
                clearReceivedValue={selectedFunctionalId === 'allFunctional'}
            />
            <SelectWithFormContext
                fieldName={'role'}
                label={dictionary?.role}
                labelId={'roleSelect'}
                receivedValue={notification?.role ? notification?.role : 'all'}
                options={setOptions(dictionary)}
            />
            {switcherData.map(({ id, label }) => {
                return (
                    <ExpandedSwitcher
                        key={id}
                        fieldName={id}
                        label={label}
                        checked={formSwitcherValue}
                        setChecked={setFormSwitcherValue}
                        receivedValue={formSwitcherValue[id]}
                    />
                );
            })}
        </FormContainer>
    );
};

export default NotificationsForm;

export const NotificationsMultiLanguageFields = ({ language, receivedValue }) => {
    const { dictionary } = useContext(PageContext);

    return (
        <>
            <Box sx={{ marginBottom: 2.5 }}>
                <GeneralInput
                    fieldName={'title_' + language}
                    label={dictionary?.title}
                    receivedValue={receivedValue ? receivedValue?.name[language] : ''}
                />
            </Box>
            <Box>
                <TextEditorWithFormContext
                    fieldName={'editor_' + language}
                    receivedValue={receivedValue ? receivedValue?.content[language] : ''}
                />
            </Box>
        </>
    );
};
