import { apiSlice } from './apiSlice';

const apiPasswordSettingsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        changePassword: builder.mutation({
            query: (data) => ({
                url: data.url,
                method: 'POST',
                body: data.body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useChangePasswordMutation } = apiPasswordSettingsSlice;
