import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Spinner from '../../components/ui/Spinner/Spinner';
import MainLayoutInner from './MainLayoutInner';

const MainLayout = () => {
    return (
        <MainLayoutInner>
            <Sidebar />
            <Header />
            <Suspense fallback={<Spinner lazy={true} sx={{ height: 'calc(100vh - 120px)' }} />}>
                <Outlet />
            </Suspense>
        </MainLayoutInner>
    );
};

export default MainLayout;
