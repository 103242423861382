import { useState, useContext, useLayoutEffect } from 'react';

import {
    useGetSoundsQuery,
    useChangeSoundStatusMutation,
} from '../../api/clientPartApi/apiSoundsSlice';

import { setSoundStatus, setSounds } from '../../reducers/authorizationSlice';

import { FormControl, Typography, Box } from '@mui/material';
import { FlexBox } from '../../mui/theme';

import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';

import Section from '../../layouts/Section/Section';
import Title from '../../components/ui/Title/Title';
import BaseSwitcher from '../../components/ui/Switcher/BaseSwitcher';
import BaseSelect from '../../components/Form/FormSelect/Select/BaseSelect';

import { PageContext } from '../../pages/Page';

import { setSoundData } from '../../data/soundData';

const SoundNotificationsForm = () => {
    const { dictionary, soundStatus, sounds, dispatch, alertMessageContent, language } =
        useContext(PageContext);

    const [soundSwitcher, setSoundSwitcher] = useState(soundStatus);
    const [selectedSounds, setSelectedSounds] = useState({});

    const {
        data: soundsData,
        isLoading: soundsLoading,
        isFetching: soundsFetching,
        isError: soundsFetchingError,
        isSuccess: soundsSuccess,
    } = useGetSoundsQuery();

    const [
        changeSoundStatus,
        { isLoading: changedSoundStatusLoading, isFetching: changedSoundStatusFatching },
    ] = useChangeSoundStatusMutation();

    const soundsList = soundsSuccess ? soundsData?.data?.list : [];
    const defaultSounds = soundsSuccess ? soundsData?.data?.defaults : [];

    useLayoutEffect(() => {
        let soundsId = {};

        for (const [key, { id }] of Object.entries(sounds)) {
            soundsId = { ...soundsId, [key]: id };
        }

        setSelectedSounds(soundsId);
    }, []);

    const onChangeHandler = (e, id) => {
        const sound = soundsList.find(({ id }) => id === e.target.value);

        dispatch(
            setSounds({
                ...sounds,
                [id]: {
                    id: sound.id,
                    url: sound.url,
                },
            })
        );

        setSelectedSounds({ ...selectedSounds, [id]: e.target.value });

        const body = {
            ...selectedSounds,
            [id]: e.target.value,
            soundStatus: !soundStatus === true ? 1 : 0,
        };

        changeSoundStatus(body)
            .unwrap()
            .then((res) => {})
            .catch((error) => {
                alertMessageContent(dictionary?.something_go_wrong, 'error');
            });
    };

    const playSelectedSound = (id) => {
        const currentSound = soundsList.find((sound) => sound.id === selectedSounds[id]);
        const audio = new Audio(currentSound.url);

        audio.play();
    };

    const changeSoundStatusState = () => {
        setSoundSwitcher(!soundSwitcher);
        dispatch(setSoundStatus(!soundSwitcher));

        const body = {
            ...selectedSounds,
            soundStatus: !soundStatus === true ? 1 : 0,
        };

        changeSoundStatus(body)
            .unwrap()
            .then((res) => {})
            .catch((error) => {
                setSoundSwitcher(!soundSwitcher);
                dispatch(setSoundStatus(!soundSwitcher));
                alertMessageContent(dictionary?.something_go_wrong, 'error');
            });
    };

    return (
        <Section
            sx={{ maxWidth: 730 }}
            isLoading={soundsLoading || changedSoundStatusLoading}
            isFetching={soundsFetching || changedSoundStatusFatching}
            isError={soundsFetchingError}
        >
            <Title
                heading="h3"
                title={dictionary?.notifications}
                sx={{ color: 'secondaryColor.main', marginBottom: 2.5 }}
            />
            <Box sx={{ marginBottom: 2.5 }}>
                <Typography fontWeight={700} lineHeight="normal" sx={{ marginBottom: 2.125 }}>
                    {dictionary?.turn_on_sound}
                </Typography>
                <BaseSwitcher
                    switcherClass={'switcher'}
                    label={soundSwitcher ? dictionary?.on : dictionary?.off}
                    checked={soundSwitcher}
                    setChecked={setSoundSwitcher}
                    useSomeFunction={true}
                    switcherFunction={() => changeSoundStatusState()}
                />
            </Box>
            {JSON.stringify(selectedSounds) !== '{}' && soundsList.length !== 0 && (
                <FlexBox className="col-40">
                    {setSoundData(dictionary).map(({ id, title, description }) => {
                        return (
                            <FlexBox className="col-17" key={id}>
                                <Typography fontWeight={700} lineHeight="normal">
                                    {title}
                                </Typography>
                                <Typography fontWeight={500} lineHeight="normal">
                                    {description}
                                </Typography>
                                <FlexBox
                                    className="row-17"
                                    alignItems="center"
                                    sx={{
                                        '& > svg': {
                                            cursor: 'pointer',
                                            pointerEvents:
                                                selectedSounds[id] === 'mute' ? 'none' : 'all',
                                            opacity: selectedSounds[id] === 'mute' ? 0.5 : 1,
                                        },
                                        '& > svg path': {
                                            transition: 'fill 0.250s',
                                        },
                                        '&:hover > svg path': {
                                            fill:
                                                selectedSounds[id] === 'mute'
                                                    ? '#2383c8'
                                                    : '#196ca7 !important',
                                        },
                                    }}
                                >
                                    <FormControl>
                                        <BaseSelect
                                            displayEmpty
                                            onChangeHandler={(e) => onChangeHandler(e, id)}
                                            options={soundsList.map((item, { name }) => {
                                                if (item.id === defaultSounds[id]) {
                                                    return {
                                                        ...item,
                                                        name: dictionary?.default,
                                                    };
                                                } else if (item.id === 'mute') {
                                                    return {
                                                        ...item,
                                                        name: dictionary?.mute,
                                                    };
                                                } else {
                                                    return { ...item };
                                                }
                                            })}
                                            value={
                                                selectedSounds[id]?.id
                                                    ? selectedSounds[id]?.id
                                                    : selectedSounds[id]
                                            }
                                        />
                                    </FormControl>
                                    <PlayIcon onClick={() => playSelectedSound(id)} />
                                </FlexBox>
                            </FlexBox>
                        );
                    })}
                </FlexBox>
            )}
        </Section>
    );
};

export default SoundNotificationsForm;
