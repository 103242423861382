import { useState, useEffect, useContext } from 'react';

import { Box } from '@mui/material';

import LanguageTabs from '../../../../components/Tabs/LanguageTabs';
import ExpandedSwitcher from '../../../../components/ui/Switcher/ExpandedSwitcher';

import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';

import { PageContext } from '../../../Page';

export const FifthStep = ({
  savedSite,
  clickOnSubmitButton,
  setClickOnSubmitButton,
}) => {
  const [messageSwitcherValue, setMessageSwitcherValue] = useState({
    first_switcher:
      savedSite?.visitor_messages?.[0]?.active !== undefined
        ? savedSite?.visitor_messages?.[0]?.active
        : false,
    second_switcher:
      savedSite?.visitor_messages?.[1]?.active !== undefined
        ? savedSite?.visitor_messages?.[1]?.active
        : false,
    third_switcher:
      savedSite?.visitor_messages?.[2]?.active !== undefined
        ? savedSite?.visitor_messages?.[2]?.active
        : false,
  });

  return (
    <LanguageTabs
      tabContent={'fifthStep'}
      fieldNamePrefix={[
        'first_button_text_',
        'first_user_message_',
        'second_button_text_',
        'second_user_message_',
        'third_button_text_',
        'third_user_message_',
      ]}
      receivedValue={savedSite}
      maxNumOfCharacters={'30'}
      tabsSx={{ position: 'absolute', top: -2, right: 0 }}
      messageSwitcherValue={messageSwitcherValue}
      setMessageSwitcherValue={setMessageSwitcherValue}
      savedLanguages={savedSite?.languages}
      clickOnSubmitButton={clickOnSubmitButton}
      setClickOnSubmitButton={setClickOnSubmitButton}
    />
  );
};

export const FifthStepContent = ({
  language,
  receivedValue,
  messageSwitcherValue,
  setMessageSwitcherValue,
  tabError,
  clickOnSubmitButton,
  setClickOnSubmitButton,
}) => {
  const { alertMessageContent, dictionary } = useContext(PageContext);

  const switcherData = [
    { id: 'first', label: `${dictionary?.quick_message} 1` },
    { id: 'second', label: `${dictionary?.quick_message} 2` },
    { id: 'third', label: `${dictionary?.quick_message} 3` },
  ];

  useEffect(() => {
    if (tabError && clickOnSubmitButton) {
      alertMessageContent(dictionary?.localization_error, 'error');
      setClickOnSubmitButton(false);
    }
  }, [tabError, clickOnSubmitButton]);

  return (
    <>
      {switcherData.map(({ id, label }, index) => {
        return (
          <Box
            key={id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2.25,
              marginBottom: 3.75,
            }}
          >
            <ExpandedSwitcher
              fieldName={id + '_switcher'}
              label={label}
              checked={messageSwitcherValue}
              setChecked={setMessageSwitcherValue}
              receivedValue={messageSwitcherValue[id + '_switcher']}
            />
            {messageSwitcherValue[id + '_switcher'] && (
              <>
                <GeneralInput
                  fieldName={id + '_button_text_' + language}
                  label={dictionary?.button_text}
                  receivedValue={
                    receivedValue?.visitor_messages?.[index]?.button?.[language]
                  }
                />
                <GeneralInput
                  fieldName={id + '_user_message_' + language}
                  label={dictionary?.user_message}
                  receivedValue={
                    receivedValue?.visitor_messages?.[index]?.message?.[
                      language
                    ]
                  }
                  multiline={true}
                  sx={{
                    '& textarea': {
                      height: '120px!important',
                    },
                  }}
                />
              </>
            )}
          </Box>
        );
      })}
    </>
  );
};
