import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, InputLabel } from '@mui/material';
import BaseSelect from './BaseSelect';

const SelectWithFormContext = ({
    fieldName,
    label,
    labelId,
    options,
    receivedValue = '',
    disabled,
    subItem,
    selectSx = {},
    emtyOption,
    setSelectedValue,
    useSelectedValueImmediatly = false,
}) => {
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
    } = useFormContext();

    useEffect(() => {
        if ((receivedValue || getValues(fieldName)) && receivedValue !== getValues(fieldName)) {
            setValue(fieldName, receivedValue);
        }
    }, [receivedValue]);

    const onChangeHandler = (e) => {
        setValue(fieldName, e.target.value, true);
        clearErrors(fieldName);

        if (useSelectedValueImmediatly) {
            setSelectedValue(e.target.value);
        }
    };

    return (
        <FormControl>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { field, ref } }) => (
                    <BaseSelect
                        {...field}
                        value={getValues(fieldName) || ''}
                        label={label}
                        labelId={labelId}
                        options={
                            emtyOption
                                ? [
                                      { id: '', name: 'None', sx: { color: 'transparent' } },
                                      ...options,
                                  ]
                                : options
                        }
                        inputRef={ref}
                        subItem={subItem}
                        error={!!errors[fieldName]}
                        onChangeHandler={onChangeHandler}
                        disabled={disabled ? disabled : false}
                        selectSx={selectSx}
                    />
                )}
            />
            <InputLabel id={labelId} error={!!errors[fieldName]}>
                {label}
            </InputLabel>
        </FormControl>
    );
};

export default SelectWithFormContext;
