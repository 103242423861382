import { useState, useContext, useLayoutEffect } from 'react';
import {
    useGetNotificationsQuery,
    useGetNotificationQuery,
} from '../../../api/adminPartApi/apiNotificationsSlice';

import Section from '../../../layouts/Section/Section';
import NotificationsList from './NotificationsList';
import NotificationsForm from './NotificationsForm';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Notifications = () => {
    const [formSwitcherValue, setFormSwitcherValue] = useState({
        evaluation_switcher: false,
        feedback_switcher: false,
    });

    const { dictionary } = useContext(PageContext);
    const { content, changeContentHandler, createSlug, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (hash === '#create' && content !== 'createNotification') {
            changeContentHandler('createNotification');
        } else if (hash.includes('#notification') && content !== 'editNotification') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('editNotification', id, true);
        } else if (!hash && content !== 'notificationsList') {
            changeContentHandler('notificationsList');
        }
    }, [hash]);

    const {
        data: notifications,
        isFetching: notificationsFetching,
        isLoading: notificationsLoading,
        isSuccess: notificationsSuccess,
        isError: notificationsFetchingError,
        error: notificationsError,
    } = useGetNotificationsQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            status: createSlug('status'),
            range_start: createSlug('range_start'),
            range_end: createSlug('range_end'),
            count: createSlug('count'),
        },
        { skip }
    );

    const {
        data: selectedNotification,
        isFetching: selectedNotificationFetching,
        isLoading: selectedNotificationLoading,
        isSuccess: selectedNotificationSuccess,
        isError: selectedNotificationFetchingError,
    } = useGetNotificationQuery(content === 'editNotification' ? selectedElementId : '', {
        skip: content !== 'editNotification',
    });

    const notificationsList = notifications?.data?.notifications?.data || [];
    const notification = selectedNotification?.data || '';
    const notificationsListPaginationData = notifications?.data?.notifications || {};

    return (
        <Section
            key={selectedNotification?.data?.id}
            isLoading={notificationsLoading || selectedNotificationLoading}
            isFetching={notificationsFetching || selectedNotificationFetching}
            isError={notificationsFetchingError || selectedNotificationFetchingError}
            error={notificationsError}
            title={
                content === 'notificationsList'
                    ? dictionary?.notifications_title_1
                    : content === 'createNotification'
                    ? dictionary?.notifications_title_2
                    : dictionary?.notifications_title_3
            }
            sx={{ maxWidth: content === 'notificationsList' ? 970 : 730 }}
            content={content}
        >
            {(!content || content === 'notificationsList') && (
                <NotificationsList
                    notificationsList={notificationsList}
                    notificationsSuccess={notificationsSuccess}
                    changeContentHandler={changeContentHandler}
                    paginationData={notificationsListPaginationData}
                />
            )}
            {(content === 'createNotification' || content === 'editNotification') && (
                <NotificationsForm
                    content={content}
                    notification={content === 'editNotification' ? notification : ''}
                    selectedNotificationSuccess={selectedNotificationSuccess}
                    formSwitcherValue={formSwitcherValue}
                    setFormSwitcherValue={setFormSwitcherValue}
                />
            )}
        </Section>
    );
};

export default Notifications;
