import { useContext, useState, useCallback } from 'react';
import { useGetAllAppealsCategoriesQuery } from '../../../api/apiAppealsSlice';
import { useGetClientsQuery } from '../../../api/adminPartApi/apiClientsSlice';

import { Grid } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import SearchSelect from '../../../components/Form/FormSelect/AutocompleteSelect/SearchSelect';
import MyDatePicker from '../../../components/DatePicker/DatePicker';

import { PageContainerContext } from '../../../pages/PageContainer';
import { PageContext } from '../../Page';

import { setSelectLabel, setFilterParams } from '../../../helpers/functions';

import {
    setRoleSelect as setRoleOptions,
    setAdminPartAppealStatusSelect as setStatusOptions,
} from '../../../data/formSelectData';

const currentQueryParams = [
    'category_id',
    'status',
    'role',
    'client_id',
    'range_start',
    'range_end',
];

const AppealFilter = ({ clearFilter, selectQuerySkip }) => {
    const { clientCheck, dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const [searchQuery, setSearchQuery] = useState('');

    const newRoleOptions = setRoleOptions(dictionary).slice(0, 3);

    const {
        data: categories,
        isFetching: categoriesFetching,
        isLoading: categoriesLoading,
        isSuccess: categoriesSuccess,
        isError: categoriesFetchingError,
    } = useGetAllAppealsCategoriesQuery(
        {
            part: clientCheck ? 'client' : 'admin',
            payload: clientCheck ? '?sort_by=name&sort_order=asc' : '/all',
        },
        { skip: selectQuerySkip }
    );

    const {
        data: clients,
        isFetching: clientsFetching,
        isLoading: clientsLoading,
        isSuccess: clientsSuccess,
        isError: clientsFetchingError,
    } = useGetClientsQuery(
        {
            limit: '?limit=5',
            search: searchQuery.replaceAll('%20', ' '),
            sortBy: '&sort_by=name',
            sortOrder: '&sort_order=asc',
        },
        { skip: selectQuerySkip || clientCheck }
    );

    const clientsList =
        clientsSuccess && searchQuery && !clientsFetching
            ? clients?.data?.accounts?.data.map(({ id, name }) => ({
                  id,
                  label: name,
              }))
            : [];

    const formSubmitHandler = useCallback(
        (data) => {
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    setFilterParams(data.category, 'category_id', params);
                    setFilterParams(data.status, 'status', params);

                    if (!clientCheck) {
                        setFilterParams(data.role, 'role', params);
                        setFilterParams(data.client?.id, 'client_id', params);
                        setFilterParams(
                            data.date ? data.date[0].trim() : '',
                            'range_start',
                            params
                        );
                        setFilterParams(data.date ? data.date[1].trim() : '', 'range_end', params);
                    }
                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={2.6855}>
                <SelectWithFormContext
                    fieldName={'category'}
                    label={setSelectLabel(
                        categoriesFetching,
                        categoriesLoading,
                        categoriesFetchingError,
                        dictionary?.category,
                        dictionary
                    )}
                    labelId={'categorySelect'}
                    options={categoriesSuccess ? categories?.data?.categories?.data : ''}
                    emtyOption={true}
                    receivedValue={queryParams.get('category_id')}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <SelectWithFormContext
                    fieldName={'status'}
                    label={dictionary?.status}
                    labelId={'statusSelect'}
                    options={setStatusOptions(dictionary)}
                    emtyOption={true}
                    receivedValue={queryParams.get('status')}
                />
            </Grid>
            {!clientCheck && (
                <>
                    <Grid item xs={2.6855}>
                        <SelectWithFormContext
                            fieldName={'role'}
                            label={dictionary?.role}
                            labelId={'roleSelect'}
                            options={newRoleOptions}
                            emtyOption={true}
                            receivedValue={queryParams.get('role')}
                        />
                    </Grid>
                    <Grid item xs={2.6855}>
                        <SearchSelect
                            fieldName={'client'}
                            label={
                                searchQuery
                                    ? dictionary?.client_full_name
                                    : setSelectLabel(
                                          clientsFetching,
                                          clientsLoading,
                                          clientsFetchingError,
                                          dictionary?.client_full_name,
                                          dictionary
                                      )
                            }
                            options={clientsList}
                            clearFilter={clearFilter}
                            setSearchQuery={setSearchQuery}
                            receivedValue={queryParams.get('client_id')}
                            isSuccess={clientsSuccess && !clientsFetching}
                        />
                    </Grid>
                    <Grid item xs={2.6855}>
                        <MyDatePicker
                            fieldName={'date'}
                            clearFilter={clearFilter}
                            placeholder={dictionary?.date}
                            withoutTime={true}
                            receivedValue={
                                queryParams.get('range_start')
                                    ? [queryParams.get('range_start'), queryParams.get('range_end')]
                                    : ''
                            }
                        />
                    </Grid>
                </>
            )}
        </FilterFormContainer>
    );
};

export default AppealFilter;
