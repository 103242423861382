import { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';

import { setContent } from '../../reducers/sectionSlice';

import { Box } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import MenuIcon from '@mui/icons-material/Menu';

import Navbar from '../Navbar/Navbar';
import LanguageTabs from '../../components/Tabs/LanguageTabs';
import { FlexBox } from '../../mui/theme';

import { ReactComponent as Logo } from '../../assets/logo-en.svg';
import { ReactComponent as CloseMenuIcon } from '../../assets/icons/close.svg';
import { createMuiComponent } from '../../mui/functions';

import { PageContext } from '../../pages/Page';

const MySidebar = createMuiComponent(Box, 'MuiSidebar');
const MySidebarButton = createMuiComponent(Box, 'MuiSidebarButton');

const Sidebar = () => {
  const { setExpandedAccordionOfNav, indexPath, navigate, dispatch } =
    useContext(PageContext);

  const [active, setActive] = useState(false);

  const removeAddedClasses = () => {
    if (window.innerWidth >= 1600) {
      document.body.classList.remove('lock');
      setActive(false);
    }
  };

  useEffect(() => {
    if (active) {
      document.body.classList.add('lock');

      window.addEventListener('resize', removeAddedClasses);

      return () => window.removeEventListener('resize', removeAddedClasses);
    } else {
      document.body.classList.remove('lock');
    }
  }, [active]);

  const activeClass = classNames('', {
    active,
  });

  const returnToTheHomePage = () => {
    dispatch(setContent(''));
    setExpandedAccordionOfNav('');
    navigate(`/${indexPath}`);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (active) {
          setActive(!active);
        }
      }}
    >
      <Box>
        <MySidebarButton
          className={activeClass}
          onClick={() => setActive(!active)}
        >
          {active ? (
            <CloseMenuIcon />
          ) : (
            <MenuIcon sx={{ color: 'basicColor.colorWhite' }} />
          )}
        </MySidebarButton>
        <MySidebar className={activeClass}>
          <Box sx={{ padding: '24px 24px 0 24px', marginBottom: 1.25 }}>
            <Logo style={{ cursor: 'pointer' }} onClick={returnToTheHomePage} />
          </Box>
          <FlexBox
            className="col-40"
            sx={{
              height: '100%',
              justifyContent: 'space-between',
              padding: '0 24px 24px 24px',
              overflow: 'auto',
              marginTop: 1.25,
            }}
          >
            <Navbar />
            <LanguageTabs
              onlyTabs={true}
              tabsClass={'tabs-language--sidebar'}
            />
          </FlexBox>
        </MySidebar>
      </Box>
    </ClickAwayListener>
  );
};

export default Sidebar;
