export const setUserButtons = (dictionary) => {
    return [
        {
            id: 'settings',
            styles: { marginBottom: 0.75, backgroundColor: 'basicColor.colorWhite' },
            title: dictionary?.settings,
        },
        {
            id: 'exit',
            styles: { backgroundColor: 'basicColor.color100' },
            title: dictionary?.exit,
        },
    ];
};

export const setBalanceButtons = (dictionary) => {
    return [
        {
            id: 'tariff',
            styles: { marginBottom: 0.75, backgroundColor: 'basicColor.colorWhite' },
            title: dictionary?.tariff,
        },
        {
            id: 'payment',
            styles: { marginBottom: 0.75, backgroundColor: 'basicColor.colorWhite' },
            title: dictionary?.payment_history,
        },
        {
            id: 'replenish',
            styles: { marginBottom: 0.75, backgroundColor: 'basicColor.colorWhite' },
            title: dictionary?.replenish,
        },
    ];
};
