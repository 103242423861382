import { createSlice } from '@reduxjs/toolkit';

const authorizationSlice = createSlice({
    name: 'auth',
    initialState: {
        token: '',
        login: '',
        role: '',
        clientId: '',
        language: '',
        indexPath: '',
        userOnlineSection: '',
        status: '',
        takeDialogues: false,
        workTime: false,
        sounds: {},
        soundStatus: false,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setLogin: (state, action) => {
            state.login = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setClientId: (state, action) => {
            state.clientId = action.payload;
        },
        setIndexPath: (state, action) => {
            state.indexPath = action.payload;
        },
        setUserOnlineSection: (state, action) => {
            state.userOnlineSection = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setTakeDialogues: (state, action) => {
            state.takeDialogues = action.payload;
        },
        setWorkTime: (state, action) => {
            state.workTime = action.payload;
        },
        setSounds: (state, action) => {
            state.sounds = action.payload;
        },
        setSoundStatus: (state, action) => {
            state.soundStatus = action.payload;
        },
    },
});

export const {
    setToken,
    setLogin,
    setRole,
    setClientId,
    setIndexPath,
    setPart,
    setUserOnlineSection,
    setLanguage,
    setStatus,
    setTakeDialogues,
    setWorkTime,
    setSounds,
    setSoundStatus,
} = authorizationSlice.actions;
export default authorizationSlice.reducer;
