export const setUsersTableHeadings = (dictionary) => {
    return [
        {
            id: 'login',
            title: dictionary?.login,
            width: '36.77%',
        },
        {
            id: 'role',
            title: dictionary?.role,
            width: '25.76%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '25.76%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.71%',
        },
    ];
};

export const setFunctionalTableHeadings = (dictionary) => {
    return [
        {
            id: 'name',
            title: dictionary?.name,
            width: '62.53%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '25.76%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.71%',
        },
    ];
};

export const setClientsTableHeadings = (dictionary) => {
    return [
        {
            id: 'id',
            title: 'ID',
            width: '11.71%',
        },
        {
            id: 'name',
            title: dictionary?.full_name,
            width: '35.78%',
        },
        {
            id: 'role',
            title: dictionary?.role,
            width: '12%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '25.76%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '14.75%',
        },
    ];
};

export const setClientFunctionalTableHeadings = (dictionary) => {
    return [
        {
            id: 'name',
            title: dictionary?.functional,
            width: '69.4%',
        },
        {
            id: 'type',
            title: dictionary?.value,
            disableSorting: true,
            width: '15.3%',
        },
        {
            id: 'price',
            title: dictionary?.price,
            width: '15.3%',
        },
    ];
};

export const setClientActionsTableHeadings = (dictionary) => {
    return [
        {
            id: 'datetime',
            title: dictionary?.date_time,
            width: '19.12%',
        },
        {
            id: 'functional.name',
            title: dictionary?.functional,
            disableSorting: true,
            width: '59.66%',
        },
        {
            id: 'total',
            title: dictionary?.total_price,
            width: '21.22%',
        },
    ];
};

export const setClientStatusTableHeadings = (dictionary) => {
    return [
        {
            id: 'datetime',
            title: dictionary?.date_time,
            width: '16.25%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '19.12%',
        },
        {
            id: 'login',
            title: dictionary?.user,
            width: '64.63%',
        },
    ];
};

export const setNotificationsTableHeadings = (dictionary) => {
    return [
        {
            id: 'name',
            title: dictionary?.title,
            width: '48.95%',
        },
        {
            id: 'published_at',
            title: dictionary?.publication_date,
            width: '19.9%',
        },
        {
            id: 'views',
            title: dictionary?.views,
            width: '16.4%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '14.75%',
        },
    ];
};

export const setCategoriesTableHeadings = (dictionary) => {
    return [
        {
            id: 'name',
            title: dictionary?.name,
            width: '88.29%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.71%',
        },
    ];
};

export const setAdminPartAppealsTableHeadings = (dictionary) => {
    return [
        {
            id: 'id',
            title: 'ID',
            width: '7.06%',
        },
        {
            id: 'subject',
            title: dictionary?.subject,
            width: '22.54%',
        },
        {
            id: 'fullname',
            title: dictionary?.client,
            width: '14.1%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '14.1%',
        },
        {
            id: 'name',
            title: dictionary?.notification,
            width: '14.1%',
        },
        {
            id: 'created_at',
            title: dictionary?.date_time,
            width: '21.04%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.06%',
        },
    ];
};

export const setPaymendHistoryTableHeadings = (dictionary) => {
    return [
        {
            id: 'date',
            title: dictionary?.date,
            width: '16.32%',
        },
        {
            id: 'functional',
            title: dictionary?.functional,
            width: '83.68%',
            disableSorting: true,
        },
    ];
};

export const setSitesTableHeadings = (dictionary) => {
    return [
        {
            id: 'domain',
            title: dictionary?.domain,
            width: '54.9%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '30.37%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '14.73%',
        },
    ];
};

export const setEmployeesTableHeadings = (dictionary) => {
    return [
        {
            id: 'fullname',
            title: dictionary?.full_name,
            width: '28.17%',
        },
        {
            id: 'role',
            title: dictionary?.role,
            width: '16.66%',
        },
        {
            id: 'sites_count',
            title: dictionary?.channels,
            width: '22.3%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '21.13%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.74%',
        },
    ];
};

export const setEmployeesChannelsTableHeadings = (dictionary) => {
    return [
        {
            id: 'fullname',
            title: dictionary?.full_name,
            width: '43.47%',
        },
        {
            id: 'role',
            title: dictionary?.role,
            width: '21.66%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '23.13%',
        },
        {
            id: 'addOperator',
            title: dictionary?.add,
            width: '11.74%',
        },
    ];
};

export const setVisitorsOnlineTableHeadings = (dictionary) => {
    return [
        {
            id: 'id',
            title: 'ID',
            width: '7.05%',
        },
        {
            id: 'fullname',
            title: dictionary?.full_name,
            width: '26.66%',
        },
        {
            id: 'domain',
            title: dictionary?.site,
            width: '17.98%',
        },
        {
            id: 'current_page_name',
            title: dictionary?.page_name,
            width: '17.98%',
        },
        {
            id: 'online_at',
            title: dictionary?.online_at,
            width: '11.28%',
        },
        {
            id: 'chat_status',
            title: dictionary?.status,
            width: '12%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.05%',
        },
    ];
};

export const setVisitorsTableHeadings = (dictionary) => {
    return [
        {
            id: 'fullname',
            title: dictionary?.full_name,
            width: '33.76%',
        },
        {
            id: 'email',
            title: 'E-mail',
            width: '21.1%',
        },
        {
            id: 'phone',
            title: dictionary?.phone,
            width: '21.1%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '17.02%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.02%',
        },
    ];
};

export const setDialoguesTableHeadings = (dictionary) => {
    return [
        {
            id: 'id',
            title: dictionary?.dialogue_id,
            width: '10.17%',
        },
        {
            id: 'fullname',
            title: dictionary?.visitor,
            width: '16.93%',
        },
        {
            id: 'site',
            title: dictionary?.site,
            width: '16.93%',
        },
        {
            id: 'name',
            title: dictionary?.user,
            width: '16.93%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '11.08%',
        },
        {
            id: 'created_at',
            title: dictionary?.created_at,
            width: '11.12%',
        },
        {
            id: 'last_message_at',
            title: dictionary?.last_message,
            width: '11.93%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.03%',
        },
    ];
};

export const setOperatorDialoguesTableHeadings = (dictionary) => {
    return [
        {
            id: 'id',
            title: dictionary?.dialogue_id,
            width: '13.53%',
        },
        {
            id: 'fullname',
            title: dictionary?.visitor,
            width: '21.12%',
        },
        {
            id: 'site',
            title: dictionary?.site,
            width: '21.12%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '16.08%',
        },
        {
            id: 'last_message_at',
            title: dictionary?.last_message,
            width: '21.12%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.03%',
        },
    ];
};

export const setCommandsTableHeadings = (dictionary) => {
    return [
        {
            id: 'trigger',
            title: dictionary?.trigger,
            width: '23.42%',
        },
        {
            id: 'text',
            title: dictionary?.content,
            width: '41.45%',
        },
        {
            id: 'categories',
            title: dictionary?.category,
            width: '23.42%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.71%',
        },
    ];
};

export const setAnswersTableHeadings = (dictionary) => {
    return [
        {
            id: 'text',
            title: dictionary?.message,
            width: '64.95%',
        },
        {
            id: 'created_at',
            title: dictionary?.created_at,
            width: '23.36%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '11.69%',
        },
    ];
};

export const setClientPartAppealsTableHeadings = (dictionary) => {
    return [
        {
            id: 'subject',
            title: dictionary?.subject,
            width: '40.03%',
        },
        {
            id: 'status',
            title: dictionary?.status,
            width: '24.86%',
        },
        {
            id: 'created_at',
            title: dictionary?.last_message,
            width: '28.09%',
        },
        {
            id: 'action',
            title: dictionary?.action,
            disableSorting: true,
            width: '7.02%',
        },
    ];
};
