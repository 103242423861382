import { useEffect, useState, useContext } from 'react';
import { useGetAllSitesQuery } from '../../../../api/clientPartApi/apiSitesSlice';
import { useGetAllClientSitesQuery } from '../../../../api/adminPartApi/apiClientsSlice';

import AutocompleteSelectWithFormContext from './AutocompleteSelectWithFormContext';

import { setSelectLabel, queryStringToArrayWithId } from '../../../../helpers/functions';

import { PageContext } from '../../../../pages/Page';

const ChannelsAutocompleteSelect = ({
    receivedValue,
    queryParams,
    clientId,
    selectArrowSx = {},
}) => {
    const { dictionary, clientCheck } = useContext(PageContext);

    const {
        data: channelsForClientPart,
        isLoading: channelsForClientPartLoading,
        isFetching: channelsForClientPartFetching,
        isSuccess: channelsForClientPartSuccess,
        isError: channelsForClientPartFetchingError,
    } = useGetAllSitesQuery({ type: '' }, { skip: !clientCheck });

    const {
        data: channelsForAdminPart,
        isLoading: channelsForAdminPartLoading,
        isFetching: channelsForAdminPartFetching,
        isSuccess: channelsForAdminPartSuccess,
        isError: channelsForAdminPartFetchingError,
    } = useGetAllClientSitesQuery(clientId, { skip: clientCheck });

    const [newReceivedValue, setNewReceivedValue] = useState([]);

    const changeKeyNameToLabel = (arr) => {
        return arr.map(({ id, domain }) => ({ id, label: domain }));
    };

    const newSitesOptions =
        channelsForClientPartSuccess || channelsForAdminPartSuccess
            ? changeKeyNameToLabel(
                  clientCheck ? channelsForClientPart?.data : channelsForAdminPart?.data
              )
            : [];

    useEffect(() => {
        if (channelsForClientPartSuccess || channelsForAdminPartSuccess) {
            if (queryParams && queryParams.get('sites')) {
                setNewReceivedValue(
                    queryStringToArrayWithId(queryParams.get('sites'), newSitesOptions)
                );
            } else if (receivedValue && receivedValue.length !== 0) {
                setNewReceivedValue(changeKeyNameToLabel(receivedValue));
            } else {
                setNewReceivedValue([]);
            }
        }
    }, [channelsForClientPartSuccess, channelsForAdminPartSuccess, receivedValue, queryParams]);

    return (
        <AutocompleteSelectWithFormContext
            fieldName={'sites'}
            label={setSelectLabel(
                channelsForClientPartFetching || channelsForAdminPartFetching,
                channelsForClientPartLoading || channelsForAdminPartLoading,
                channelsForClientPartFetchingError || channelsForAdminPartFetchingError,
                dictionary?.channels,
                dictionary
            )}
            options={newSitesOptions}
            placeholder={`${dictionary?.channel}...`}
            receivedValue={newReceivedValue}
            isSuccess={channelsForClientPartSuccess || channelsForAdminPartSuccess}
            selectArrowSx={selectArrowSx}
        />
    );
};

export default ChannelsAutocompleteSelect;
