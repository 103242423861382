import { useContext } from 'react';

import { Box, Typography } from '@mui/material';

import MyTooltip from '../../../../components/ui/Tooltip/MyTooltip';
import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';
import MyButton from '../../../../components/ui/Button/MyButton';

import { ReactComponent as InformationIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as ConfirmedIcon } from '../../../../assets/icons/confirmed.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';

import { PageContext } from '../../../Page';

const StepCode = ({
    isSuccess,
    informationText,
    informationTextWrapperSx,
    fieldName,
    successText,
    confirmButtonHandler,
    verificationCode,
    textareaValue,
    textareaLabel,
    textareaHeight,
    copiedText,
    copy,
    buttonTitle,
    content,
    resetSiteDomain,
    savedSite,
}) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    return (
        <>
            {verificationCode &&
                ((content === 'createSite' && !savedSite?.id) ||
                    resetSiteDomain ||
                    fieldName === 'widjet_integration') && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1.25,
                            ...informationTextWrapperSx,
                        }}
                    >
                        <InformationIcon />
                        <Typography variant="body2">{informationText}</Typography>
                    </Box>
                )}
            <Box
                sx={{
                    display: 'flex',
                    gap: 2.25,
                    alignItems: 'start',
                    opacity:
                        (!resetSiteDomain && content === 'editSite') ||
                        (!resetSiteDomain && content === 'createSite' && savedSite?.id)
                            ? 0
                            : 1,
                }}
            >
                <Box
                    key={verificationCode}
                    sx={{
                        position: 'relative',
                        maxWidth: 480,
                        width: '100%',
                        '& svg': {
                            position: 'absolute',
                            top: 9,
                            right: 14,
                            zIndex: 1,

                            opacity: verificationCode ? 1 : 0.2,
                            pointerEvents: verificationCode ? 'all' : 'none',
                            cursor: 'pointer',
                        },
                        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
                            color: verificationCode ? 'inherit' : 'basicColor.disabled',
                        },
                        '& .MuiInputBase-multiline textarea': {
                            overflow: 'hidden !important',
                            textareaWithIntegrationCodelign: 'left',
                            ...textareaHeight,
                        },
                    }}
                >
                    <MyTooltip position={'top'} title={dictionary?.copy}>
                        <CopyIcon
                            onClick={() => {
                                if (copiedText === undefined) {
                                    if (fieldName === 'verification_code') {
                                        copy(
                                            `<meta name='streamchat-verification-code' content='${verificationCode}' /> `
                                        );
                                    } else {
                                        copy(textareaValue);
                                    }
                                    alertMessageContent(dictionary?.data_copied, 'success');
                                }
                            }}
                        />
                    </MyTooltip>
                    <GeneralInput
                        fieldName={fieldName}
                        label={textareaLabel}
                        multiline={true}
                        sx={{ pointerEvents: 'none' }}
                        disabled={!!!verificationCode}
                        receivedValue={textareaValue}
                        rows={1}
                    />
                </Box>
                <MyButton
                    buttonClasses={'btn--extra-small'}
                    title={buttonTitle}
                    disabled={!!!verificationCode}
                    buttonHandler={() => {
                        confirmButtonHandler();
                    }}
                />
            </Box>
            {isSuccess && verificationCode && (
                <Box sx={{ display: 'flex', gap: 2.25 }}>
                    {isSuccess && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.25 }}>
                            <ConfirmedIcon />
                            <Typography
                                variant="body2"
                                sx={{ color: 'secondaryColor.main', fontWeight: 500 }}
                            >
                                {successText}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default StepCode;
