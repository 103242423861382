import { useContext, useState, useLayoutEffect } from 'react';
import {
    useGetCategoriesQuery,
    useGetCategorieQuery,
} from '../../../api/adminPartApi/apiCategoriesSlice';

import Section from '../../../layouts/Section/Section';
import CategoriesList from './CategoriesList';
import CategoriesForm from './CategoriesForm';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Categories = () => {
    const { dictionary } = useContext(PageContext);
    const { content, changeContentHandler, createSlug, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    const [categorieSwitcher, setCategorieSwitcher] = useState(false);

    useLayoutEffect(() => {
        if (hash === '#create' && content !== 'createCategorie') {
            changeContentHandler('createCategorie', '', '', 'support');
        } else if (hash.includes('#categorie') && content !== 'editCategorie') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('editCategorie', id, true, 'support');
        } else if (!hash && content !== 'categoriesList') {
            changeContentHandler('categoriesList', '', '', 'support');
        }
    }, [hash]);

    const {
        data: categories,
        isFetching: categoriesFetching,
        isLoading: categoriesLoading,
        isSuccess: categoriesSuccess,
        isError: categoriesFetchingError,
        error: categoriesError,
    } = useGetCategoriesQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
        },
        { skip }
    );

    const {
        data: selectedCategorie,
        isFetching: selectedCategorieFetching,
        isLoading: selectedCategorieLoading,
        isError: selectedCategorieFetchingError,
    } = useGetCategorieQuery(content === 'editCategorie' ? selectedElementId : '', {
        skip: content !== 'editCategorie',
    });

    const categoriesList = categories?.data?.categories?.data || [];
    const categorie = selectedCategorie?.data || '';
    const categoriesListPaginationData = categories?.data?.categories || {};

    return (
        <>
            <Section
                key={selectedCategorie?.data?.id}
                isLoading={categoriesLoading || selectedCategorieLoading}
                isFetching={categoriesFetching || selectedCategorieFetching}
                isError={categoriesFetchingError || selectedCategorieFetchingError}
                error={categoriesError}
                title={
                    content === 'categoriesList'
                        ? dictionary?.categories
                        : content === 'createCategorie'
                        ? dictionary?.categories_title_1
                        : dictionary?.categories_title_2
                }
                sx={{ maxWidth: content === 'categoriesList' ? 920 : 730 }}
                content={content}
            >
                {(!content || content === 'categoriesList') && (
                    <CategoriesList
                        categoriesList={categoriesList}
                        categoriesSuccess={categoriesSuccess}
                        changeContentHandler={changeContentHandler}
                        paginationData={categoriesListPaginationData}
                    />
                )}
                {(content === 'createCategorie' || content === 'editCategorie') && (
                    <CategoriesForm
                        content={content}
                        categorie={content === 'editCategorie' ? categorie : ''}
                        categorieSwitcher={categorieSwitcher}
                        setCategorieSwitcher={setCategorieSwitcher}
                        changeContentHandler={changeContentHandler}
                    />
                )}
            </Section>
        </>
    );
};

export default Categories;
