import { useState, useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField, InputAdornment, IconButton } from '@mui/material';

import { ReactComponent as VisibilityIcon } from '../../../assets/icons/hide.svg';
import { ReactComponent as VisibilityOffIcon } from '../../../assets/icons/show.svg';
import MyTooltip from '../../ui/Tooltip/MyTooltip';

import { PageContext } from '../../../pages/Page';
import { FormContainerContext } from '../FormContainer';

const PasswordInput = ({ fieldName, label, passwordsMatch, setPasswordsMatch }) => {
    const {
        alertMessageContent,
        serverValidationError,
        setServerValidationError,
        initialDictionary,
        dictionary,
    } = useContext(PageContext);
    const { usedFormValidationErrors } = useContext(FormContainerContext);

    const [showPassword, setShowPassword] = useState(false);
    const [isAutoFill, setIsAutoFill] = useState(false);

    const showPasswordHandler = () => {
        setShowPassword(!showPassword);
    };

    const {
        control,
        formState: { errors },
        clearErrors,
        getValues,
    } = useFormContext();

    useEffect(() => {
        if (passwordsMatch !== undefined && usedFormValidationErrors[fieldName]?.type === 'oneOf') {
            setPasswordsMatch(false);
            alertMessageContent(dictionary?.password_mismatch, 'error');
        }
    }, [usedFormValidationErrors]);

    const mouseDownPasswordHandler = (event) => {
        event.preventDefault();
    };

    const removeError = () => {
        if (errors[fieldName]) {
            clearErrors(fieldName);
        }

        if (serverValidationError?.length) {
            setServerValidationError([]);
        }
    };

    return (
        <>
            <Controller
                name={fieldName}
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        sx={{ paddingInline: '14px 0' }}
                                        aria-label="toggle password visibility"
                                        onClick={showPasswordHandler}
                                        onMouseDown={mouseDownPasswordHandler}
                                        edge="end"
                                        tabIndex={-1}
                                    >
                                        <MyTooltip
                                            position={'top'}
                                            title={
                                                showPassword
                                                    ? (dictionary ? dictionary : initialDictionary)
                                                          ?.hide
                                                    : (dictionary ? dictionary : initialDictionary)
                                                          ?.show
                                            }
                                        >
                                            <div style={{ display: 'flex' }}>
                                                {showPassword ? (
                                                    <VisibilityOffIcon />
                                                ) : (
                                                    <VisibilityIcon />
                                                )}
                                            </div>
                                        </MyTooltip>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onFocus={removeError}
                        type={showPassword ? 'text' : 'password'}
                        label={label}
                        variant="outlined"
                        error={!!errors[fieldName] || !!serverValidationError?.includes(fieldName)}
                        helperText={
                            errors[fieldName] && errors[fieldName]?.message !== 'noMatch'
                                ? `${getValues(fieldName)?.length}/100`
                                : null
                        }
                        inputProps={{
                            onAnimationStart: (e) => {
                                e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
                            },
                            onAnimationEnd: (e) =>
                                e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
                        }}
                        InputLabelProps={{
                            shrink: isAutoFill || undefined,
                        }}
                    />
                )}
            />
        </>
    );
};

export default PasswordInput;
