import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

import MyLink from '../../../components/ui/Link/MyLink';
import { FlexBox } from '../../../mui/theme';

import { dateConverter } from '../../../helpers/functions';

const ViewVisitorAccordion = ({ links, open }) => {
    const renderLinks = () => {
        return links.map(({ datetime, href, name }, index) => {
            return (
                <FlexBox key={index} className="row-10">
                    <Typography variant="body2" sx={{ minWidth: 140, color: 'basicColor.main' }}>
                        {dateConverter(datetime)}
                    </Typography>
                    <MyLink link={href} text={name} />
                </FlexBox>
            );
        });
    };

    return (
        <Accordion
            sx={{
                width: '100%',
                '&:before': {
                    backgroundColor: 'transparent',
                },
                '& .MuiButtonBase-root': {
                    width: 23,
                    padding: 0,
                    left: 'calc(100% - 20px)',
                    position: 'relative',
                    backgroundColor: 'transparent',

                    '&:hover': {
                        backgroundColor: 'transparent!important',
                    },

                    '&:hover svg path': {
                        fill: 'secondaryColor.light',
                    },
                },
            }}
            expanded={open}
        >
            <AccordionSummary></AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingLeft: 0,
                    paddingTop: 2.5,
                    borderTop: '1px solid #CADDE5',
                    marginTop: 2.5,
                }}
            >
                <FlexBox className="col-10">{renderLinks()}</FlexBox>
            </AccordionDetails>
        </Accordion>
    );
};

export default ViewVisitorAccordion;
