import { Box } from '@mui/material';

export function CustomTabPanel(props) {
    const { children, value, index, maxWidth, sx = {}, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{
                display: value === index ? 'block' : 'none',
                visibility: value === index ? 'visible' : 'hidden',
                position: value === index ? 'static' : 'absolute',
                width: '100%',
                ...sx,
            }}
        >
            <Box
            // sx={{
            //     display: value === index ? 'block' : 'none!important',
            //     visibility: value === index ? 'visible' : 'hidden',
            //     position: value === index ? 'static' : 'absolute',
            // }}
            >
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
