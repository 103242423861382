import { useState } from 'react';

export function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(undefined);

    const copy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(true);

            setTimeout(() => {
                setCopiedText(undefined);
            }, 1000);
            // return true;
        } catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(false);
            setTimeout(() => {
                setCopiedText(undefined);
            }, 1000);
            // return false;
        }
    };

    return [copiedText, copy];
}
