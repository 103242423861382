import { useContext } from 'react';

import { Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';

const TblPageDisplay = ({ data, sx = {}, parametr = 'limit', removedParametr = 'page' }) => {
    const { dictionary } = useContext(PageContext);
    const { setQueryParams } = useContext(PageContainerContext);
    const pages = [15, 25, 50];

    const handleChange = (event, newAlignment) => {
        if (newAlignment) {
            setQueryParams((params) => {
                params.set(parametr, newAlignment);
                params.delete(removedParametr);

                return params;
            });
        }
    };

    return (
        <Box
            sx={{
                maxWidth: '220px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                ...sx,
            }}
        >
            <Typography sx={{ marginRight: 1.75 }} variant="body2">
                {dictionary?.show_by}
            </Typography>
            <ToggleButtonGroup
                value={Number(data?.per_page)}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
            >
                <ToggleButton value={pages[0]}>{pages[0]}</ToggleButton>
                <ToggleButton value={pages[1]}>{pages[1]}</ToggleButton>
                <ToggleButton value={pages[2]}>{pages[2]}</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

export default TblPageDisplay;
