import { useContext, useCallback } from 'react';
import { useGetAllFunctionalQuery } from '../../../api/adminPartApi/apiFunctionalSlice';

import { Grid } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { setAdminPartStatusSelect as setStatusOptions } from '../../../data/formSelectData';
import { setSelectLabel, setFilterParams } from '../../../helpers/functions';

const currentQueryParams = ['functional_id', 'functional_value', 'status'];

const ClientsFilter = ({ selectQuerySkip }) => {
    const { dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const {
        data: functional,
        isFetching: functionalFetching,
        isLoading: functionalLoading,
        isSuccess: functionalSuccess,
        isError: functionalFetchingError,
    } = useGetAllFunctionalQuery(undefined, { skip: selectQuerySkip });

    const formSubmitHandler = useCallback(
        (data) => {
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    setFilterParams(data.functional_id, 'functional_id', params);
                    setFilterParams(data.functional_value, 'functional_value', params);
                    setFilterParams(data.status, 'status', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={4.4654}>
                <SelectWithFormContext
                    fieldName={'functional_id'}
                    label={setSelectLabel(
                        functionalFetching,
                        functionalLoading,
                        functionalFetchingError,
                        dictionary?.functional,
                        dictionary
                    )}
                    labelId={'functionalSelect'}
                    options={functionalSuccess ? functional?.data : ''}
                    emtyOption={true}
                    receivedValue={queryParams.get('functional_id')}
                />
            </Grid>
            <Grid item xs={4.4654}>
                <GeneralInput
                    fieldName={'functional_value'}
                    label={dictionary?.value}
                    receivedValue={queryParams.get('functional_value')}
                    type={'number'}
                />
            </Grid>
            <Grid item xs={4.4654}>
                <SelectWithFormContext
                    fieldName={'status'}
                    label={dictionary?.status}
                    labelId={'statusSelect'}
                    options={setStatusOptions(dictionary)}
                    emtyOption={true}
                    receivedValue={queryParams.get('status')}
                />
            </Grid>
        </FilterFormContainer>
    );
};

export default ClientsFilter;
