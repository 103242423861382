import { useLayoutEffect, useContext } from 'react';
import {
    useChangeClientEmployeeStatusMutation,
    useDeleteInvitedClientEmployeeMutation,
} from '../../../api/apiCommonClientEmployeesSlice';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';
import { FlexBox } from '../../../mui/theme';

import { ReactComponent as TurnOffIcon } from '../../../assets/icons/turn-on.svg';
import { ReactComponent as TurnOnIcon } from '../../../assets/icons/turn-off.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';

import { setStatusLabel } from '../../../helpers/functions';

import { setEmployeesTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { timer } from '../../../helpers/functions';

const EmployeesList = ({
    userRole,
    employeeAdminId,
    employeesSuccess,
    employees,
    employeesList,
    paginationData,
}) => {
    const { navigate, alertMessageContent, dictionary, language } = useContext(PageContext);
    const { changeContentHandler, hash } = useContext(PageContainerContext);

    const maxNumOfEmployees = Number(employees?.data?.employees?.max) || '';
    const currentNumOfEmployees = employees?.data?.employees?.total || '';

    useLayoutEffect(() => {
        if (hash === '#invite' && maxNumOfEmployees && maxNumOfEmployees <= currentNumOfEmployees) {
            navigate('/employees');
        }
    }, [hash]);

    const [changeEmployeeStatus] = useChangeClientEmployeeStatusMutation();
    const [deleteInvitedEmployee] = useDeleteInvitedClientEmployeeMutation();

    const changeStatusHandler = (id, action) => {
        changeEmployeeStatus({
            id,
            action,
            adminPart: userRole === 'sadmin' ? true : false,
        }).unwrap();
    };

    const deleteEmployeeHandler = (id) => {
        deleteInvitedEmployee({ id, adminPart: userRole === 'sadmin' ? true : false }).unwrap();
    };

    const svHeadCells = () =>
        setHeadCells(dictionary)
            .filter((cell) => cell.id !== 'role')
            .map((cell) => {
                if (cell.id === 'fullname') {
                    return {
                        ...cell,
                        width: '44.84%',
                    };
                } else {
                    return cell;
                }
            });

    return (
        <>
            <MyToolbar
                filter={userRole === 'adm' || userRole === 'sadmin' ? true : false}
                moveToLeft={true}
                myButtonTitle={dictionary?.invite}
                search={dictionary?.employees_search_placeholder}
                buttonSx={{
                    position: userRole === 'adm' || userRole === 'sadmin' ? 'absolute' : 'static',
                    left: 0,
                    top: 0,
                    zIndex: 1,
                }}
                buttonClass={'btn--small'}
                paginationData={paginationData}
                buttonHandler={() => {
                    if (maxNumOfEmployees <= currentNumOfEmployees) {
                        alertMessageContent(
                            userRole === 'sadmin'
                                ? dictionary?.users_limit
                                : dictionary?.employees_limit,
                            'information'
                        );
                    } else if (userRole === 'sadmin') {
                        navigate(
                            `admin/clients/client_adm-${employeeAdminId}#invite_employee?tab=4`
                        );
                    } else {
                        changeContentHandler('inviteEmployee');
                        navigate('/employees#invite');
                    }
                }}
            />
            <TblContainer>
                <TblHead
                    setHeadCells={
                        userRole === 'adm' || userRole === 'sadmin' ? setHeadCells : svHeadCells
                    }
                />
                <TableBody>
                    {employeesList.length === 0 && employeesSuccess && <EmptyListAlertText />}
                    {employeesList.length > 0 &&
                        employeesList.map(
                            ({
                                id,
                                fullname,
                                role,
                                sites_count,
                                status,
                                date_finish,
                                channels,
                            }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell>
                                            {status === 'invite' && !fullname
                                                ? dictionary?.invitation_send
                                                : fullname}
                                        </TableCell>
                                        {(userRole === 'adm' || userRole === 'sadmin') && (
                                            <TableCell>
                                                {role === 'sv'
                                                    ? dictionary?.supervizor
                                                    : dictionary?.operator}
                                            </TableCell>
                                        )}

                                        <TableCell>
                                            <MyTooltip
                                                position={'top'}
                                                title={
                                                    <FlexBox className="col-4">
                                                        {channels.map((channel) => {
                                                            return (
                                                                <Typography
                                                                    key={channel}
                                                                    sx={{
                                                                        backgroundColor:
                                                                            'basicColor.color400',
                                                                        borderRadius: '20px',
                                                                        display: 'inline-block',
                                                                        padding: '3px 10px',
                                                                        width: 'max-content',
                                                                    }}
                                                                >
                                                                    {channel}
                                                                </Typography>
                                                            );
                                                        })}
                                                    </FlexBox>
                                                }
                                            >
                                                <Typography>{sites_count}</Typography>
                                            </MyTooltip>
                                        </TableCell>
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                            {date_finish && (
                                                <Box sx={{ marginTop: 1.25 }}>
                                                    <Typography fontWeight={600}>
                                                        {timer(date_finish).length !== 0
                                                            ? dictionary?.code_is_active
                                                            : dictionary?.code_is_inactive}
                                                        &nbsp;
                                                    </Typography>
                                                    {timer(date_finish).length !== 0 &&
                                                        timer(date_finish).map(
                                                            ({ hours, minutes, seconds }) => {
                                                                return (
                                                                    <Typography key={'timer'}>
                                                                        {hours}:{minutes}:{seconds}
                                                                    </Typography>
                                                                );
                                                            }
                                                        )}
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        if (userRole === 'sadmin') {
                                                            changeContentHandler(
                                                                'editClient',
                                                                id,
                                                                true
                                                            );
                                                            navigate(
                                                                `admin/clients/client_adm-${employeeAdminId}#edit_employee-${id}?tab=4`
                                                            );
                                                        } else {
                                                            changeContentHandler(
                                                                'editEmployee',
                                                                id,
                                                                true
                                                            );
                                                            navigate(`/employees#employee_${id}`);
                                                        }
                                                    }}
                                                />
                                            </MyTooltip>
                                            {userRole === 'sadmin' && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.viewing}
                                                >
                                                    <ViewingIcon
                                                        onClick={() =>
                                                            navigate(
                                                                `admin/clients/client_adm-${employeeAdminId}#view_employee-${id}?tab=4`
                                                            )
                                                        }
                                                    />
                                                </MyTooltip>
                                            )}
                                            {status !== 'invite' ? (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={
                                                        status === 'enabled'
                                                            ? dictionary?.turn_off
                                                            : dictionary?.turn_on
                                                    }
                                                >
                                                    {status === 'enabled' ? (
                                                        <TurnOffIcon
                                                            onClick={() =>
                                                                changeStatusHandler(id, 'disable')
                                                            }
                                                        />
                                                    ) : (
                                                        <TurnOnIcon
                                                            onClick={() =>
                                                                changeStatusHandler(id, 'enable')
                                                            }
                                                        />
                                                    )}
                                                </MyTooltip>
                                            ) : (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.delete}
                                                >
                                                    <DeleteIcon
                                                        onClick={() => deleteEmployeeHandler(id)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Employees'} />
        </>
    );
};

export default EmployeesList;
