import { apiSlice } from '../apiSlice';

const apiFunctionalSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFunctional: builder.query({
            query: (query) =>
                `admin/functional${query.limit}${query.page}${query.sortBy}${query.sortOrder}`,
            providesTags: ['Functional'],
        }),
        getAllFunctional: builder.query({
            query: () => `admin/functional/all`,
            providesTags: ['Functional'],
        }),
        changeFunctionalStatus: builder.mutation({
            query: (data) => ({
                url: `admin/functional/${data.id}/${data.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Functional'],
        }),
        getSelectedFunctional: builder.query({
            query: (id) => ({
                url: `admin/functional/${id}`,
            }),
            providesTags: ['Functional'],
        }),
        editFunctional: builder.mutation({
            query: (functional) => ({
                url: `admin/functional/${functional.id}`,
                method: 'PUT',
                body: functional.body,
            }),
            invalidatesTags: ['Functional'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetFunctionalQuery,
    useGetAllFunctionalQuery,
    useChangeFunctionalStatusMutation,
    useGetSelectedFunctionalQuery,
    useEditFunctionalMutation,
} = apiFunctionalSlice;
