import { useContext, useEffect } from 'react';

import { useGetAllSitesQuery } from '../../../../api/clientPartApi/apiSitesSlice';

import { Typography, TextField } from '@mui/material';
import { FlexBox } from '../../../../mui/theme';

import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';
import SelectWithFormContext from '../../../../components/Form/FormSelect/Select/SelectWithFormContext';
import AutocompleteSelectWithFormContext from '../../../../components/Form/FormSelect/AutocompleteSelect/AutocompleteSelectWithFormContext';

import { setSelectLabel } from '../../../../helpers/functions';

import { PageContext } from '../../../Page';
import { FormContainerContext } from '../../../../components/Form/FormContainer';

const FirstStep = ({ token, channelName, receivedSites, isSubmited }) => {
    const { dictionary, alertMessageContent } = useContext(PageContext);
    const { usedFormValidationErrors } = useContext(FormContainerContext);

    useEffect(() => {
        if (JSON.stringify(usedFormValidationErrors) !== '{}') {
            alertMessageContent(dictionary?.next_step_failed, 'error');
        }
    }, [usedFormValidationErrors]);

    const {
        data: sites,
        isLoading: sitesLoading,
        isFetching: sitesFetching,
        isSuccess: sitesSuccess,
        isError: sitesFetchingError,
    } = useGetAllSitesQuery({ type: '?type=site' });

    const changeKeyNameToLabel = (arr) => {
        return arr.map(({ id, domain }) => ({ id, label: domain }));
    };

    const sitesList = sitesSuccess ? changeKeyNameToLabel(sites?.data) : [];
    const receivedSitesList = changeKeyNameToLabel(receivedSites);

    return (
        <>
            <GeneralInput
                fieldName={'token'}
                label={dictionary?.bot_token}
                receivedValue={token}
                helperText={false}
            />
            <FlexBox className="col-10">
                <Typography variant="body2" fontWeight={600}>
                    {dictionary?.channel_name_title}
                </Typography>
                <GeneralInput
                    fieldName={'name'}
                    label={dictionary?.channel_name}
                    receivedValue={channelName}
                />
            </FlexBox>
            <FlexBox className="col-10">
                <Typography variant="body2" fontWeight={600}>
                    {dictionary?.select_widget_title}
                </Typography>
                <AutocompleteSelectWithFormContext
                    fieldName={'sites'}
                    label={setSelectLabel(
                        sitesFetching,
                        sitesLoading,
                        sitesFetchingError,
                        dictionary?.select_widget,
                        dictionary
                    )}
                    options={sitesList}
                    placeholder={`${dictionary?.widget}...`}
                    receivedValue={receivedSitesList}
                    isSuccess={sitesSuccess && !isSubmited}
                />
            </FlexBox>
        </>
    );
};

export default FirstStep;
