import { useContext } from 'react';
import { useChangeUserStatusMutation } from '../../../api/adminPartApi/apiUsersSlice';

import { TableBody, TableRow, TableCell, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as UnblockIcon } from '../../../assets/icons/unblock.svg';
import { ReactComponent as BlockIcon } from '../../../assets/icons/block.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setStatusLabel } from '../../../helpers/functions';

import { setUsersTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const UsersList = ({ usersList, usersSuccess, changeContentHandler, paginationData }) => {
    const { navigate, dictionary } = useContext(PageContext);

    const [changeUserStatus] = useChangeUserStatusMutation();

    const changeStatusHandler = (id, action) => {
        changeUserStatus({ id, action }).unwrap();
    };

    const renderRoleTitle = (role) => {
        switch (role) {
            case 'sadmin':
                return dictionary?.sadmin;
            case 'admin':
                return dictionary?.admin;
            case 'mng':
                return dictionary?.mng;
        }
    };

    return (
        <>
            <MyToolbar
                search={dictionary?.users_search_placeholder}
                paginationData={paginationData}
                buttonHandler={() => {
                    changeContentHandler('createUser');
                    navigate('/admin/users#create');
                }}
            />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {usersList.length === 0 && usersSuccess && <EmptyListAlertText />}
                    {usersList.length > 0 &&
                        usersList.map(({ id, name, role, status }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell>{name}</TableCell>
                                    <TableCell>{renderRoleTitle(role)}</TableCell>
                                    <TableCell className="status">
                                        <Typography className={status}>
                                            {setStatusLabel(status, dictionary)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="action">
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <EditIcon
                                                onClick={() => {
                                                    changeContentHandler('editUser', id, true);
                                                    navigate(`/admin/users#user_${id}`);
                                                }}
                                            />
                                        </MyTooltip>
                                        <MyTooltip
                                            position={'top'}
                                            title={
                                                status === 'active'
                                                    ? dictionary?.block
                                                    : dictionary?.activate
                                            }
                                        >
                                            {status === 'active' ? (
                                                <BlockIcon
                                                    onClick={() => changeStatusHandler(id, 'block')}
                                                />
                                            ) : (
                                                <UnblockIcon
                                                    onClick={() =>
                                                        changeStatusHandler(id, 'unblock')
                                                    }
                                                />
                                            )}
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Users'} />
        </>
    );
};

export default UsersList;
