import { useState, useContext } from 'react';
import { useChangeClientStatusMutation } from '../../../api/adminPartApi/apiClientsSlice';

import MyPopper from './MyPopper';
import ChangeValueInput from '../../Form/FormInputs/ChangeValueInput';

import { PageContext } from '../../../pages/Page';

const MyClientsPopper = ({ tooltipTitle, buttonTitle, status, clientId }) => {
    const { dictionary } = useContext(PageContext);

    const MIN_NUM_OF_MONTH = 1;
    const MAX_NUM_OF_MONTH = 6;

    const [value, setValue] = useState(MIN_NUM_OF_MONTH);

    const [changeClientStatus] = useChangeClientStatusMutation();

    const changeStatusHandler = () => {
        if (status === 'active' || status === 'blocked') {
            changeClientStatus({ id: clientId, action: 'stop', duration: value }).unwrap();
        } else {
            changeClientStatus({ id: clientId, action: 'unstop', extraPath: '' }).unwrap();
        }

        setTimeout(() => {
            setValue(MIN_NUM_OF_MONTH);
        }, 200);
    };

    return (
        <MyPopper
            status={status}
            tooltipTitle={tooltipTitle}
            buttonTitle={buttonTitle}
            submitButtonHandler={changeStatusHandler}
        >
            {(status === 'active' || status === 'blocked') && (
                <ChangeValueInput
                    label={dictionary?.num_of_months}
                    id={'numOfMonth'}
                    maxValue={MAX_NUM_OF_MONTH}
                    minValue={MIN_NUM_OF_MONTH}
                    value={value}
                    setValue={setValue}
                />
            )}
        </MyPopper>
    );
};

export default MyClientsPopper;
