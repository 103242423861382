import { apiSlice } from '../apiSlice';

const apiResetPasswordSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        passwordResetRequest: builder.mutation({
            query: (data) => ({
                url: `client/reset?email=${data.email}`,
                method: 'POST',
            }),
        }),
        setNewPassword: builder.mutation({
            query: (data) => ({
                url: `client/reset/password?email=${data.email}&password=${data.password}&code=${data.code}`,
                method: 'POST',
            }),
        }),
        checkClientResetCode: builder.mutation({
            query: (code) => ({
                url: `client/reset/check?code=${code}`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    usePasswordResetRequestMutation,
    useSetNewPasswordMutation,
    useCheckClientResetCodeMutation,
} = apiResetPasswordSlice;
