import { apiSlice } from '../apiSlice';

const apiTemplatesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCommands: builder.query({
            query: (query) => ({
                url: `client/commands${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.categories}${query.search}`,
            }),
            providesTags: ['Commands'],
        }),
        getAllCategoriesOfCommands: builder.query({
            query: () => ({
                url: `client/commands/categories/all?sort_by=name&sort_order=asc`,
            }),
            providesTags: ['Commands Categories'],
        }),
        createCategoryOfCommands: builder.mutation({
            query: (id) => ({
                url: `client/commands/categories?name=${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Commands Categories'],
        }),
        getCommand: builder.query({
            query: (id) => ({
                url: `client/commands/${id}`,
            }),
            providesTags: ['Commands'],
        }),
        createCommand: builder.mutation({
            query: (command) => ({
                url: 'client/commands/',
                method: 'POST',
                body: command,
            }),
            invalidatesTags: ['Commands'],
        }),
        editCommand: builder.mutation({
            query: (command) => ({
                url: `client/commands/${command.id}`,
                method: 'PUT',
                body: command.body,
            }),
            invalidatesTags: ['Commands'],
        }),
        deleteCommand: builder.mutation({
            query: (id) => ({
                url: `client/commands/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Commands'],
        }),
        getAnswers: builder.query({
            query: (query) => ({
                url: `/client/answers${query.limit}${query.page}${query.sortBy}${query.sortOrder}`,
            }),
            providesTags: ['Answers'],
        }),
        deleteAnswer: builder.mutation({
            query: (id) => ({
                url: `client/answers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Answers'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCommandsQuery,
    useGetCommandQuery,
    useGetAllCategoriesOfCommandsQuery,
    useCreateCategoryOfCommandsMutation,
    useCreateCommandMutation,
    useEditCommandMutation,
    useDeleteCommandMutation,
    useGetAnswersQuery,
    useDeleteAnswerMutation,
} = apiTemplatesSlice;
