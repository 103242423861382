import { useState, useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MySelectSubItem from '../MySelectSubItem';

import { PageContext } from '../../../../pages/Page';

const BaseSelect = ({
    label,
    labelId,
    options,
    subItem,
    onChangeHandler,
    selectSx = {},
    ...rest
}) => {
    const DROPDOWN_HEIGHT = 218;
    const DROPDOWN_ITEM_HEIGHT = 36;
    const numOfDropdownItems = options?.length || 0;

    const { language } = useContext(PageContext);

    const [dropdownPosition, setDropdownPosition] = useState({
        anchor: 'bottom',
        transform: 'top',
    });
    const [dropdownStyles, setDropdownStyles] = useState({
        borderTop: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    });

    const [selectBorderRadius, setSelectBorderRadius] = useState({
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    });

    const selectStyles = {
        ...selectBorderRadius,
        '& .MuiSelect-select': {
            lineHeight: 1.29,
        },
        '& .MuiSvgIcon-root': {
            color: 'red',
        },
    };

    const onOpenHandler = (e) => {
        const selectHeight = e.target.offsetParent.clientHeight;
        const selectPosition = e.clientY;
        const dropdownHeight =
            DROPDOWN_HEIGHT > DROPDOWN_ITEM_HEIGHT * numOfDropdownItems
                ? DROPDOWN_ITEM_HEIGHT * numOfDropdownItems
                : DROPDOWN_HEIGHT;

        if (selectPosition + dropdownHeight + selectHeight > window.innerHeight) {
            setDropdownPosition({
                anchor: 'top',
                transform: 'bottom',
            });
            setSelectBorderRadius({
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            });
            setDropdownStyles({
                borderBottom: 'none',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            });
        } else {
            setDropdownPosition({
                anchor: 'bottom',
                transform: 'top',
            });
            setSelectBorderRadius({
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            });
            setDropdownStyles({
                borderTop: 'none',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            });
        }
    };

    const onCloseHandler = () => {
        setSelectBorderRadius({
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
        });
    };

    return (
        <Select
            sx={{ ...selectStyles, ...selectSx }}
            labelId={labelId}
            label={label}
            IconComponent={ExpandMoreIcon}
            onClose={onCloseHandler}
            onOpen={(e) => onOpenHandler(e)}
            onChange={(e) => onChangeHandler(e)}
            MenuProps={{
                PaperProps: {
                    sx: {
                        maxHeight: DROPDOWN_HEIGHT,
                        ...dropdownStyles,
                    },
                },
                anchorOrigin: {
                    vertical: dropdownPosition?.anchor,
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: dropdownPosition?.transform,
                    horizontal: 'left',
                },
                // getContentAnchorEl: null,
            }}
            {...rest}
        >
            {subItem && <MySelectSubItem />}
            {options &&
                options.map(({ id, name, sx = {} }) => {
                    return (
                        <MenuItem key={uuidv4()} value={id} sx={{ ...sx }}>
                            {name?.[language] ? name?.[language] : name}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default BaseSelect;
