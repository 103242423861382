import { Switch, FormControlLabel } from '@mui/material';

const Switcher = ({ label, checked, onClick, switcherSx, ...rest }) => {
    return (
        <FormControlLabel
            sx={{ ...switcherSx }}
            value="end"
            label={label}
            labelPlacement="end"
            {...rest}
            control={<Switch checked={checked} onClick={onClick} tabIndex={-1} />}
        />
    );
};

export default Switcher;
