import { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';

import AutocompleteSelectWithFormContext from './AutocompleteSelectWithFormContext';

import { PageContext } from '../../../../pages/Page';

const SearchSelect = ({
    label,
    fieldName,
    options,
    clearFilter,
    searchQuery,
    setSearchQuery,
    placeholder,
    receivedValue,
    isSuccess,
}) => {
    const { dictionary } = useContext(PageContext);
    const { setValue } = useFormContext();

    const [query, setQuery] = useState('');
    const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        if (clearFilter) {
            setInputValue(null);
        }
    }, [clearFilter]);

    useEffect(() => {
        if (query) {
            const timeoutId = setTimeout(() => {
                setSearchQuery('&q=' + encodeURIComponent(query));
            }, 300);

            return () => clearTimeout(timeoutId);
        } else {
            setSearchQuery('');
            setInputValue(null);
        }
    }, [query]);

    useEffect(() => {
        if (receivedValue !== inputValue?.id && !query) {
            if (isSuccess && receivedValue) {
                const receivedInputValue = options.find((option) => receivedValue === option.id);

                if (receivedInputValue) {
                    setInputValue(receivedInputValue);
                    setValue(fieldName, receivedInputValue.id);
                }
            } else {
                setInputValue(null);
                setValue(fieldName, '');
            }
        }
    }, [isSuccess, receivedValue, searchQuery]);

    const handleSearch = ({ target }) => {
        setQuery(target.value);
    };

    const textFieldStyles = {
        '& .MuiOutlinedInput-root': {
            // padding: '10px 14px!important',
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input ': {
            padding: '0!important',
        },
    };

    return (
        <AutocompleteSelectWithFormContext
            fieldName={fieldName}
            label={label}
            value={inputValue}
            options={options}
            multiple={false}
            noOptionsText={
                options.length === 0 && isSuccess
                    ? dictionary?.no_matches
                    : `${dictionary?.search}...`
            }
            onChange={(event, data) => {
                setValue(fieldName, data);
                setInputValue(data);
            }}
            filterOptions={false}
            // isOptionEqualToValue={(option, value) => option?.id === value?.id || value === ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    sx={{ ...textFieldStyles }}
                    onChange={(e) => {
                        handleSearch(e);
                    }}
                />
            )}
            serverSearch={true}
            setSearchQuery={setSearchQuery}
            onKeyDown={(e) => {
                if (e.key === 'Delete') {
                    setQuery('');
                    setSearchQuery('');
                    setValue(fieldName, '');
                    setInputValue(null);
                }
            }}
        />
    );
};

export default SearchSelect;
