import { useCallback, useContext } from 'react';
import {
    useCreateCommandMutation,
    useEditCommandMutation,
    useGetAllCategoriesOfCommandsQuery,
    useCreateCategoryOfCommandsMutation,
} from '../../../api/clientPartApi/apiTemplatesSlice';

import { Box, Typography } from '@mui/material';

import FormContainer from '../../../components/Form/FormContainer';
import AutocompleteSelectWithFormContext from '../../../components/Form/FormSelect/AutocompleteSelect/AutocompleteSelectWithFormContext';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';
import TextEditorWithFormContext from '../../../components/TextEditor/TextEditorWithFormContext';
import MySelectSubItem from '../../../components/Form/FormSelect/MySelectSubItem';

import { createCommandSchema as schema } from '../../../settings/formSchema';

import { setSelectLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const CommandsForm = ({ content, command, selectedCommandSuccess }) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    const [
        createCommand,
        {
            isLoading: createdCommandLoading,
            isFetching: createdCommandFetching,
            isSuccess: createdCommandSuccess,
        },
    ] = useCreateCommandMutation();

    const [editCommand, { isLoading: editedCommandLoading, isFetching: editedCommandFetching }] =
        useEditCommandMutation();

    const {
        data: categories,
        isLoading: categoriesLoading,
        isFetching: categoriesFetching,
        isSuccess: categoriesSuccess,
        isError: categoriesFetchingError,
    } = useGetAllCategoriesOfCommandsQuery();

    const [
        createCategory,
        {
            isLoading: createdCategoryLoading,
            isFetching: createdCategoryFetching,
            isSuccess: createdCategorySuccess,
        },
    ] = useCreateCategoryOfCommandsMutation();

    const mapAnArray = (arr) => {
        return arr.map(({ id, name }) => {
            return { id, label: name };
        });
    };

    const createCategoryHandler = (name) => {
        createCategory(name)
            .unwrap()
            .then((res) => alertMessageContent(dictionary?.category_created, 'success'))
            .catch((error) => {
                console.log(error);
                if (error?.data?.errors?.name) {
                    alertMessageContent(dictionary?.category_exists, 'error');
                } else {
                    alertMessageContent(dictionary?.something_go_wrong, 'error');
                }
            });
    };

    const formSubmitHandler = useCallback((data) => {
        const categoriesId = data.categorie.map((item) => {
            return item.id;
        });

        const submittedCommand = {
            categories: categoriesId,
            trigger: data.trigger,
            text: data.text,
        };

        if (content === 'createCommand') {
            createCommand(submittedCommand)
                .unwrap()
                .then((res) => alertMessageContent(dictionary?.command_created, 'success'))
                .catch((error) => {
                    if (error?.data?.errors?.trigger) {
                        alertMessageContent(dictionary?.trigger_exists, 'error');
                    } else {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    }
                });
        } else {
            editCommand({ id: command.id, body: submittedCommand })
                .unwrap()
                .then((res) => alertMessageContent(dictionary?.command_edited, 'success'))
                .catch((error) => {
                    if (error?.data?.errors?.trigger) {
                        alertMessageContent(dictionary?.trigger_exists, 'error');
                    } else {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    }
                });
        }
    }, []);

    return (
        <FormContainer
            schema={schema}
            submitButtonTitle={command ? dictionary?.save : dictionary?.create}
            submitButtonClass={'btn--primary'}
            formSubmitHandler={formSubmitHandler}
            cleareForm={createdCommandSuccess}
            isLoading={createdCommandLoading || editedCommandLoading || createdCategoryLoading}
            isFetching={createdCommandFetching || editedCommandFetching || createdCategoryFetching}
        >
            <Box>
                <AutocompleteSelectWithFormContext
                    fieldName={'categorie'}
                    label={setSelectLabel(
                        categoriesFetching,
                        categoriesLoading,
                        categoriesFetchingError,
                        dictionary?.category,
                        dictionary
                    )}
                    placeholder={`${dictionary?.search}...`}
                    options={categoriesSuccess ? mapAnArray(categories?.data?.result) : []}
                    selectSx={{
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        zIndex: 1,
                    }}
                    receivedValue={command?.categories ? mapAnArray(command?.categories) : []}
                    isSuccess={selectedCommandSuccess}
                />
                <MySelectSubItem
                    buttonHandler={createCategoryHandler}
                    isSuccess={createdCategorySuccess}
                />
            </Box>
            <Box sx={{ position: 'relative' }}>
                <Typography
                    sx={{
                        position: 'absolute',
                        top: 1,
                        left: 14,
                        color: 'basicColor.main',
                        fontSize: 20,
                    }}
                >
                    *
                </Typography>
                <GeneralInput
                    fieldName={'trigger'}
                    label={dictionary?.trigger}
                    sx={{ '& input': { paddingLeft: '20px!important' } }}
                    receivedValue={command?.trigger}
                />
            </Box>
            <TextEditorWithFormContext fieldName={'text'} receivedValue={command?.text} />
        </FormContainer>
    );
};

export default CommandsForm;
