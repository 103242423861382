import { Box, Typography } from '@mui/material';

import { ReactComponent as CallIcon } from '../../../assets/icons/phone-call.svg';

const FormCall = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                minHeight: 382,
                '& svg': {
                    marginBottom: 3.75,
                    // animationName: 'trin',
                    // animationDuration: '1.5s',
                    // animationIterationCount: 'infinite',
                    // animationTimingFunction: 'linear',
                    // '@keyframes trin': {
                    //     from: {
                    //         transform: 'rotate3d(0, 0, 1, 0deg)',
                    //     },
                    //     '20%, 32%, 44%, 56%, 68%': {
                    //         transform: 'rotate3d(0, 0, 1, 0deg)',
                    //     },
                    //     '23%, 35%, 47%, 59%, 71%': {
                    //         transform: 'rotate3d(0,0,1,15deg)',
                    //     },
                    //     '26%, 38%, 50%, 62%, 74%': {
                    //         transform: 'rotate3d(0,0,1,0deg)',
                    //     },
                    //     '29%, 41%, 53%, 65%, 77%': {
                    //         transform: 'rotate3d(0,0,1,-15deg)',
                    //     },
                    //     '80%': {
                    //         transform: 'rotate3d(0, 0, 1, 0deg)',
                    //     },
                    // },
                },
            }}
        >
            <CallIcon />
            <Box>
                <Typography
                    sx={{
                        marginBottom: 1.25,
                        lineHeight: 1.5,
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Мы звоним Вам, чтобы <br /> убедиться, что это Вы
                </Typography>
                <Typography variant="body1">Пожалуйста подтвердите!</Typography>
            </Box>
        </Box>
    );
};

export default FormCall;
