import { Link } from '@mui/material';

const MyLink = ({ link, text, sx = {} }) => {
    return (
        <Link sx={{ ...sx }} href={link} target="_blank" rel="noopener" underline="hover">
            {text || link}&nbsp;
        </Link>
    );
};

export default MyLink;
