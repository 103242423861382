import { useState, useContext, useCallback } from 'react';
import { useChangePasswordMutation } from '../../api/apiPasswordSettingsSlice';

import Section from '../../layouts/Section/Section';
import FormContainer from '../../components/Form/FormContainer';
import PasswordInput from '../../components/Form/FormInputs/PasswordInput';
import Title from '../../components/ui/Title/Title';

import { settingsSchema as schema } from '../../settings/formSchema';

import { PageContext } from '../Page';

const PasswordSettingsForm = ({ title, sx }) => {
    const { clientCheck, setServerValidationError, alertMessageContent, dictionary } =
        useContext(PageContext);

    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const [changePassword, { isLoading, isFetching, isSuccess }] = useChangePasswordMutation();

    const formSubmitHandler = useCallback((data) => {
        const passwords = {
            current_password: data?.current_password,
            new_password: data?.new_password,
        };
        changePassword({
            url: clientCheck ? '/client/settings/password' : 'admin/password',
            body: passwords,
        })
            .unwrap()
            .then((res) => {
                alertMessageContent(dictionary?.password_has_changed, 'success');
            })
            .catch((error) => {
                setServerValidationError(['current_password']);
                if (error?.data?.errors?.current_password) {
                    alertMessageContent(dictionary?.wrong_password, 'error');
                } else {
                    alertMessageContent(dictionary?.something_go_wrong, 'error');
                }
            });
    }, []);

    return (
        <Section title={title} sx={{ ...sx }}>
            <FormContainer
                schema={schema}
                submitButtonTitle={dictionary?.save}
                submitButtonClass={'btn--primary'}
                submitButtonHandler={() => setPasswordsMatch(true)}
                useButtonHandler={true}
                formSubmitHandler={formSubmitHandler}
                useValidationErrors={true}
                cleareForm={isSuccess}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                {clientCheck && (
                    <Title
                        heading="h3"
                        title={dictionary?.security}
                        sx={{ color: 'secondaryColor.main' }}
                    />
                )}
                <PasswordInput
                    fieldName={'current_password'}
                    label={dictionary?.current_password}
                />
                <PasswordInput fieldName={'new_password'} label={dictionary?.new_password} />
                <PasswordInput
                    fieldName={'confirm_password'}
                    label={dictionary?.confirm_password}
                    passwordsMatch={passwordsMatch}
                    setPasswordsMatch={setPasswordsMatch}
                />
            </FormContainer>
        </Section>
    );
};

export default PasswordSettingsForm;
