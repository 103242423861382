import { useContext } from 'react';

import { Typography } from '@mui/material';

import ChatToolbar from './ChatToolbar';
import { Chat as DialogContent } from '../../../../components/Chat/Chat';
import { FlexBox } from '../../../../mui/theme';

import { PageContext } from '../../../Page';

const Chat = ({
  blackList,
  dialogueId,
  dialogueStatus,
  visitorStatus,
  changeTags,
  alertMessageContent,
  showVisitorData,
  setShowVisitorData,
  skipVisitorData,
  setSkipVisitorData,
  connectToDialogHandler,
  passDialogueHandler,
  changeDialogueStatusHandler,
  changeVisitorStatusHandler,
  selectedDialogueSuccess,
  visitorContactsRequest,
  sendForm,
}) => {
  const { dictionary } = useContext(PageContext);

  return (
    <>
      {dialogueId && (
        <ChatToolbar
          blackList={blackList}
          dialogueId={dialogueId}
          dialogueStatus={dialogueStatus}
          visitorStatus={visitorStatus}
          changeTags={changeTags}
          alertMessageContent={alertMessageContent}
          showVisitorData={showVisitorData}
          setShowVisitorData={setShowVisitorData}
          skipVisitorData={skipVisitorData}
          setSkipVisitorData={setSkipVisitorData}
          connectToDialogHandler={connectToDialogHandler}
          passDialogueHandler={passDialogueHandler}
          changeDialogueStatusHandler={changeDialogueStatusHandler}
          changeVisitorStatusHandler={changeVisitorStatusHandler}
          selectedDialogueSuccess={selectedDialogueSuccess}
          visitorContactsRequest={visitorContactsRequest}
          sendForm={sendForm}
        />
      )}
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: dialogueId ? 'calc(100% - 60px)' : '100%',
          border: dialogueId ? '1px solid #DAECF9' : 'none',
          borderRadius: 1.25,
        }}
      >
        {!dialogueId && (
          <>
            <Typography variant="body3" color="basicColor.color700">
              {dictionary?.select_dialogue}
            </Typography>
          </>
        )}
        {dialogueId && (
          <DialogContent
            type="dialogs"
            role="client"
            // key={dialogueId + '_' + dialogueStatus}
            key={dialogueId}
            chatId={dialogueId}
          />
        )}
      </FlexBox>
    </>
  );
};

export default Chat;
