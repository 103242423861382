import { apiSlice } from './apiSlice';

const apiCommonClientEmployeesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClientEmployee: builder.query({
            query: ({ adminPart, id }) => ({
                url: adminPart ? `admin/clients/employees/${id}` : `client/employees/${id}`,
            }),
            providesTags: ['Employees', 'Clients'],
        }),
        changeClientEmployeeStatus: builder.mutation({
            query: ({ adminPart, id, action }) => ({
                url: adminPart
                    ? `admin/clients/employees/${id}/${action}`
                    : `client/employees/${id}/${action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Employees', 'Clients'],
        }),
        deleteInvitedClientEmployee: builder.mutation({
            query: ({ adminPart, id }) => ({
                url: adminPart
                    ? `admin/clients/employees/${id}/delete`
                    : `client/employees/${id}/delete`,
                method: 'POST',
            }),
            invalidatesTags: ['Employees', 'Clients'],
        }),
        inviteClientEmployee: builder.mutation({
            query: ({ id, body }) => ({
                url: id ? `/admin/clients/${id}/employees/invite` : 'client/employees/invite',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Employees', 'Clients'],
        }),
        getAnInvitationCode: builder.query({
            query: (id) => ({
                url: id ? `admin/clients/${id}/employees/code` : `client/employees/code`,
            }),
        }),
        editClientEmployee: builder.mutation({
            query: ({ adminPart, id, body }) => ({
                url: adminPart ? `admin/clients/employees/${id}` : `client/employees/${id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['Employees', 'Clients'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetClientEmployeeQuery,
    useChangeClientEmployeeStatusMutation,
    useInviteClientEmployeeMutation,
    useDeleteInvitedClientEmployeeMutation,
    useGetAnInvitationCodeQuery,
    useEditClientEmployeeMutation,
} = apiCommonClientEmployeesSlice;
