import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, TextField, Stack } from '@mui/material';
import BaseAutocompleteSelect from './BaseAutocompleteSelect';

const AutocompleteSelectWithFormContext = ({
    fieldName,
    options,
    label,
    placeholder,
    receivedValue,
    multiple = true,
    serverSearch,
    setSearchQuery,
    selectSx = {},
    selectArrowSx = {},
    isSuccess,
    filterOptions = true,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext();

    useEffect(() => {
        if (isSuccess) {
            if (receivedValue && receivedValue?.length !== 0 && multiple) {
                setValue(fieldName, receivedValue);
            } else if (receivedValue && !multiple) {
                const receivedInputValue = options.find((option) => receivedValue === option?.id);

                setValue(fieldName, receivedInputValue);
            } else if (receivedValue && receivedValue?.length === 0) {
                setValue(fieldName, []);
            }
        }
    }, [receivedValue, isSuccess]);

    return (
        <Stack>
            <FormControl>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                        <BaseAutocompleteSelect
                            sx={selectSx}
                            selectArrowSx={selectArrowSx}
                            value={field.value || []}
                            multiple={multiple}
                            options={options}
                            filterSelectedOptions={filterOptions}
                            onChange={(_, data) => {
                                setValue(fieldName, data);
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id || value === []
                            }
                            serverSearch={serverSearch}
                            setSearchQuery={setSearchQuery}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    placeholder={placeholder}
                                    error={!!errors[fieldName]}
                                />
                            )}
                            onKeyDown={(e) => {
                                if (e.key === 'Delete') {
                                    setValue(fieldName, []);
                                }
                            }}
                            {...rest}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
};

export default AutocompleteSelectWithFormContext;
