import { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Spinner from './components/ui/Spinner/Spinner';

// const Page = lazy(() => import('./pages/Page'));
import Page from './pages/Page';

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                {/* <Suspense fallback={<Spinner lazy={true} />}> */}
                <Page />
                {/* </Suspense> */}
            </div>
        </BrowserRouter>
    );
}

export default App;
