import { useEffect, useState, useRef } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { MuiColorInput } from 'mui-color-input';

import { SketchPicker } from 'react-color';

const ColorInput = ({
  fieldName,
  label,
  defaultValue,
  receivedValue,
  colorType = 'hex', // Added prop to accept color format ('hex', 'rgb', 'hsl')
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const [isOpened, setIsOpened] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (receivedValue) {
      setValue(fieldName, receivedValue);
    } else {
      setValue(fieldName, defaultValue);
    }
  }, [receivedValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        !event.target.closest('.sketch-picker')
      ) {
        setIsOpened(false);
      }
    };

    const handleClick = (e) => {
      if (inputRef.current && inputRef.current.contains(e.target)) {
        setIsOpened(true);
      }
    };

    window.addEventListener('click', handleClick);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('click', handleClick);
    };
  }, [fieldName]);

  const formatColor = (color) => {
    switch (colorType) {
      case 'rgb':
        return `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
      case 'hsl':
        return `hsl(${color.hsl.h}, ${color.hsl.s}%, ${color.hsl.l}%)`;
      case 'hex':
      default:
        return color.hex;
    }
  };

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <MuiColorInput
              {...field}
              label={label}
              ref={inputRef}
              value={getValues(fieldName)}
              sx={{
                '.MuiInputBase-root': {
                  cursor: 'pointer',
                },
                '.MuiButton-colorPrimary': {
                  pointerEvents: 'none',
                },
              }}
            />
            {isOpened && (
              <SketchPicker
                color={field.value}
                onChange={(color) => {
                  field.onChange(formatColor(color));
                }}
                disableAlpha={true}
              />
            )}
          </Box>
        )}
      />
    </>
  );
};

export default ColorInput;
