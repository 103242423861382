import Switcher from './SwitcherTemplate';

const BaseSwitcher = ({
    id,
    label,
    checked,
    setChecked,
    switcherSx = {},
    switcherClass,
    objectWithStates,
    serverRequest,
    sendServerRequest,
    useSomeFunction = false,
    switcherFunction,
}) => {
    const handleChange = () => {
        if (objectWithStates) {
            setChecked({ ...objectWithStates, [id]: !checked });

            if (useSomeFunction) {
                switcherFunction();
            }
        } else {
            if (useSomeFunction) {
                switcherFunction();
            } else {
                setChecked(!checked);
            }
        }
    };

    return (
        <Switcher
            switcherSx={switcherSx}
            className={switcherClass}
            checked={checked || false}
            onClick={() => {
                handleChange();

                if (serverRequest) {
                    sendServerRequest();
                }
            }}
            label={label}
        />
    );
};

export default BaseSwitcher;
