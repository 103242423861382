import { useContext } from 'react';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../../components/Table/TableContainer';
import TblHead from '../../../../components/Table/TableHead';
import MyToolbar from '../../../../components/Toolbar/MyToolbar';
import EmptyListAlertText from '../../../../components/ui/Alert/EmptyListAlertText';
import BaseSwitcher from '../../../../components/ui/Switcher/BaseSwitcher';

import { setStatusLabel } from '../../../../helpers/functions';

import { setEmployeesChannelsTableHeadings as setHeadCells } from '../../../../data/tableHeadingsData';

import { PageContext } from '../../../Page';

const SecondStep = ({
    operators,
    setOperators,
    employeesList,
    employeesSuccess,
    setSearch,
    setSortByWithoutQuery,
    setSortOrderWithoutQuery,
    newEmployeesList,
}) => {
    const { dictionary } = useContext(PageContext);

    const updateOperators = (id) => {
        if (operators.includes(id)) {
            setOperators([...operators.filter((item) => item !== id)]);
        } else {
            setOperators([...operators, id]);
        }
    };

    return (
        <Box sx={{ marginBottom: 3.5 }}>
            <MyToolbar
                search={dictionary?.employees_search_placeholder}
                changeContentButton={false}
                withoutSearchQueryString={true}
                setSearch={setSearch}
            />
            <TblContainer tableSx={{ minHeight: 114 }}>
                <TblHead
                    setHeadCells={setHeadCells}
                    setSortByWithoutQuery={setSortByWithoutQuery}
                    setSortOrderWithoutQuery={setSortOrderWithoutQuery}
                    setSortWithoutQueryString={true}
                />
                <TableBody>
                    {employeesList.length === 0 && employeesSuccess && (
                        <EmptyListAlertText sx={{ minHeight: 59 }} />
                    )}
                    {(employeesList.length > 0 || newEmployeesList.length > 0) &&
                        (newEmployeesList.length > 0 ? newEmployeesList : employeesList).map(
                            ({ id, fullname, role, status }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell>{fullname}</TableCell>
                                        <TableCell>
                                            {role === 'adm'
                                                ? dictionary?.admin
                                                : role === 'sv'
                                                ? dictionary?.supervizor
                                                : dictionary?.operator}
                                        </TableCell>
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            className="status"
                                            sx={{
                                                display: 'inline-flex',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <BaseSwitcher
                                                switcherSx={{ marginInline: 'auto' }}
                                                checked={operators.includes(id) ? true : false}
                                                id={id}
                                                useSomeFunction={true}
                                                switcherFunction={() => updateOperators(id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>
        </Box>
    );
};

export default SecondStep;
