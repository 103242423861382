import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';

import { setContent, setSkip, setSelectedElementId } from '../../reducers/sectionSlice';

import NavAccordion from './NavAccordion';
import NavSubItem from './NavSubItem';
import Counter from '../../components/ui/Counter/Counter';

import { PageContext } from '../../pages/Page';
import { MainLayoutInnerContext } from '../MainLayout/MainLayoutInner';

import { setNavLinkName } from '../../data/navLinkData';

import './nav.scss';

const NavItem = ({ route, activeItem, setActiveItem }) => {
    const {
        expandedAccordionOfNav,
        setExpandedAccordionOfNav,
        userOnlineSection,
        clientCheck,
        setAlertMessage,
        dictionary,
    } = useContext(PageContext);
    const {
        newAppeals,
        activeDialogues,
        waitingDialogues,
        appealsCounterSuccess,
        activeDialoguesSuccess,
        waitingDialoguesSuccess,
        newMessagesInActiveDialogues,
    } = useContext(MainLayoutInnerContext);

    const dispatch = useDispatch();

    const { path, hiddenRoutes, counter, visitorsOnline, initialContent, accordion } = route;

    const linkName = setNavLinkName(dictionary).filter(({ id }) => path === id)[0]?.linkName;

    const setInitialProps = (initialContent) => {
        dispatch(setContent(initialContent));
        dispatch(setSkip(false));
        dispatch(setSelectedElementId(''));
    };

    const singleRoute = () => {
        return (
            <NavLink
                onClick={() => {
                    setExpandedAccordionOfNav(false);
                    setAlertMessage({});
                    setActiveItem(path);
                    if (initialContent) {
                        setInitialProps(initialContent);
                    }
                }}
                className={({ isActive }) =>
                    isActive ? 'nav__link nav__link--main _active' : 'nav__link nav__link--main'
                }
                to={path}
            >
                {linkName}
            </NavLink>
        );
    };

    const multipleRoutes = () => {
        return (
            <NavAccordion
                path={path}
                linkName={linkName}
                expandedAccordionOfNav={expandedAccordionOfNav}
                setExpandedAccordionOfNav={setExpandedAccordionOfNav}
                setInitialProps={setInitialProps}
                accordion={accordion}
            >
                <ul className="nav__sub-list">
                    {hiddenRoutes.map((route) => {
                        return (
                            <NavSubItem
                                key={route.path}
                                route={route}
                                isSuccess={
                                    appealsCounterSuccess ||
                                    activeDialoguesSuccess ||
                                    waitingDialoguesSuccess
                                }
                                value={
                                    !clientCheck
                                        ? newAppeals
                                        : {
                                              counterFirst: activeDialogues,
                                              counterSecond: waitingDialogues,
                                          }
                                }
                                setInitialProps={setInitialProps}
                            />
                        );
                    })}
                </ul>
            </NavAccordion>
        );
    };

    const navItemContent = hiddenRoutes ? multipleRoutes(path) : singleRoute();

    return (
        <>
            {(visitorsOnline === userOnlineSection || visitorsOnline === undefined) && (
                <li className="nav__item">
                    {navItemContent}
                    {counter &&
                        ((appealsCounterSuccess &&
                            newAppeals &&
                            expandedAccordionOfNav !== 'support') ||
                            (activeDialoguesSuccess &&
                                activeDialogues &&
                                expandedAccordionOfNav !== 'dialogues')) && (
                            <Counter
                                sx={{
                                    position: 'absolute',
                                    right: 14,
                                    top: 14,
                                    pointerEvents: 'none',
                                    backgroundColor:
                                        !hiddenRoutes && activeItem === 'admin/support'
                                            ? 'basicColor.colorWhite'
                                            : 'secondaryColor.main',
                                    color:
                                        !hiddenRoutes && activeItem === 'admin/support'
                                            ? 'secondaryColor.main'
                                            : 'basicColor.colorWhite',
                                }}
                                indicator={waitingDialogues || newMessagesInActiveDialogues}
                            >
                                {appealsCounterSuccess ? newAppeals : activeDialogues}
                            </Counter>
                        )}
                </li>
            )}
        </>
    );
};

export default NavItem;
