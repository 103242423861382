import { useState, useContext } from 'react';

import { FormControl, InputLabel, TextField } from '@mui/material';

import MyPopper from '../../../../components/ui/Popper/MyPopper';
import BaseSelect from '../../../../components/Form/FormSelect/Select/BaseSelect';

import { setClientPartBlockPeriodSelect as setOptions } from '../../../../data/formSelectData';

import { PageContext } from '../../../Page';

const BlockVisitorPopper = ({ visitorStatus, changeVisitorStatusHandler, clientId }) => {
    const { dictionary } = useContext(PageContext);

    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');

    const onChangeHandler = (e) => {
        setSelectedPeriod(e.target.value);
    };

    const cleareFields = () => {
        setTimeout(() => {
            setSelectedPeriod('');
            setSelectedNumber('');
        }, 200);
    };

    return (
        <MyPopper
            tooltipTitle={visitorStatus === 'blocked' ? dictionary?.unblock : dictionary?.block}
            status={visitorStatus === 'active' ? 'block_user' : 'unblock_user'}
            clientId={clientId}
            buttonTitle={visitorStatus === 'blocked' ? dictionary?.unblock : dictionary?.block}
            submitButtonHandler={() => {
                if (visitorStatus === 'active') {
                    changeVisitorStatusHandler('block', selectedPeriod, selectedNumber);
                } else {
                    changeVisitorStatusHandler('unblock');
                }

                cleareFields();
            }}
            cleareFields={true}
            cleareFieldsHandler={() => cleareFields()}
            submitButtonClass={
                visitorStatus === 'active' && (!selectedPeriod || !selectedNumber)
                    ? 'btn--disabled'
                    : ''
            }
            popperClass={'popper--user-status'}
            // sx={{ inset: '10px auto auto -30px!important' }}
        >
            {visitorStatus === 'active' && (
                <>
                    <FormControl>
                        <BaseSelect
                            label={dictionary?.period}
                            labelId={'periodSelect'}
                            value={selectedPeriod}
                            onChangeHandler={onChangeHandler}
                            options={setOptions(dictionary)}
                        />
                        <InputLabel id={'periodSelect'}>{dictionary?.period}</InputLabel>
                    </FormControl>
                    <TextField
                        label={dictionary?.number}
                        type="number"
                        value={selectedNumber}
                        onChange={(e) => setSelectedNumber(e.target.value)}
                    />
                </>
            )}
        </MyPopper>
    );
};

export default BlockVisitorPopper;
