import { createSlice } from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';

const messagesSlice = createSlice({
    name: 'chatMessages',
    initialState:
    {
        income: [],
        outcome: [],
        dialogsHistory: [],
    },
    reducers: {
        clearMessages: (state) => {
            state.income = [];
            state.outcome = [];
        },
        setDialogHistory: (state, action) => {
            const dialog = state.dialogsHistory.find((dialog) => dialog.id === action.payload);
            if (dialog) {
                state.income = dialog.income;
                state.outcome = dialog.outcome;
            }
        },
        excludeDialogHistory: (state, action) => {
            state.dialogsHistory = action.payload.dialogsHistory;
        }

    },

    extraReducers: (builder) => {
        builder.addMatcher(
            apiSlice.endpoints.getMessages.matchFulfilled,
            (state, action) => {
                const page = action?.meta?.arg?.originalArgs?.page;
                if (page > 1) {
                    const income = action.payload.data?.chat?.messages?.income ? action.payload.data.chat.messages.income : action.payload.data.client.chat.messages.income;
                    const outcome = action.payload.data?.chat?.messages?.outcome ? action.payload.data.chat.messages.outcome : action.payload.data.client.chat.messages.outcome;

                    state.income = state.income.concat(income);
                    state.outcome = state.outcome.concat(outcome);

                    state.dialogsHistory = state.dialogsHistory.filter((dialog) => dialog.parentId !== action.payload.data.parentId);
                    state.dialogsHistory.push({
                        id: action.meta.arg.originalArgs.id,
                        income: state.income,
                        outcome: state.outcome,
                        parentId: action.payload.data.parentId,
                        page
                    });
                } else {
                    state.income = action.payload.data?.chat?.messages?.income ? action.payload.data.chat.messages.income : action.payload.data.client.chat.messages.income;
                    state.outcome = action.payload.data?.chat?.messages?.outcome ? action.payload.data.chat.messages.outcome : action.payload.data.client.chat.messages.outcome;
                }
            }
        );
    },
});

export const { clearMessages, setDialogHistory, excludeDialogHistory } = messagesSlice.actions;

export default messagesSlice.reducer;