import { useContext, useLayoutEffect, useEffect } from 'react';
import {
  useGetVisitorsQuery,
  useGetVisitorQuery,
} from '../../../api/clientPartApi/apiVisitosSlice';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import Section from '../../../layouts/Section/Section';
import VisitorsList from './VisitorsList';
import ViewVisitor from './ViewVisitor';
import EditVisitor from './EditVisitor';

import { socket } from '../../Page';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Visitors = () => {
  const { dictionary } = useContext(PageContext);
  const {
    createSlug,
    content,
    changeContentHandler,
    skip,
    selectedElementId,
    hash,
  } = useContext(PageContainerContext);

  const [copiedText, copy] = useCopyToClipboard();

  useLayoutEffect(() => {
    if (hash.includes('#view') && content !== 'viewVisitor') {
      const id = hash.slice(hash.indexOf('_') + 1);
      changeContentHandler('viewVisitor', id, true, 'visitors');
    } else if (hash.includes('#visitor') && content !== 'editVisitor') {
      const id = hash.slice(hash.indexOf('_') + 1);
      changeContentHandler('editVisitor', id, true, 'visitors');
    } else if (!hash && content !== 'visitorsList') {
      changeContentHandler('visitorsList', '', '', 'visitors');
    }
  }, [hash]);

  const {
    data: visitors,
    isLoading: visitorsLoading,
    isFetching: visitorsFetching,
    isSuccess: visitorsSuccess,
    isError: visitorsFetchingError,
    error: visitorsError,
    refetch: fetchVisitors,
  } = useGetVisitorsQuery(
    {
      limit: createSlug('limit'),
      page: createSlug('page'),
      sortBy: createSlug('sort_by'),
      sortOrder: createSlug('sort_order'),
      search: createSlug('q'),
      sites: createSlug('sites', 'array'),
      status: createSlug('status'),
    },
    { skip }
  );

  const {
    data: selectedVisitor,
    isLoading: selectedVisitorLoading,
    isFetching: selectedVisitorFetching,
    isSuccess: selectedVisitorSuccess,
    isError: selectedVisitorFetchingError,
  } = useGetVisitorQuery(
    {
      id:
        content === 'editVisitor' || content === 'viewVisitor'
          ? selectedElementId
          : '',
      view: content === 'viewVisitor' ? '/view' : '',
    },
    { skip: !skip }
  );

  const visitorsList = visitors ? visitors?.data?.visitors?.data : [];
  const visitorsListPaginationData = visitors?.data?.visitors || {};
  const visitor = selectedVisitor?.data || '';

  useEffect(() => {
    const updateVisitorsList = (data) => {
      // console.log(data);
      if (content === 'visitorsList') {
        fetchVisitors();
      }
    };

    socket.on('visitor.list', updateVisitorsList);

    return () => {
      socket.off('visitor.list', updateVisitorsList);
    };
  }, [content]);

  return (
    <>
      <Section
        isLoading={visitorsLoading || selectedVisitorLoading}
        isFetching={visitorsFetching || selectedVisitorFetching}
        isError={visitorsFetchingError || selectedVisitorFetchingError}
        error={visitorsError}
        title={
          content === 'visitorsList'
            ? dictionary?.visitors_list
            : content === 'editVisitor'
            ? dictionary?.edit_visitor
            : dictionary?.view_visitor
        }
        sx={{ maxWidth: content === 'visitorsList' ? 1490 : 540 }}
        content={content}
      >
        {(!content || content === 'visitorsList') && (
          <VisitorsList
            copy={copy}
            copiedText={copiedText}
            visitorsList={visitorsList}
            visitorsSuccess={visitorsSuccess}
            changeContentHandler={changeContentHandler}
            paginationData={visitorsListPaginationData}
          />
        )}
        {content === 'viewVisitor' && (
          <ViewVisitor
            visitor={visitor}
            copy={copy}
            copiedText={copiedText}
            tooltipPosition={'top'}
          />
        )}
        {content === 'editVisitor' && <EditVisitor visitor={visitor} />}
      </Section>
    </>
  );
};

export default Visitors;
