import { apiSlice } from '../apiSlice';

const apiNotificationsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: (query) =>
                `admin/notifications${query.limit}${query.page || ''}${query.sortBy || ''}${query.sortOrder || ''}${query.status || ''}${query.range_start || ''}${query.range_end || ''}${query.count || ''}`,
            providesTags: ['Notifications'],
        }),
        changeNotificationStatus: builder.mutation({
            query: (notification) => ({
                url: `admin/notifications/${notification.id}/${notification.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Notifications'],
        }),
        createNotification: builder.mutation({
            query: (notification) => ({
                url: 'admin/notifications',
                method: 'POST',
                body: notification,
            }),
            invalidatesTags: ['Notifications'],
        }),
        getNotification: builder.query({
            query: (id) => ({
                url: `admin/notifications/${id}`,
            }),
            providesTags: ['Notifications'],
        }),
        editNotification: builder.mutation({
            query: (notification) => ({
                url: `admin/notifications/${notification.id}`,
                method: 'PUT',
                body: notification.body,
            }),
            invalidatesTags: ['Notifications'],
        }),
        deleteNotification: builder.mutation({
            query: (id) => ({
                url: `admin/notifications/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Notifications'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetNotificationsQuery,
    useChangeNotificationStatusMutation,
    useCreateNotificationMutation,
    useGetNotificationQuery,
    useEditNotificationMutation,
    useDeleteNotificationMutation,
} = apiNotificationsSlice;
