import { useState, useContext, useEffect } from 'react';
import {
    useGetClientNotificationsQuery,
    useEvaluateNotificationMutation,
    useSendFeedbackOnNotificationMutation,
    useViewNotificationsMutation,
} from '../../api/clientPartApi/apiClientPartNotificationsSlice';

import {
    Box,
    Typography,
    List,
    ListItem,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';

import TblPagination from '../../components/Table/TablePagination';
import InformationBar from '../../components/InformationBar/InformationBar';

import { ReactComponent as ViewingIcon } from '../../assets/icons/hide.svg';
import { ReactComponent as SadSmileIcon } from '../../assets/icons/sad-smile.svg';
import { ReactComponent as NeutralSmileIcon } from '../../assets/icons/neutral-smile.svg';
import { ReactComponent as HappySmileIcon } from '../../assets/icons/happy-smile.svg';
import { ReactComponent as SendMessageIcon } from '../../assets/icons/send-message.svg';

import { socket } from '../../pages/Page';

import { PageContext } from '../../pages/Page';

const HeaderNotifications = ({
    showNotifications,
    setShowNotifications,
    skipNotifications,
    setSkipNotifications,
    fetchNewNotifications,
}) => {
    const { alertMessageContent, dictionary, language } = useContext(PageContext);

    const [messages, setMessages] = useState({});
    const [pageNumber, setPageNumber] = useState('');

    const {
        data: notifications,
        isLoading: notificationsLoading,
        isFetching: notificationsFetching,
        isSuccess: notificationsSuccess,
        isError: notificationsFetchingError,
        refetch: fetchNotifications,
    } = useGetClientNotificationsQuery({ page: `&page=${pageNumber}` });

    const [
        viewNotifications,
        { isLoading: viewedNotificationsLoading, isFetching: viewedNotificationsFetching },
    ] = useViewNotificationsMutation();

    const [
        evaluateNotification,
        { isLoading: evaluatedNotificationLoading, isFetching: evaluatedNotificationFetching },
    ] = useEvaluateNotificationMutation();

    const [sendFeedback, { isLoading: sendFeedbackLoading, isFetching: sendFeedbackFetching }] =
        useSendFeedbackOnNotificationMutation();

    const notificationsList = notifications?.data?.notifications?.data || [];
    const notificationsListPaginationData = notifications?.data?.notifications || {};
    const notificationsListIds = notificationsList
        .map(({ id, viewed }) => {
            if (!viewed) {
                return id;
            }
        })
        .filter((item) => item);

    useEffect(() => {
        socket.on('notification.publish', (data) => {
            fetchNotifications();
            fetchNewNotifications();
        });

        socket.on('notification.unpublish', (data) => {
            fetchNotifications();
            fetchNewNotifications();
        });

        socket.on('notification.delete', (data) => {
            fetchNotifications();
            fetchNewNotifications();
        });
    }, []);

    useEffect(() => {
        if (!skipNotifications) {
            if (notificationsListIds.length !== 0) {
                notificationsListIds.forEach((id) => {
                    viewNotifications(id).unwrap().then().catch();
                });
            }
        }
    }, [skipNotifications, notifications]);

    const evaluateNotificationHandler = (id, evaluation) => {
        evaluateNotification({ id, evaluation })
            .unwrap()
            .then()
            .catch((error) => {
                alertMessageContent(dictionary?.something_go_wrong, 'error');
            });
    };

    const sendFeedbackHandler = (id, feedback) => {
        sendFeedback({ id, body: feedback })
            .unwrap()
            .then((res) => {
                alertMessageContent(dictionary?.message_send, 'success');
            })
            .catch((error) => {
                alertMessageContent(dictionary?.message_not_send, 'error');
            });
    };

    return (
        <InformationBar
            title={dictionary?.notifications}
            show={showNotifications}
            setShow={setShowNotifications}
            setSkip={setSkipNotifications}
            isLoading={
                notificationsLoading ||
                viewedNotificationsLoading ||
                evaluatedNotificationLoading ||
                sendFeedbackLoading
            }
            isFetching={
                notificationsFetching ||
                viewedNotificationsFetching ||
                evaluatedNotificationFetching ||
                sendFeedbackFetching
            }
            isSuccess={notificationsSuccess}
            isError={notificationsFetchingError}
            sx={{ width: 420 }}
        >
            <List className="information-bar__body">
                {notificationsList.map(
                    ({ id, evaluation, feedback, feedback_sended, name, content, views }) => {
                        return (
                            <ListItem key={id}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            lineHeight: 1.375,
                                            marginBottom: 1.25,
                                        }}
                                    >
                                        {name?.[language]}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            maxHeight: 80,
                                            overflowY: 'auto',
                                            wordBreak: 'break-all',
                                            '& p': { margin: 0 },
                                        }}
                                        variant="body2"
                                        dangerouslySetInnerHTML={{ __html: content?.[language] }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: 'auto',
                                        justifyContent: 'space-between',
                                        '& .MuiBox-root': {
                                            display: 'flex',
                                            gap: 1.25,
                                        },
                                    }}
                                >
                                    <Box>
                                        <ViewingIcon />
                                        <Typography>{views}</Typography>
                                    </Box>
                                    {evaluation && (
                                        <Box
                                            sx={{
                                                '& svg': {
                                                    cursor: 'pointer',
                                                },
                                                '& svg path': {
                                                    fill: 'basicColor.fill900',
                                                },
                                                '& svg.negative path': {
                                                    fill: '#FD4D5C',
                                                },
                                                '& svg.neutral path': {
                                                    fill: '#196CA7',
                                                },
                                                '& svg.positive path': {
                                                    fill: '#6CB351',
                                                },
                                            }}
                                        >
                                            <SadSmileIcon
                                                className={
                                                    evaluation?.value === 'negative'
                                                        ? 'negative'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    if (!evaluation?.value) {
                                                        evaluateNotificationHandler(id, 'negative');
                                                    }
                                                }}
                                            />
                                            <NeutralSmileIcon
                                                className={
                                                    evaluation?.value === 'neutral' ? 'neutral' : ''
                                                }
                                                onClick={() => {
                                                    if (!evaluation?.value) {
                                                        evaluateNotificationHandler(id, 'neutral');
                                                    }
                                                }}
                                            />
                                            <HappySmileIcon
                                                className={
                                                    evaluation?.value === 'positive'
                                                        ? 'positive'
                                                        : ''
                                                }
                                                onClick={() => {
                                                    if (!evaluation?.value) {
                                                        evaluateNotificationHandler(id, 'positive');
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                {feedback && !feedback_sended && (
                                    <TextField
                                        onChange={(event) => {
                                            setMessages({
                                                ...messages,
                                                [id]: event.target.value,
                                            });
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        sx={{
                                                            opacity: messages[id] ? 1 : 0.2,
                                                            pointerEvents: messages[id]
                                                                ? 'all'
                                                                : 'none',
                                                        }}
                                                        onClick={() => {
                                                            sendFeedbackHandler(id, {
                                                                message: messages[id],
                                                            });
                                                        }}
                                                    >
                                                        <SendMessageIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            </ListItem>
                        );
                    }
                )}
            </List>
            <TblPagination
                data={notificationsListPaginationData}
                withoutQueryParams={true}
                setPageNumber={setPageNumber}
                siblingCount={1}
                size="small"
                boundaryCount={0}
            />
        </InformationBar>
    );
};

export default HeaderNotifications;
