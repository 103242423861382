import { apiSlice } from '../apiSlice';

const apiTariffSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTariff: builder.query({
            query: () => ({
                url: `/client/functional`,
            }),
            providesTags: ['Tariff'],
        }),
        tariffAdjust: builder.mutation({
            query: (tariff) => ({
                url: `client/tariff`,
                method: 'POST',
                body: tariff,
            }),
            invalidatesTags: ['Tariff'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetTariffQuery,
    useTariffAdjustMutation,
    // useChangeSiteStatusMutation,
} = apiTariffSlice;
