import { Typography } from '@mui/material';

const TextButton = ({ title, buttonHandler, sx = {} }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                color: 'colorAccent.fifth',
                fontWeight: 600,
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
                ...sx,
            }}
            onClick={buttonHandler}
        >
            {title}
        </Typography>
    );
};

export default TextButton;
