import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Spinner = ({ lazy, sx = {} }) => {
    return (
        <Box
            sx={{
                position: lazy ? 'relative' : 'absolute',
                zIndex: 2,
                top: 0,
                left: 0,
                width: lazy ? '100%' : '100%',
                height: lazy ? '100vh' : '100%',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: lazy ? 'basicColor.color100' : 'rgb(89, 95, 107, 0.3)',
                borderRadius: 2.5,
                ...sx,
            }}
        >
            <CircularProgress sx={{ color: 'secondaryColor.main' }} />
        </Box>
    );
};

export default Spinner;
