import { useEffect, useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField, InputAdornment } from '@mui/material';

import { PageContext } from '../../../pages/Page';

const GeneralInput = ({
  fieldName,
  label,
  receivedValue = '',
  type = 'text',
  multiline = false,
  units = '',
  sx = {},
  disabled = false,
  placeholder = '',
  getCurrentValue,
  setCurrentValue,
  required = true,
  maxNumOfCharacters = '',
  helperText = true,
  clearReceivedValue = false,
  ...rest
}) => {
  const { serverValidationError, setServerValidationError } =
    useContext(PageContext);

  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const [isAutoFill, setIsAutoFill] = useState(false);

  useEffect(() => {
    if (disabled || required) {
      clearErrors(fieldName);
    }
  }, [disabled, required]);

  useEffect(() => {
    if (getCurrentValue) {
      const subscription = watch((fieldName) => {
        setCurrentValue(fieldName);
      });

      return () => subscription.unsubscribe();
    }
  }, [watch]);

  useEffect(() => {
    if (receivedValue) {
      setValue(fieldName, receivedValue);
    } else if (!receivedValue || clearReceivedValue) {
      setValue(fieldName, '');
    }
  }, [receivedValue, clearReceivedValue]);

  const removeError = () => {
    if (errors[fieldName]) {
      clearErrors(fieldName);
    }

    if (serverValidationError?.length) {
      setServerValidationError([]);
    }
  };

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={''}
        rules={
          {
            // required,
          }
        }
        render={({ field }) => (
          <TextField
            {...field}
            sx={{ ...sx }}
            type={type}
            label={label}
            error={
              !!errors[fieldName] ||
              !!serverValidationError?.includes(fieldName)
              // &&
              // required
            }
            helperText={
              errors[fieldName] &&
              required &&
              type === 'text' &&
              (!multiline || (multiline && maxNumOfCharacters)) &&
              helperText
                ? `${getValues(fieldName)?.length}/${
                    maxNumOfCharacters || '100'
                  }`
                : null
            }
            onFocus={removeError}
            multiline={multiline}
            disabled={disabled}
            placeholder={placeholder}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className="units"
                  sx={{
                    display: units ? 'flex' : 'none',
                  }}
                  position="end"
                >
                  {units}
                </InputAdornment>
              ),
            }}
            inputProps={{
              onAnimationStart: (e) => {
                e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
              },
              onAnimationEnd: (e) =>
                e.animationName === 'mui-auto-fill-cancel' &&
                setIsAutoFill(false),
            }}
            onKeyDown={(e) =>
              type === 'number' &&
              ['e', 'E', '+', '-', '.', ','].includes(e.key) &&
              e.preventDefault()
            }
            InputLabelProps={{
              shrink: isAutoFill || undefined,
            }}
            {...rest}
          />
        )}
      />
    </>
  );
};

export default GeneralInput;
