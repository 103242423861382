import { useContext, useCallback, useState, useLayoutEffect } from 'react';

import { Grid, InputAdornment } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ChannelsAutocompleteSelect from '../../../components/Form/FormSelect/AutocompleteSelect/ChannelsAutocompleteSelect';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

import { setRoleSelect as setRoleOptions } from '../../../data/formSelectData';

import { arrayToQueryString, setFilterParams } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const currentQueryParams = ['role_id', 'sites', 'email', 'site'];

const EmployeesFilter = ({ selectQuerySkip }) => {
    const { dictionary, clientCheck } = useContext(PageContext);
    const { queryParams, setQueryParams, pathname } = useContext(PageContainerContext);

    const [employeeId, setEmployeeId] = useState('');

    useLayoutEffect(() => {
        if (pathname.startsWith('/admin/clients/client_')) {
            const id = pathname.slice(pathname.indexOf('-') + 1);

            setEmployeeId(id);
        }
    }, [pathname]);

    const newRoleOptions = setRoleOptions(dictionary).slice(1, 3);

    const formSubmitHandler = useCallback(
        (data) => {
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }
                    const newData = arrayToQueryString(data.sites, 'sites');

                    setFilterParams(newData, 'sites', params);
                    setFilterParams(data.role_id, 'role_id', params);
                    setFilterParams(data.email, 'email', params);
                    setFilterParams(data.site, 'site', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
            formSx={{ maxWidth: 700 }}
        >
            <Grid item>
                <SelectWithFormContext
                    fieldName={'role_id'}
                    label={dictionary?.role}
                    labelId={'roleSelect'}
                    options={newRoleOptions}
                    emtyOption={true}
                    receivedValue={queryParams.get('role_id')}
                />
            </Grid>
            <Grid item>
                {!selectQuerySkip && (
                    <ChannelsAutocompleteSelect queryParams={queryParams} clientId={employeeId} />
                )}
            </Grid>
            {!clientCheck && (
                <>
                    <GeneralInput
                        fieldName={'email'}
                        placeholder={dictionary?.employees_filter_placeholder1}
                        receivedValue={queryParams.get('email')}
                        sx={{ maxWidth: 320, width: '100%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <GeneralInput
                        fieldName={'site'}
                        placeholder={dictionary?.employees_filter_placeholder2}
                        receivedValue={queryParams.get('site')}
                        sx={{ maxWidth: 320, width: '100%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </>
            )}
        </FilterFormContainer>
    );
};

export default EmployeesFilter;
