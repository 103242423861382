import { useContext } from 'react';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as IsAnsweredIcon } from '../../../assets/icons/is-answered.svg';
import { ReactComponent as DidNotAnsweredIcon } from '../../../assets/icons/did-not-answered.svg';
import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';

import { dateConverter, setStatusLabel } from '../../../helpers/functions';

import {
  setAdminPartAppealsTableHeadings as setAdminHeadCells,
  setClientPartAppealsTableHeadings as setClientHeadCellas,
} from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const AppealsList = ({
  appealsList,
  appealsSuccess,
  changeContentHandler,
  paginationData,
}) => {
  const { role, clientCheck, navigate, dictionary, language } =
    useContext(PageContext);

  return (
    <>
      <MyToolbar
        filter={true}
        moveToLeft={clientCheck ? true : false}
        changeContentButton={clientCheck || false}
        search={!clientCheck ? dictionary?.appeals_search_placeholder : null}
        buttonSx={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}
        buttonClass={'btn--small'}
        paginationData={paginationData}
        buttonHandler={() => {
          changeContentHandler('createAppeal');
          navigate('/support#create');
        }}
      />
      <TblContainer tableClass={'table-main--big'}>
        <TblHead
          setHeadCells={clientCheck ? setClientHeadCellas : setAdminHeadCells}
        />
        <TableBody>
          {appealsList.length === 0 && appealsSuccess && <EmptyListAlertText />}
          {appealsList.length > 0 &&
            appealsList.map(
              ({
                id,
                is_answered,
                subject,
                status,
                client,
                created_at,
                last_message_at,
                notification,
              }) => {
                return (
                  <TableRow className="table-main__row" key={id}>
                    {!clientCheck && (
                      <TableCell className="ellipsis">
                        <MyTooltip
                          position={'top'}
                          title={id}
                          arrow={false}
                          interactive={false}
                        >
                          <Typography>{id}</Typography>
                        </MyTooltip>
                      </TableCell>
                    )}
                    {!clientCheck && (
                      <TableCell>
                        <Box
                          sx={{
                            display: 'inline-flex',
                            gap: 1.25,
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            {is_answered ? (
                              <IsAnsweredIcon />
                            ) : (
                              <DidNotAnsweredIcon />
                            )}
                          </Box>
                          <Typography>{subject}</Typography>
                        </Box>
                      </TableCell>
                    )}
                    {clientCheck && <TableCell>{subject}</TableCell>}
                    {!clientCheck && <TableCell>{client.fullname}</TableCell>}
                    <TableCell className="status">
                      <Typography className={status}>
                        {setStatusLabel(status, dictionary)}
                      </Typography>
                    </TableCell>
                    {!clientCheck && (
                      <TableCell>
                        {notification?.name?.[language]
                          ? notification?.name?.[language]
                          : '-'}
                      </TableCell>
                    )}

                    <TableCell>
                      <Typography>
                        {dictionary?.created}: {dateConverter(created_at)}
                      </Typography>
                      <Typography>
                        {dictionary?.last_message_abbr}: {''}
                        {dateConverter(last_message_at)}
                      </Typography>
                    </TableCell>
                    <TableCell className="action">
                      <MyTooltip position={'top'} title={dictionary?.viewing}>
                        <ViewingIcon
                          onClick={() => {
                            changeContentHandler(
                              'viewAppeal',
                              id,
                              true,
                              'support'
                            );

                            if (role === 'mng') {
                              navigate(`/admin/support#appeal_${id}`);
                            } else if (clientCheck) {
                              navigate(`/support#appeal_${id}`);
                            } else {
                              navigate(`/admin/support/appeals#appeal_${id}`);
                            }
                          }}
                        />
                      </MyTooltip>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </TblContainer>
      <TblPagination data={paginationData} invalidateTag={'Appeals'} />
    </>
  );
};

export default AppealsList;
