import { Typography } from '@mui/material';

const Title = ({ title, heading, sx, ...rest }) => {
  return (
    <Typography sx={{ ...sx }} variant={heading} {...rest}>
      {title}
    </Typography>
  );
};

export default Title;
