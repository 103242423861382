import { useCallback, useState, useContext, useLayoutEffect } from 'react';
import {
  useGetPersonalInformationQuery,
  useChangePersonalInformationMutation,
  useVerificationRequestMutation,
  useDeleteAvatarMutation,
} from '../../api/clientPartApi/apiPersonalInformationSlice';

import { Box, Typography } from '@mui/material';
import { FlexBox } from '../../mui/theme';

import Section from '../../layouts/Section/Section';
import FormContainer from '../../components/Form/FormContainer';
import GeneralInput from '../../components/Form/FormInputs/GeneralInput';
import Title from '../../components/ui/Title/Title';
import TextButton from '../../components/ui/Button/TextButton';
import UploadFile from '../../components/Form/UploadFile/UploadFile';
import SinglePhoneSelect from '../../components/Form/FormSelect/PhoneSelect/SinglePhoneSelect';

import { ReactComponent as ConfirmIcon } from '../../assets/icons/confirmed.svg';

import { personalInformationSchema as schema } from '../../settings/formSchema';

import { PageContext } from '../../pages/Page';

const PersonalInformationForm = () => {
  const {
    setAlertMessage,
    alertMessageContent,
    setServerValidationError,
    dictionary,
  } = useContext(PageContext);

  // const [verificationField, setVerificationField] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  const {
    data: information,
    isFetching: informationFetching,
    isLoading: informationLoading,
    isSuccess: informationSuccess,
    isError: informationFetchingError,
    error: informationError,
  } = useGetPersonalInformationQuery();

  const [
    editPersonaliInformation,
    {
      isLoading: changeiInformationloading,
      isFetching: changeiInformationFetching,
    },
  ] = useChangePersonalInformationMutation();

  const [
    verificationRequest,
    {
      isLoading: verificationRequestloading,
      isFetching: verificationRequestFetching,
      isError: verificationRequestFetchingError,
      isSuccess: verificationRequestSuccess,
    },
  ] = useVerificationRequestMutation();

  const [
    deleteAvatar,
    { isLoading: deleteAvatarloading, isFetching: deleteAvatarFetching },
  ] = useDeleteAvatarMutation();

  const client = information?.data || [];

  const deleteAvatarHandler = () => {
    deleteAvatar().unwrap();
  };

  const formSubmitHandler = useCallback(
    (data) => {
      const formData = new FormData();

      formData.append('surname', data.surname);
      formData.append('name', data.name);
      formData.append('patronymic', data.patronymic);
      formData.append('email', data.email);
      formData.append('phone', data.phone);

      if (data?.phone_data) {
        formData.append('phone_data', JSON.stringify(data.phone_data));
      }

      if (data?.file && data?.file[0]?.preview) {
        formData.append('file', data.file[0]);
        formData.append('fileName', data.file[0].name);
      }

      if (!phoneValidationError) {
        editPersonaliInformation(formData)
          .unwrap()
          .then(() => {
            alertMessageContent(
              dictionary?.personal_information_success,
              'success'
            );
            setIsSubmited(false);
          })
          .catch((error) => {
            if (error?.data?.errors?.email) {
              setServerValidationError(['email']);
              alertMessageContent(dictionary?.phone_exists, 'error');
            } else if (error?.data?.errors?.phone) {
              alertMessageContent(dictionary?.phone_error, 'error');
            } else {
              alertMessageContent(dictionary?.something_go_wrong, 'error');
            }
          });
      }
    },
    [phoneValidationError]
  );

  const confirmEmailHandler = useCallback(() => {
    setAlertMessage({});

    verificationRequest('email')
      .unwrap()
      .then((res) => {
        alertMessageContent(dictionary?.code_sent, 'success');
      })
      .catch((error) => {
        console.log(error);
        setServerValidationError(['email']);
        alertMessageContent(dictionary?.email_not_confirmed, 'error');
      });
  }, []);

  const confirmPhoneHandler = useCallback(() => {
    // setAlertMessage({});
    // verificationRequest('phone')
    //     .unwrap()
    //     .then(
    //         (res) => console.log(res)
    //         //  setVerificationField('phone')
    //     )
    //     .catch((error) => {
    //         console.log(error);
    //         setServerValidationError(['phone']);
    //         alertMessageContent(dictionary?.phone_not_confirmed, 'error');
    //     });
  }, []);

  return (
    <Section
      title={dictionary?.user_settings}
      sx={{ maxWidth: 730, marginBottom: 3.75 }}
      isError={informationFetchingError}
      error={informationError}
    >
      <FormContainer
        // key={informationSuccess}
        schema={schema}
        submitButtonTitle={dictionary?.save}
        submitButtonClass={'btn--primary'}
        submitButtonHandler={() => setIsSubmited(true)}
        useButtonHandler={true}
        formSubmitHandler={formSubmitHandler}
        isLoading={
          informationLoading ||
          changeiInformationloading ||
          verificationRequestloading ||
          deleteAvatarloading
        }
        isFetching={
          informationFetching ||
          changeiInformationFetching ||
          verificationRequestFetching ||
          deleteAvatarFetching
        }
      >
        <Title
          heading="h3"
          title={dictionary?.personal_information}
          sx={{ color: 'secondaryColor.main' }}
        />
        <GeneralInput
          fieldName={'surname'}
          label={dictionary?.surname}
          receivedValue={client?.surname || ''}
        />
        <GeneralInput
          fieldName={'name'}
          label={dictionary?.first_name}
          receivedValue={client?.name || ''}
        />
        <GeneralInput
          fieldName={'patronymic'}
          label={dictionary?.patronymic}
          receivedValue={client?.patronymic || ''}
        />
        <FlexBox className="row-20" alignItems="center">
          <GeneralInput
            fieldName={'email'}
            label={'E-mail'}
            receivedValue={client?.email || ''}
          />
          <Box>
            {client?.emailCheck === 0 && (
              <TextButton
                title={dictionary?.confirm_email}
                buttonHandler={confirmEmailHandler}
              />
            )}
            {client?.emailCheck === 1 && (
              <FlexBox
                className="row-8"
                alignItems="center"
                sx={{ '& svg': { flexShrink: 0 } }}
              >
                <ConfirmIcon />
                <Typography sx={{ lineHeight: 1.2 }}>
                  {dictionary?.email_confirmed}
                </Typography>
              </FlexBox>
            )}
          </Box>
        </FlexBox>
        <FlexBox className="row-20" alignItems="center">
          <SinglePhoneSelect
            fieldName={'phone'}
            dropdownWidth={480}
            receivedValue={client?.phone || ''}
            phoneValidationError={phoneValidationError}
            setPhoneValidationError={setPhoneValidationError}
            isSubmited={isSubmited}
            isSuccess={informationSuccess}
          />
          <Box>
            {client?.phoneCheck === 0 && (
              <TextButton
                title={dictionary?.confirm_phone}
                buttonHandler={confirmPhoneHandler}
              />
            )}
            {client?.phoneCheck === 1 && (
              <FlexBox
                className="row-8"
                alignItems="center"
                sx={{ '& svg': { flexShrink: 0 } }}
              >
                <ConfirmIcon />
                <Typography sx={{ lineHeight: 1.2 }}>
                  {dictionary?.phone_confirmed}
                </Typography>
              </FlexBox>
            )}
          </Box>
        </FlexBox>
        <UploadFile
          fieldName={'file'}
          title={dictionary?.avatar}
          receivedValue={client?.avatar?.file}
          file={avatar}
          setFile={setAvatar}
          deleteImage={deleteAvatarHandler}
        />
      </FormContainer>
    </Section>
  );
};

export default PersonalInformationForm;
