import { useContext } from 'react';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';
import MyLink from '../../../components/ui/Link/MyLink';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { dateConverter, setStatusLabel } from '../../../helpers/functions';

import { setVisitorsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const VisitorsList = ({
    copy,
    copiedText,
    visitorsList,
    visitorsSuccess,
    paginationData,
    changeContentHandler,
}) => {
    const { alertMessageContent, navigate, dictionary } = useContext(PageContext);

    const copyIconStyles = {
        position: 'relative',
        top: 5,
        marginLeft: 1.25,
        cursor: 'pointer',
    };

    const copyTextHandler = (text) => {
        if (copiedText === undefined) {
            copy(text);
            alertMessageContent(dictionary?.data_copied, 'success');
        }
    };

    return (
        <>
            <MyToolbar
                filter={true}
                search={dictionary?.visitors_list_search_placeholder}
                paginationData={paginationData}
                changeContentButton={false}
            />
            <TblContainer tableClass={'table-main--big'}>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {visitorsList.length === 0 && visitorsSuccess && <EmptyListAlertText />}
                    {visitorsList.length > 0 &&
                        visitorsList.map(
                            ({ id, fullname, email, phone, status, blocking = '' }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell>{fullname}</TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'secondaryColor.main',
                                                '& svg': {
                                                    ...copyIconStyles,
                                                },
                                            }}
                                        >
                                            {email}
                                            {email && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() => copyTextHandler(email)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'secondaryColor.main',
                                                '& svg': {
                                                    ...copyIconStyles,
                                                },
                                            }}
                                        >
                                            {phone
                                                ? phone.startsWith('+')
                                                    ? phone
                                                    : '+' + phone
                                                : ''}
                                            {phone && (
                                                <MyTooltip
                                                    position={'top'}
                                                    title={dictionary?.copy}
                                                >
                                                    <CopyIcon
                                                        className="copy-icon"
                                                        onClick={() => copyTextHandler(phone)}
                                                    />
                                                </MyTooltip>
                                            )}
                                        </TableCell>
                                        <TableCell className="status">
                                            <Typography className={status}>
                                                {setStatusLabel(status, dictionary)}
                                            </Typography>
                                            {blocking &&
                                                blocking.map(({ site, expiration }, index) => {
                                                    return (
                                                        <Box key={index}>
                                                            <MyLink
                                                                link={`${site?.protocol}://${site?.domain}`}
                                                                text={site?.domain}
                                                            />
                                                            <Typography>
                                                                до {dateConverter(expiration)}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}
                                        </TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.viewing}>
                                                <ViewingIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'viewVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#view_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                            <MyTooltip position={'top'} title={dictionary?.edit}>
                                                <EditIcon
                                                    onClick={() => {
                                                        changeContentHandler(
                                                            'editVisitor',
                                                            id,
                                                            true,
                                                            'visitors'
                                                        );
                                                        navigate(`/visitors/all#visitor_${id}`);
                                                    }}
                                                />
                                            </MyTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Visitors'} />
        </>
    );
};

export default VisitorsList;
