import { useState, useContext, useLayoutEffect, useEffect } from 'react';
import { useGetChannelQuery } from '../../../../api/clientPartApi/apiChannelSlice';

import Section from '../../../../layouts/Section/Section';
import ChannelForm from './ChannelForm';

import { PageContext } from '../../../Page';
import { PageContainerContext } from '../../../PageContainer';

const Channel = () => {
    const { navigate, dictionary } = useContext(PageContext);
    const { content, changeContentHandler, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    const [channelId, setChannelId] = useState('');

    useLayoutEffect(() => {
        if (hash.includes('#create') && content !== 'createChannel') {
            changeContentHandler('createChannel');
        } else if (hash.includes('#edit') && content !== 'editChannel') {
            const id = hash.substring(hash.indexOf('_') + 1, hash.lastIndexOf('#'));
            changeContentHandler('editChannel', id, true);
            navigate(`/channels/channel-form#edit_${id}#step-1`);
        }
    }, [hash]);

    function beforeUnloadHandler(e) {
        e.preventDefault();
        e.returnValue = '';
    }

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnloadHandler, true);

        return () => window.removeEventListener('beforeunload', beforeUnloadHandler, true);
    }, []);

    const {
        data: selectedChannel,
        isLoading: selectedChannelLoading,
        isFetching: selectedChannelFetching,
        isSuccess: selectedChannelSuccess,
        isError: selectedChannelFetchingError,
        error: selectedChannelError,
    } = useGetChannelQuery(content === 'editChannel' ? selectedElementId : '', {
        skip: !skip,
    });

    const channel = selectedChannelSuccess ? selectedChannel?.data : '';

    return (
        <>
            <Section
                key={channel.id === selectedElementId}
                isLoading={selectedChannelLoading}
                isFetching={selectedChannelFetching}
                isError={selectedChannelFetchingError}
                error={selectedChannelError}
                title={
                    content === 'createChannel'
                        ? dictionary?.connect_telegram
                        : dictionary?.edit_telegram
                }
                sx={{ maxWidth: 730 }}
                content={content}
            >
                <ChannelForm
                    content={content}
                    channel={channel}
                    channelId={channelId}
                    setChannelId={setChannelId}
                />
            </Section>
        </>
    );
};

export default Channel;
