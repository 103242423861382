import { Table, Box } from '@mui/material';

const TblContainer = ({ children, tableClass, tableSx = {} }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                minHeight: 322,
                ...tableSx,
            }}
        >
            <Table
                className={tableClass ? `table-main ${tableClass}` : 'table-main'}
                aria-label="table"
            >
                {children}
            </Table>
        </Box>
    );
};

export default TblContainer;
