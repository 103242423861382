import { useContext } from 'react';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { setClientStatusTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';
import { dateConverter, setStatusLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const ClientStatuses = ({ statusesList, isSuccess, isError, statusesListPaginationData }) => {
    const { dictionary } = useContext(PageContext);

    if (isError) {
        return <Typography variant="h3">ERROR</Typography>;
    }

    return (
        <>
            <TblContainer tableClass={'table-main--middle'}>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {statusesList.length === 0 && isSuccess && <EmptyListAlertText />}
                    {statusesList.length > 0 &&
                        statusesList.map(({ id, datetime, status, user }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell>{dateConverter(datetime)}</TableCell>
                                    <TableCell className="status">
                                        <Typography className={status}>
                                            {setStatusLabel(status, dictionary)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{user?.login}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={statusesListPaginationData} invalidateTag={'Clients'} />
        </>
    );
};

export default ClientStatuses;
