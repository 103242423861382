import { manager, admin, superAdmin } from './AdminPartUsers';
import { operator, supervisor, admin as clientAdmin } from './ClientPartUsers';

export const users = [
    {
        role: manager.id,
        routeList: manager.routeList,
    },
    {
        role: admin.id,
        routeList: admin.routeList,
    },
    {
        role: superAdmin.id,
        routeList: superAdmin.routeList,
    },
    {
        role: operator.id,
        routeList: operator.routeList,
    },
    {
        role: supervisor.id,
        routeList: supervisor.routeList,
    },
    {
        role: clientAdmin.id,
        routeList: clientAdmin.routeList,
    },
];
