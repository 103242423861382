import { apiSlice } from '../apiSlice';

const apiSitesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSites: builder.query({
            query: (query) => ({
                url: `client/sites${query.limit}${query.page}${query.sortBy}${query.sortOrder}`,
            }),
            providesTags: ['Channels'],
        }),
        getAllSites: builder.query({
            query: (query) => ({
                url: `client/sites/all${query.type}`,
            }),
            invalidatesTags: ['Channels'],
        }),
        getSite: builder.query({
            query: (id) => ({
                url: `client/sites/${id}`,
            }),
            providesTags: ['Channels'],
        }),
        changeSiteStatus: builder.mutation({
            query: (site) => ({
                url: `client/sites/${site.id}/${site.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Channels'],
        }),
        deleteSite: builder.mutation({
            query: (id) => ({
                url: `client/sites/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Channels'],
        }),
        chooseDefaultLanguage: builder.mutation({
            query: (data) => ({
                url: `client/sites/${data.id}/language`,
                method: 'POST',
                body: data.body,
            }),
            invalidatesTags: ['Channels'],
        }),
        checkDomain: builder.mutation({
            query: (data) => ({
                url: `client/sites/domain/check?${data.domain}${data.id}`,
                method: 'POST',
            }),
        }),
        verifyDomain: builder.mutation({
            query: (data) => ({
                url: `client/sites/domain/verify?${data.domain}&verification_code=${data.code}`,
                method: 'POST',
            }),
        }),
        checkWidjetIntegration: builder.mutation({
            query: (data) => ({
                url: `client/sites/integration/check?${data.domain}&integration_code=${data.code}`,
                method: 'POST',
            }),
        }),
        createSite: builder.mutation({
            query: (site) => ({
                url: `client/sites`,
                method: 'POST',
                body: site,
            }),
            invalidatesTags: ['Channels'],
        }),
        editSite: builder.mutation({
            query: (site) => ({
                url: `client/sites/${site.id}/edit`,
                method: 'POST',
                body: site.body,
            }),
            invalidatesTags: ['Channels'],
        }),
        resetDomain: builder.mutation({
            query: (id) => ({
                url: `client/sites/${id}/domain/reset`,
                method: 'POST',
            }),
            invalidatesTags: ['Channels'],
        }),
        deleteLogo: builder.mutation({
            query: (id) => ({
                url: `client/sites/${id}/logo`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Channels'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSitesQuery,
    useGetSiteQuery,
    useGetAllSitesQuery,
    useChangeSiteStatusMutation,
    useDeleteSiteMutation,
    useChooseDefaultLanguageMutation,
    useCheckDomainMutation,
    useVerifyDomainMutation,
    useCheckWidjetIntegrationMutation,
    useCreateSiteMutation,
    useEditSiteMutation,
    useResetDomainMutation,
    useDeleteLogoMutation,
} = apiSitesSlice;
