import { useState, useContext, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

import { ReactComponent as SortingIcon } from '../../assets/icons/bold-arrow.svg';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';

const TblHead = ({
    setHeadCells,
    sortByParametr = 'sort_by',
    sortOrderParametr = 'sort_order',
    setSortByWithoutQuery,
    setSortOrderWithoutQuery,
    setSortWithoutQueryString = false,
}) => {
    const { dictionary } = useContext(PageContext);
    const { setQueryParams, queryParams } = useContext(PageContainerContext);

    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');

    useEffect(() => {
        if (!queryParams.get(sortByParametr)) {
            setOrder('');
        }
    }, [queryParams.get(sortByParametr)]);

    const handleSortRequest = (id) => {
        if ((id === orderBy && order === 'asc') || (!orderBy && order === 'asc')) {
            setOrder('desc');
        } else {
            setOrder('asc');
        }

        setCurrentQueryParams(id);
    };

    const setCurrentQueryParams = (id) => {
        if (setSortWithoutQueryString) {
            setSortByWithoutQuery(id);
        } else {
            setQueryParams(
                (params) => {
                    params.set(sortByParametr, id);

                    return params;
                },
                { replace: true }
            );
        }

        if (setSortWithoutQueryString) {
            setSortOrderWithoutQuery(
                id !== orderBy || (id === orderBy && order !== 'asc') ? 'asc' : 'desc'
            );
        } else {
            setQueryParams(
                (params) => {
                    params.set(
                        sortOrderParametr,
                        id !== orderBy || (id === orderBy && order !== 'asc') ? 'asc' : 'desc'
                    );

                    return params;
                },
                { replace: true }
            );
        }
    };

    return (
        <TableHead>
            <TableRow>
                {setHeadCells(dictionary).map(({ id, title, disableSorting, width }) => (
                    <TableCell
                        key={uuidv4()}
                        sx={{
                            width,
                        }}
                    >
                        {disableSorting ? (
                            title
                        ) : (
                            <TableSortLabel
                                sx={{
                                    gap: 1.25,
                                    '&:focus': {
                                        color: 'basicColor.colorWhite',
                                    },
                                    '& svg': {
                                        flexShrink: 0,
                                    },
                                    '& svg path:first-of-type': {
                                        opacity: order === 'desc' && id === orderBy ? 1 : 0.5,
                                    },
                                    '& svg path:last-of-type': {
                                        opacity: order === 'asc' && id === orderBy ? 1 : 0.5,
                                    },
                                }}
                                active={orderBy === id}
                                onClick={() => {
                                    setOrderBy(id);
                                    handleSortRequest(id);
                                }}
                                IconComponent={() => <SortingIcon />}
                            >
                                {title}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TblHead;
