import { useContext } from 'react';
import { useDeleteCommandMutation } from '../../../api/clientPartApi/apiTemplatesSlice';

import { TableBody, TableRow, TableCell, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

import { setCommandsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const CommandsList = ({ commandsList, commandsSuccess, paginationData, changeContentHandler }) => {
    const { navigate, dictionary } = useContext(PageContext);

    const [deleteCommand] = useDeleteCommandMutation();

    const deleteCommandHandler = (id) => {
        deleteCommand(id).unwrap();
    };

    return (
        <>
            <MyToolbar
                filter={true}
                moveToLeft={true}
                search={dictionary?.command_search_placeholder}
                buttonSx={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}
                buttonClass={'btn--small'}
                paginationData={paginationData}
                buttonHandler={() => {
                    changeContentHandler('createCommand', '', '', 'templates');
                    navigate('/templates/commands#create');
                }}
            />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {commandsList.length === 0 && commandsSuccess && <EmptyListAlertText />}
                    {commandsList.length > 0 &&
                        commandsList.map(({ id, trigger, text, categories }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell>{`* ${trigger}`}</TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{ '& p': { margin: 0 }, wordBreak: 'break-all' }}
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {categories.map((categorie, index) => {
                                            if (
                                                (categories.length === index + 1 &&
                                                    categories.length > 1) ||
                                                categories.length === 1
                                            ) {
                                                return categorie.name;
                                            } else {
                                                return categorie.name + ', ';
                                            }
                                        })}
                                    </TableCell>
                                    <TableCell className="action">
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <EditIcon
                                                onClick={() => {
                                                    changeContentHandler(
                                                        'editCommand',
                                                        id,
                                                        true,
                                                        'templates'
                                                    );
                                                    navigate(`/templates/commands#command_${id}`);
                                                }}
                                            />
                                        </MyTooltip>
                                        <MyTooltip position={'top'} title={dictionary?.delete}>
                                            <DeleteIcon onClick={() => deleteCommandHandler(id)} />
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={paginationData} invalidateTag={'Commands'} />
        </>
    );
};

export default CommandsList;
