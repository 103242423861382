import { useContext } from 'react';

import { Box, Typography } from '@mui/material';

import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import EmployeesForm from '../../clientPartPages/Employees/EmployeesForm';
import Title from '../../../components/ui/Title/Title';
import { FlexBox } from '../../../mui/theme';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as ConfirmedIcon } from '../../../assets/icons/confirmed.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checked.svg';

import { setStatusLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const ClientData = ({
  client,
  copy,
  copiedText,
  actionType,
  employeeAdminId,
  tabValue,
}) => {
  const { alertMessageContent, dictionary, navigate, content } =
    useContext(PageContext);
  const { hash } = useContext(PageContainerContext);

  const copyTextHandler = (text) => {
    if (copiedText === undefined) {
      alertMessageContent(dictionary?.data_copied, 'success');
      copy(text);
    }
  };

  const returnToPreviousSection = () => {
    if (!hash || hash === '#edit') {
      navigate('/admin/clients');
    } else {
      navigate(`admin/clients/client_adm-${employeeAdminId}?tab=4`);
    }
  };

  const setSectionSubtitle = () => {
    if (actionType === 'edit' && tabValue !== undefined) {
      return dictionary?.edit_client;
    } else if (actionType === 'edit') {
      return dictionary?.edit_user;
    } else {
      return dictionary?.invite_user;
    }
  };

  const renderCopyIcon = (copiedText, translatedCopiedText) => {
    return (
      <MyTooltip position={'top'} title={dictionary?.copy} key={copiedText}>
        <CopyIcon
          style={{
            opacity: copiedText ? 1 : 0,
            visibility: copiedText ? 'visible' : 'hidden',
          }}
          className="copy-icon"
          onClick={() => copyTextHandler(translatedCopiedText || copiedText)}
        />
      </MyTooltip>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {actionType === 'view' && (
        <Box
          sx={{
            display: 'flex',
            marginBottom: client?.role === 'adm' ? 0 : 2.5,
          }}
        >
          <FlexBox
            className="row-30"
            sx={{ minWidth: '250px!important', width: 'auto' }}
          >
            <FlexBox
              className="col-10"
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: 700,
                  height: 22,
                },
              }}
            >
              <Typography>{dictionary?.full_name}:</Typography>
              <Typography>E-mail:</Typography>
              <Typography>{dictionary?.phone}:</Typography>
              <Typography>{dictionary?.status}:</Typography>
              {client?.role === 'adm' && (
                <Typography> {dictionary?.channels}: </Typography>
              )}
            </FlexBox>
            <FlexBox
              className="col-10"
              sx={{
                '& .MuiTypography-root': { height: 22 },
                maxWidth: client?.role === 'adm' ? '100%' : 268,
              }}
            >
              <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {client?.fullname || ''}
              </Typography>
              <Typography
                sx={{
                  color: 'secondaryColor.main',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {client?.email || ''}
              </Typography>
              <Typography sx={{ color: 'secondaryColor.main' }}>
                {client?.phone
                  ? client?.phone.startsWith('+')
                    ? client?.phone
                    : '+' + client?.phone
                  : ''}
              </Typography>
              <Typography>
                {setStatusLabel(client?.status || '', dictionary)}
              </Typography>
              {client?.channels?.length === 0 && client?.role === 'adm' && (
                <Typography> </Typography>
              )}
              {client?.channels?.length > 0 &&
                client?.role === 'adm' &&
                client?.channels.map((channel) => {
                  return <Typography key={channel}>{channel}</Typography>;
                })}
            </FlexBox>
            <FlexBox
              className="col-8"
              sx={{
                justifyContent: 'space-between',
                '& .copy-icon': {
                  cursor: 'pointer',
                },
              }}
            >
              {renderCopyIcon(client?.fullname)}
              <FlexBox
                className="row-10"
                sx={{
                  height: 22,
                  '& .checked': { opacity: client?.email ? 1 : 0 },
                }}
              >
                {renderCopyIcon(client?.email)}
                <CheckedIcon className="checked" />
                {client?.email_verified_at && <ConfirmedIcon />}
              </FlexBox>
              <FlexBox className="row-10">
                {renderCopyIcon(client?.phone)}
                {client?.phone_verified_at && <ConfirmedIcon />}
              </FlexBox>
              {renderCopyIcon(
                client?.status,
                setStatusLabel(client?.status, dictionary)
              )}
              {client?.channels?.length === 0 && client?.role === 'adm' && (
                <Box sx={{ height: 22 }}> </Box>
              )}
              {client?.channels?.length > 0 &&
                client?.role === 'adm' &&
                client?.channels.map((channel) => {
                  return renderCopyIcon(channel);
                })}
            </FlexBox>
          </FlexBox>
        </Box>
      )}
      {client && client.length !== 0 && (
        <>
          {(actionType === 'edit' || actionType === 'invite') && (
            <Title
              heading="h2"
              title={setSectionSubtitle()}
              sx={{ marginBottom: 2.5 }}
            />
          )}
          <EmployeesForm
            actionType={actionType}
            isWorkScheduleEnabled={client?.isWorkScheduleEnabled}
            employeeAdminId={employeeAdminId}
            employee={actionType === 'invite' ? [] : client}
            part={'adminPart'}
            returnToPreviousSection={returnToPreviousSection}
          />
        </>
      )}
    </Box>
  );
};

export default ClientData;
