import { useContext } from 'react';

import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { setClientActionsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { dateConverter, setFunctionalItemValue } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const ClientActions = ({ actionsList, isSuccess, isError, actionsListPaginationData }) => {
    const { dictionary, language } = useContext(PageContext);

    if (isError) {
        return <Typography variant="h3">ERROR</Typography>;
    }

    const tableBodyStyles = {
        '& .MuiTableRow-root:not(:last-of-type)': {
            marginBottom: 0.625,
        },
        '& .MuiTableCell-root:nth-of-type(1)': {
            flex: '1 1 auto',
        },
        '& .MuiTableCell-root:nth-of-type(2), & .MuiTableCell-root:nth-of-type(3)': {
            maxWidth: 80,
            width: '100%',
            justifyContent: 'end',
        },
    };

    return (
        <>
            <TblContainer tableClass={'table-main--middle'}>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {actionsList.length === 0 && isSuccess && <EmptyListAlertText />}
                    {actionsList.length > 0 &&
                        actionsList.map(({ id, datetime, functional, total }) => {
                            return (
                                <TableRow
                                    className="table-main__row"
                                    sx={{ verticalAlign: 'top' }}
                                    key={id}
                                >
                                    <TableCell>{dateConverter(datetime)}</TableCell>
                                    <TableCell>
                                        <Table>
                                            <TableBody sx={{ ...tableBodyStyles }}>
                                                {functional.map(
                                                    ({ name, new_value, old_value, type }) => {
                                                        return (
                                                            <TableRow
                                                                className="table-main__row--flex"
                                                                key={id + name?.[language]}
                                                            >
                                                                <TableCell>
                                                                    {name?.[language]}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        textDecoration:
                                                                            'line-through',
                                                                        color: 'basicColor.color700',
                                                                    }}
                                                                >
                                                                    {setFunctionalItemValue(
                                                                        old_value,
                                                                        type,
                                                                        dictionary
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {setFunctionalItemValue(
                                                                        new_value,
                                                                        type,
                                                                        dictionary
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                    <TableCell>{total} UAH</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={actionsListPaginationData} invalidateTag={'Clients'} />
        </>
    );
};

export default ClientActions;
