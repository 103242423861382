import { Button } from '@mui/material';

const SubmitButton = ({
  submitButtonHandler,
  title,
  additionalClass,
  disabled,
  children,
  sx,
}) => {
  return (
    <Button
      id="steps-submit-button"
      className={`btn ${additionalClass ? additionalClass : ''}`}
      sx={sx}
      type="submit"
      variant="contained"
      disabled={disabled ? disabled : false}
      onClick={submitButtonHandler}
    >
      {children ? children : ''}
      {title}
    </Button>
  );
};

export default SubmitButton;
