import { useCallback, useState, useContext, useEffect } from 'react';
import {
    useEditClientEmployeeMutation,
    useInviteClientEmployeeMutation,
    useGetAnInvitationCodeQuery,
} from '../../../api/apiCommonClientEmployeesSlice';

import classNames from 'classnames';

import FormContainer from '../../../components/Form/FormContainer';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ChannelsAutocompleteSelect from '../../../components/Form/FormSelect/AutocompleteSelect/ChannelsAutocompleteSelect';
import ScheduleForm from '../Channels/Site/ScheduleForm';
import Title from '../../../components/ui/Title/Title';
import SinglePhoneSelect from '../../../components/Form/FormSelect/PhoneSelect/SinglePhoneSelect';
import MyButton from '../../../components/ui/Button/MyButton';

import {
    setClientPartInvitationTypeSelect as setTypeOptions,
    setRoleSelect as setRoleOptions,
} from '../../../data/formSelectData';

import {
    contactsSchema,
    employeeSchema,
    employeeSchemaWithPersonalInformation,
    emailInvitationEmployeeSchema,
    codeInvitationEmployeeSchema,
} from '../../../settings/formSchema';

import { createScheduleObj } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const EmployeesForm = ({
    employee,
    employeesWorkTime,
    actionType,
    part,
    employeeAdminId,
    returnToPreviousSection,
}) => {
    const { role, alertMessageContent, setServerValidationError, dictionary, clientCheck } =
        useContext(PageContext);

    const [invitationSelectValue, setInvitationSelectValue] = useState('');
    const [phoneValidationError, setPhoneValidationError] = useState(false);
    const [blockSubmitButton, setBlockSubmitButton] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [invitationCodeValue, setInvitationCodeValue] = useState('');

    const newAdminRoleOptions = setRoleOptions(dictionary).slice(1, 3);
    const newSuperRoleOptions = setRoleOptions(dictionary).slice(2, 3);

    const [
        inviteEmployee,
        {
            isLoading: inviteEmployeeLoading,
            isFetching: inviteEmployeeFetching,
            isSuccess: inviteEmployeeSuccess,
        },
    ] = useInviteClientEmployeeMutation();

    const [editEmployee, { isLoading: editedEmployeeLoading, isFetching: editedEmployeeFetching }] =
        useEditClientEmployeeMutation();

    const {
        data: invitationCode,
        isLoading: invitationCodeLoading,
        isFetching: invitationCodeFetching,
        isSuccess: invitationCodeSuccess,
        refetch: refetchInvitationCode,
    } = useGetAnInvitationCodeQuery(employeeAdminId || '', {
        skip: invitationSelectValue === 'code' ? false : true,
    });

    console.log(invitationCodeValue);
    useEffect(() => {
        if (invitationSelectValue === 'code' && invitationCodeValue) {
            refetchInvitationCode();
        }
    }, [invitationSelectValue, invitationCodeValue]);

    const formSubmitHandler = useCallback(
        (data) => {
            if (!phoneValidationError) {
                const schedule = employee?.role === 'adm' ? '' : createScheduleObj(data);
                const sitesId = employee?.role === 'adm' ? '' : data.sites.map(({ id }) => id);
                const fullname = {
                    surname: data.surname,
                    name: data.name,
                    patronymic: data.patronymic,
                };
                const contacts = {
                    email: data.email,
                    phone: data.phone,
                    phone_data: data.phone_data,
                };
                const restData = {
                    role: data.role,
                    sites: sitesId,
                    dialogs_count: data.dialogs_count ? data.dialogs_count : null,
                };
                const allData = {
                    schedule,
                    ...fullname,
                    ...contacts,
                    ...restData,
                };
                const invitationType =
                    invitationSelectValue === 'email'
                        ? { type: data.invitation_type }
                        : { type: data.invitation_type, invitation_code: data.invitation_code };

                const setEditedBody = () => {
                    if (part === 'clientPart') {
                        return {
                            schedule,
                            ...restData,
                        };
                    } else {
                        if (employee?.role === 'adm') {
                            return contacts;
                        } else {
                            return allData;
                        }
                    }
                };

                const setInvitedBody = () => {
                    if (invitationSelectValue === 'email') {
                        return { ...allData, ...invitationType };
                    } else {
                        return {
                            schedule,
                            ...restData,
                            ...invitationType,
                        };
                    }
                };

                const setSuccessAlertMessageContent = () => {
                    if (part === 'clientPart') {
                        return dictionary?.employee_edited;
                    } else if (part === 'adminPart' && employee?.role === 'adm') {
                        return dictionary?.client_edited;
                    } else {
                        return dictionary?.user_edited;
                    }
                };

                if (actionType === 'edit') {
                    editEmployee({
                        id: employee.id,
                        body: setEditedBody(),
                        adminPart: part === 'adminPart' ? true : false,
                    })
                        .unwrap()
                        .then((res) => {
                            alertMessageContent(setSuccessAlertMessageContent(), 'success');
                        })
                        .catch((error) => {
                            alertMessageContent(dictionary?.something_go_wrong, 'error');
                        });
                } else {
                    inviteEmployee({ body: setInvitedBody(), id: employeeAdminId })
                        .unwrap()
                        .then((res) => {
                            if (invitationSelectValue === 'code') {
                                setInvitationCodeValue(data.invitation_code);
                            }

                            setInvitationSelectValue('');

                            alertMessageContent(
                                part === 'clientPart'
                                    ? dictionary?.employee_invited
                                    : dictionary?.user_invited,
                                'success'
                            );
                            setIsSubmited(false);
                        })
                        .catch((error) => {
                            if (error?.data?.errors?.email) {
                                setServerValidationError(['email']);
                                alertMessageContent(
                                    part === 'clientPart'
                                        ? dictionary?.dictionary?.employee_email_error
                                        : dictionary?.user_email_exists,
                                    'error'
                                );
                            } else {
                                alertMessageContent(dictionary?.something_go_wrong, 'error');
                            }
                        });
                }
            }
        },
        [invitationSelectValue, employee, actionType]
    );

    const setFormSchema = () => {
        if (invitationSelectValue === 'email') {
            return emailInvitationEmployeeSchema;
        } else if (invitationSelectValue === 'code') {
            return codeInvitationEmployeeSchema;
        } else if (part === 'adminPart' && employee?.role === 'adm') {
            return contactsSchema;
        } else if (part === 'adminPart' && employee?.role !== 'adm' && !employee?.code) {
            return employeeSchemaWithPersonalInformation;
        } else {
            return employeeSchema;
        }
    };

    const currentSubmitButtonClass = classNames({
        'btn--disabled': blockSubmitButton,
        'btn--none': !invitationSelectValue && (actionType === 'invite' || actionType === 'view'),
        'btn--primary': actionType,
    });

    const setSubmitButtonTitle = () => {
        switch (actionType) {
            case 'invite':
                return dictionary?.invite;
            case 'edit':
                return dictionary?.save;
            case 'view':
                return dictionary?.save;
        }
    };

    return (
        <>
            <FormContainer
                schema={setFormSchema()}
                submitButtonTitle={setSubmitButtonTitle()}
                submitButtonClass={currentSubmitButtonClass}
                submitButtonHandler={() => setIsSubmited(true)}
                useButtonHandler={true}
                formSubmitHandler={formSubmitHandler}
                formInnerSx={{
                    pointerEvents: actionType === 'view' ? 'none' : 'all',
                    maxWidh: 480,
                }}
                cleareForm={inviteEmployeeSuccess}
                useValidationErrors={true}
                isLoading={inviteEmployeeLoading || editedEmployeeLoading || invitationCodeLoading}
                isFetching={
                    inviteEmployeeFetching || editedEmployeeFetching || invitationCodeFetching
                }
                spinnerSx={part === 'adminPart' ? { height: 'calc(100% + 185px)', top: -155 } : {}}
            >
                {actionType === 'invite' && (
                    <SelectWithFormContext
                        fieldName={'invitation_type'}
                        label={dictionary?.invitation_type}
                        labelId={'invitationSelect'}
                        options={setTypeOptions(dictionary)}
                        setSelectedValue={setInvitationSelectValue}
                        useSelectedValueImmediatly={true}
                    />
                )}
                {(invitationSelectValue === 'email' ||
                    (part === 'adminPart' && actionType === 'edit' && !employee?.code)) && (
                    <>
                        {employee?.role !== 'adm' && (
                            <>
                                <GeneralInput
                                    fieldName={'surname'}
                                    label={dictionary?.surname}
                                    receivedValue={employee?.surname || ''}
                                />
                                <GeneralInput
                                    fieldName={'name'}
                                    label={dictionary?.first_name}
                                    receivedValue={employee?.name || ''}
                                />
                                <GeneralInput
                                    fieldName={'patronymic'}
                                    label={dictionary?.patronymic}
                                    receivedValue={employee?.patronymic || ''}
                                />
                            </>
                        )}
                        <GeneralInput
                            fieldName={'email'}
                            label={'E-mail'}
                            receivedValue={employee?.email || ''}
                        />
                        <SinglePhoneSelect
                            fieldName={'phone'}
                            dropdownWidth={480}
                            phoneValidationError={phoneValidationError}
                            setPhoneValidationError={setPhoneValidationError}
                            isSubmited={isSubmited}
                            receivedValue={employee?.phone || ''}
                        />
                    </>
                )}
                {(invitationSelectValue || actionType === 'edit' || actionType === 'view') && (
                    <>
                        {actionType === 'edit' && clientCheck && (
                            <Title heading="h3" title={employee?.fullname} />
                        )}
                        {employee?.role !== 'adm' && (
                            <>
                                <SelectWithFormContext
                                    fieldName={'role'}
                                    label={dictionary?.role}
                                    labelId={'roleSelect'}
                                    options={
                                        role === 'sv' && clientCheck
                                            ? newSuperRoleOptions
                                            : newAdminRoleOptions
                                    }
                                    receivedValue={employee?.role}
                                    selectSx={{
                                        '& .MuiSvgIcon-root': {
                                            opacity: actionType === 'view' ? 0 : 1,
                                        },
                                    }}
                                />
                                <ChannelsAutocompleteSelect
                                    clientId={
                                        actionType === 'invite'
                                            ? employeeAdminId
                                            : employee?.ownerId
                                    }
                                    selectQuerySkip={false}
                                    receivedValue={employee?.sites}
                                    selectArrowSx={{
                                        opacity: actionType === 'view' ? 0 : 1,
                                    }}
                                />
                            </>
                        )}
                        {employeesWorkTime && employee?.role !== 'adm' && (
                            <>
                                <Title heading="h3" title={dictionary?.schedule} />
                                <ScheduleForm
                                    setBlockSubmitButton={setBlockSubmitButton}
                                    receivedValue={actionType === 'invite' ? false : true}
                                    schedule={employee?.schedule}
                                    hideInformationText={actionType === 'view'}
                                />
                            </>
                        )}
                        {employee?.role !== 'adm' && (
                            <GeneralInput
                                fieldName={'dialogs_count'}
                                label={dictionary?.num_of_dialogues}
                                receivedValue={employee?.dialogs_count}
                                type={'number'}
                            />
                        )}
                    </>
                )}
                {((invitationSelectValue === 'code' && invitationCodeSuccess) ||
                    (employee?.code && actionType === 'edit')) && (
                    <GeneralInput
                        fieldName={'invitation_code'}
                        label={dictionary?.invitation_code}
                        receivedValue={invitationCode?.code || employee?.code}
                        disabled={true}
                    />
                )}
            </FormContainer>
            {part === 'adminPart' && (
                <MyButton
                    title={dictionary?.close}
                    buttonClasses={'btn--primary btn--cancel'}
                    buttonHandler={() => returnToPreviousSection()}
                    sx={{
                        position:
                            actionType === 'edit' || invitationSelectValue ? 'absolute' : 'static',
                        marginTop: actionType === 'view' || actionType === 'invite' ? 2.5 : 0,
                        bottom: 0,
                        left: actionType === 'edit' || invitationSelectValue ? 280 : 0,
                    }}
                />
            )}
        </>
    );
};

export default EmployeesForm;
