import { useState, createContext, useLayoutEffect, useContext } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';

import MyButton from '../ui/Button/MyButton';
import ClientsFilter from '../../pages/adminPartPages/Clients/ClientsFilter';
import NotificationsFilter from '../../pages/adminPartPages/Notifications/NotificationsFilter';
import AppealFilter from '../../pages/adminPartPages/Support/AppealFilter';
import PaymentFilter from '../../pages/clientPartPages/Payment/PaymentFilter';
import EmployeesFilter from '../../pages/clientPartPages/Employees/EmployeesFilter';
import VisitorsFilter from '../../pages/clientPartPages/Visitors/VisitorsFilter';
import CommandsFilter from '../../pages/clientPartPages/Templates/CommandsFilter';
import DialoguesFilter from '../../pages/clientPartPages/Dialogues/DialoguesFilter';

import { PageContext } from '../../pages/Page';
import { PageContainerContext } from '../../pages/PageContainer';

export const FilterContext = createContext('');

const allFilterParams = [
    'sites',
    'user',
    'visitor',
    'tag',
    'status',
    'range_start',
    'range_end',
    'functional_id',
    'functional_value',
    'category_id',
    'client_id',
    'role',
    'role_id',
    'categories',
    'email',
    'site',
    'count',
];

const FilterAccordion = ({ moveToLeft }) => {
    const { dictionary, content } = useContext(PageContext);
    const { queryParams } = useContext(PageContainerContext);

    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [selectQuerySkip, setSelectQuerySkip] = useState(true);

    const cleareFilterFields = () => {
        setClearFilter(true);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            setSelectQuerySkip(false);
        }
    };

    // when we click on back/forward browser button or upload the page and there are queryParams
    useLayoutEffect(() => {
        if (queryParams.size && !showButton) {
            if (allFilterParams.some((param) => queryParams.has(param))) {
                setShowButton(true);
                setExpanded('filter');
                setSelectQuerySkip(false);
            }
        }
    }, [queryParams]);

    return (
        <Box sx={{ position: 'relative' }}>
            <Accordion
                expanded={expanded === 'filter'}
                className={'toolbar__accordion'}
                onChange={handleChange('filter')}
            >
                <AccordionSummary
                    className={'toolbar__accordion-button'}
                    aria-controls="panel1a-content"
                >
                    <MyButton
                        title={dictionary?.filters}
                        buttonClasses={'btn--small'}
                        sx={{
                            left: moveToLeft ? 190 : '',
                        }}
                    />
                </AccordionSummary>
                <FilterContext.Provider
                    value={{
                        showButton,
                        setShowButton,
                        clearFilter,
                        setClearFilter,
                        allFilterParams,
                    }}
                >
                    <AccordionDetails className={'toolbar__accordion-details'}>
                        {content === 'clientsList' && (
                            <ClientsFilter selectQuerySkip={selectQuerySkip} />
                        )}
                        {content === 'notificationsList' && <NotificationsFilter />}
                        {content === 'appealsList' && (
                            <AppealFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {content === 'payment' && (
                            <PaymentFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {(content === 'employeesList' || content === 'clientInfo') && (
                            <EmployeesFilter selectQuerySkip={selectQuerySkip} />
                        )}
                        {(content === 'visitorsOnline' || content === 'visitorsList') && (
                            <VisitorsFilter selectQuerySkip={selectQuerySkip} />
                        )}
                        {content === 'dialoguesList' && (
                            <DialoguesFilter
                                selectQuerySkip={selectQuerySkip}
                                clearFilter={clearFilter}
                            />
                        )}
                        {content === 'commandsList' && (
                            <CommandsFilter selectQuerySkip={selectQuerySkip} />
                        )}
                    </AccordionDetails>
                </FilterContext.Provider>
            </Accordion>
            {showButton && (
                <MyButton
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: moveToLeft ? 380 : 190,
                    }}
                    title={dictionary?.clear_filters}
                    buttonClasses={'btn--light btn--small'}
                    buttonHandler={() => cleareFilterFields()}
                />
            )}
        </Box>
    );
};

export default FilterAccordion;
