import { configureStore, isRejected } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import authorizationSlice from '../reducers/authorizationSlice';
import sectionSlice from '../reducers/sectionSlice';
import messagesSlice from '../reducers/messagesSlice';

const UNAUTHORIZED_CODE = 401;

export const rtkQueryErrorLogger = () => (next) => (action) => {
    if (isRejected(action)) {
        if (action?.payload?.status === UNAUTHORIZED_CODE) {
            const requestedUrl = action?.meta?.baseQueryMeta?.request?.url;
            const url = new URL(requestedUrl);

            if (url.pathname.startsWith('/client')) {
                localStorage.removeItem('client');
                window.location.href = '/';
            } else if (url.pathname.startsWith('/admin')) {
                localStorage.removeItem('admin');
                window.location.href = '/admin';
            }
        }
    }

    return next(action);
};

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        authorizationSlice,
        sectionSlice,
        messagesSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware).concat(rtkQueryErrorLogger),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
