import { Tooltip } from '@mui/material';

const MyTooltip = ({ children, position, title, interactive = true, arrow = true, sx = {} }) => {
    return (
        <Tooltip
            title={title}
            arrow={!arrow ? arrow : true}
            disableInteractive={!interactive ? interactive : true}
            placement={position}
            componentsProps={{
                tooltip: {
                    sx: {
                        ...sx,
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
};

export default MyTooltip;
