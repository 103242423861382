import { useContext, useCallback, useEffect, useState } from 'react';
import { useGetAllCategoriesOfCommandsQuery } from '../../../api/clientPartApi/apiTemplatesSlice';

import { Grid } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import AutocompleteSelectWithFormContext from '../../../components/Form/FormSelect/AutocompleteSelect/AutocompleteSelectWithFormContext';

import {
    setSelectLabel,
    arrayToQueryString,
    setFilterParams,
    queryStringToArrayWithId,
} from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const currentQueryParams = ['categories'];

const CommandsFilter = ({ selectQuerySkip }) => {
    const { dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const {
        data: categories,
        isLoading: categoriesLoading,
        isFetching: categoriesFetching,
        isSuccess: categoriesSuccess,
        isError: categoriesFetchingError,
    } = useGetAllCategoriesOfCommandsQuery(undefined, { skip: selectQuerySkip });

    const [newReceivedValue, setNewReceivedValue] = useState([]);

    const categoriesList = categoriesSuccess
        ? categories?.data?.result.map(({ id, name }) => ({
              id,
              label: name,
          }))
        : [];

    useEffect(() => {
        if (categoriesSuccess) {
            if (queryParams && queryParams.get('categories')) {
                setNewReceivedValue(
                    queryStringToArrayWithId(queryParams.get('categories'), categoriesList)
                );
            } else {
                setNewReceivedValue([]);
            }
        }
    }, [categoriesSuccess, queryParams]);

    const formSubmitHandler = useCallback(
        (data) => {
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    const newData = arrayToQueryString(data.categories, 'categories');
                    setFilterParams(newData, 'categories', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={4.4654}>
                <AutocompleteSelectWithFormContext
                    fieldName={'categories'}
                    label={setSelectLabel(
                        categoriesFetching,
                        categoriesLoading,
                        categoriesFetchingError,
                        dictionary?.category,
                        dictionary
                    )}
                    placeholder={`${dictionary?.category}...`}
                    options={categoriesList}
                    receivedValue={newReceivedValue}
                    isSuccess={categoriesSuccess}
                />
            </Grid>
        </FilterFormContainer>
    );
};

export default CommandsFilter;
