import { apiSlice } from '../apiSlice';

const apiVisitorsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVisitors: builder.query({
            query: (query) => ({
                url: query.online
                    ? `/client/visitors${query.online}${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.search}${query.sites}${query.status}&all=1`
                    : `/client/visitors${query.limit}${query.default_sort || ''}${query.page || ''}${query.sortBy || ''} ${query.sortOrder || ''}${query.search}${query.sites || ''}${query.status || ''}`,
            }),
            providesTags: ['Visitors'],
        }),
        getVisitor: builder.query({
            query: (data) => ({
                url: `client/visitors/${data.id}${data.view}`,
            }),
            providesTags: ['Visitors'],
        }),
        editVisitor: builder.mutation({
            query: (visitor) => ({
                url: `client/visitors/${visitor.id}`,
                method: 'PUT',
                body: visitor.body,
            }),
            invalidatesTags: ['Visitors'],
        }),
        changeVisitorStatus: builder.mutation({
            query: (visitor) => ({
                url: `client/visitors/${visitor.id}/${visitor.action}${visitor.payload}`,
                method: 'POST',
            }),
            invalidatesTags: ['Visitors', 'Dialogues'],
        }),
        connectToVisitor: builder.mutation({
            query: (id) => ({
                url: `client/visitors/${id}/connect`,
                method: 'POST',
            }),
            invalidatesTags: ['Visitors', 'Dialogues'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetVisitorsOnlineQuery,
    useGetVisitorsQuery,
    useGetVisitorQuery,
    useEditVisitorMutation,
    useChangeVisitorStatusMutation,
    useConnectToVisitorMutation,
} = apiVisitorsSlice;
