import { useState, useContext, useEffect } from 'react';
import { useGetNewNotificationsQuery } from '../../api/clientPartApi/apiClientPartNotificationsSlice';

import { Slider } from '@mui/material';

import HeaderAccordion from './HeaderAccordion';
import MyButton from '../../components/ui/Button/MyButton';
import HeaderNotifications from './HeaderNotifications';
import HeaderSwitchers from './HeaderSwitchers';

import { setUserButtons, setBalanceButtons } from '../../data/headerData';
import { createMuiComponent } from '../../mui/functions';

import { PageContext } from '../../pages/Page';

const MyHeader = createMuiComponent('header', 'MuiHeader');

const Header = () => {
    const { clientCheck, role, dictionary } = useContext(PageContext);

    const WARNING_LIMIT = 90;
    const ZERO_BALANCE = 100;

    const [value, setValue] = useState(5);
    const [sliderClass, setSliderClass] = useState('');
    const [showNotifications, setShowNotifications] = useState(false);
    const [userStatusCounter, setUserStatusCounter] = useState('');
    const [skipNotifications, setSkipNotifications] = useState(true);

    const {
        data: newNotifications,
        error: newNotificationsError,
        refetch: fetchNewNotifications,
    } = useGetNewNotificationsQuery(undefined, {
        skip: !clientCheck,
    });

    const newNotificationsCount = newNotifications?.notifications?.count || '';

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }

        if (newValue === ZERO_BALANCE) {
            setSliderClass('warning zero-balance');
        } else if (newValue >= WARNING_LIMIT) {
            setSliderClass('warning ');
        } else {
            setSliderClass('');
        }
    };

    const valueLabelFormat = (value) => {
        return `${value}%`;
    };

    const showNotificationsHandler = () => {
        setShowNotifications(true);
        setSkipNotifications(false);
    };

    return (
        <MyHeader className={`header ${clientCheck ? 'header--client' : 'header--admin'}`}>
            {clientCheck && (
                <>
                    <Slider
                        sx={{ pointerEvents: 'none' }}
                        className={sliderClass}
                        aria-label="Always visible"
                        value={value}
                        step={5}
                        onChange={handleChange}
                        valueLabelFormat={valueLabelFormat}
                        valueLabelDisplay="on"
                    />
                    {role === 'adm' && (
                        <HeaderAccordion
                            clientCheck={clientCheck}
                            title={'99 000 UAH'}
                            currentClass={
                                'header__accordion-button header__accordion-button--balance'
                            }
                            buttons={setBalanceButtons}
                        />
                    )}
                    <HeaderSwitchers
                        userStatusCounter={userStatusCounter}
                        setUserStatusCounter={setUserStatusCounter}
                    />
                    <MyButton
                        buttonClasses={'btn--small btn--notification'}
                        buttonHandler={() => showNotificationsHandler()}
                        title={dictionary?.notifications}
                        sx={{
                            '&::before': {
                                display: newNotificationsCount ? 'block' : 'none',
                            },
                        }}
                    />
                    <HeaderNotifications
                        showNotifications={showNotifications}
                        setShowNotifications={setShowNotifications}
                        skipNotifications={skipNotifications}
                        setSkipNotifications={setSkipNotifications}
                        fetchNewNotifications={fetchNewNotifications}
                    />
                </>
            )}
            <HeaderAccordion
                currentClass={'header__accordion-button header__accordion-button--user'}
                buttons={setUserButtons}
                userStatusCounter={userStatusCounter}
                setUserStatusCounter={setUserStatusCounter}
            />
        </MyHeader>
    );
};

export default Header;
