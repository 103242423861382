import { apiSlice } from '../apiSlice';

const apiClientsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClients: builder.query({
            query: (client) =>
                `admin/clients${client.limit}${client.page || ''}${client.sortBy || ''}${client.sortOrder || ''}${client.search}${client.functionalId || ''}${client.functionalValue || ''}${client.status || ''}`,
            providesTags: ['Clients'],
        }),
        changeClientStatus: builder.mutation({
            query: (client) => ({
                url: client.duration
                    ? `admin/clients/${client.id}/${client.action}?duration=${client.duration}`
                    : `admin/clients${client.extraPath}/${client.id}/${client.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Clients'],
        }),
        getClient: builder.query({
            query: (client) => ({
                url: client.section
                    ? `admin/clients/${client.id}/${client.section}?${client.limit || ''}${client.page || ''}${client.sortBy || ''}${client.sortOrder || ''}${client.search || ''}${client.role_id || ''}${client.sites || ''}${client.email || ''}${client.site || ''}`
                    : `admin/clients${client.extraPath}/${client.id}`,
            }),
            providesTags: ['Clients'],
        }),
        deleteInvitedClient: builder.mutation({
            query: (id) => ({
                url: `admin/clients/employees/${id}/delete`,
                method: 'POST',
            }),
            invalidatesTags: ['Clients'],
        }),
        editClient: builder.mutation({
            query: (client) => ({
                url: `admin/clients/employees/${client.id}`,
                method: 'PUT',
                body: client.body,
            }),
            invalidatesTags: ['Clients'],
        }),
        getAllClientSites: builder.query({
            query: (id) => ({
                url: `admin/clients/${id}/employees/sites/all`,
            }),
            invalidatesTags: ['Clients'],
        }),
    }),
    overrideExisting: false,
});

export const { useGetClientsQuery, useChangeClientStatusMutation, useGetClientQuery, useDeleteInvitedClientMutation, 
    useEditClientMutation, useGetAllClientSitesQuery } = apiClientsSlice;
