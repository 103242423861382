import { useCallback, useContext } from 'react';

import { useEditFunctionalMutation } from '../../../api/adminPartApi/apiFunctionalSlice';

import FormContainer from '../../../components/Form/FormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';
import LanguageTabs from '../../../components/Tabs/LanguageTabs';

import { setAdminPartDataTypeSelect as setOptions } from '../../../data/formSelectData';
import { editeFunctionalSchema as schema } from '../../../settings/formSchema';

import { PageContext } from '../../Page';

const FunctionalForm = ({ functional }) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    const [
        editFunctional,
        { isLoading: editedFunctionalloading, isFetching: editedFunctionalfetching },
    ] = useEditFunctionalMutation();

    const formSubmitHandler = useCallback(
        (data) => {
            const editedFunctional = {
                name: {
                    uk: data.name_uk,
                    en: data.name_en,
                    ru: data.name_ru,
                },
                type: data.type,
                price: data.price,
            };

            editFunctional({ id: functional.id, body: editedFunctional })
                .unwrap()
                .then((res) => alertMessageContent(dictionary?.functional_edited, 'success'))
                .catch((error) => {
                    alertMessageContent(dictionary?.something_go_wrong, 'error');
                });
        },
        [functional]
    );

    return (
        <FormContainer
            schema={schema}
            submitButtonTitle={dictionary?.save}
            submitButtonClass={'btn--primary'}
            formSubmitHandler={formSubmitHandler}
            useValidationErrors={true}
            isLoading={editedFunctionalloading}
            isFetching={editedFunctionalfetching}
        >
            <LanguageTabs
                label={dictionary?.name}
                fieldNamePrefix={'name_'}
                receivedValue={functional?.name}
            />
            <SelectWithFormContext
                fieldName={'type'}
                label={dictionary?.data_type}
                labelId={'dataTypeSelect'}
                disabled={true}
                receivedValue={functional?.type}
                options={setOptions(dictionary)}
            />
            <GeneralInput
                fieldName={'price'}
                label={dictionary?.unit_cost}
                receivedValue={functional?.price}
                type={'number'}
            />
        </FormContainer>
    );
};

export default FunctionalForm;
