import { useState, useLayoutEffect, useContext, useEffect } from 'react';
import { useGetTariffQuery, useTariffAdjustMutation } from '../../api/clientPartApi/apiTariffSlice';

import { Typography, Box } from '@mui/material';
import { FlexBox } from '../../mui/theme';

import Section from '../../layouts/Section/Section';
import Title from '../../components/ui/Title/Title';
import MyButton from '../../components/ui/Button/MyButton';
import ChangeValueInput from '../../components/Form/FormInputs/ChangeValueInput';
import BaseSwitcher from '../../components/ui/Switcher/BaseSwitcher';

import { ReactComponent as InformationIcon } from '../../assets/icons/info.svg';

import { PageContext } from '../Page';
import { PageContainerContext } from '../PageContainer';

const Tariff = () => {
    const { alertMessageContent, setAlertMessage, dictionary, language } = useContext(PageContext);
    const { content, changeContentHandler, pathname } = useContext(PageContainerContext);

    const [tariffInputValue, setTariffInputValue] = useState({});
    const [tariffSwitcherValue, setTariffSwitcherValue] = useState({});
    const [totalMonthPrice, setTotalMonthPrice] = useState('');
    // to display the required warnings
    const [lastClickedSwitcher, setLastClickedSwitcher] = useState('');

    const totalPriceData = [
        { id: 'day', text: dictionary?.total_day_price },
        { id: 'month', text: dictionary?.total_month_price },
    ];

    const {
        data,
        isLoading: tariffLoading,
        isFetching: tariffFetching,
        isSuccess: tariffSuccess,
        isError: tariffFetchingError,
        error: tariffError,
    } = useGetTariffQuery();

    const [
        ajustTariff,
        {
            isLoading: ajustableTariffLoading,
            isFetching: ajustableTariffFetching,
            isSuccess: ajustableTariffSuccess,
            isError: ajustableTariffFetchingError,
        },
    ] = useTariffAdjustMutation();

    const tariff = data?.data || [];

    useLayoutEffect(() => {
        if (pathname === '/tariff' && content !== 'tariff') {
            changeContentHandler('tariff');
        }
    }, [pathname, content]);

    useLayoutEffect(() => {
        if (tariffSuccess) {
            const findExistingTypes = () => {
                let numberAndMemoryTypes = {};
                let logicTypes = {};

                tariff.map((item) => {
                    if (item.type === 'number' || item.type === 'memory') {
                        return (numberAndMemoryTypes = {
                            ...numberAndMemoryTypes,
                            [item.system_name]: Number(item.value),
                        });
                    } else if (item.type === 'logic') {
                        return (logicTypes = {
                            ...logicTypes,
                            [item.system_name]: item.value,
                        });
                    } else return;
                });

                return [numberAndMemoryTypes, logicTypes];
            };

            const totalPrice = tariff
                .filter(({ value }) => value)
                .reduce((acc, { price }) => {
                    return acc + price;
                }, 0);

            setTariffInputValue({ ...findExistingTypes()[0] });
            setTariffSwitcherValue({ ...findExistingTypes()[1] });
            setTotalMonthPrice(totalPrice);
        }
    }, [tariffSuccess]);

    useEffect(() => {
        if (tariffSuccess && lastClickedSwitcher) {
            setAlertMessage({});
            if (!tariffSwitcherValue?.work_time && lastClickedSwitcher === 'work_time') {
                alertMessageContent(dictionary?.work_time_tooltip, 'information');
            } else if (!tariffSwitcherValue?.black_list && lastClickedSwitcher === 'black_list') {
                alertMessageContent(dictionary?.black_list_tooltip, 'information');
            } else if (!tariffSwitcherValue?.user_online && lastClickedSwitcher === 'user_online') {
                alertMessageContent(dictionary?.user_online_tooltip, 'information');
            } else if (!tariffSwitcherValue?.hide_copy && lastClickedSwitcher === 'hide_copy') {
                alertMessageContent(dictionary?.hide_copy_tooltip, 'information');
            }
        }
    }, [tariffSuccess, lastClickedSwitcher, tariffSwitcherValue]);

    const changeTariffHandler = (tariffInputValue, tariffSwitcherValue) => {
        setAlertMessage({});

        const values = { ...tariffInputValue, ...tariffSwitcherValue };

        const ajustableTariffArr = tariff.map(({ system_name, id }) => {
            for (let [key, value] of Object.entries(values)) {
                if (key === system_name) {
                    return {
                        [system_name]: {
                            id,
                            value,
                        },
                    };
                }
            }
        });

        let ajustableTariffObj = {};

        for (let i = 0; i < ajustableTariffArr.length; i++) {
            Object.assign(ajustableTariffObj, ajustableTariffArr[i]);
        }

        ajustTariff({ functional: ajustableTariffObj })
            .unwrap()
            .then((res) => alertMessageContent(dictionary?.tariff_changed, 'success'))
            .catch((error) => alertMessageContent(dictionary?.something_go_wrong, 'error'));
    };

    return (
        <>
            <Section
                title={dictionary?.welcome}
                sx={{ maxWidth: 540, minHeight: tariffLoading || tariffFetching ? 560 : 'inherit' }}
                isLoading={tariffLoading || ajustableTariffLoading}
                isFetching={tariffFetching || ajustableTariffFetching}
                isError={tariffFetchingError || ajustableTariffFetchingError}
                error={tariffError}
            >
                {tariffSuccess && (
                    <FlexBox className="col-20">
                        <Title heading="h3" title={dictionary?.tariff} />
                        {tariff.map(({ id, name, type, max, min, step, system_name }) => {
                            if (type === 'number' || type === 'memory') {
                                return (
                                    <ChangeValueInput
                                        key={id}
                                        label={name?.[language]}
                                        id={system_name}
                                        maxValue={max}
                                        minValue={min}
                                        step={type === 'memory' ? step : 1}
                                        units={type === 'memory' ? ` ${dictionary?.mb}` : ''}
                                        objectWithStates={tariffInputValue}
                                        value={tariffInputValue[system_name]}
                                        setValue={setTariffInputValue}
                                    />
                                );
                            } else if (type === 'logic') {
                                return (
                                    <BaseSwitcher
                                        key={id}
                                        id={system_name}
                                        label={name?.[language]}
                                        objectWithStates={tariffSwitcherValue}
                                        checked={tariffSwitcherValue[system_name]}
                                        setChecked={setTariffSwitcherValue}
                                        lastClicked={true}
                                        useSomeFunction={true}
                                        switcherFunction={() => setLastClickedSwitcher(system_name)}
                                    />
                                );
                            }
                        })}
                        <Box>
                            {totalPriceData.map(({ id, text }) => {
                                return (
                                    <FlexBox
                                        className="row-20"
                                        justifyContent="space-between"
                                        key={id}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ marginBottom: id === 'day' ? 1.25 : 0 }}
                                        >
                                            {text}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontWeight={500}
                                            color="secondaryColor.main"
                                        >
                                            {id === 'month'
                                                ? totalMonthPrice
                                                : Math.floor(totalMonthPrice / 30)}{' '}
                                            UAH
                                        </Typography>
                                    </FlexBox>
                                );
                            })}
                        </Box>
                        {ajustableTariffSuccess && (
                            <FlexBox className="row-14" alignItems="center">
                                <InformationIcon />
                                <Typography variant="body2">
                                    {dictionary?.tariff_information_message}
                                </Typography>
                            </FlexBox>
                        )}
                        <MyButton
                            title={dictionary?.save}
                            buttonClasses={'btn--primary'}
                            buttonHandler={() => {
                                changeTariffHandler(tariffInputValue, tariffSwitcherValue);
                            }}
                        />
                    </FlexBox>
                )}
                {tariffFetchingError && <Typography variant="h3">ERROR</Typography>}
            </Section>
        </>
    );
};

export default Tariff;
