import { apiSlice } from '../apiSlice';

const commonDialogsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: ({ id, role, type, page }) => ({
                url: `${role}/${type}/${id}?preload=${page}`,
            })
        }),
        postMessage: builder.mutation({
            query: ({ id, role, type, text, file }) => ({
                url: `${role}/${type}/${id}${file ? '' : `?text=${text}`}`,
                method: 'POST',
                body: file ? file : false,
            }),
        }),
        editMessage: builder.mutation({
            query: ({ id, role, type, messageId, text }) => ({
                url: `${role}/${type}/${id}/${messageId}?text=${text}`,
                method: 'PUT',
            }),
        }),
        deleteMessage: builder.mutation({
            query: ({ id, role, type, messageId }) => ({
                url: `${role}/${type}/${id}/${messageId}`,
                method: 'DELETE',
            }),
        }),
        getMessageData: builder.query({
            query: ({ id }) => ({
                url: `client/dialogs/message/${id}`,
            }),
        }),
        getUnreadMessages: builder.query({
            query: () => ({
                url: 'client/dialogs/counter',
            }),
        }),
        // postMessageFile: builder.mutation({
        //     query: ({ id, file }) => ({
        //         url: `client/dialogs/${id}`,
        //         method: 'POST',
        //         body: file,
        //     }),
        // }),
    }),
    overrideExisting: true,

});

export const {
    useGetMessagesQuery,
    usePostMessageMutation,
    useEditMessageMutation,
    useDeleteMessageMutation,
    usePostFileMutation,
    useGetMessageDataQuery,
    useGetUnreadMessagesQuery,
    // usePostMessageFileMutation
} = commonDialogsSlice;


