import { useEffect, useState, createContext, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Box } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import SubmitButton from '../ui/Button/SubmitButton';
import Spinner from '../ui/Spinner/Spinner';

import { createMuiComponent } from '../../mui/functions';

import { PageContext } from '../../pages/Page';

export const FormContainerContext = createContext('');

const MyForm = createMuiComponent('form', 'MuiForm');
const MyFormInner = createMuiComponent(Box, 'MuiFormInner');

const FormContainer = ({
  children,
  schema,
  formClass = '',
  formInnerSx = {},
  formSubmitHandler,
  isLoading,
  isFetching,
  cleareForm,
  useValidationErrors = false,
  unregisterFields,
  setUnregisterFields,
  spinnerSx = {},
  submitButtonTitle,
  submitButtonClass,
  useButtonHandler = false,
  submitButtonHandler,
}) => {
  const { setAlertMessage } = useContext(PageContext);
  const [usedFormValidationErrors, setUsedFormValidationErrors] = useState({});

  const methods = useForm({ resolver: yupResolver(schema) });
  const { reset, formState, handleSubmit } = methods;

  useEffect(() => {
    if (unregisterFields) {
      reset();
      setUnregisterFields(false);
    }
  }, [unregisterFields]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [cleareForm]);

  useEffect(() => {
    if (useValidationErrors && JSON.stringify(formState.errors) !== '{}') {
      setUsedFormValidationErrors(formState.errors);
    }
  }, [formState.errors]);

  return (
    <>
      <FormProvider {...methods}>
        <FormContainerContext.Provider
          value={{
            usedFormValidationErrors,
            setUsedFormValidationErrors,
          }}
        >
          <MyForm
            className={`form ${formClass}`}
            onSubmit={handleSubmit(formSubmitHandler)}
            noValidate
          >
            {(isLoading || isFetching) && (
              <Spinner
                sx={{
                  width: 'calc(100% + 60px)',
                  height: 'calc(100% + 60px)',
                  top: -30,
                  left: -30,
                  ...spinnerSx,
                }}
              />
            )}
            <MyFormInner sx={{ ...formInnerSx }}>
              {children}
              <SubmitButton
                id="steps-submit-button"
                title={submitButtonTitle}
                additionalClass={submitButtonClass}
                submitButtonHandler={() => {
                  setAlertMessage({});
                  setUsedFormValidationErrors({});

                  if (useButtonHandler) {
                    submitButtonHandler();
                  }
                }}
              />
            </MyFormInner>
          </MyForm>
        </FormContainerContext.Provider>
      </FormProvider>
    </>
  );
};

export default FormContainer;
