import { useContext } from 'react';

import { Box, Typography, Link, Button } from '@mui/material';

import Title from '../../../components/ui/Title/Title';

import { ReactComponent as ConfirmedIcon } from '../../../assets/icons/confirmed.svg';

import { PageContext } from '../../Page';

const Congratulations = ({ leaveForm }) => {
  const { dictionary } = useContext(PageContext);

  return (
    <>
      <Box
        sx={{
          '& svg': {
            display: 'flex',
            width: 76,
            height: 76,
          },
        }}
      >
        <ConfirmedIcon />
      </Box>
      <Title heading="h3" title={dictionary?.congratulations} />
      <Typography variant="body2" sx={{ maxWidth: 460, textAlign: 'center' }}>
        {dictionary?.congratulations_title}
      </Typography>
      <Button
        sx={{
          width: '100%',
          maxWidth: 200,
          padding: 1.5,

          fontWeight: 600,
          fontSize: 14,
          lineHeight: 1.4286,
          textAlign: 'center',

          color: 'basicColor.colorWhite',
          backgroundColor: 'secondaryColor.main',
          borderRadius: 1.25,

          transition: 'background-color 250ms',

          '&:hover': {
            backgroundColor: 'secondaryColor.light',
            boxShadow: 'none',
          },
        }}
        onClick={leaveForm}
      >
        {dictionary?.channels_list}
      </Button>
    </>
  );
};

export default Congratulations;
