import {
  useState,
  useLayoutEffect,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { Controller, set, useFormContext } from 'react-hook-form';

import { TextField, Box } from '@mui/material';

import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { Calendar } from 'react-multi-date-picker';
import DateObject from 'react-date-object';

import { PageContext } from '../../pages/Page';

import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
import './datePicker.scss';

import { locales } from '../../helpers/functions';

const MyDatePicker = ({
  fieldName,
  clearFilter,
  placeholder,
  withoutTime,
  receivedValue,
}) => {
  const { language, dictionary } = useContext(PageContext);
  const { control, setValue, getValues } = useFormContext();

  const [calendarValue, setCalendarValue] = useState('');
  const [dateFormat, setDateFormat] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const calendarRef = useRef(null);
  const textFieldRef = useRef(null);

  const calendarStyles = {
    zIndex: 1000,
    position: 'absolute',
    left: textFieldRef.current?.offsetLeft,
    top: textFieldRef.current?.offsetTop + 39,
    width: '100%',
    borderRadius: '4px',
    '.rmdp-time-picker div input': {
      width: '35px',
    },
    '.rmdp-wrapper.rmdp-shadow': {
      width: 'auto',
    },
    '.rmdp-day.rmdp-range': {
      backgroundColor: 'secondaryColor.main',
    },
    '.rmdp-day.rmdp-today': {
      backgroundColor: 'secondaryColor.light',
    },
    '.rmdp-day.rmdp-today span': {
      borderRadius: '0',
      backgroundColor: 'secondaryColor.light',
    },
    '.rmdp-week-day': {
      color: 'secondaryColor.main',
      boxShadow: 'none',
    },
    '.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover': {
      backgroundColor: 'secondaryColor.light',
      borderRadius: '0',
    },
    '.rmdp-range-hover.start:not(.force), .rmdp-range.start:not(.force), .rmdp-range-hover.end:not(.force), .rmdp-range.end:not(.force)':
      {
        borderRadius: '0',
      },
    '.rmdp-day span': {
      inset: '0',
    },
    '.rmdp-input': {
      cursor: 'pointer',
    },
    '.rmdp-wrapper.rmdp-shadow': {
      boxShadow: 'none',
      border: '1px solid secondaryColor.main',
    },
    '.rmdp-arrow': {
      borderColor: 'secondaryColor.main',
    },
    '.rmdp-arrow-container:hover': {
      cursor: 'pointer',
      backgroundColor: 'secondaryColor.light',
    },
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const NUM_OF_DATE_CHARACTERS = 10;

  const changeCalendarHandler = (res) => {
    let newResWithMissingTime;

    const addMissingTime = (position) => {
      newResWithMissingTime = res.map((item, index) => {
        if (index === position) {
          return item.concat('', '00:00');
        } else {
          return item;
        }
      });
    };

    if (
      res[0].trim().length > NUM_OF_DATE_CHARACTERS &&
      res[1].trim().length === NUM_OF_DATE_CHARACTERS
    ) {
      addMissingTime(1);
    } else if (
      res[0].trim().length === NUM_OF_DATE_CHARACTERS &&
      res[1].trim().length > NUM_OF_DATE_CHARACTERS
    ) {
      addMissingTime(0);
    }

    const visibleRes = (newResWithMissingTime || res).map((item) => {
      const year = item.slice(0, 4);
      const month = item.slice(5, 7);
      const day = item.slice(8, 10);
      const time = item.slice(11, 17);

      return `${day}.${month}.${year}${time ? ', ' + time : ''}`;
    });

    setDateFormat(`${visibleRes[0] + ' - ' + visibleRes[1]}`);
    setCalendarValue(newResWithMissingTime || res);
    setValue(fieldName, newResWithMissingTime || res);
  };

  useEffect(() => {
    const replaceSelectWithDiv = () => {
       setTimeout(function(){
	  const timePickerElement = document.querySelector('.rmdp-calendar + div');
      if (timePickerElement) {
        timePickerElement.id = 'time-picker';
        const selectElement = timePickerElement.querySelector('.rmdp-input');

        if (selectElement) {
          var options = Array.from(selectElement.options);
          const customDiv = document.createElement('div');
          customDiv.className = 'custom-time-select';

          const selectedTime = document.createElement('div');
          selectedTime.className = 'selected-time';
          customDiv.appendChild(selectedTime);
		  var index = 0;
		  if(selectElement.selectedIndex != undefined){
			  index = selectElement.selectedIndex;
		  }
          selectedTime.textContent = selectElement.querySelectorAll('option')[index]?.textContent
          selectedTime.setAttribute(
            'value',
            selectElement.querySelectorAll('option')[index]?.value
          );

          const label = document.createElement('label');
          label.className = 'custom-time-label';
          label.textContent = dictionary?.choose_time;
          customDiv.appendChild(label);

          const dropdown = document.createElement('div');
          dropdown.className = 'custom-time-options';
          const arrow = document.createElement('span');
          arrow.innerHTML =
            '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-16k9sjp-MuiSvgIcon-root-MuiSelect-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="#196ca7"></path></svg>';
          customDiv.appendChild(arrow);
          arrow.className = 'custom-select-arrow';

          options.forEach((option, i) => {
            const optionDiv = document.createElement('div');
            optionDiv.textContent = option.textContent;            
            optionDiv.setAttribute('data-id', i);
            optionDiv.setAttribute('value', option.value);

            optionDiv.onclick = () => {
              options.forEach((option) => {
                option.classList.remove('selected');
              });
              // customDiv.textContent = option.textContent;
              selectedTime.innerText = option.textContent;
              selectElement.value = option.value;
              selectElement.classList.add('selected');
			  selectElement.selectedIndex = i;
			 
				selectedTime.textContent = option.textContent;
			 
              selectElement.dispatchEvent(
                new Event('change', { bubbles: true })
              );
            };

            dropdown.appendChild(optionDiv);
          });

          document.addEventListener('click', (e) => {
            if (!e.target.closest('.custom-time-select')) {
              customDiv.classList.remove('open');
              dropdown.style.display = 'none';
            } else {
              dropdown.style.display = 'block';
              customDiv.classList.add('open');
            }
            if (e.target.closest('.custom-time-options')) {
              customDiv.classList.remove('open');
              dropdown.style.display = 'none';
            }
          });

          timePickerElement.children[1].insertAdjacentElement(
            'beforebegin',
            customDiv
          );
          timePickerElement.children[1].insertAdjacentElement(
            'beforeend',
            dropdown
          );

          selectElement.style.display = 'none';
          selectElement.parentNode.style.display = 'none';
        }
		 
      }}, 100);
    };
    const timer = setTimeout(() => {
      replaceSelectWithDiv();
    }, 100);

    return () => {
      clearTimeout(timer);
      // delete custom time picker
      const timePickerElement = document.querySelector('.rmdp-calendar + div');
      if (timePickerElement) {
        const customDiv = timePickerElement.querySelector(
          '.custom-time-select'
        );
        if (customDiv) {
          customDiv.remove();
        }
        const dropdown = timePickerElement.querySelector(
          '.custom-time-options'
        );
        if (dropdown) {
          dropdown.remove();
        }
      }
    };
  }, [calendarValue, getValues(fieldName), isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const selectedTime = document.querySelector('.selected-time');
      if (!selectedTime) return;

      const inputHours = document.getElementsByName('hour')[0];
      const inputMinutes = document.getElementsByName('minute')[0];
      const buttonsUp = document.querySelectorAll('.rmdp-up');
      const buttonsDown = document.querySelectorAll('.rmdp-down');
      const options = document.querySelectorAll('.custom-time-options div');
      if (
        !inputHours ||
        !inputMinutes ||
        !buttonsUp ||
        !buttonsDown ||
        !options
      )
        return;

      const buttons = [...buttonsUp, ...buttonsDown];

      const changeTime = ({ target }) => {
        selectedTime.textContent = `${inputHours.value}:${inputMinutes.value}`;
      };

      inputHours.addEventListener('input', changeTime);
      inputMinutes.addEventListener('input', changeTime);

      buttons.forEach((button) => {
        button.addEventListener('click', () => {
          setTimeout(() => {
            const updatedTime = `${inputHours.value}:${inputMinutes.value}`;
            options.forEach((option, i) => {
              const optionValue = option.getAttribute('value');
              const selectedTimeValue = selectedTime.getAttribute('value');
              if (
                optionValue === selectedTimeValue &&
                option.textContent !== updatedTime
              ) {
                option.textContent = updatedTime;
              }
            });
            selectedTime.textContent = updatedTime;
          }, 100);
        });
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [calendarValue, isOpen]);

  useEffect(() => {
    if ((receivedValue || getValues(fieldName)) && !clearFilter) {
      if (Array.isArray(receivedValue) && Array.isArray(getValues(fieldName))) {
        // when we click on back/forward browser button or upload the page and there are receivedValue and getValues(fieldName) is true
        const isEqual = receivedValue.every(
          (item, index) =>
            receivedValue[index].trim() === getValues(fieldName)[index].trim()
        );

        if (!isEqual) {
          changeCalendarHandler(receivedValue);
        }
      } else if (receivedValue) {
        // when we click on back/forward browser button or upload the page and there are receivedValue and getValues(fieldName) is false
        changeCalendarHandler(receivedValue);
      } else if (!receivedValue) {
        // when we click on back/forward browser button and there are no receivedValue but getValues(fieldName) still true
        setCalendarValue('');
        setValue(fieldName, '');
        setDateFormat('');
      }
    }
  }, [receivedValue, clearFilter]);

  useLayoutEffect(() => {
    if (clearFilter) {
      setCalendarValue('');
      setValue(fieldName, '');
      setDateFormat('');
    }
  }, [clearFilter]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const timeInput = document.querySelector('.rmdp-input');
        if (!timeInput) return;
        const timeInputValue = timeInput.value;
        // if (!timeInputValue.length) {
        //   timeInput.insertAdjacentHTML(
        //     'afterbegin',
        //     '<option style="display: none;" disabled selected value="">' +
        //       dictionary?.choose_time +
        //       '<option>'
        //   );
        //   const options = timeInput.querySelectorAll('option');
        //   timeInput.addEventListener('focus', () => {
        //     console.log(options[0]);
        //     [...options].filter((option) => !option.value);
        //   });
        // }
      }, 300);
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      const dateRightBlock = document.querySelector('.rmdp-calendar + div');
      if (!dateRightBlock) return;
      if (!getValues(fieldName) || !isOpen) {
        dateRightBlock.style.display = 'none';
      } else {
        dateRightBlock.style.display = 'grid';
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [fieldName, isOpen, getValues(fieldName)]);

  // useEffect(() => {
  //   if (receivedValue) {
  //     const convertedDates = receivedValue.map(
  //       (dateStr) => new DateObject(dateStr)
  //     );
  //     setCalendarValue(convertedDates);
  //   }
  // }, [receivedValue]);

  useEffect(() => {
    setCalendarValue(getValues(fieldName));
  }, [getValues(fieldName), fieldName, isOpen]);

  const createDateStr = (year, month, day, hour = '00', minute = '00') => {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };
  
  

  return (
    <Controller
      name={fieldName}
      control={control}
      render={(field) => (
        <Box
          sx={(theme) => ({
            width: '100px',
            '.MuiBox-root': {
              width: 'auto',
              boxShadow: 'none',
              border: `1px solid ${theme.palette.basicColor.color900}`,
            },
          })}
        >
          {/* {console.log('calendarValue', calendarValue)} */}
          {/* {console.log('getValues', getValues(fieldName))} */}
          {isOpen && (
            <Box
              component={Calendar}
              locale={locales[language]}
              value={calendarValue}
              sx={calendarStyles}
              ref={calendarRef}
              {...field}
              range
              numberOfMonths={2}
              closeCalendarOnChange={false}
              onChange={(date) => {
				
				  
				 
                const newDate = new DateObject(date[0]);
                const year = newDate?.year;
                var month = newDate?.month;
				if(month < 10){
					month = '0' + month;
				}
                var day = newDate?.day;
				if(day < 10){
					day = '0' + day;
				}

                var hour = newDate?.hour;
				if(hour < 10){
					hour = '0' + hour;
				}
                var minute = newDate?.minute;
				if(minute < 10){
					minute = '0' + minute;
				}

                const newDate2 = new DateObject(date[1]);
                const year2 = newDate2?.year;
                var month2 = newDate2?.month;
				if(month2 < 10){
					month2 = '0' + month2;
				}
                var day2 = newDate2?.day;
				if(day2 < 10){
					day2 = '0' + day2;
				}

                var hour2 = newDate2?.hour;
				if(hour2 < 10){
					hour2 = '0' + hour2;
				}
                var minute2 = newDate2?.minute;
				if(minute2 < 10){
					minute2 = '0' + minute2;
				}
								
                setValue(fieldName, [
                  createDateStr(year, month, day, hour, minute),
                  createDateStr(year2, month2, day2, hour2, minute2),
                ]);				
				
				const select = document.getElementsByClassName('rmdp-input');
                var selectOptions = [
                  ...document.getElementsByTagName('option'),
                ];
				
				var optionItem = 0;
                
                const options = document.querySelectorAll(
                  '.custom-time-options div'
                );

				const timeOptions = document.querySelector('.custom-time-options');
				
				options.forEach((option, i) => {
					option.remove();
				});
				
				setTimeout(function(){
					if(select.selectedIndex !== undefined){
						optionItem = select.selectedIndex;
					}
					const selectedTime = document.querySelector('.selected-time');
					if (selectedTime) {
						if(optionItem == 0){
							selectedTime.textContent = `${hour}:${minute}`;
							selectedTime.setAttribute('value', day);
						}else{
							selectedTime.textContent = `${hour2}:${minute2}`;
							selectedTime.setAttribute('value', day2);
						}
					}
					
					selectOptions = [
						...document.getElementsByTagName('option'),
					];
					//console.log(select.selectedIndex);
					
					selectOptions.forEach((option, i) => {
					  const optionDiv = document.createElement('div');
					  var time = `${hour}:${minute}`;
					  if(i == 1){
						  time = `${hour2}:${minute2}`;
					  }
					  
					  optionDiv.textContent = time;
					  optionDiv.setAttribute('data-id', i);
					  optionDiv.setAttribute('value', option.value);
					  //console.log(optionDiv, option);

					  optionDiv.onclick = (el) => {		
						option.selected = true;
						select.selectedIndex = i;
											
						options.forEach((option) => {
							option.classList.remove('selected');
						});
						el.target.classList.add('selected');
						selectedTime.textContent = option.textContent;
						selectedTime.setAttribute('value', option.value);
						
						let event = new Event('change', { bubbles: true });
						select[0].dispatchEvent(event);
					  };
	
					  timeOptions.appendChild(optionDiv);
					  
					  var selectDay = document.querySelector('#time-picker > div > div + div');
					  if(selectDay && option.value == selectDay.textContent){
						  option.selected = true;
							let event = new Event('change', { bubbles: true });
							select[0].dispatchEvent(event);					
							options.forEach((option) => {
								option.classList.remove('selected');
							});
					  }					 
					  
					  if(i == optionItem){
						  option.selected = true;
					  }else{
						  option.selected = false;
					  }
					  
					  let event = new Event('change', { bubbles: true });
					  select[0].dispatchEvent(event);					
					  options.forEach((option) => {
						option.classList.remove('selected');
					  });
					});
				}, 100);
              }}
              format={'YYYY/MM/DD HH:mm'}
              language={language}
              plugins={[
                <TimePicker
                  position="right"
                  placeholder="Select time"
                  format="HH:mm"
                  hideSeconds
                  names={{
                    selectTime: 'Select time',
                    close: 'Close',
                  }}
                />,
              ]}
            />
          )}
          <TextField
            ref={textFieldRef}
            label={placeholder}
            value={getValues(fieldName) || ''}
            onFocus={() => setIsOpen(true)}
            type="text"
            autoComplete="off"
            sx={{
              '.MuiInputBase-input': {
                cursor: 'pointer',
              },
              '& .MuiTextField-root': {
                cursor: 'pointer',
              },
              '& .MuiInputBase-root:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Box>
      )}
      // render={({ field }) => (
      //   <Box
      //     key={dateFormat}
      //     sx={{
      //       '& .react-minimal-datetime-range__range-input-separator': {
      //         display: calendarValue ? 'inline-block' : 'none !important',
      //       },

      //       '& .react-minimal-datetime-range__range-input-wrapper input.react-minimal-datetime-range__range-input':
      //         {
      //           position: 'relative',
      //         },
      //       '& .react-minimal-datetime-range__range-input-wrapper::before': {
      //         content: `'${dateFormat}'`,
      //         position: 'absolute',
      //         display: 'flex',
      //         alignItems: 'center',
      //         width: '87%',
      //         height: '100%',
      //         color: 'basicColor.main',
      //         backgroundColor: 'basicColor.colorWhite',
      //         zIndex: dateFormat ? 1 : 0,
      //         lineHeight: 1.29,
      //         fontSize: 14,
      //       },
      //       '& .react-minimal-datetime-range__clear': {
      //         display: calendarValue ? 'block' : 'none',
      //       },
      //       '& .react-minimal-datetime-range .react-minimal-datetime-range__button--type':
      //         {
      //           display: withoutTime ? 'none' : 'inline-block',
      //         },
      //       '& .react-minimal-datetime-range-calendar__body-container .react-minimal-datetime-range-calendar__table-cel':
      //         {
      //           padding: language === 'en' ? '9px' : '6.3px',
      //         },
      //     }}
      //   >
      //     {/* <RangePicker
      //       enableTimeSelection={false}
      //       locale={language}
      //       allowPageClickToClose={true}
      //       onConfirm={(res) => {
      //         changeCalendarHandler(res);
      //       }}
      //       onClear={() => {
      //         setDateFormat('');
      //         setValue(fieldName, '');
      //         setCalendarValue('');
      //       }}
      //       style={{ maxWidth: '320px', width: '100%', margin: '0 auto' }}
      //       placeholder={[placeholder]}
      //       showOnlyTime={false}
      //       defaultDates={getValues(fieldName)}
      //     /> */}

      //   </Box>
      // )}
    />
  );
};

export default MyDatePicker;
