import { useState, useContext } from 'react';
import { useEmployeesPassListQuery } from '../../../../api/clientPartApi/apiEmployeesSlice';

import { TextField } from '@mui/material';

import MyPopper from '../../../../components/ui/Popper/MyPopper';
import BaseAutocompleteSelect from '../../../../components/Form/FormSelect/AutocompleteSelect/BaseAutocompleteSelect';

import { setSelectLabel } from '../../../../helpers/functions';

import { PageContext } from '../../../Page';

const PassDialogPopper = ({ dialogueId, dialogueStatus, passDialogueHandler }) => {
    const { dictionary } = useContext(PageContext);

    const [selectedUser, setSelectedUser] = useState('');

    const {
        data: employees,
        isFetching: employeesFetching,
        isLoading: employeesLoading,
        isSuccess: employeesSuccess,
        isError: employeesFetchingError,
    } = useEmployeesPassListQuery(dialogueId, { skip: dialogueStatus !== 'unresolved' });

    const convertReceivedData = (arr) => {
        return arr.map(({ id, fullname, name }) => {
            return {
                id,
                label: fullname || name,
            };
        });
    };

    const cleareFields = () => {
        setTimeout(() => {
            setSelectedUser('');
        }, 200);
    };

    const employeesList = employeesSuccess
        ? convertReceivedData(employees?.data.filter(({ status }) => status === 'enabled'))
        : [];

    return (
        <MyPopper
            tooltipTitle={dictionary?.pass_dialogue}
            status={'pass_dialogue'}
            buttonTitle={dictionary?.pass}
            id={dialogueId}
            submitButtonHandler={() => {
                passDialogueHandler(selectedUser.id);
                cleareFields();
            }}
            submitButtonClass={!selectedUser?.id ? 'btn--disabled' : ''}
            cleareFields={true}
            cleareFieldsHandler={() => cleareFields()}
        >
            <BaseAutocompleteSelect
                options={employeesList}
                onChange={(event, data) => {
                    setSelectedUser(data);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={setSelectLabel(
                            employeesFetching,
                            employeesLoading,
                            employeesFetchingError,
                            dictionary?.user,
                            dictionary
                        )}
                    />
                )}
            />
        </MyPopper>
    );
};

export default PassDialogPopper;
