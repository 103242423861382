import { useContext } from 'react';
import {
  useChangeSiteStatusMutation,
  useDeleteSiteMutation,
} from '../../../api/clientPartApi/apiSitesSlice';

import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyPopper from '../../../components/ui/Popper/MyPopper';
import MyLink from '../../../components/ui/Link/MyLink';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as TurnOffIcon } from '../../../assets/icons/turn-on.svg';
import { ReactComponent as TurnOnIcon } from '../../../assets/icons/turn-off.svg';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';

import {
  setStatusLabel,
  timer,
  dictionary,
  widgetTemplate,
} from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const ChannelsListTemplate = ({
  list,
  listContent,
  setHeadCells,
  isSuccess,
  paginationData,
  copyTextHandler,
  content,
  path,
}) => {
  const { navigate, dictionary } = useContext(PageContext);
  const { changeContentHandler } = useContext(PageContainerContext);

  const [changeSiteStatus] = useChangeSiteStatusMutation();
  const [deleteSite] = useDeleteSiteMutation();

  const changeStatusHandler = (id, action) => {
    changeSiteStatus({ id, action }).unwrap();
  };

  const deleteChannelHandler = (id) => {
    deleteSite(id).unwrap();
  };

  const myTimer = (day) => {
    const currentTimer = timer(day);

    return currentTimer.map(({ hours, minutes, seconds }, index) => {
      return (
        <Box key={index} sx={{ marginTop: 1.25 }}>
          <Typography fontWeight={600}>
            {dictionary?.time_to_delete}&nbsp;
          </Typography>
          <Typography>
            {hours}:{minutes}:{seconds}
          </Typography>
        </Box>
      );
    });
  };

  return (
    <>
      <TblContainer tableSx={{ minHeight: 114 }}>
        <TblHead
          setHeadCells={setHeadCells}
          sortByParametr={
            listContent === 'channels' ? 'second_sort_by' : 'sort_by'
          }
          sortOrderParametr={
            listContent === 'channels' ? 'second_sort_order' : 'sort_order'
          }
        />
        <TableBody>
          {list.length === 0 && isSuccess && (
            <EmptyListAlertText sx={{ minHeight: 59 }} />
          )}
          {list.length > 0 &&
            list.map(
              ({
                id,
                domain,
                protocol,
                status,
                expiration,
                hash,
                error,
                name,
              }) => {
                return (
                  <TableRow className="table-main__row" key={id}>
                    <TableCell
                      sx={{
                        '& svg path': {
                          fill: '#fd4d5c',
                        },
                      }}
                    >
                      <Box sx={{ display: 'inline-flex', gap: 2 }}>
                        {name && <Typography>{name}</Typography>}
                        {domain && (
                          <MyLink
                            link={`${protocol}://${domain}`}
                            text={domain}
                          />
                        )}
                        {listContent === 'sites' &&
                          status !== 'nopublic' &&
                          (error?.step4 || error?.step5) && (
                            <MyTooltip
                              sx={{
                                color: 'colorAccent.secondLight',
                                width: 162,
                              }}
                              position={'top'}
                              title={`${
                                error?.step4
                                  ? dictionary?.site_step_4_warning
                                  : ''
                              }  ${
                                error?.step5
                                  ? dictionary?.site_step_5_warning
                                  : ''
                              }`}
                            >
                              <ErrorIcon />
                            </MyTooltip>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell className="status">
                      <Typography className={status}>
                        {setStatusLabel(status, dictionary)}
                      </Typography>
                      {expiration && myTimer(expiration)}
                    </TableCell>
                    <TableCell
                      className="action"
                      sx={{
                        '& svg:last-of-type': {
                          opacity: status === 'deleting' ? 0.5 : 1,
                          pointerEvents: status === 'deleting' ? 'none' : 'all',
                        },
                      }}
                    >
                      {listContent === 'sites' && status !== 'nopublic' && (
                        <MyTooltip
                          position={'top'}
                          title={dictionary?.copy_widjet_code}
                        >
                          <CopyIcon
                            className="copy-icon"
                            onClick={() =>
                              copyTextHandler(widgetTemplate(hash))
                            }
                          />
                        </MyTooltip>
                      )}
                      <MyTooltip position={'top'} title={dictionary?.edit}>
                        <EditIcon
                          onClick={() => {
                            changeContentHandler(content, id, true);
                            navigate(
                              listContent === 'channels'
                                ? path + `_${id}#step-1`
                                : path
                            );
                          }}
                        />
                      </MyTooltip>
                      {status !== 'nopublic' && (
                        <MyTooltip
                          position={'top'}
                          title={
                            status === 'active'
                              ? dictionary?.turn_off
                              : dictionary?.turn_on
                          }
                        >
                          {status === 'active' ? (
                            <TurnOffIcon
                              onClick={() => changeStatusHandler(id, 'disable')}
                            />
                          ) : (
                            <TurnOnIcon
                              onClick={() => {
                                if (status === 'disabled') {
                                  changeStatusHandler(id, 'enable');
                                } else {
                                  changeStatusHandler(id, 'nopublic');
                                }
                              }}
                            />
                          )}
                        </MyTooltip>
                      )}
                      <MyPopper
                        tooltipTitle={dictionary?.delete}
                        status={'deleting'}
                        currentId={id}
                        buttonTitle={dictionary?.delete}
                        submitButtonHandler={deleteChannelHandler}
                      >
                        <Typography variant="body2">
                          {dictionary?.channel_is_deleted}
                        </Typography>
                      </MyPopper>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </TblContainer>
      <TblPagination
        data={paginationData}
        invalidateTag={'Sites'}
        parametr={listContent === 'channels' ? 'second_page' : 'page'}
      />
    </>
  );
};

export default ChannelsListTemplate;
