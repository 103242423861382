import { useState, useContext } from 'react';

import { Box } from '@mui/material';

import ExpandedSwitcher from '../../../../components/ui/Switcher/ExpandedSwitcher';

import { PageContext } from '../../../Page';

const SixthStep = ({ savedSite }) => {
    const { dictionary } = useContext(PageContext);

    const [requestSwitcherValue, setRequestSwitcherValue] = useState({
        surname_switcher:
            savedSite?.visitor_data_request?.[0]?.active !== undefined
                ? savedSite?.visitor_data_request?.[0]?.active
                : true,
        name_switcher:
            savedSite?.visitor_data_request?.[1]?.active !== undefined
                ? savedSite?.visitor_data_request?.[1]?.active
                : true,
        patronymic_switcher:
            savedSite?.visitor_data_request?.[2]?.active !== undefined
                ? savedSite?.visitor_data_request?.[2]?.active
                : true,
        email_switcher:
            savedSite?.visitor_data_request?.[3]?.active !== undefined
                ? savedSite?.visitor_data_request?.[3]?.active
                : true,
        phone_switcher:
            savedSite?.visitor_data_request?.[4]?.active !== undefined
                ? savedSite?.visitor_data_request?.[4]?.active
                : true,
    });

    const switcherData = [
        { id: 'surname', label: dictionary?.surname },
        { id: 'name', label: dictionary?.first_name },
        { id: 'patronymic', label: dictionary?.patronymic },
        { id: 'email', label: 'E-mail' },
        { id: 'phone', label: dictionary?.phone },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
            {switcherData.map(({ id, label }) => {
                return (
                    <ExpandedSwitcher
                        key={id}
                        fieldName={id + '_switcher'}
                        label={label}
                        checked={requestSwitcherValue}
                        setChecked={setRequestSwitcherValue}
                        receivedValue={requestSwitcherValue[id + '_switcher']}
                    />
                );
            })}
        </Box>
    );
};

export default SixthStep;
