import { useContext } from 'react';

import { FlexBox } from '../mui/theme';
import Title from '../components/ui/Title/Title';

import { PageContext } from './Page';

const NotFound = () => {
    const { dictionary } = useContext(PageContext);

    return (
        <FlexBox className="page__body col" flex={1} alignItems="center" justifyContent="center">
            <Title heading={'h2'} title={dictionary?.page_not_found} />
        </FlexBox>
    );
};

export default NotFound;
