import { useState, useContext } from 'react';

import { Table, TableBody, TableRow, TableCell, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyLink from '../../../components/ui/Link/MyLink';
import ViewVisitorAccordion from './ViewVisitotAccordion';
import { FlexBox } from '../../../mui/theme';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checked.svg';

import { PageContext } from '../../Page';

import '/node_modules/flag-icons/css/flag-icons.min.css';

const ViewVisitor = ({ visitor, copy, copiedText, block, tooltipPosition }) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    const [openVisitedPages, setOpenVisitedPages] = useState(false);

    const {
        id,
        fullname,
        email,
        phones,
        ua,
        ip,
        country,
        city,
        code,
        ga,
        messengers,
        status,
        blocking,
        current_page,
        visits,
    } = visitor;

    const tableBodyStyles = {
        '& .MuiTableRow-root:not(:last-of-type)': {
            marginBottom: 2.25,
        },
        '& .MuiTableCell-root': {
            columnGap: '10px !important',

            '& .copy-icon': {
                cursor: 'pointer',
                marginLeft: block === 'dialogues' ? 'auto' : 0,
            },
            '& svg': {
                flexShrink: 0,
            },
        },
        '& .MuiTableCell-root:nth-of-type(1)': {
            flexShrink: 0,
            minWidth: block === 'dialogues' ? 140 : 100,
            marginRight: 1.25,

            fontWeight: 700,
        },
        '& .MuiTableCell-root:nth-of-type(2)': {
            width: block === 'dialogues' ? '100%' : 'auto',
        },
    };

    const setMessengerLabel = (label) => {
        switch (label) {
            case 'telegram':
                return dictionary?.telegram;
            case 'viber':
                return dictionary?.viber;
            case 'messenger':
                return dictionary?.messenger;
            default:
                return '';
        }
    };

    const copyTextHandler = (text) => {
        if (copiedText === undefined) {
            copy(text);
            alertMessageContent(dictionary?.data_copied, 'success');
        }
    };

    return (
        <>
            <Box>
                <Typography variant="h4">{dictionary?.information}</Typography>
                <Table className="table-main--small" sx={{ marginBottom: 3.75 }}>
                    <TableBody sx={{ ...tableBodyStyles }}>
                        {block === 'dialogues' && (
                            <TableRow className="table-main__row--flex">
                                <TableCell>ID:</TableCell>
                                <TableCell sx={{ wordBreak: 'break-all' }}>
                                    {id || ''}
                                    <MyTooltip position={tooltipPosition} title={dictionary?.copy}>
                                        <CopyIcon
                                            className="copy-icon"
                                            onClick={() => copyTextHandler(id)}
                                        />
                                    </MyTooltip>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow className="table-main__row--flex">
                            <TableCell>{dictionary?.full_name}:</TableCell>
                            <TableCell sx={{ wordBreak: 'break-all' }}>
                                {fullname || ''}
                                {fullname && block === 'dialogues' && <CheckedIcon />}
                            </TableCell>
                        </TableRow>
                        <TableRow className="table-main__row--flex">
                            <TableCell>E-mail:</TableCell>
                            <TableCell sx={{ color: 'secondaryColor.main' }}>
                                {email}
                                {email && (
                                    <MyTooltip position={tooltipPosition} title={dictionary?.copy}>
                                        <CopyIcon
                                            className="copy-icon"
                                            onClick={() => copyTextHandler(email)}
                                        />
                                    </MyTooltip>
                                )}
                            </TableCell>
                        </TableRow>
                        {phones?.length === 0 && (
                            <TableRow className="table-main__row--flex">
                                <TableCell> {dictionary?.phone}: </TableCell>
                            </TableRow>
                        )}
                        {phones?.length > 0 &&
                            phones.map(({ phone }, index) => {
                                return (
                                    <TableRow
                                        className="table-main__row--flex"
                                        sx={{
                                            marginBottom:
                                                phones.length === index + 1
                                                    ? 'inherit'
                                                    : '10px!important',
                                        }}
                                        key={index}
                                    >
                                        <TableCell>
                                            {index === 0 ? dictionary?.phone + ':' : ''}
                                        </TableCell>
                                        <TableCell
                                            sx={{ color: 'secondaryColor.main' }}
                                            key={phone}
                                        >
                                            {phone.startsWith('+') ? phone : '+' + phone}
                                            <MyTooltip
                                                position={tooltipPosition}
                                                title={dictionary?.copy}
                                            >
                                                <CopyIcon
                                                    className="copy-icon"
                                                    onClick={() => copyTextHandler(phone)}
                                                />
                                            </MyTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {status === 'blocked' &&
                            blocking.map(({ expiration, site }, index) => {
                                return (
                                    <TableRow
                                        className="table-main__row--flex"
                                        sx={{
                                            marginBottom:
                                                blocking.length === index + 1
                                                    ? 'inherit'
                                                    : '10px!important',
                                        }}
                                        key={index}
                                    >
                                        <TableCell>
                                            {index === 0 ? dictionary?.blocking : ''}
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Box
                                                    component="span"
                                                    sx={{ color: 'secondaryColor.main' }}
                                                >
                                                    {site.domain}
                                                </Box>
                                                <Box component="span">
                                                    {' '}
                                                    {dictionary?.until +
                                                        ' ' +
                                                        expiration.replaceAll('-', '.')}
                                                </Box>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
            <Box>
                <Typography variant="h4">{dictionary?.messengers}</Typography>
                <Table className="table-main--small" sx={{ marginBottom: 3.75 }}>
                    <TableBody sx={{ ...tableBodyStyles }}>
                        {Object.entries(messengers || {}).map(([key, value]) => {
                            return (
                                <TableRow className="table-main__row--flex" key={key}>
                                    <TableCell>{setMessengerLabel(key)}:</TableCell>
                                    <TableCell sx={{ color: 'secondaryColor.main' }}>
                                        {value}
                                        {value && (
                                            <MyTooltip
                                                position={tooltipPosition}
                                                title={dictionary?.copy}
                                            >
                                                <CopyIcon
                                                    className="copy-icon"
                                                    onClick={() => copyTextHandler(value)}
                                                />
                                            </MyTooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box>
                <Typography variant="h4">{dictionary?.about_the_device}</Typography>
                <Table className="table-main--small" sx={{ marginBottom: visits ? 3.75 : 0 }}>
                    <TableBody sx={{ ...tableBodyStyles }}>
                        <TableRow className="table-main__row--flex">
                            <TableCell>User-agent:</TableCell>
                            <TableCell>{ua}</TableCell>
                        </TableRow>
                        <TableRow className="table-main__row--flex">
                            <TableCell>{dictionary?.ip}</TableCell>
                            <TableCell>
                                <FlexBox
                                    className="row-4"
                                    sx={{ flexWrap: 'wrap', rowGap: '0 !important' }}
                                >
                                    <span className={`fi fi-${code?.toLowerCase()}`}></span>
                                    {country && <Typography>{country},</Typography>}
                                    {city && <Typography>{city}:</Typography>}
                                    <Typography>{ip}</Typography>
                                </FlexBox>
                            </TableCell>
                        </TableRow>
                        <TableRow className="table-main__row--flex">
                            <TableCell>Identifier GA:</TableCell>
                            <TableCell>{ga}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            {visits && current_page && (
                <Box sx={{ position: 'relative', paddingBottom: 3.75 }}>
                    <Box
                        sx={{
                            zIndex: 1,
                            position: 'relative',
                            width: 'calc(100% - 30px)',
                        }}
                    >
                        <Typography variant="h4">{dictionary?.visits}</Typography>
                        <FlexBox
                            className="row-10"
                            alignItems="flex-start"
                            sx={{
                                '& svg': {
                                    cursor: 'pointer',
                                    transition: 'transform 200ms',
                                    transform: !openVisitedPages
                                        ? 'rotate(0deg)'
                                        : 'rotate(180deg)',
                                },
                            }}
                        >
                            <Table
                                className="table-main--small"
                                sx={{
                                    minHeight: 24,
                                    wordBreak: 'break-word',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <TableBody sx={{ ...tableBodyStyles }}>
                                    <TableRow className="table-main__row--flex">
                                        <TableCell>{dictionary?.current_page}</TableCell>
                                        <TableCell>
                                            <MyLink
                                                text={current_page.name}
                                                link={current_page.href}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <ExpandMoreIcon
                                onClick={() => {
                                    setOpenVisitedPages(!openVisitedPages);
                                }}
                            />
                        </FlexBox>
                    </Box>
                    {visits && visits.length !== 0 && (
                        <ViewVisitorAccordion links={visits} open={openVisitedPages} />
                    )}
                </Box>
            )}
        </>
    );
};

export default ViewVisitor;
