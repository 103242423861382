import { apiSlice } from './apiSlice';

const apiAppealsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAppeals: builder.query({
            query: (query) =>
                `${query.part}/support${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.search}${query.category_id}${query.range_start}${query.range_end}${query.status}${query.role}${query.client_id}`,
            // `${query.part}/support${query.limit || ""}${query.page || ""}${query.sortBy || ""}${query.sortOrder || ""}${query.search || ""}${query.category_id || ""}${query.range_start || ""}${query.range_end || ""}${query.status || ""}${query.role || ""}${query.client_id || ""}`,
            providesTags: ['Appeals'],
        }),
        getSelectedAppeal: builder.query({
            query: (data) => ({
                url: `${data.part}/support/${data.id}`,
            }),
            providesTags: ['Appeals'],
        }),
        getNewAppeals: builder.query({
            query: () => ({
                url: 'admin/support/counter',
            }),
            // invalidatesTags: ['Appeals'],
            providesTags: ['Appeals'],
        }),
        createAppeal: builder.mutation({
            query: (appeal) => ({
                url: 'client/support',
                method: 'POST',
                body: appeal,
            }),
            invalidatesTags: ['Appeals'],
            // providesTags: ['Appeals'],
        }),
        changeAppealStatus: builder.mutation({
            query: (data) => ({
                url: `admin/support/${data.id}/?status=${data.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Appeals'],
            // providesTags: ['Appeals'],
        }),
        getAllAppealsCategories: builder.query({
            query: (data) => `${data.part}/support/categories${data.payload}`,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAppealsQuery,
    useGetSelectedAppealQuery,
    useGetNewAppealsQuery,
    useCreateAppealMutation,
    useChangeAppealStatusMutation,
    useGetAllAppealsCategoriesQuery,
} = apiAppealsSlice;
