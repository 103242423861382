import { createSlice } from '@reduxjs/toolkit';

const sectionSlice = createSlice({
    name: 'section',
    initialState: {
        content: '',
        skip: false,
        selectedElementId: '',
    },
    reducers: {
        setContent: (state, action) => {
            state.content = action.payload;
        },
        setSkip: (state, action) => {
            state.skip = action.payload;
        },
        setSelectedElementId: (state, action) => {
            state.selectedElementId = action.payload;
        },
    },
});

export const { setContent, setSkip, setSelectedElementId } = sectionSlice.actions;
export default sectionSlice.reducer;
