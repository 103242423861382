import { useState, useEffect, useContext } from 'react';

import { Box } from '@mui/material';

import ViewVisitor from '../Visitors/ViewVisitor';
import EditVisitor from '../Visitors/EditVisitor';
import InformationBar from '../../../components/InformationBar/InformationBar';

import { PageContext } from '../../Page';

const DialogueVisitorData = ({
  visitor,
  dialogueId,
  copy,
  copiedText,
  showVisitorData,
  setShowVisitorData,
  setSkipVisitorData,
  isLoading,
  isFetching,
  isSuccess,
  isError,
}) => {
  const { dictionary } = useContext(PageContext);

  const [content, setContent] = useState('viewVisitor');
  const [contentTitle, setContentTitle] = useState(dictionary?.visitor);

  const changeContentHandler = (content, title) => {
    setContent(content);
    setContentTitle(title);
  };

  useEffect(() => {
    if (!showVisitorData) {
      changeContentHandler('viewVisitor', dictionary?.visitor);
    }
  }, [showVisitorData]);

  return (
    <>
      <InformationBar
        key={dialogueId}
        title={contentTitle}
        show={showVisitorData}
        setShow={setShowVisitorData}
        setSkip={setSkipVisitorData}
        isLoading={isLoading}
        isFetching={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        sx={{
          width: 450,
          '& .information-bar__header': {
            justifyContent: 'flex-start',
          },
        }}
        editIcon={content === 'viewVisitor' ? true : false}
        changeContentHandler={changeContentHandler}
      >
        <Box className="information-bar__body information-bar__body--dialogues">
          {content === 'viewVisitor' && (
            <ViewVisitor
              visitor={visitor}
              copy={copy}
              copiedText={copiedText}
              block={'dialogues'}
              tooltipPosition={'left'}
            />
          )}
          {content === 'editVisitor' && (
            <EditVisitor
              visitor={visitor}
              block={'dialogues'}
              changeContentHandler={changeContentHandler}
            />
          )}
        </Box>
      </InformationBar>
    </>
  );
};

export default DialogueVisitorData;
