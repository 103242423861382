import { useState, useContext } from 'react';

import { Autocomplete } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PageContext } from '../../../../pages/Page';

const BaseAutocompleteSelect = ({
    options,
    serverSearch,
    setSearchQuery,
    sx,
    selectArrowSx,
    ...rest
}) => {
    const { dictionary } = useContext(PageContext);

    const [selectBorderRadius, setSelectBorderRadius] = useState({
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    });

    return (
        <Autocomplete
            sx={{
                '&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': { paddingRight: 1.75 },
                '& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    ...selectBorderRadius,
                    ...sx,
                },
                '& .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-endAdornment .MuiButtonBase-root':
                    { ...selectArrowSx },
                '& .MuiInputBase-root .MuiButtonBase-root': {
                    transition: 'transform 200ms',
                },
                '& .MuiInputBase-root.Mui-focused .MuiAutocomplete-endAdornment .MuiButtonBase-root':
                    {
                        transform: 'rotate(180deg)',
                    },
            }}
            clearIcon={null}
            popupIcon={<ExpandMoreIcon />}
            noOptionsText={dictionary?.nothing_found}
            options={options}
            openText=""
            closeText=""
            onClose={() => {
                setSelectBorderRadius({
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                });
                // return options after no matches
                if (serverSearch) {
                    setSearchQuery('');
                }
            }}
            onOpen={(e) => {
                setSelectBorderRadius({
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                });
            }}
            componentsProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                rootBoundary: 'document',
                            },
                        },
                    ],
                },
            }}
            {...rest}
        />
    );
};

export default BaseAutocompleteSelect;
