import { useState, useContext, useLayoutEffect } from 'react';

import { NavLink } from 'react-router-dom';

import Counter from '../../components/ui/Counter/Counter';

import { PageContext } from '../../pages/Page';

import { setNavLinkName } from '../../data/navLinkData';

const NavSubItem = ({ route, isSuccess, value, setInitialProps }) => {
    const { setAlertMessage, dictionary } = useContext(PageContext);

    const [currentValue, setCurrentValue] = useState('');

    const {
        path,
        // linkName,
        counter,
        counterType,
        initialContent,
    } = route;

    const linkName = setNavLinkName(dictionary).filter(
        ({ id, hiddenRoutes }) => path === id && hiddenRoutes
    )[0]?.linkName;

    useLayoutEffect(() => {
        if (value) {
            if (typeof value === 'object') {
                for (const [key, currentValue] of Object.entries(value)) {
                    if (key === counterType) {
                        setCurrentValue(currentValue);
                    }
                }
            } else {
                setCurrentValue(value);
            }
        }
    }, [value]);

    return (
        <li className="nav__sub-item">
            <NavLink
                onClick={() => {
                    setAlertMessage({});
                    if (initialContent) {
                        setInitialProps(initialContent);
                    }
                }}
                className={({ isActive }) =>
                    isActive
                        ? 'nav__link nav__link--secondary _active'
                        : 'nav__link nav__link--secondary'
                }
                to={path}
            >
                {linkName}
                {counter && isSuccess && currentValue && (
                    <Counter sx={{ position: 'absolute', right: 14, top: 9 }} indicator={false}>
                        {currentValue}
                    </Counter>
                )}
            </NavLink>
        </li>
    );
};

export default NavSubItem;
