import { useContext } from 'react';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { setFunctionalItemValue } from '../../../helpers/functions';
import { setClientFunctionalTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const ClientFunctional = ({ functionalList, isSuccess, isError }) => {
    const { dictionary, language } = useContext(PageContext);

    if (isError) {
        return <Typography variant="h3">ERROR</Typography>;
    }

    return (
        <TblContainer>
            <TblHead setHeadCells={setHeadCells} />
            <TableBody>
                {functionalList.length === 0 && isSuccess && <EmptyListAlertText />}
                {functionalList.length > 0 &&
                    functionalList.map(({ id, name, value, price, type }) => {
                        return (
                            <TableRow className="table-main__row" key={id}>
                                <TableCell>{name?.[language]}</TableCell>
                                <TableCell>
                                    {setFunctionalItemValue(value, type, dictionary)}
                                </TableCell>
                                <TableCell>{price} UAH</TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </TblContainer>
    );
};

export default ClientFunctional;
