import Clients from '../pages/adminPartPages/Clients/Clients';
import Client from '../pages/adminPartPages/Clients/Client';
import Functional from '../pages/adminPartPages/Functional/Functional';
import Notifications from '../pages/adminPartPages/Notifications/Notifications';
import Categories from '../pages/adminPartPages/Support/Categories';
import Appeals from '../pages/adminPartPages/Support/Appeals';
import Users from '../pages/adminPartPages/Users/Users';
import SettingsPage from '../pages/SettingsPage/SettingsPage';

class User {
    constructor(id) {
        this.id = id;
        this.clients = {
            path: 'clients',
            initialContent: 'clientsList',
            Component: <Clients />,
        };
        this.client = {
            path: 'clients/:id',
            notNavLink: true,
            Component: <Client />,
        };
        this.settings = {
            path: 'settings',
            notNavLink: true,
            Component: <SettingsPage />,
        };
        this.routeList = [this.clients, this.client, this.settings];
    }
}

class Manager extends User {
    constructor(id) {
        super(id);
        this.support = {
            path: 'support',
            initialContent: 'appealsList',
            Component: <Appeals />,
            counter: true,
        };
        this.routeList = [...this.routeList, this.support];
    }
}

class Admin extends User {
    constructor(id) {
        super(id);
        this.notifications = {
            path: 'notifications',
            initialContent: 'notificationsList',
            Component: <Notifications />,
        };
        this.support = {
            path: 'support/categories',
            Component: <Categories />,
            counter: true,
            accordion: 'support',
            hiddenRoutes: [
                {
                    path: 'support/categories',
                    initialContent: 'categoriesList',
                    Component: <Categories />,
                },
                {
                    path: 'support/appeals',
                    initialContent: 'appealsList',
                    Component: <Appeals />,
                    counter: true,
                },
            ],
        };
        this.routeList = [...this.routeList, this.notifications, this.support];
    }
}

class SuperAdmin extends Admin {
    constructor(id) {
        super(id);
        this.users = {
            path: 'users',
            initialContent: 'usersList',
            Component: <Users />,
        };
        this.functional = {
            path: 'functional',
            initialContent: 'functionalList',
            Component: <Functional />,
        };
        this.routeList = [this.users, this.functional, ...this.routeList];
    }
}

export const manager = new Manager('mng');
export const admin = new Admin('admin');
export const superAdmin = new SuperAdmin('sadmin');
