import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  useGetDialoguesListQuery,
  useConnectToDialogueMutation,
} from '../../../api/clientPartApi/apiDialoguesSlice';

import { TableBody, TableRow, TableCell, Typography } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyLink from '../../../components/ui/Link/MyLink';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import {
  setDialoguesTableHeadings as setHeadCells,
  setOperatorDialoguesTableHeadings as setOperatorHeadCells,
} from '../../../data/tableHeadingsData';

import { dateConverter, setStatusLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { socket } from '../../Page';

const AllDialogues = () => {
  const { role, navigate, dictionary, clientId } = useContext(PageContext);
  const {
    createSlug,
    content,
    skip,
    hash,
    changeContentHandler,
    setSelectedDialogueWithRedirection,
  } = useContext(PageContainerContext);

  useLayoutEffect(() => {
    if (!hash && content !== 'dialoguesList') {
      changeContentHandler('dialoguesList', '', '', 'dialogues');
    }
  }, [hash]);

  const {
    data: dialogues,
    isFetching: dialoguesFetching,
    isLoading: dialoguesLoading,
    isSuccess: dialoguesSuccess,
    isError: dialoguesFetchingError,
    error: dialoguesError,
    refetch: fetchDialogues,
  } = useGetDialoguesListQuery(
    {
      limit: createSlug('limit'),
      page: createSlug('page'),
      sortBy: createSlug('sort_by'),
      sortOrder: createSlug('sort_order'),
      search: createSlug('q'),
      sites: createSlug('sites', 'array'),
      user: createSlug('user'),
      visitor: createSlug('visitor'),
      tag: createSlug('tag'),
      country: createSlug('country'),
      status: createSlug('status'),
      range_start: createSlug('range_start'),
      created_at: createSlug('created_at'),
      range_end: createSlug('range_end'),
    },
    { skip }
  );

  const [connectToDialog] = useConnectToDialogueMutation();

  const dialoguesList = dialogues ? dialogues?.data?.dialogs?.data : [];
  const dialoguesListPaginationData = dialogues?.data?.dialogs || {};

  const viewDialogHandler = (id, status) => {
    if (status === 'waiting') {
      navigate(`/dialogues/waiting#dialogue_${id}`);
    } else {
      navigate(`/dialogues/active#dialogue_${id}`);
    }
  };

  useEffect(() => {
    const updateDialoguesList = (data) => {
      fetchDialogues();
    };

    socket.on('dialog.list', updateDialoguesList);
    socket.on('visitor.list', updateDialoguesList);

    return () => {
      socket.off('dialog.list', updateDialoguesList);
      socket.off('visitor.list', updateDialoguesList);
    };
  }, []);

  const connectToDialogHandler = (id, status) => {
    connectToDialog(id)
      .unwrap()
      .then((res) => {
        setSelectedDialogueWithRedirection({
          isClicked: true,
          status: status === 'waiting' ? 'waiting' : 'someonesUnresolved',
          redirectedFromActiveOrWaitingDialoguesSection: false,
        });
        navigate(`/dialogues/active#dialogue_${id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Section
      isLoading={dialoguesLoading}
      isFetching={dialoguesFetching}
      isError={dialoguesFetchingError}
      error={dialoguesError}
      title={dictionary?.dialogues_list}
      sx={{ maxWidth: 1490 }}
      content={content}
    >
      <MyToolbar
        filter={true}
        search={dictionary?.dialogues_search_placeholder}
        paginationData={dialoguesListPaginationData}
        changeContentButton={false}
      />
      <TblContainer>
        <TblHead
          setHeadCells={role !== 'op' ? setHeadCells : setOperatorHeadCells}
        />
        <TableBody>
          {dialoguesList.length === 0 && dialoguesSuccess && (
            <EmptyListAlertText />
          )}
          {dialoguesList.length > 0 &&
            dialoguesList.map(
              ({
                id,
                visitor,
                site,
                user,
                users,
                created_at,
                last_message_at,
                status,
              }) => {
                return (
                  <TableRow className="table-main__row" key={id}>
                    <TableCell className="ellipsis ellipsis--allDialogues">
                      <MyTooltip
                        position={'top-start'}
                        title={id}
                        arrow={false}
                        interactive={false}
                      >
                        <Typography>{id}</Typography>
                      </MyTooltip>
                    </TableCell>
                    <TableCell className="ellipsis">
                      <MyTooltip
                        position={'top-start'}
                        title={visitor?.fullname}
                        arrow={false}
                        interactive={false}
                      >
                        <Typography>{visitor?.fullname}</Typography>
                      </MyTooltip>
                    </TableCell>
                    <TableCell>
                      <MyLink
                        link={`${site?.protocol}://${site?.domain}`}
                        text={site?.domain}
                      />
                    </TableCell>
                    {role !== 'op' && <TableCell>{user?.name}</TableCell>}
                    <TableCell className="status">
                      <Typography className={status}>
                        {setStatusLabel(status, dictionary)}
                      </Typography>
                    </TableCell>
                    <TableCell>{dateConverter(created_at)}</TableCell>
                    <TableCell>{dateConverter(last_message_at)}</TableCell>
                    <TableCell className="action">
                      <MyTooltip position={'top'} title={dictionary?.viewing}>
                        <ViewingIcon
                          onClick={() => {
                            viewDialogHandler(id, status);
                          }}
                        />
                      </MyTooltip>
                      {(status === 'waiting' ||
                        (status === 'unresolved' &&
                          !users.includes(clientId))) && (
                        <MyTooltip position={'top'} title={dictionary?.connect}>
                          <AddIcon
                            onClick={() => connectToDialogHandler(id, status)}
                          />
                        </MyTooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </TblContainer>
      <TblPagination
        data={dialoguesListPaginationData}
        invalidateTag={'Dialogues'}
      />
    </Section>
  );
};

export default AllDialogues;
