import { useCallback, useContext } from 'react';
import {
  useCreateAppealMutation,
  useGetAllAppealsCategoriesQuery,
} from '../../../api/apiAppealsSlice';

import FormContainer from '../../../components/Form/FormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import GeneralInput from '../../../components/Form/FormInputs/GeneralInput';

import { createAppealSchema as schema } from '../../../settings/formSchema';

import { setSelectLabel } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { useNavigate } from 'react-router-dom';

const CreateAppealForm = () => {
  const { alertMessageContent, dictionary } = useContext(PageContext);

  const [
    createAppeal,
    {
      isLoading: createdAppealLoading,
      isFetching: createdAppealFetching,
      isSuccess: createdAppealSuccess,
    },
  ] = useCreateAppealMutation();

  const {
    data: categories,
    isFetching: categoriesFetching,
    isLoading: categoriesLoading,
    isSuccess: categoriesSuccess,
    isError: categoriesFetchingError,
  } = useGetAllAppealsCategoriesQuery({
    part: 'client',
    payload: '?sort_by=name&sort_order=asc',
  });

  const navigate = useNavigate();
  const categoriesOptions = categories?.data?.categories?.data;

  const formSubmitHandler = useCallback((data) => {
    const newAppeal = {
      category_id: data.category,
      subject: data.subject,
      message: data.message,
    };

    createAppeal(newAppeal)
      .unwrap()
      .then((res) => alertMessageContent(dictionary?.appeal_created, 'success'))
      .catch((error) => {
        alertMessageContent(dictionary?.something_go_wrong, 'error');
      })
      .finally(() => {
        navigate('/support');
      });
  }, []);

  return (
    <>
      <FormContainer
        schema={schema}
        submitButtonTitle={dictionary?.create}
        submitButtonClass={'btn--primary'}
        formSubmitHandler={formSubmitHandler}
        cleareForm={createdAppealSuccess}
        isLoading={createdAppealLoading}
        isFetching={createdAppealFetching}
      >
        <SelectWithFormContext
          fieldName={'category'}
          label={setSelectLabel(
            categoriesFetching,
            categoriesLoading,
            categoriesFetchingError,
            dictionary?.category,
            dictionary
          )}
          labelId={'categorySelect'}
          options={categoriesSuccess ? categoriesOptions : ''}
        />
        <GeneralInput fieldName={'subject'} label={dictionary?.subject} />
        <GeneralInput
          fieldName={'message'}
          label={dictionary?.message}
          multiline={true}
          sx={{
            '& textarea': {
              height: '180px!important',
            },
          }}
        />
      </FormContainer>
    </>
  );
};

export default CreateAppealForm;
