import { useState, useContext, useLayoutEffect } from 'react';
import { useGetClientQuery } from '../../../api/adminPartApi/apiClientsSlice';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { useGetClientEmployeeQuery } from '../../../api/apiCommonClientEmployeesSlice';

import { Tabs, Tab, Box, Typography } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import ClientData from './ClientData';
import ClientFunctional from './ClientFunctional';
import ClientActions from './ClientActions';
import ClientStatuses from './ClientStatuses';
import ClientEmployees from './ClientEmployees';

import { CustomTabPanel, a11yProps } from '../../../components/Tabs/TabPanel';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Client = () => {
    const { dictionary, navigate } = useContext(PageContext);
    const {
        createSlug,
        queryParams,
        setQueryParams,
        content,
        changeContentHandler,
        pathname,
        hash,
    } = useContext(PageContainerContext);

    const DATA_TAB = 0;
    const FUNCTIONAL_TAB = 1;
    const ACTIONS_TAB = 2;
    const STATUSES_TAB = 3;
    const USERS_TAB = 4;

    const tabs = [
        { label: dictionary?.data, value: DATA_TAB, title: dictionary?.clients_title_2 },
        {
            label: dictionary?.functional,
            value: FUNCTIONAL_TAB,
            title: dictionary?.clients_title_3,
        },
        { label: dictionary?.actions, value: ACTIONS_TAB, title: dictionary?.actions },
        { label: dictionary?.statuses, value: STATUSES_TAB, title: dictionary?.clients_title_4 },
        { label: dictionary?.users, value: USERS_TAB, title: dictionary?.users },
    ];

    const [tabValue, setTabValue] = useState(DATA_TAB);
    const [sectionTitle, setSectionTitle] = useState(dictionary?.clients_title_2);
    const [actionType, setActionType] = useState('');
    const [clientId, setClientId] = useState('');
    const [clientRole, setClientRole] = useState('');
    const [clientEmployeeId, setClientEmployeeId] = useState('');

    const [copiedText, copy] = useCopyToClipboard();

    useLayoutEffect(() => {
        if (pathname.startsWith('/admin/clients/client_')) {
            const id = pathname.slice(pathname.indexOf('-') + 1);
            const role = pathname.slice(pathname.indexOf('_') + 1).split('-')[0];
            const getTabValueFromQueryParams = () => {
                setQueryParams(
                    (params) => {
                        if (!params.get('tab')) {
                            params.set('tab', tabValue);
                        } else {
                            params.set('tab', params.get('tab'));
                            setTabValue(Number(params.get('tab')));
                        }

                        return params;
                    },
                    { replace: true }
                );
            };

            setClientId(id);
            setClientRole(role);
            changeContentHandler('clientInfo', '', true, false);

            if (hash.startsWith('#view_employee-') || hash.startsWith('#edit_employee-')) {
                const id = hash.slice(hash.indexOf('-') + 1);
                setClientEmployeeId(id);
                setTabValue(USERS_TAB);
            } else if (hash.startsWith('#invite_employee')) {
                setClientEmployeeId(id);
                setActionType('invite');
                setTabValue(USERS_TAB);
            }

            if (hash === '#edit' || hash.startsWith('#edit_employee-')) {
                setActionType('edit');
            } else if (!hash || hash.startsWith('#view_employee-')) {
                setActionType('view');
                if (!hash) {
                    getTabValueFromQueryParams();
                }
            }
        } else if (!pathname.startsWith('/admin/clients/client_')) {
            navigate('/admin/clients');
        }
    }, [pathname, hash]);

    useLayoutEffect(() => {
        if (queryParams.size && queryParams.get('tab') !== tabValue) {
            setTabValue(Number(queryParams.get('tab')));
        }
    }, [queryParams]);

    const {
        data: clientData,
        isFetching: clientDataFetching,
        isLoading: clientDataLoading,
        isError: clientDataFetchingError,
        error: clientDataError,
    } = useGetClientQuery(
        { id: clientId, extraPath: clientRole !== 'adm' ? '/employees' : '' },
        { skip: !clientId }
    );

    const {
        data: clientFunctional,
        isLoading: clientFunctionalLoading,
        isFetching: clientFunctionalFetching,
        isSuccess: clientFunctionalSuccess,
        isError: clientFunctionalFetchingError,
        error: clientFunctionalError,
    } = useGetClientQuery(
        {
            id: clientId,
            section: 'functional',
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
        },
        { skip: !(tabValue === FUNCTIONAL_TAB) }
    );

    const {
        data: clientActions,
        isLoading: clientActionsLoading,
        isFetching: clientActionsFetching,
        isSuccess: clientActionsSuccess,
        isError: clientActionsFetchingError,
        error: clientActionsError,
    } = useGetClientQuery(
        {
            limit: 'limit=5',
            id: clientId,
            section: 'actions',
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
        },
        { skip: !(tabValue === ACTIONS_TAB) }
    );

    const {
        data: clientStatuses,
        isLoading: clientStatusesLoading,
        isFetching: clientStatusesFetching,
        isSuccess: clientStatusesSuccess,
        isError: clientStatusesFetchingError,
        error: clientStatusesError,
    } = useGetClientQuery(
        {
            limit: 'limit=5',
            id: clientId,
            section: 'statuses',
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
        },
        { skip: !(tabValue === STATUSES_TAB) }
    );

    const {
        data: clientEmployees,
        isLoading: clientEmployeesLoading,
        isFetching: clientEmployeesFetching,
        isSuccess: clientEmployeesSuccess,
        isError: clientEmployeesFetchingError,
        error: clientEmployeesError,
    } = useGetClientQuery(
        {
            limit: 'limit=15',
            id: clientId,
            section: 'employees',
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q'),
            role_id: createSlug('role_id'),
            sites: createSlug('sites', 'array'),
            email: createSlug('email'),
            site: createSlug('site'),
        },
        { skip: !(tabValue === USERS_TAB) }
    );

    const {
        data: clientEmployee,
        isFetching: clientEmployeeFetching,
        isLoading: clientEmployeeLoading,
        isSuccess: clientEmployeeSuccess,
        isError: clientEmployeeFetchingError,
        error: clientEmployeeError,
    } = useGetClientEmployeeQuery(
        { id: clientEmployeeId, adminPart: true },
        { skip: !clientEmployeeId }
    );

    const client = clientRole === 'adm' ? clientData?.data?.user : clientData?.data;
    const functionalList = clientFunctional?.data || [];
    const actionsList = clientActions?.data?.actions?.data || [];
    const actionsListPaginationData = clientActions?.data?.actions || {};
    const statusesList = clientStatuses?.data?.statuses?.data || [];
    const statusesListPaginationData = clientStatuses?.data?.statuses || {};
    const clientEmployeesList = clientEmployees?.data?.employees?.data || [];
    const clientEmployeesListPaginationData = clientEmployees?.data?.employees || {};
    const employee = clientEmployee?.data || [];

    const tabHandler = (title, tab) => {
        setSectionTitle(title);
        setQueryParams(
            (params) => {
                params.delete('page');
                params.delete('sort_by');
                params.delete('sort_order');
                params.delete('sites');
                params.delete('role_id');
                params.delete('email');
                params.delete('site');
                params.set('tab', tab);
                return params;
            },
            { replace: true }
        );
    };

    if (clientDataFetchingError) {
        return <Typography variant="h3">ERROR</Typography>;
    }

    return (
        <Section
            key={clientEmployee?.data?.id}
            isLoading={
                clientDataLoading ||
                clientFunctionalLoading ||
                clientActionsLoading ||
                clientStatusesLoading ||
                clientEmployeesLoading ||
                clientEmployeeLoading
            }
            isFetching={
                clientDataFetching ||
                clientFunctionalFetching ||
                clientActionsFetching ||
                clientStatusesFetching ||
                clientEmployeesFetching ||
                clientEmployeeFetching
            }
            error={
                clientDataError ||
                clientFunctionalError ||
                clientActionsError ||
                clientStatusesError ||
                clientEmployeesError ||
                clientEmployeeError
            }
            title={sectionTitle}
            sx={{
                maxWidth:
                    (clientRole === 'adm' && actionType !== 'edit') ||
                    hash.startsWith('#edit_employee-')
                        ? 1110
                        : 540,
            }}
            content={content}
        >
            <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ borderColor: 'transparent' }}>
                    <Tabs
                        className="tabs-clients"
                        value={tabValue}
                        onChange={(event, newValue) => {
                            setTabValue(newValue);
                        }}
                        aria-label="basic tabs example"
                    >
                        {tabs.map(({ label, value, title }) => {
                            if (
                                (clientRole === 'adm' && actionType !== 'edit') ||
                                value === DATA_TAB ||
                                hash.startsWith('#edit_employee-')
                            ) {
                                return (
                                    <Tab
                                        key={value}
                                        sx={{
                                            pointerEvents:
                                                (clientRole === 'adm' && actionType !== 'edit') ||
                                                hash.startsWith('#edit_employee-')
                                                    ? 'all'
                                                    : 'none',
                                        }}
                                        label={label}
                                        {...a11yProps(value)}
                                        onClick={() => tabHandler(title, value)}
                                    />
                                );
                            }
                        })}
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={DATA_TAB}>
                    <ClientData
                        client={client}
                        copy={copy}
                        copiedText={copiedText}
                        actionType={actionType}
                        tabValue={tabValue}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={FUNCTIONAL_TAB}>
                    <ClientFunctional
                        functionalList={functionalList}
                        isSuccess={clientFunctionalSuccess}
                        isError={clientFunctionalFetchingError}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={ACTIONS_TAB}>
                    <ClientActions
                        actionsList={actionsList}
                        isSuccess={clientActionsSuccess}
                        isError={clientActionsFetchingError}
                        actionsListPaginationData={actionsListPaginationData}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={STATUSES_TAB}>
                    <ClientStatuses
                        statusesList={statusesList}
                        isSuccess={clientStatusesSuccess}
                        isError={clientStatusesFetchingError}
                        statusesListPaginationData={statusesListPaginationData}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={USERS_TAB}>
                    <ClientEmployees
                        clientId={clientId}
                        employee={employee}
                        employees={clientEmployees}
                        employeesList={clientEmployeesList}
                        paginationData={clientEmployeesListPaginationData}
                        employeesSuccess={clientEmployeesSuccess}
                        copy={copy}
                        copiedText={copiedText}
                        actionType={actionType}
                        clientEmployeeSuccess={clientEmployeeSuccess}
                    />
                </CustomTabPanel>
            </Box>
        </Section>
    );
};

export default Client;
