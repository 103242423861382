import { useContext, useCallback } from 'react';

import { Grid } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ChannelsAutocompleteSelect from '../../../components/Form/FormSelect/AutocompleteSelect/ChannelsAutocompleteSelect';

import {
    setClientPartStatusSelect as setClientStatus,
    setClientPartVisitorOnlineStatusSelect as setVisitorStatus,
} from '../../../data/formSelectData';

import { arrayToQueryString, setFilterParams } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const currentQueryParams = ['sites', 'status'];

const VisitorsFilter = ({ selectQuerySkip }) => {
    const { dictionary, content } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const formSubmitHandler = useCallback(
        (data) => {
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    const newData = arrayToQueryString(data.sites, 'sites');
                    setFilterParams(newData, 'sites', params);

                    setFilterParams(data.status, 'status', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={2.6855}>
                <ChannelsAutocompleteSelect
                    selectQuerySkip={selectQuerySkip}
                    queryParams={queryParams}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <SelectWithFormContext
                    fieldName={'status'}
                    label={dictionary?.status}
                    labelId={'statusSelect'}
                    options={
                        content === 'visitorsOnline'
                            ? setVisitorStatus(dictionary)
                            : setClientStatus(dictionary)
                    }
                    emtyOption={true}
                    receivedValue={queryParams.get('status')}
                />
            </Grid>
        </FilterFormContainer>
    );
};

export default VisitorsFilter;
