const REACT_APP_WIDGET_URL = process.env.REACT_APP_WIDGET_URL;

export const setCurrentTime = (ISOstring) => {
    let currentTime = ISOstring;
    const TIME_TO_ADD_DAYS = 86400;
    const TIME_TO_ADD_ZERO = 10;

    let days = currentTime > TIME_TO_ADD_DAYS ? Math.floor(currentTime / TIME_TO_ADD_DAYS) : '';

    while (currentTime / TIME_TO_ADD_DAYS >= 1) {
        currentTime = currentTime - TIME_TO_ADD_DAYS;
    }

    let newTime = currentTime;

    let hours = Math.floor((newTime * 1000) / (1000 * 60 * 60));
    let minutes = Math.floor(((newTime * 1000) % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor(((newTime * 1000) % (1000 * 60)) / 1000);

    days = days ? `${days}:` : '';
    minutes = minutes < TIME_TO_ADD_ZERO ? `0${minutes}:` : `${minutes}:`;
    seconds = seconds < TIME_TO_ADD_ZERO ? `0${seconds}` : seconds;

    if (hours > 0 && hours < TIME_TO_ADD_ZERO) {
        hours = hours < TIME_TO_ADD_ZERO ? '0' + hours + ':' : hours;
    } else if (hours >= TIME_TO_ADD_ZERO) {
        hours = hours + ':';
    } else if (hours === 0) {
        if (days) {
            hours = '00:';
        } else {
            hours = '';
        }
    }

    return (days + hours + minutes + seconds).trim();
};

export const dateConverter = (ISOstring, to = false) => {
    const date = new Date(ISOstring);

    if (to === 'date') {
        return date.toLocaleDateString().replaceAll('-', '.');
    } else if (to === 'time') {
        return date.toLocaleTimeString();
    } else if (to === 'timestamp') {
        return date.getTime() / 1000;
    } else {
        return date.toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    }
};

export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
    if (localStorage.getItem(key) === null) {
        localStorage.setItem(key, JSON.stringify([]));
    } else {
        return JSON.parse(localStorage.getItem(key));
    }
};

export const setFunctionalItemValue = (value, type, dictionary) => {
    switch (type) {
        case 'number':
            return value;
        case 'memory':
            return value + ` ${dictionary?.mb}`;
        case 'boolean':
            if (value) {
                return dictionary?.yes;
            } else return dictionary?.no;
    }
};

export const setStatusLabel = (status, dictionary) => {
    switch (status) {
        case 'active':
            return dictionary?.active;
        case 'enabled':
            return dictionary?.active;
        case 'blocked':
            return dictionary?.blocked;
        case 'stopped':
            return dictionary?.suspended;
        case 'archived':
            return dictionary?.in_archive;
        case 'new':
            return dictionary?.new;
        case 'processing':
            return dictionary?.processing;
        case 'processed':
            return dictionary?.processed;
        case 'rejected':
            return dictionary?.rejected;
        case 'disabled':
            return dictionary?.disabled;
        case 'invite':
            return dictionary?.invited;
        case 'nopublic':
            return dictionary?.not_published;
        case 'deleting':
            return dictionary?.deleted;
        case 'free':
            return dictionary?.free;
        case 'chatting':
            return dictionary?.chatting;
        case 'waiting':
            return dictionary?.waiting;
        case 'resolved':
            return dictionary?.resolved;
        case 'unresolved':
            return dictionary?.unresolved;
        default:
            return '';
    }
};

export const setSelectLabel = (fetching, loading, error, label, dictionary) => {
    if (fetching || loading) {
        return `${dictionary?.loading}...`;
    } else if (error) {
        return 'Error';
    } else {
        return label;
    }
};

export const setMarginLabels = (position, name, dictionary) => {
    switch (position) {
        case 'bottom-right':
            switch (name) {
                case 'margin_first':
                    return dictionary?.margin_bottom;
                case 'margin_second':
                    return dictionary?.margin_right;
            }
        case 'bottom-left':
            switch (name) {
                case 'margin_first':
                    return dictionary?.margin_bottom;
                case 'margin_second':
                    return dictionary?.margin_left;
            }
        case 'top-right':
            switch (name) {
                case 'margin_first':
                    return dictionary?.margin_top;
                case 'margin_second':
                    return dictionary?.margin_right;
            }
        case 'top-left':
            switch (name) {
                case 'margin_first':
                    return dictionary?.margin_top;
                case 'margin_second':
                    return dictionary?.margin_left;
            }
    }
};

export const scrollToElement = (className, index, smooth = false) => {
    const nodeCollection = document.querySelectorAll(`.${className}`);
    const lastNodeCollectionElement = nodeCollection?.[index ? index : nodeCollection.length - 1];
    lastNodeCollectionElement?.scrollIntoView(
        smooth && (index === 0 ? { behavior: 'smooth', block: 'start' } : { behavior: 'smooth' })
    );
};

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};

export const stringAvatar = (name = '') => ({
    sx: {
        bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
});

export const convertBytes = (bytes, dictionary) => {
    let result;

    switch (true) {
        case bytes < 1024:
            result = `${bytes} bytes`;
            break;
        case bytes < 1048576:
            result = `${(bytes / 1024).toFixed(0)} ${dictionary?.kb}`;
            break;
        case bytes < 1073741824:
            result = `${(bytes / 1048576).toFixed(2)} ${dictionary?.mb}`;
            break;
        default:
            result = `${(bytes / 1073741824).toFixed(2)} ${dictionary?.gb}`;
            break;
    }

    return result;
};
export const createScheduleObj = (data) => {
    return {
        mon: {
            active: data?.mon_switcher,
            start: data?.mon_switcher ? data?.mon_start : '',
            end: data?.mon_switcher ? data?.mon_end : '',
        },
        tue: {
            active: data?.tue_switcher,
            start: data?.tue_switcher ? data?.tue_start : '',
            end: data?.tue_switcher ? data?.tue_end : '',
        },
        wed: {
            active: data?.wed_switcher,
            start: data?.wed_switcher ? data?.wed_start : '',
            end: data?.wed_switcher ? data?.wed_end : '',
        },
        thu: {
            active: data?.thu_switcher,
            start: data?.thu_switcher ? data?.thu_start : '',
            end: data?.thu_switcher ? data?.thu_end : '',
        },
        fri: {
            active: data?.fri_switcher,
            start: data?.fri_switcher ? data?.fri_start : '',
            end: data?.fri_switcher ? data?.fri_end : '',
        },
        sat: {
            active: data?.sat_switcher,
            start: data?.sat_switcher ? data?.sat_start : '',
            end: data?.sat_switcher ? data?.sat_end : '',
        },
        sun: {
            active: data?.sun_switcher,
            start: data?.sun_switcher ? data?.sun_start : '',
            end: data?.sun_switcher ? data?.sun_end : '',
        },
    };
};

export const arrayToQueryString = (arr, field) => {
    if (arr) {
        const arrayOfId = arr.map(({ id }) => id);
        const objWithId = { [field]: arrayOfId };
        const paramses = new URLSearchParams();

        objWithId[field].forEach((item, index) => {
            paramses.append(`${field}[${index}]`, item);
        });

        return paramses.toString();
    }
};

export const queryStringToArrayWithId = (string, options) => {
    const queryString = string.split('&');

    let arrWithId = [];

    for (let i = 0; i < queryString.length; i++) {
        arrWithId.push(queryString[i].split('=')[1]);
    }

    const newArr = options
        .map((option) => {
            for (let i = 0; i < arrWithId?.length; i++) {
                if (arrWithId[i] === option.id || arrWithId[i] === option.id.toString()) {
                    return option;
                }
            }
        })
        .filter((option) => option);

    return newArr;
};

export const timer = (day) => {
    const finaleDate = new Date(day).getTime();
    const currentDate = new Date().getTime();
    const timeDifference = finaleDate - currentDate;

    let hours = Math.floor(timeDifference / (1000 * 60 * 60));
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (hours < 0 || minutes < 0 || seconds < 0) {
        return [];
    }

    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }

    return [{ hours, minutes, seconds }];
};

export const sortByDate = (a, b) => new Date(a?.created_at) - new Date(b?.created_at);

export const checkIsTheSameData = (message, index, arr, isNegative = true) =>
    isNegative
        ? new Date(message?.created_at).toLocaleDateString() !==
        new Date(arr[index - 1]?.created_at).toLocaleDateString()
        : new Date(message?.created_at).toLocaleDateString() ===
        new Date(arr[index + 1]?.created_at).toLocaleDateString();

export const widgetTemplate = (code) =>
    `<script>
    !function(){if(window&&window.document){
    localStorage.setItem("widgetId","${code}");
    let d=new Date().getTime();
    let e=document.createElement("script");
    e.src="${REACT_APP_WIDGET_URL}/widget.js?ver="+d;
    e.async=!0;document.head.appendChild(e)}
  }();
  </script>`;

export const setFilterParams = (value, field, params) => {
    if ((Array.isArray(value) && value?.length !== 0) || (!Array.isArray(value) && value)) {
        params.set(field, value);
    } else {
        params.delete(field);
    }
};

export const removeUnnecessaryQueryParams = (
    queryParams,
    setQueryParams,
    currentQueryParams,
    id,
    necessaryParam
) => {
    setQueryParams((params) => {
        currentQueryParams.forEach((param) => {
            if (queryParams.get(param)) {
                params.delete(param);
            }
        });

        if (id) {
            params.set(necessaryParam, id);
        }
        return params;
    });
};

export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};

export const locales = {
    en: {
        name: 'English',
        months: [
            ['January', 'Jan'],
            ['February', 'Feb'],
            ['March', 'Mar'],
            ['April', 'Apr'],
            ['May', 'May'],
            ['June', 'Jun'],
            ['July', 'Jul'],
            ['August', 'Aug'],
            ['September', 'Sep'],
            ['October', 'Oct'],
            ['November', 'Nov'],
            ['December', 'Dec'],
        ],
        weekDays: [
            ['Sunday', 'Sun'],
            ['Monday', 'Mon'],
            ['Tuesday', 'Tue'],
            ['Wednesday', 'Wed'],
            ['Thursday', 'Thu'],
            ['Friday', 'Fri'],
            ['Saturday', 'Sat'],
        ],
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        meridiems: [
            ['AM', 'am'],
            ['PM', 'pm'],
        ],
    },
    ru: {
        name: 'Russian',
        months: [
            ['Январь', 'Янв'],
            ['Февраль', 'Фев'],
            ['Март', 'Мар'],
            ['Апрель', 'Апр'],
            ['Май', 'Май'],
            ['Июнь', 'Июн'],
            ['Июль', 'Июл'],
            ['Август', 'Авг'],
            ['Сентябрь', 'Сен'],
            ['Октябрь', 'Окт'],
            ['Ноябрь', 'Ноя'],
            ['Декабрь', 'Дек'],
        ],
        weekDays: [
            ['Воскресенье', 'Вс'],
            ['Понедельник', 'Пн'],
            ['Вторник', 'Вт'],
            ['Среда', 'Ср'],
            ['Четверг', 'Чт'],
            ['Пятница', 'Пт'],
            ['Суббота', 'Сб'],
        ],
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        meridiems: [
            ['AM', 'am'],
            ['PM', 'pm'],
        ],
    },
    uk: {
        name: 'Ukrainian',
        months: [
            ['Січень', 'Січ'],
            ['Лютий', 'Лют'],
            ['Березень', 'Бер'],
            ['Квітень', 'Кві'],
            ['Травень', 'Тра'],
            ['Червень', 'Чер'],
            ['Липень', 'Лип'],
            ['Серпень', 'Сер'],
            ['Вересень', 'Вер'],
            ['Жовтень', 'Жов'],
            ['Листопад', 'Лис'],
            ['Грудень', 'Гру'],
        ],
        weekDays: [
            ['Неділя', 'Нд'],
            ['Понеділок', 'Пн'],
            ['Вівторок', 'Вт'],
            ['Середа', 'Ср'],
            ['Четвер', 'Чт'],
            ['П’ятниця', 'Пт'],
            ['Субота', 'Сб'],
        ],
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        meridiems: [
            ['AM', 'am'],
            ['PM', 'pm'],
        ],
    },
};
