export const setAdminPartRoleSelect = (dictionary) => {
    return [
        {
            id: 'sadmin',
            name: dictionary?.sadmin,
        },
        {
            id: 'admin',
            name: dictionary?.admin,
        },
        {
            id: 'mng',
            name: dictionary?.mng,
        },
    ];
};

export const setAdminPartStatusSelect = (dictionary) => {
    return [
        {
            id: 'active',
            name: dictionary?.active,
        },
        {
            id: 'blocked',
            name: dictionary?.blocked,
        },
        {
            id: 'stopped',
            name: dictionary?.suspended,
        },
        {
            id: 'invited',
            name: dictionary?.invited,
        },
        {
            id: 'disabled',
            name: dictionary?.disabled,
        },
    ];
};

export const setAdminPartDataTypeSelect = (dictionary) => {
    return [
        {
            id: 'number',
            name: dictionary?.number,
        },
        {
            id: 'logic',
            name: dictionary?.logic,
        },
        {
            id: 'memory',
            name: dictionary?.memory,
        },
    ];
};

export const setRoleSelect = (dictionary) => {
    return [
        {
            id: 'adm',
            name: dictionary?.admin,
        },
        {
            id: 'sv',
            name: dictionary?.supervizor,
        },
        {
            id: 'op',
            name: dictionary?.operator,
        },
        {
            id: 'all',
            name: dictionary?.all,
        },
    ];
};

export const setAdminPartNotificationsStatusSelect = (dictionary) => {
    return [
        {
            id: 'publish',
            name: 'Опубліковано',
        },
        {
            id: 'hidden',
            name: 'Скрито',
        },
    ];
};

export const setAdminPartAppealStatusSelect = (dictionary) => {
    return [
        {
            id: 'new',
            name: dictionary?.new,
        },
        {
            id: 'processed',
            name: dictionary?.processed,
        },
        {
            id: 'processing',
            name: dictionary?.processing,
        },
        {
            id: 'rejected',
            name: dictionary?.rejected,
        },
    ];
};

export const clientPartFontSelect = [
    {
        id: 'Arial',
        name: 'Arial',
    },
    {
        id: 'Comic Sans',
        name: 'Comic Sans',
    },
    {
        id: 'Courier New',
        name: 'Courier New',
    },
    {
        id: 'Evolventa',
        name: 'Evolventa',
    },
    {
        id: 'Georgia',
        name: 'Georgia',
    },
    {
        id: 'Helvetica',
        name: 'Helvetica',
    },
    {
        id: 'Inter',
        name: 'Inter',
    },
    {
        id: 'Montserrat',
        name: 'Montserrat',
    },
    {
        id: 'Noto Sans',
        name: 'Noto Sans',
    },
    {
        id: 'Open Sans',
        name: 'Open Sans',
    },
    {
        id: 'Roboto',
        name: 'Roboto',
    },
    {
        id: 'Tahoma',
        name: 'Tahoma',
    },
    {
        id: 'Times New Roman',
        name: 'Times New Roman',
    },
    {
        id: 'Trebuchet MS',
        name: 'Trebuchet MS',
    },
    {
        id: 'Verdana',
        name: 'Verdana',
    },
];

export const setClientPartPositionSelect = (dictionary) => {
    return [
        {
            id: 'bottom-right',
            name: dictionary?.bottom_right,
        },
        {
            id: 'bottom-left',
            name: dictionary?.bottom_left,
        },
        {
            id: 'top-right',
            name: dictionary?.top_right,
        },
        {
            id: 'top-left',
            name: dictionary?.top_left,
        },
    ];
};

export const clientPartTimezoneSelect = [
    { id: 'UTC -12:00', name: 'UTC -12:00' },
    { id: 'UTC -11:00', name: 'UTC -11:00' },
    { id: 'UTC -10:00', name: 'UTC -10:00' },
    { id: 'UTC -09:30', name: 'UTC -09:30' },
    { id: 'UTC -09:00', name: 'UTC -09:00' },
    { id: 'UTC -08:00', name: 'UTC -08:00' },
    { id: 'UTC -07:00', name: 'UTC -07:00' },
    { id: 'UTC -06:00', name: 'UTC -06:00' },
    { id: 'UTC -05:00', name: 'UTC -05:00' },
    { id: 'UTC -04:00', name: 'UTC -04:00' },
    { id: 'UTC -03:30', name: 'UTC -03:30' },
    { id: 'UTC -03:00', name: 'UTC -03:00' },
    { id: 'UTC -02:00', name: 'UTC -02:00' },
    { id: 'UTC -01:00', name: 'UTC -01:00' },
    { id: 'UTC +00:00', name: 'UTC +00:00' },
    { id: 'UTC +01:00', name: 'UTC +01:00' },
    { id: 'UTC +02:00', name: 'UTC +02:00' },
    { id: 'UTC +03:00', name: 'UTC +03:00' },
    { id: 'UTC +03:30', name: 'UTC +03:30' },
    { id: 'UTC +04:00', name: 'UTC +04:00' },
    { id: 'UTC +04:30', name: 'UTC +04:30' },
    { id: 'UTC +05:00', name: 'UTC +05:00' },
    { id: 'UTC +05:30', name: 'UTC +05:30' },
    { id: 'UTC +05:45', name: 'UTC +05:45' },
    { id: 'UTC +06:00', name: 'UTC +06:00' },
    { id: 'UTC +06:30', name: 'UTC +06:30' },
    { id: 'UTC +07:00', name: 'UTC +07:00' },
    { id: 'UTC +08:00', name: 'UTC +08:00' },
    { id: 'UTC +08:45', name: 'UTC +08:45' },
    { id: 'UTC +09:00', name: 'UTC +09:00' },
    { id: 'UTC +09:30', name: 'UTC +09:30' },
    { id: 'UTC +10:00', name: 'UTC +10:00' },
    { id: 'UTC +10:30', name: 'UTC +10:30' },
    { id: 'UTC +11:00', name: 'UTC +11:00' },
    { id: 'UTC +11:30', name: 'UTC +11:30' },
    { id: 'UTC +12:00', name: 'UTC +12:00' },
    { id: 'UTC +12:45', name: 'UTC +12:45' },
    { id: 'UTC +13:00', name: 'UTC +13:00' },
    { id: 'UTC +14:00', name: 'UTC +14:00' },
];

export const setClientPartInvitationTypeSelect = (dictionary) => {
    return [
        {
            id: 'code',
            name: dictionary?.by_code,
        },
        {
            id: 'email',
            name: dictionary?.by_email,
        },
    ];
};

export const setClientPartStatusSelect = (dictionary) => {
    return [
        {
            id: 'active',
            name: dictionary?.active,
        },
        {
            id: 'blocked',
            name: dictionary?.blocked,
        },
    ];
};

export const setClientPartVisitorOnlineStatusSelect = (dictionary) => {
    return [
        {
            id: 'free',
            name: dictionary?.free,
        },
        {
            id: 'waiting',
            name: dictionary?.waiting,
        },
        {
            id: 'chatting',
            name: dictionary?.chatting,
        },
    ];
};

export const setClientPartDialoguesStatusSelect = (dictionary) => {
    return [
        {
            id: 'waiting',
            name: dictionary?.waiting,
        },
        {
            id: 'unresolved',
            name: dictionary?.unresolved,
        },
        {
            id: 'resolved',
            name: dictionary?.resolved,
        },
    ];
};

export const setClientPartBlockPeriodSelect = (dictionary) => {
    return [
        {
            id: 'hour',
            name: dictionary?.houres,
        },
        {
            id: 'day',
            name: dictionary?.days,
        },
        {
            id: 'week',
            name: dictionary?.weeks,
        },
        {
            id: 'month',
            name: dictionary?.months,
        },
    ];
};
