import { Button } from '@mui/material';

const MyButton = ({ title, buttonHandler, buttonClasses, disabled, sx }) => {
    return (
        <Button
            className={`btn ${buttonClasses}`}
            sx={sx ? { ...sx } : null}
            variant="contained"
            onClick={buttonHandler}
            disabled={disabled ? disabled : false}
        >
            {title}
        </Button>
    );
};

export default MyButton;
