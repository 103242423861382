import { useState, useLayoutEffect, useContext } from 'react';

import { TextField, InputAdornment } from '@mui/material';

import MyButton from '../../ui/Button/MyButton';

import { PageContext } from '../../../pages/Page';

const MySelectSubItem = ({ buttonHandler, isSuccess }) => {
    const { dictionary } = useContext(PageContext);
    const [inputValue, setInputValue] = useState('');

    useLayoutEffect(() => {
        if (isSuccess) {
            setInputValue('');
        }
    }, [isSuccess]);

    return (
        <TextField
            sx={{
                top: -1,
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                    paddingRight: '0!important',
                },
                '& fieldset': {
                    borderTop: 'none!important',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            }}
            placeholder={`${dictionary?.category_name}...`}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <MyButton
                            title={dictionary?.create}
                            buttonClasses={'btn--commands'}
                            sx={{
                                right: '-9px',
                                borderBottomRightRadius: '5px!important',
                            }}
                            buttonHandler={() => buttonHandler(inputValue)}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default MySelectSubItem;
