import { apiSlice } from '../apiSlice';

const apiPaymentHistorySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentHistory: builder.query({
            query: (query) => ({
                url: `client/payments/?limit=5${query.page}${query.sortBy}${query.sortOrder}
                ${query.rangeStart}${query.rangeEnd}
                `,
            }),
            providesTags: ['Payment History'],
        }),
    }),

    overrideExisting: false,
});

export const { useGetPaymentHistoryQuery } = apiPaymentHistorySlice;
