import { useContext, useLayoutEffect } from 'react';
import {
    useGetAnswersQuery,
    useDeleteAnswerMutation,
} from '../../../api/clientPartApi/apiTemplatesSlice';

import { TableBody, TableRow, TableCell, Box } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblPagination from '../../../components/Table/TablePagination';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

import { setAnswersTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

import { dateConverter } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Answers = () => {
    const { dictionary } = useContext(PageContext);
    const { createSlug, content, hash, changeContentHandler } = useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (!hash && content !== 'answersList') {
            changeContentHandler('answersList', '', '', 'templates');
        }
    }, [hash]);

    const {
        data: answers,
        isFetching: answersFetching,
        isLoading: answersLoading,
        isSuccess: answersSuccess,
        isError: answersFetchingError,
        error: answersError,
    } = useGetAnswersQuery({
        limit: createSlug('limit'),
        page: createSlug('page'),
        sortBy: createSlug('sort_by'),
        sortOrder: createSlug('sort_order'),
    });

    const [deleteAnswer] = useDeleteAnswerMutation();

    const answersList = answers?.data?.answers?.data || [];
    const answersListPaginationData = answers?.data?.answers || {};

    const deleteAnswerHandler = (id) => {
        deleteAnswer(id).unwrap();
    };

    return (
        <div className="page__body">
            <Section
                isLoading={answersLoading}
                isFetching={answersFetching}
                isError={answersFetchingError}
                error={answersError}
                title={dictionary?.answers_list}
                sx={{ maxWidth: 920 }}
            >
                <MyToolbar paginationData={answersListPaginationData} changeContentButton={false} />
                <TblContainer>
                    <TblHead setHeadCells={setHeadCells} />
                    <TableBody>
                        {answersList.length === 0 && answersSuccess && <EmptyListAlertText />}
                        {answersList.length > 0 &&
                            answersList.map(({ id, text, created_at }) => {
                                return (
                                    <TableRow className="table-main__row" key={id}>
                                        <TableCell>
                                            <Box
                                                sx={{ '& p': { margin: 0 } }}
                                                dangerouslySetInnerHTML={{ __html: text }}
                                            />
                                        </TableCell>
                                        <TableCell>{dateConverter(created_at)}</TableCell>
                                        <TableCell className="action">
                                            <MyTooltip position={'top'} title={dictionary?.delete}>
                                                <DeleteIcon
                                                    onClick={() => deleteAnswerHandler(id)}
                                                />
                                            </MyTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </TblContainer>
                <TblPagination data={answersListPaginationData} invalidateTag={'Answers'} />
            </Section>
        </div>
    );
};

export default Answers;
