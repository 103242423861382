import { useCallback, useLayoutEffect, useContext } from 'react';
import {
    useCreateCategorieMutation,
    useEditCategorieMutation,
} from '../../../api/adminPartApi/apiCategoriesSlice';

import FormContainer from '../../../components/Form/FormContainer';
import ExpandedSwitcher from '../../../components/ui/Switcher/ExpandedSwitcher';
import LanguageTabs from '../../../components/Tabs/LanguageTabs';

import { PageContext } from '../../Page';

import { createCategorySchema as schema } from '../../../settings/formSchema';

const CategoriesForm = ({
    content,
    categorie,
    categorieSwitcher,
    setCategorieSwitcher,
    changeContentHandler,
}) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    useLayoutEffect(() => {
        if (categorie) {
            setCategorieSwitcher(categorie?.active);
        } else {
            setCategorieSwitcher(false);
        }
    }, [categorie]);

    const [
        createCategorie,
        {
            isLoading: createdCategorieLoading,
            isFetching: createdCategorieFetching,
            isSuccess: createdCategorieSuccess,
        },
    ] = useCreateCategorieMutation();

    const [
        editCategorie,
        { isLoading: editedCategorieLoading, isFetching: editedCategorieFetching },
    ] = useEditCategorieMutation();

    const formSubmitHandler = useCallback(
        (data) => {
            const categorieData = {
                name: {
                    uk: data.name_uk,
                    en: data.name_en,
                    ru: data.name_ru,
                },
                active: data.activity || false,
            };

            if (content === 'createCategorie') {
                createCategorie(categorieData)
                    .unwrap()
                    .then((res) => {
                        alertMessageContent(dictionary?.categorie_created, 'success');
                        setCategorieSwitcher(false);
                    })
                    .catch((error) => {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    });
            } else {
                editCategorie({ id: categorie.id, body: categorieData })
                    .unwrap()
                    .then((res) => {
                        changeContentHandler('categoriesList', '', '', 'support');
                        alertMessageContent(dictionary?.categorie_edited, 'success');
                    })
                    .catch((error) => {
                        alertMessageContent(dictionary?.something_go_wrong, 'error');
                    });
            }
        },
        [categorie]
    );

    return (
        <FormContainer
            schema={schema}
            formClass={'form--secondary'}
            submitButtonTitle={categorie ? dictionary?.save : dictionary?.create}
            submitButtonClass={'btn--primary'}
            formSubmitHandler={formSubmitHandler}
            cleareForm={createdCategorieSuccess}
            useValidationErrors={true}
            isLoading={createdCategorieLoading || editedCategorieLoading}
            isFetching={createdCategorieFetching || editedCategorieFetching}
        >
            <LanguageTabs
                label={dictionary?.name}
                receivedValue={categorie?.name}
                fieldNamePrefix={'name_'}
            />
            <ExpandedSwitcher
                fieldName={'activity'}
                label={dictionary?.activity}
                checked={categorieSwitcher}
                setChecked={setCategorieSwitcher}
                receivedValue={categorie?.active}
            />
        </FormContainer>
    );
};

export default CategoriesForm;
