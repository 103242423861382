import { apiSlice } from '../apiSlice';

const apiConversationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        changeInConversationStatus: builder.mutation({
            query: (status) => ({
                url: `/client/settings/edit/single?in_conversation=${status}`,
                method: 'POST',
            }),
        }),
        changeTakeDialogsStatus: builder.mutation({
            query: (status) => ({
                url: `/client/settings/edit/single?take_dialogs=${status}`,
                method: 'POST',
            }),
        }),
    }),

    overrideExisting: false,
});

export const { useChangeInConversationStatusMutation, useChangeTakeDialogsStatusMutation } =
    apiConversationSlice;
