import { useContext } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PageContext } from '../../Page';

const PaymentAccordion = ({ children, id, functional, expanded, setExpanded }) => {
    const { dictionary } = useContext(PageContext);

    const setTotalPrice = () => {
        if (functional.length > 0) {
            return functional
                .filter(({ value }) => value)
                .reduce((acc, { price }) => {
                    return acc + price;
                }, 0);
        } else {
            return 0;
        }
    };

    const totalPrice = setTotalPrice() + ' UAH';

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion
            sx={{ backgroundColor: 'transparent' }}
            expanded={expanded === id}
            onChange={handleChange(id)}
        >
            <AccordionSummary
                sx={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                    },
                    '&.MuiButtonBase-root:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={{ fontWeight: 600 }}>{dictionary?.total_price}</Typography>
                <Typography
                    sx={{
                        fontWeight: 600,
                        marginRight: 2.5,
                        color: 'secondaryColor.main',
                    }}
                >
                    {totalPrice}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 42px 0 0', marginTop: 2.5 }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default PaymentAccordion;
