import { useContext, useState, useCallback } from 'react';
import { useGetAllEmployeesQuery } from '../../../api/clientPartApi/apiEmployeesSlice';
import { useGetVisitorsQuery } from '../../../api/clientPartApi/apiVisitosSlice';
import { useGetDialogueTagsQuery } from '../../../api/clientPartApi/apiDialoguesSlice';

import { Grid, TextField } from '@mui/material';

import FilterFormContainer from '../../../components/Filter/FilterFormContainer';
import SelectWithFormContext from '../../../components/Form/FormSelect/Select/SelectWithFormContext';
import MyDatePicker from '../../../components/DatePicker/DatePicker';
import ChannelsAutocompleteSelect from '../../../components/Form/FormSelect/AutocompleteSelect/ChannelsAutocompleteSelect';
import SearchSelect from '../../../components/Form/FormSelect/AutocompleteSelect/SearchSelect';
import AutocompleteSelectWithFormContext from '../../../components/Form/FormSelect/AutocompleteSelect/AutocompleteSelectWithFormContext';

import { setClientPartDialoguesStatusSelect as setStatusOptions } from '../../../data/formSelectData';

import { setSelectLabel, arrayToQueryString, setFilterParams } from '../../../helpers/functions';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const currentQueryParams = [
    'sites',
    'user',
    'visitor',
    'tag',
    'status',
    'range_start',
    'range_end',
];

const DialoguesFilter = ({ clearFilter, selectQuerySkip }) => {
    const { role, dictionary } = useContext(PageContext);
    const { queryParams, setQueryParams } = useContext(PageContainerContext);

    const [visitorsSearchQuery, setVisitorsSearchQuery] = useState('');

    const {
        data: employees,
        isFetching: employeesFetching,
        isLoading: employeesLoading,
        isSuccess: employeesSuccess,
        isError: employeesFetchingError,
    } = useGetAllEmployeesQuery(
        { default_sort: 'sort_by=fullname&sort_order=asc' },
        { skip: selectQuerySkip && role !== 'op' }
    );

    const {
        data: visitors,
        isLoading: visitorsLoading,
        isFetching: visitorsFetching,
        isSuccess: visitorsSuccess,
        isError: visitorsFetchingError,
    } = useGetVisitorsQuery(
        {
            default_sort: '&sort_by=name&sort_order=asc',
            limit: visitorsSearchQuery ? '?limit=5' : '?limit=0',
            search: visitorsSearchQuery.replaceAll('%20', ' '),
        },
        { skip: selectQuerySkip }
    );

    const {
        data: tags,
        isLoading: tagsLoading,
        isFetching: tagsFetching,
        isSuccess: tagsSuccess,
        isError: tagsFetchingError,
    } = useGetDialogueTagsQuery({}, { skip: selectQuerySkip });

    const visitorsList =
        visitorsSuccess && visitorsSearchQuery && !visitorsFetching
            ? visitors?.data?.visitors?.data.map(({ id, fullname }) => ({
                  id,
                  label: fullname,
              }))
            : [];

    const employeesList = employeesSuccess
        ? employees?.data
              .filter(({ status }) => status === 'enabled')
              .map(({ id, fullname }) => ({
                  id,
                  label: fullname,
              }))
        : [];
    const tagsList = tagsSuccess
        ? tags?.data?.tags?.data.map(({ id, name }) => ({
              id,
              label: name,
          }))
        : [];

    const formSubmitHandler = useCallback(
        (data) => {
            console.log(data);
            setQueryParams(
                (params) => {
                    if (queryParams.get('page')) {
                        params.delete('page');
                    }

                    const newData = arrayToQueryString(data.sites, 'sites');

                    setFilterParams(newData, 'sites', params);
                    setFilterParams(data.user?.id, 'user', params);
                    setFilterParams(data.visitor?.id, 'visitor', params);
                    setFilterParams(data.tag?.id, 'tag', params);
                    setFilterParams(data.status, 'status', params);
                    setFilterParams(data.date ? data.date[0].trim() : '', 'range_start', params);
                    setFilterParams(data.date ? data.date[1].trim() : '', 'range_end', params);

                    return params;
                }
                // { replace: true }
            );
        },
        [queryParams]
    );

    return (
        <FilterFormContainer
            formSubmitHandler={formSubmitHandler}
            currentQueryParams={currentQueryParams}
        >
            <Grid item xs={2.6855}>
                <ChannelsAutocompleteSelect
                    selectQuerySkip={selectQuerySkip}
                    queryParams={queryParams}
                />
            </Grid>
            {role !== 'op' && (
                <Grid item xs={2.6855}>
                    <AutocompleteSelectWithFormContext
                        fieldName={'user'}
                        options={employeesList}
                        multiple={false}
                        getOptionLabel={(option) => option.label || ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={setSelectLabel(
                                    employeesFetching,
                                    employeesLoading,
                                    employeesFetchingError,
                                    dictionary?.user,
                                    dictionary
                                )}
                            />
                        )}
                        receivedValue={queryParams.get('user')}
                        isSuccess={visitorsSuccess}
                        filterOptions={false}
                    />
                </Grid>
            )}
            <Grid item xs={2.6855}>
                <SearchSelect
                    fieldName={'visitor'}
                    label={
                        visitorsSearchQuery
                            ? dictionary?.visitor
                            : setSelectLabel(
                                  visitorsFetching,
                                  visitorsLoading,
                                  visitorsFetchingError,
                                  dictionary?.visitor,
                                  dictionary
                              )
                    }
                    options={visitorsList}
                    clearFilter={clearFilter}
                    searchQuery={visitorsSearchQuery}
                    setSearchQuery={setVisitorsSearchQuery}
                    placeholder={`${dictionary?.visitor}...`}
                    receivedValue={queryParams.get('visitor')}
                    isSuccess={visitorsSuccess && !visitorsFetching}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <AutocompleteSelectWithFormContext
                    fieldName={'tag'}
                    label={setSelectLabel(
                        tagsFetching,
                        tagsLoading,
                        tagsFetchingError,
                        dictionary?.tag,
                        dictionary
                    )}
                    options={tagsList}
                    multiple={false}
                    getOptionLabel={(option) => option.label || ''}
                    placeholder={`${dictionary?.tag}...`}
                    receivedValue={queryParams.get('tag')}
                    isSuccess={tagsSuccess}
                    filterOptions={false}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <SelectWithFormContext
                    fieldName={'country'}
                    label={dictionary?.country}
                    labelId={'countrySelect'}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <SelectWithFormContext
                    fieldName={'status'}
                    label={dictionary?.status}
                    labelId={'statusSelect'}
                    options={setStatusOptions(dictionary)}
                    emtyOption={true}
                    receivedValue={queryParams.get('status')}
                />
            </Grid>
            <Grid item xs={2.6855}>
                <MyDatePicker
                    fieldName={'date'}
                    clearFilter={clearFilter}
                    placeholder={dictionary?.date_time}
                    receivedValue={
                        queryParams.get('range_start')
                            ? [queryParams.get('range_start'), queryParams.get('range_end')]
                            : ''
                    }
                    queryParams={queryParams}
                />
            </Grid>
        </FilterFormContainer>
    );
};

export default DialoguesFilter;
