import { useContext, useLayoutEffect, useState } from 'react';
import { useGetClientsQuery } from '../../../api/adminPartApi/apiClientsSlice';
import {
    useChangeClientStatusMutation,
    useDeleteInvitedClientMutation,
} from '../../../api/adminPartApi/apiClientsSlice';

import { NavLink } from 'react-router-dom';
import { TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import TblContainer from '../../../components/Table/TableContainer';
import TblHead from '../../../components/Table/TableHead';
import TblPagination from '../../../components/Table/TablePagination';
import MyToolbar from '../../../components/Toolbar/MyToolbar';
import MyTooltip from '../../../components/ui/Tooltip/MyTooltip';
import MyClientsPopper from '../../../components/ui/Popper/MyClientsPopper';
import EmptyListAlertText from '../../../components/ui/Alert/EmptyListAlertText';

import { ReactComponent as BlockIcon } from '../../../assets/icons/block.svg';
import { ReactComponent as UnblockIcon } from '../../../assets/icons/unblock.svg';
import { ReactComponent as ViewingIcon } from '../../../assets/icons/hide.svg';
import { ReactComponent as TurnOffIcon } from '../../../assets/icons/turn-on.svg';
import { ReactComponent as TurnOnIcon } from '../../../assets/icons/turn-off.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { setStatusLabel, timer } from '../../../helpers/functions';
import { setClientsTableHeadings as setHeadCells } from '../../../data/tableHeadingsData';

const Clients = () => {
    const { dictionary } = useContext(PageContext);
    const { createSlug, content, skip, changeContentHandler, pathname } =
        useContext(PageContainerContext);

    useLayoutEffect(() => {
        if ((!content || content !== 'clientsList') && pathname === '/admin/clients') {
            changeContentHandler('clientsList');
        }
    }, [pathname]);

    const {
        data: clients,
        isFetching: clientsFetching,
        isLoading: clientsLoading,
        isSuccess: clientsSuccess,
        isError: clientsFetchingError,
        error: clientsError,
    } = useGetClientsQuery(
        {
            limit: createSlug('limit'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
            search: createSlug('q'),
            functionalId: createSlug('functional_id'),
            functionalValue: createSlug('functional_value'),
            status: createSlug('status'),
        },
        { skip }
    );

    const clientsList = clients?.data?.accounts?.data || [];
    const clientslListPaginationData = clients?.data?.accounts || {};

    const [changeClientStatus] = useChangeClientStatusMutation();
    const [deleteInvitedEmployee] = useDeleteInvitedClientMutation();

    const changeStatusHandler = (id, action, extraPath = '') => {
        changeClientStatus({ id, action, extraPath }).unwrap();
    };

    const setClientRole = (role) => {
        switch (role) {
            case 'adm':
                return dictionary?.admin;
            case 'sv':
                return dictionary?.supervizor;
            case 'op':
                return dictionary?.operator;
        }
    };

    const setStatusActionIcon = (role, status, id) => {
        let component;
        let tooltipTitle;

        switch ((role, status)) {
            case ('adm', 'active'):
                component = <BlockIcon onClick={() => changeStatusHandler(id, 'block')} />;
                tooltipTitle = dictionary?.block;
                break;
            case ('adm', 'stopped'):
                component = <BlockIcon onClick={() => changeStatusHandler(id, 'block')} />;
                tooltipTitle = dictionary?.block;
                break;
            case ('adm', 'blocked'):
                component = <UnblockIcon onClick={() => changeStatusHandler(id, 'unblock')} />;
                tooltipTitle = dictionary?.activate;
                break;
            case (!'adm', 'enabled'):
                component = (
                    <TurnOffIcon onClick={() => changeStatusHandler(id, 'disable', '/employees')} />
                );
                tooltipTitle = dictionary?.turn_off;
                break;
            case (!'adm', 'disabled'):
                component = (
                    <TurnOnIcon onClick={() => changeStatusHandler(id, 'enable', '/employees')} />
                );
                tooltipTitle = dictionary?.turn_on;
                break;
            case (!'adm', 'invite'):
                component = <DeleteIcon onClick={() => deleteInvitedEmployee(id)} />;
                tooltipTitle = dictionary?.delete;
                break;
        }

        return (
            <MyTooltip position={'top'} title={tooltipTitle}>
                {component}
            </MyTooltip>
        );
    };

    return (
        <Section
            isLoading={clientsLoading}
            isFetching={clientsFetching}
            isError={clientsFetchingError}
            error={clientsError}
            title={dictionary?.clients_title_1}
            sx={{
                maxWidth: 920,
            }}
            content={content}
        >
            <MyToolbar
                filter={true}
                changeContentButton={false}
                paginationData={clientslListPaginationData}
                search={dictionary?.clients_search_placeholder}
                sx={{ flexWrap: 'wrap' }}
            />
            <TblContainer>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                    {clientsList.length === 0 && clientsSuccess && <EmptyListAlertText />}
                    {clientsList.length > 0 &&
                        clientsList.map(({ id, name, status, role, ownerId, date_finish }) => {
                            return (
                                <TableRow className="table-main__row" key={id}>
                                    <TableCell className="ellipsis">
                                        <MyTooltip
                                            position={'top-start'}
                                            title={id}
                                            arrow={false}
                                            interactive={false}
                                        >
                                            <Typography>{id}</Typography>
                                        </MyTooltip>
                                    </TableCell>
                                    <TableCell>
                                        {status === 'invite' && !name
                                            ? dictionary?.invitation_send
                                            : name}
                                    </TableCell>
                                    <TableCell>{setClientRole(role)}</TableCell>
                                    <TableCell className="status">
                                        <Typography className={status}>
                                            {setStatusLabel(status, dictionary)}
                                        </Typography>
                                        {date_finish && (
                                            <Box sx={{ marginTop: 1.25 }}>
                                                <Typography fontWeight={600}>
                                                    {timer(date_finish).length !== 0
                                                        ? dictionary?.code_is_active
                                                        : dictionary?.code_is_inactive}
                                                    &nbsp;
                                                </Typography>
                                                {timer(date_finish).length !== 0 &&
                                                    timer(date_finish).map(
                                                        ({ hours, minutes, seconds }) => {
                                                            return (
                                                                <Typography key={'timer'}>
                                                                    {hours}:{minutes}:{seconds}
                                                                </Typography>
                                                            );
                                                        }
                                                    )}
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell
                                        className="action"
                                        sx={{
                                            '& a': {
                                                display: 'flex',
                                            },
                                        }}
                                    >
                                        <MyTooltip position={'top'} title={dictionary?.edit}>
                                            <NavLink to={`client_${role}-${id}#edit`}>
                                                <EditIcon />
                                            </NavLink>
                                        </MyTooltip>
                                        <MyTooltip position={'top'} title={dictionary?.viewing}>
                                            <NavLink to={`client_${role}-${id}`}>
                                                <ViewingIcon />
                                            </NavLink>
                                        </MyTooltip>
                                        {role === 'adm' && (
                                            <MyClientsPopper
                                                tooltipTitle={
                                                    status === 'stopped'
                                                        ? dictionary?.restore
                                                        : dictionary?.suspend
                                                }
                                                status={status}
                                                clientId={id}
                                                buttonTitle={
                                                    status === 'stopped'
                                                        ? dictionary?.confirm
                                                        : dictionary?.suspend
                                                }
                                            />
                                        )}
                                        {setStatusActionIcon(role, status, id)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </TblContainer>
            <TblPagination data={clientslListPaginationData} invalidateTag={'Clients'} />
        </Section>
    );
};

export default Clients;
