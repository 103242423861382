import { useEffect, useContext } from 'react';

import { Box, Typography } from '@mui/material';
import GeneralInput from '../../../../components/Form/FormInputs/GeneralInput';

import LanguageTabs from '../../../../components/Tabs/LanguageTabs';

import { PageContext } from '../../../Page';

export const FourthStep = ({ savedSite, clickOnSubmitButton, setClickOnSubmitButton }) => {
    return (
        <LanguageTabs
            tabContent={'fourthStep'}
            receivedValue={savedSite}
            fieldNamePrefix={['work_greeting_', 'work_result_', 'non_work_greeting_']}
            tabsSx={{ position: 'absolute', top: -2, right: 0 }}
            savedLanguages={savedSite?.languages}
            clickOnSubmitButton={clickOnSubmitButton}
            setClickOnSubmitButton={setClickOnSubmitButton}
        />
    );
};

export const FourthStepContent = ({
    language,
    receivedValue,
    tabError,
    clickOnSubmitButton,
    setClickOnSubmitButton,
}) => {
    const { alertMessageContent, dictionary } = useContext(PageContext);

    useEffect(() => {
        if (tabError && clickOnSubmitButton) {
            alertMessageContent(dictionary?.localization_error, 'error');
            setClickOnSubmitButton(false);
        }
    }, [tabError, clickOnSubmitButton]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2.25,
                    marginBottom: 3.75,
                }}
            >
                <Typography variant="body3">{dictionary?.work_time}</Typography>
                <GeneralInput
                    fieldName={'work_greeting_' + language}
                    label={dictionary?.work_greeting}
                    receivedValue={receivedValue?.default_messages?.work?.greeting[language]}
                />
                <GeneralInput
                    fieldName={'work_result_' + language}
                    label={dictionary?.work_result}
                    receivedValue={receivedValue?.default_messages?.work?.result[language]}
                    multiline={true}
                    sx={{
                        '& textarea': {
                            height: '120px!important',
                        },
                    }}
                    maxNumOfCharacters={500}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
                <Typography variant="body3">{dictionary?.non_work_time}</Typography>
                <GeneralInput
                    fieldName={'non_work_greeting_' + language}
                    label={dictionary?.non_work_greeting}
                    multiline={true}
                    sx={{
                        '& textarea': {
                            height: '120px!important',
                        },
                    }}
                    receivedValue={receivedValue?.default_messages?.non_work?.greeting[language]}
                    maxNumOfCharacters={500}
                />
            </Box>
        </>
    );
};
