import { apiSlice } from '../apiSlice';

const apiRegistrationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        clientRegistration: builder.mutation({
            query: (user) => ({
                url: `client/registration${user.payload}`,
                method: 'POST',
                body: user.data,
            }),
        }),
        checkClientRegistrationCode: builder.mutation({
            query: (code) => ({
                url: `client/registration/email?code=${code}`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useClientRegistrationMutation, useCheckClientRegistrationCodeMutation } =
    apiRegistrationSlice;
