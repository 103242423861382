import { apiSlice } from './apiSlice';

const apiLanguageSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDictionary: builder.query({
            query: ({ path, language = '' }) => ({
                url: `${path}/dictionary${language}`,
            }),
        }),
        getInitialDictionary: builder.query({
            query: ({ language = '' }) => ({
                url: `api/dictionary${language}`,
            }),
        }),
        changeLanguage: builder.mutation({
            query: ({ language, path }) => ({
                url: `${path}/language`,
                method: 'POST',
                body: { language },
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useChangeLanguageMutation, useGetDictionaryQuery, useGetInitialDictionaryQuery } =
    apiLanguageSlice;
