import { useContext, useLayoutEffect } from 'react';

import PersonalInformationForm from './PersonalInformationForm';
import PasswordSettingsForm from './PasswordSettingsForm';
import SoundNotificationsForm from './SoundNotificationsForm';

import { PageContext } from '../Page';
import { PageContainerContext } from '../PageContainer';

const SettingsPage = () => {
    const { content, changeContentHandler, pathname } = useContext(PageContainerContext);
    const { clientCheck, dictionary } = useContext(PageContext);

    useLayoutEffect(() => {
        if (pathname === '/settings' && content !== 'settings') {
            changeContentHandler('settings');
        }
    }, [pathname, content]);

    return (
        <>
            {clientCheck && <PersonalInformationForm />}
            <PasswordSettingsForm
                title={!clientCheck ? dictionary?.settings : ''}
                sx={{ maxWidth: !clientCheck ? 540 : 730, marginBottom: !clientCheck ? 0 : 3.5 }}
            />
            {clientCheck && <SoundNotificationsForm />}
        </>
    );
};

export default SettingsPage;
