import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { TextField } from '@mui/material';

const TimeInput = ({ fieldName, label, receivedValue = '', disabled = false, placeholder }) => {
    const [time, setTime] = useState('');

    const startsWithTwo = time[0] === '2';

    const mask = [/[0-2]/, startsWithTwo ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];

    const {
        control,
        formState: { errors },
        clearErrors,
        setValue,
    } = useFormContext();

    useEffect(() => {
        if (disabled) {
            clearErrors(fieldName);
        }
    }, [disabled]);

    useEffect(() => {
        if (receivedValue) {
            setValue(fieldName, receivedValue);
            setTime(receivedValue);
        } else {
            setValue(fieldName, '');
            setTime('');
        }
    }, [receivedValue]);

    const handleInput = ({ target: { value } }) => {
        setTime(value);
        setValue(fieldName, value.replaceAll('_', ''));
    };

    const removeError = () => {
        if (errors[fieldName]) {
            clearErrors(fieldName);
        }
    };

    const addNumbers = ({ target: { value } }) => {
        const currentValue = value.replaceAll('_', '');
        const onlyHouresNumbers = 3;

        if (currentValue.length === onlyHouresNumbers) {
            setTime(currentValue + '00');
            setValue(fieldName, currentValue + '00');
        }
        //  else if (currentValue.length === 4) {
        //     setTime(currentValue + '0');
        //     setValue(fieldName, currentValue + '0');
        // }
    };

    return (
        <Controller
            name={fieldName}
            control={control}
            // defaultValue={receivedValue}
            render={({ field: { onchange, value } }) => (
                <InputMask
                    mask={mask}
                    onChange={handleInput}
                    value={time}
                    disabled={disabled}
                    onFocus={removeError}
                    onBlur={addNumbers}
                >
                    {/* {(inputprops) => ( */}
                    <TextField
                        label={label}
                        error={!!errors[fieldName]}
                        disabled={disabled}
                        placeholder={placeholder}
                        onFocus={removeError}
                        // {...inputprops}
                    />
                    {/* )} */}
                </InputMask>
            )}
        />
    );
};

export default TimeInput;
