import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';

const RangeInput = ({ fieldName, label, receivedValue = '' }) => {
    const [fieldValue, setFieldValue] = useState('');

    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const spliters = [',', ':', '/', '-'];

    const { control, setValue, watch } = useFormContext();

    useEffect(() => {
        const subscription = watch((fieldName) => {
            if (JSON.stringify(fieldName) === '{}') {
                setFieldValue('');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (receivedValue) {
            setValue(fieldName, receivedValue);
        } else {
            setValue(fieldName, '');
        }
    }, [receivedValue]);

    return (
        <>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={label}
                        value={fieldValue}
                        onChange={(e) => {
                            const arrOfCharacters = e.target.value.split('');
                            const arrOfNumbers = e.target.value
                                .split(/[\:\-\/\,\s]/)
                                .filter((value) => value);

                            let counter = 0;

                            for (let i = 0; arrOfCharacters.length > i; i++) {
                                if (spliters.includes(arrOfCharacters[i])) {
                                    counter++;
                                }
                            }

                            if (
                                (e.target.value === '' ||
                                    (numbers.includes(e.target.value[0]) && counter <= 1)) &&
                                arrOfNumbers.length <= 2
                            ) {
                                setFieldValue(e.target.value);
                                setValue(fieldName, e.target.value);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (
                                ![
                                    ...numbers,
                                    ...spliters,
                                    'Delete',
                                    'Backspace',
                                    'ArrowLeft',
                                    'ArrowRight',
                                    ' ',
                                ].includes(e.key)
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

export default RangeInput;
