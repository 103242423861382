import { useEffect, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box } from '@mui/material';

import { TextEditor, CustomToolbar } from './TextEditor';

import { PageContext } from '../../pages/Page';

const TextEditorWithFormContext = ({ fieldName, receivedValue, placeholder }) => {
    const { dictionary } = useContext(PageContext);
    const { control, getValues, setValue } = useFormContext();

    useEffect(() => {
        if (receivedValue) {
            setValue(fieldName, receivedValue);
        }
    }, [receivedValue]);

    return (
        <Box>
            <CustomToolbar id={fieldName} />
            <Controller
                name={fieldName}
                control={control}
                render={({ field: { ref, onChange, field } }) => (
                    <TextEditor
                        {...field}
                        value={getValues(fieldName) || ''}
                        inputRef={ref}
                        fieldName={fieldName}
                        onChange={(text) => {
                            if (text === '<p><br></p>') {
                                onChange(text.replace('<p><br></p>', ''));
                            } else {
                                onChange(text);
                            }
                        }}
                        placeholder={placeholder ? placeholder : dictionary?.content}
                    />
                )}
            />
        </Box>
    );
};

export default TextEditorWithFormContext;
