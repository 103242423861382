import { useContext } from 'react';

import classNames from 'classnames';

import { PhoneInput } from 'react-international-phone';

import { PageContext } from '../../../../pages/Page';

import 'react-international-phone/style.css';
import './phoneSelect.scss';

const PhoneSelect = ({ fieldName, receivedValue, removeError, show, setShow, ...rest }) => {
    const { dictionary } = useContext(PageContext);

    const activeClass = classNames({ show });

    return (
        <PhoneInput
            className={activeClass}
            placeholder={dictionary?.phone}
            value={receivedValue}
            defaultCountry="ua"
            countrySelectorStyleProps={{
                dropdownStyleProps: {
                    show,
                    onClose: () => setShow(false),
                },
            }}
            onFocus={removeError}
            {...rest}
        />
    );
};

export default PhoneSelect;
