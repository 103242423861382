import { apiSlice } from '../apiSlice';

const apiChannelSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getChannels: builder.query({
            query: (query) => ({
                url: `client/channel${query.limit}${query.page}${query.sortBy}${query.sortOrder}`,
            }),
            providesTags: ['Channels'],
        }),
        getChannel: builder.query({
            query: (id) => ({
                url: `client/channel/${id}`,
            }),
            providesTags: ['Channels'],
        }),
        changeChannelStatus: builder.mutation({
            query: (channel) => ({
                url: `client/channel/${channel.id}/${channel.action}`,
                method: 'POST',
            }),
            invalidatesTags: ['Channels'],
        }),
        deleteChannel: builder.mutation({
            query: (id) => ({
                url: `client/channel/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Channels'],
        }),
        createChannel: builder.mutation({
            query: (channel) => ({
                url: `client/channel`,
                method: 'POST',
                body: channel,
            }),
            invalidatesTags: ['Channels'],
        }),
        editChannel: builder.mutation({
            query: (channel) => ({
                url: `client/channel/${channel.id}`,
                method: 'PUT',
                body: channel.body,
            }),
            invalidatesTags: ['Channels'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetChannelsQuery,
    useGetChannelQuery,
    useChangeChannelStatusMutation,
    useDeleteChannelMutation,
    useCreateChannelMutation,
    useEditChannelMutation,
} = apiChannelSlice;
