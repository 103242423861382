import { apiSlice } from '../apiSlice';

const apiSoundsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSounds: builder.query({
            query: () => ({
                url: 'client/sound',
            }),
        }),
        changeSoundStatus: builder.mutation({
            query: (body) => ({
                url: 'client/settings/sound',
                method: 'POST',
                body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetSoundsQuery, useChangeSoundStatusMutation } = apiSoundsSlice;
