import { useContext, useLayoutEffect } from 'react';
import {
    useGetFunctionalQuery,
    useGetSelectedFunctionalQuery,
} from '../../../api/adminPartApi/apiFunctionalSlice';

import Section from '../../../layouts/Section/Section';
import FunctionalList from './FunctionalList';
import FunctionalForm from './FunctionalForm';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Functional = () => {
    const { dictionary } = useContext(PageContext);
    const { createSlug, content, changeContentHandler, skip, selectedElementId, hash } =
        useContext(PageContainerContext);

    useLayoutEffect(() => {
        if (hash.includes('#functional') && content !== 'editFunctional') {
            const id = hash.slice(hash.indexOf('_') + 1);
            changeContentHandler('editFunctional', id, true);
        } else if (!hash && content !== 'functionalList') {
            changeContentHandler('functionalList');
        }
    }, [hash]);

    const {
        data: functional,
        isFetching: functionalFetching,
        isLoading: functionalLoading,
        isError: functionalFetchingError,
        error: functionalError,
    } = useGetFunctionalQuery(
        {
            limit: createSlug('limit', '', '?limit=15'),
            page: createSlug('page'),
            sortBy: createSlug('sort_by'),
            sortOrder: createSlug('sort_order'),
        },
        { skip }
    );

    const {
        data: singleFunctional,
        isFetching: selectedFunctionalFetching,
        isLoading: selectedFunctionalLoading,
        isError: selectedFunctionalFetchingError,
    } = useGetSelectedFunctionalQuery(content === 'editFunctional' ? selectedElementId : '', {
        skip: content !== 'editFunctional',
    });

    const functionalList = functional?.data?.functional?.data || [];
    const selectedFunctional = singleFunctional?.data || '';
    const functionalListPaginationData = functional?.data?.functional || {};

    return (
        <Section
            isLoading={functionalLoading || selectedFunctionalLoading}
            isFetching={functionalFetching || selectedFunctionalFetching}
            isError={functionalFetchingError || selectedFunctionalFetchingError}
            error={functionalError}
            title={
                content === 'functionalList'
                    ? dictionary?.functional_title_1
                    : dictionary?.functional_title_2
            }
            content={content}
            sx={{ maxWidth: content === 'functionalList' ? 920 : 730 }}
        >
            {(!content || content === 'functionalList') && (
                <FunctionalList
                    functionalList={functionalList}
                    changeContentHandler={changeContentHandler}
                    paginationData={functionalListPaginationData}
                />
            )}
            {content === 'editFunctional' && <FunctionalForm functional={selectedFunctional} />}
        </Section>
    );
};

export default Functional;
