import { useContext } from 'react';

import { Box, Typography } from '@mui/material';
import { FlexBox } from '../../../mui/theme';

import WebsiteIcon from '../../../assets/icons/website.png';
import PromotionWindow from '../../../assets/icons/promotion-window.png';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as ViberIcon } from '../../../assets/icons/viber.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';

import { saveToLocalStorage } from '../../../helpers/functions';

import { PageContext } from '../../Page';

const setChannelsData = (dictionary) => {
    return [
        {
            icon: <img src={WebsiteIcon} />,
            text: dictionary?.site,
            inProgress: false,
            type: 'site',
        },
        {
            icon: <TelegramIcon />,
            text: 'Telegram',
            inProgress: false,
        },
        {
            icon: <img src={PromotionWindow} />,
            text: dictionary?.promotion_window,
            inProgress: true,
        },
        {
            icon: <InstagramIcon />,
            text: 'Instagram',
            inProgress: true,
        },
        {
            icon: <ViberIcon />,
            text: 'Viber',
            inProgress: true,
        },
        {
            icon: <FacebookIcon />,
            text: 'Facebook',
            inProgress: true,
        },
    ];
};

const AddChannel = ({
    sitesList,
    maxNumOfSites,
    currentNumOfSites,
    changeContentHandler,
    navigate,
    alertMessageContent,
}) => {
    const { dictionary } = useContext(PageContext);

    const channelContainer = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 4,
        justifyContent: 'space-between',
        marginBottom: 2.25,
    };

    const channelBody = {
        position: 'relative',
        padding: 2,
        width: '100%',
        backgroundColor: 'basicColor.color100',
        borderRadius: 1.25,
        alignItems: 'center',
        transition: 'box-shadow 0.250s',
        '& svg, & img': {
            width: 40,
            height: 40,
        },
    };

    const channelPlug = {
        position: 'absolute',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#f5f6facc',
        opacity: 0,
        transition: 'opacity 0.250s',
        textAlign: 'center',
        padding: 1.25,
    };

    const thereIsAnActiveSite =
        sitesList.length !== 0 && sitesList.some(({ status }) => status === 'active');

    return (
        <>
            <Box sx={{ ...channelContainer }}>
                {setChannelsData(dictionary).map(({ icon, text, inProgress, type }) => {
                    return (
                        <FlexBox
                            className="col-10"
                            key={text}
                            sx={{
                                cursor:
                                    inProgress ||
                                    (!inProgress && !thereIsAnActiveSite && type !== 'site')
                                        ? 'not-allowed'
                                        : 'pointer',
                                '&:hover': {
                                    boxShadow:
                                        inProgress ||
                                        (!inProgress && !thereIsAnActiveSite && type !== 'site')
                                            ? '0px 0px 15px 0px #A8A8A880'
                                            : '0px 0px 15px 0px #2085F880',
                                    '& div': {
                                        opacity:
                                            inProgress ||
                                            (!inProgress && !thereIsAnActiveSite && type !== 'site')
                                                ? 1
                                                : 0,
                                        visibility:
                                            inProgress ||
                                            (!inProgress && !thereIsAnActiveSite && type !== 'site')
                                                ? 'visible'
                                                : 'hidden',
                                    },
                                },
                                ...channelBody,
                            }}
                            onClick={() => {
                                if (!inProgress) {
                                    if (type === 'site') {
                                        if (maxNumOfSites <= currentNumOfSites) {
                                            alertMessageContent(
                                                dictionary?.site_limit,
                                                'information'
                                            );
                                        } else {
                                            changeContentHandler('createSite');
                                            navigate('/channels/site-form#step-1');
                                            saveToLocalStorage('site', '');
                                        }
                                    } else if (thereIsAnActiveSite) {
                                        changeContentHandler('createChannel');
                                        navigate('/channels/channel-form#create');
                                    }
                                }
                            }}
                        >
                            {icon}
                            <Typography fontSize={16} fontWeight={600}>
                                {text}
                            </Typography>
                            {(inProgress ||
                                (!inProgress && !thereIsAnActiveSite && type !== 'site')) && (
                                <FlexBox sx={{ ...channelPlug }}>
                                    <Typography fontSize={16} fontWeight={600}>
                                        {thereIsAnActiveSite || inProgress
                                            ? dictionary?.in_developing
                                            : dictionary?.site_is_required}
                                    </Typography>
                                </FlexBox>
                            )}
                        </FlexBox>
                    );
                })}
            </Box>
            {!thereIsAnActiveSite && (
                <FlexBox className="row-10" alignItems="center">
                    <WarningIcon />
                    <Typography variant="body2" color="colorAccent.second">
                        {dictionary?.site_is_required}
                    </Typography>
                </FlexBox>
            )}
        </>
    );
};

export default AddChannel;
