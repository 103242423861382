import { apiSlice } from '../apiSlice';

const apiEmployeesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployees: builder.query({
            query: (query) => ({
                url: `/client/employees${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.search}${query.role_id}${query.sites}`,
            }),
            providesTags: ['Employees'],
        }),
        getAllEmployees: builder.query({
            query: (query) => ({
                url: `client/employees/all?${query?.default_sort || ''}${query?.sortBy || ''}${query?.sortOrder || ''}${query?.search || ''}`,
            }),
        }),
        employeesPassList: builder.query({
            query: (id) => ({
                url: `client/employees/pass/list/${id}`,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetEmployeesQuery,
    useGetAllEmployeesQuery,
    useEmployeesPassListQuery,
} = apiEmployeesSlice;
