import Visitors from '../pages/clientPartPages/Visitors/Visitors';
import VisitorsOnline from '../pages/clientPartPages/Visitors/VisitorsOnline';
import AllDialogues from '../pages/clientPartPages/Dialogues/AllDialogues';
import Dialogues from '../pages/clientPartPages/Dialogues/Dialogues';
import Commands from '../pages/clientPartPages/Templates/Commands';
import Answers from '../pages/clientPartPages/Templates/Answers';
import Appeals from '../pages/adminPartPages/Support/Appeals';
import Employees from '../pages/clientPartPages/Employees/Employees';
import Channels from '../pages/clientPartPages/Channels/Channels';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import Site from '../pages/clientPartPages/Channels/Site/Site';
import Channel from '../pages/clientPartPages/Channels/Channel/Channel';
import Tariff from '../pages/clientPartPages/Tariff';
import PaymentHistory from '../pages/clientPartPages/Payment/PaymentHistory';

class Operator {
    constructor(id) {
        this.id = id;
        this.expandedVisitors = {
            visitorsOnline: true,
            path: 'visitors/online',
            // linkName: 'Посетители',
            Component: <VisitorsOnline />,
            accordion: 'visitors',
            hiddenRoutes: [
                {
                    path: 'visitors/online',
                    // linkName: 'Посетители онлайн',
                    initialContent: 'visitorsOnline',
                    Component: <VisitorsOnline />,
                },
                {
                    path: 'visitors/all',
                    // linkName: 'Список посетителей',
                    initialContent: 'visitorsList',
                    Component: <Visitors />,
                },
            ],
        };
        this.visitors = {
            visitorsOnline: false,
            path: 'visitors',
            // linkName: 'Посетители',
            initialContent: 'visitorsList',
            Component: <Visitors />,
        };
        this.dialogues = {
            path: 'dialogues/all',
            // linkName: 'Диалоги',
            Component: <AllDialogues />,
            counter: true,
            accordion: 'dialogues',
            hiddenRoutes: [
                {
                    path: 'dialogues/all',
                    // linkName: 'Все',
                    initialContent: 'dialoguesList',
                    Component: <AllDialogues />,
                },
                {
                    path: 'dialogues/active',
                    // linkName: 'Активные',
                    initialContent: 'activeDialogues',
                    Component: <Dialogues />,
                    counter: true,
                    counterType: 'counterFirst',
                },
                {
                    path: 'dialogues/waiting',
                    // linkName: 'Ожидающие',
                    initialContent: 'waitingDialogues',
                    Component: <Dialogues />,
                    counter: true,
                    counterType: 'counterSecond',
                },
            ],
        };
        this.templates = {
            path: 'templates/commands',
            // linkName: 'Шаблоны',
            Component: <Commands />,
            accordion: 'templates',
            hiddenRoutes: [
                {
                    path: 'templates/commands',
                    // linkName: 'Команды',
                    initialContent: 'commandsList',
                    Component: <Commands />,
                },
                {
                    path: 'templates/answers',
                    // linkName: 'Ответы',
                    initialContent: 'answersList',
                    Component: <Answers />,
                },
            ],
        };
        this.support = {
            path: 'support',
            linkName: 'Поддержка',
            initialContent: 'appealsList',
            Component: <Appeals />,
        };
        this.settings = {
            path: 'settings',
            Component: <SettingsPage />,
            notNavLink: true,
        };
        this.routeList = [
            this.expandedVisitors,
            this.visitors,
            this.dialogues,
            this.templates,
            this.support,
            this.settings,
        ];
    }
}

class Supervisor extends Operator {
    constructor(id) {
        super(id);
        this.employees = {
            path: 'employees',
            // linkName: 'Сотрудники',
            initialContent: 'employeesList',
            Component: <Employees />,
        };
        this.routeList = [this.employees, ...this.routeList];
    }
}

class Admin extends Supervisor {
    constructor(id) {
        super(id);
        this.channels = {
            path: 'channels',
            // linkName: 'Каналы',
            initialContent: 'channelsList',
            Component: <Channels />,
        };
        this.site = {
            path: 'channels/site-form',
            Component: <Site />,
            notNavLink: true,
        };
        this.channel = {
            path: 'channels/channel-form',
            Component: <Channel />,
            notNavLink: true,
        };
        this.tariff = {
            path: 'tariff',
            Component: <Tariff />,
            notNavLink: true,
        };
        this.payment = {
            path: 'payment',
            Component: <PaymentHistory />,
            notNavLink: true,
        };
        this.routeList = [
            this.channels,
            this.site,
            this.channel,
            this.tariff,
            this.payment,
            ...this.routeList,
        ];
    }
}

export const operator = new Operator('op');
export const supervisor = new Supervisor('sv');
export const admin = new Admin('adm');
