import { useContext, useEffect } from 'react';

import SelectWithFormContext from '../../../../components/Form/FormSelect/Select/SelectWithFormContext';
import ScheduleForm from './ScheduleForm';

import { PageContext } from '../../../Page';
import { FormContainerContext } from '../../../../components/Form/FormContainer';

import { clientPartTimezoneSelect as zoneOptions } from '../../../../data/formSelectData';

const ThirdStep = ({ savedSite, setBlockSubmitButton }) => {
    const { dictionary, alertMessageContent } = useContext(PageContext);
    const { usedFormValidationErrors } = useContext(FormContainerContext);

    useEffect(() => {
        if (JSON.stringify(usedFormValidationErrors) !== '{}') {
            alertMessageContent(dictionary?.next_step_failed, 'error');
        }
    }, [usedFormValidationErrors]);

    return (
        <>
            <SelectWithFormContext
                fieldName={'timezone'}
                label={dictionary?.timezone}
                options={zoneOptions}
                receivedValue={savedSite?.timezone}
            />
            <ScheduleForm
                setBlockSubmitButton={setBlockSubmitButton}
                // receivedValue={!savedSite?.schedule ? false : true}
                schedule={savedSite?.schedule}
            />
        </>
    );
};

export default ThirdStep;
