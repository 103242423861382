import { useContext } from 'react';

import StepCode from './StepCode';

import { widgetTemplate } from '../../../../helpers/functions';

import { PageContext } from '../../../Page';

const SeventhStep = ({
  copy,
  copiedText,
  isSuccess,
  verificationCode,
  integrationCode,
  confirmButtonHandler,
}) => {
  const { dictionary } = useContext(PageContext);

  return (
    <StepCode
      copy={copy}
      copiedText={copiedText}
      isSuccess={isSuccess}
      informationText={dictionary?.connection_information}
      fieldName={'widjet_integration'}
      successText={dictionary?.widjet_success}
      informationTextWrapperSx={{
        alignItems: 'start',
        maxWidth: 600,
      }}
      verificationCode={verificationCode}
      textareaValue={widgetTemplate(integrationCode)}
      textareaLabel={dictionary?.interation_code}
      textareaHeight={{ height: '740px!important' }}
      buttonTitle={dictionary?.check}
      confirmButtonHandler={confirmButtonHandler}
    />
  );
};

export default SeventhStep;
