import { apiSlice } from '../apiSlice';

const apiLogoutSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        logout: builder.mutation({
            query: () => ({
                url: '/client/logout',
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useLogoutMutation } = apiLogoutSlice;
