import { Box } from '@mui/material';

const Counter = ({ children, sx = {}, indicator }) => {
    const counterStyles = {
        position: 'relative',

        padding: 0.5,
        minWidth: 22,
        height: 22,

        fontWeight: 600,
        fontSize: 12,
        lineHeight: 1.166,
        textAlign: 'center',

        backgroundColor: 'secondaryColor.main',
        color: 'basicColor.colorWhite',
        borderRadius: 0.75,

        '&::before': {
            position: 'absolute',
            top: -7,
            right: -7,
            content: "''",

            display: indicator ? 'block' : 'none',
            width: 13,
            height: 13,

            borderRadius: '50%',
            backgroundColor: '#F7495B',
            border: '1px solid #ffffff',
        },
    };

    return (
        <Box className="counter" sx={{ ...counterStyles, ...sx }}>
            {children}
        </Box>
    );
};

export default Counter;
