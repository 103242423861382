import { apiSlice } from './apiSlice';

const apiAuthorizationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        passAuthorization: builder.mutation({
            query: (user) => ({
                url: `${user.path}/login`,
                method: 'POST',
                body: user.data,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { usePassAuthorizationMutation } = apiAuthorizationSlice;
