import { useContext } from 'react';

import { Box } from '@mui/material';

import MyToolbar from '../../../components/Toolbar/MyToolbar';
import ChannelsListTemplate from './ChannelsListTemplate';

import {
    setSitesTableHeadings as setSitesHeadCells,
    // channelsTableHeadings as channelsHeadCells,
} from '../../../data/tableHeadingsData';

import { PageContext } from '../../Page';

const ChannelsList = ({
    sitesList,
    sitesSuccess,
    sitesPaginationData,
    channelsList,
    channelsSuccess,
    channelsPaginationData,
    changeContentHandler,
    copy,
    copiedText,
    navigate,
    alertMessageContent,
}) => {
    const { dictionary } = useContext(PageContext);

    const channelsHeadCells = () => {
        return setSitesHeadCells(dictionary).map((cell) => {
            if (cell.id === 'domain') {
                return { ...cell, title: dictionary?.channel };
            } else {
                return cell;
            }
        });
    };

    const copyTextHandler = (text) => {
        if (copiedText === undefined) {
            copy(text);
            alertMessageContent(dictionary?.data_copied, 'success');
        }
    };

    return (
        <>
            <MyToolbar
                myButtonTitle={dictionary?.add_channel}
                useButtonHandler={true}
                paginationData={sitesPaginationData}
                buttonHandler={() => {
                    changeContentHandler('addChannel');
                    navigate('/channels#add');
                }}
            />
            <Box sx={{ marginBottom: 3.75 }}>
                <ChannelsListTemplate
                    list={sitesList}
                    listContent={'sites'}
                    setHeadCells={setSitesHeadCells}
                    isSuccess={sitesSuccess}
                    paginationData={sitesPaginationData}
                    copyTextHandler={copyTextHandler}
                    content={'editSite'}
                    path={'/channels/site-form#step-1'}
                />
            </Box>
            <MyToolbar
                paginationData={channelsPaginationData}
                changeContentButton={false}
                parametr={'second_limit'}
                removedParametr={'second_page'}
            />
            <Box>
                <ChannelsListTemplate
                    list={channelsList}
                    listContent={'channels'}
                    setHeadCells={channelsHeadCells}
                    isSuccess={channelsSuccess}
                    paginationData={channelsPaginationData}
                    content={'editChannel'}
                    path={'/channels/channel-form#edit'}
                />
            </Box>
        </>
    );
};

export default ChannelsList;
