import {
  useCallback,
  useState,
  useContext,
  useLayoutEffect,
  useMemo,
  useEffect,
} from 'react';
import { useGetAllEmployeesQuery } from '../../../../api/clientPartApi/apiEmployeesSlice';
import {
  useCreateChannelMutation,
  useEditChannelMutation,
} from '../../../../api/clientPartApi/apiChannelSlice';

import classNames from 'classnames';

import { Box } from '@mui/system';

import FormContainer from '../../../../components/Form/FormContainer';
import Title from '../../../../components/ui/Title/Title';
import MyButton from '../../../../components/ui/Button/MyButton';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import Congratulations from '../Congratulations';

import { createChannelSchema as schema } from '../../../../settings/formSchema';

import { PageContext } from '../../../Page';
import { PageContainerContext } from '../../../PageContainer';

const FIRST_STEP = 1;
const SECOND_STEP = 2;
const THIRD_STEP = 3;

const ChannelForm = ({ content, channel, channelId, setChannelId }) => {
  const { alertMessageContent, navigate, dictionary } = useContext(PageContext);
  const { hash } = useContext(PageContainerContext);

  const [step, setStep] = useState(FIRST_STEP);
  const [token, setToken] = useState('');
  const [channelName, setChannelName] = useState('');
  const [sites, setSites] = useState([]);
  const [operators, setOperators] = useState([]);
  const [search, setSearch] = useState('');
  const [sortByWithoutQuery, setSortByWithoutQuery] = useState('');
  const [sortOrderWithoutQuery, setSortOrderWithoutQuery] = useState('');
  const [newEmployeesList, setNewEmployeesList] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);

  useLayoutEffect(() => {
    if (channel) {
      setToken(channel.token);
      setChannelName(channel.name);
      setChannelId(channel.id);
      setSites(channel.sites);
      setOperators(channel.clients);
    }
  }, [channel]);

  useLayoutEffect(() => {
    if (
      hash.includes('#step-1') &&
      content === 'editChannel' &&
      step !== FIRST_STEP
    ) {
      setStep(FIRST_STEP);
    } else if (
      hash.includes('#step-2') &&
      content === 'editChannel' &&
      step === FIRST_STEP
    ) {
      navigate(`/channels/channel-form#edit_${channelId}#step-1`);
    }
  }, [hash, step]);

  const [
    createChannel,
    { isLoading: createdChannelLoading, isFetching: createdChannelFetching },
  ] = useCreateChannelMutation();

  const [
    editChannel,
    { isLoading: editedChannelLoading, isFetching: editedChannelFetching },
  ] = useEditChannelMutation();

  const {
    data: employees,
    isLoading: employeesLoading,
    isFetching: employeesFetching,
    isSuccess: employeesSuccess,
  } = useGetAllEmployeesQuery({
    sortBy: sortByWithoutQuery ? `&sort_by=${sortByWithoutQuery}` : '',
    sortOrder: sortOrderWithoutQuery
      ? `&sort_order=${sortOrderWithoutQuery}`
      : '',
    search: search ? `&q=${search}` : '',
  });

  const employeesList = useMemo(() => {
    return employees?.data.filter(
      ({ status }) => status === 'enabled' || status === 'disabled'
    );
  }, [employees, search]);

  useEffect(() => {
    if (sortByWithoutQuery === 'addOperator') {
      const addedOperators = employeesList.filter(({ id }) =>
        operators.includes(id)
      );
      const notAddedOperators = employeesList.filter(
        ({ id }) => !operators.includes(id)
      );

      if (sortOrderWithoutQuery === 'asc') {
        setNewEmployeesList([...addedOperators, ...notAddedOperators]);
      } else {
        setNewEmployeesList([...notAddedOperators, ...addedOperators]);
      }
    } else if (sortByWithoutQuery !== 'addOperator') {
      setNewEmployeesList([]);
    }
  }, [
    sortByWithoutQuery,
    sortOrderWithoutQuery,
    operators,
    search,
    employeesList,
  ]);

  const formSubmitHandler = useCallback(
    (data) => {
      const incrementStep = (step) => {
        setStep(step);
      };

      const passData = (data) => {
        if (content === 'createChannel' && step === FIRST_STEP && !channelId) {
          createChannel({ ...data, step })
            .unwrap()
            .then((res) => {
              setToken(data.token);
              setChannelName(data.name);
              setChannelId(res.id);

              incrementStep(SECOND_STEP);
            })
            .catch((error) => {
              console.log(error);
              alertMessageContent(dictionary?.next_step_failed, 'error');
            });
        } else {
          editChannel({
            body: { ...data, step },
            id: channelId,
          })
            .unwrap()
            .then((res) => {
              if (step === FIRST_STEP) {
                if (content === 'editChannel') {
                  navigate(`/channels/channel-form#edit_${channelId}#step-2`);
                }

                incrementStep(SECOND_STEP);
                setToken(data.token);
                setChannelName(data.name);
              } else if (step === SECOND_STEP && content === 'editChannel') {
                navigate('/channels');
              } else if (step === SECOND_STEP && content === 'createChannel') {
                incrementStep(THIRD_STEP);
              }
            })
            .catch((error) => {
              console.log(error);
              alertMessageContent(dictionary?.next_step_failed, 'error');
            });
        }
      };

      switch (step) {
        case FIRST_STEP:
          const sitesId = data.sites.map((item) => {
            return item.id;
          });

          passData({ ...data, sites: sitesId });
          break;
        case SECOND_STEP:
          passData({ clients: operators });
          break;
      }
    },
    [step, channelId, operators]
  );

  const decrementStepHandler = () => {
    if (step === SECOND_STEP) {
      setStep(FIRST_STEP);
      setSearch('');
      setNewEmployeesList([]);
    } else {
      navigate('/channels');
    }
  };

  const buttonClass = classNames({
    'btn--disabled': step === SECOND_STEP && operators.length === 0,
    'btn--none': step === THIRD_STEP,
  });

  return (
    <>
      <FormContainer
        schema={schema}
        submitButtonTitle={
          step === FIRST_STEP ? dictionary?.next_step : dictionary?.save
        }
        submitButtonClass={`btn--primary btn--steps ${buttonClass}`}
        submitButtonHandler={() => setIsSubmited(true)}
        useButtonHandler={true}
        formSubmitHandler={formSubmitHandler}
        isLoading={
          createdChannelLoading || editedChannelLoading || employeesLoading
        }
        isFetching={
          createdChannelFetching || editedChannelFetching || employeesFetching
        }
        useValidationErrors={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: step !== THIRD_STEP ? 3.75 : 2.5,
            alignItems: step !== THIRD_STEP ? 'normal' : 'center',
            justifyContent: step !== THIRD_STEP ? 'normal' : 'center',
            minHeight: step !== THIRD_STEP ? 334 : 478,
          }}
        >
          {step !== THIRD_STEP && (
            <>
              <Title
                heading="h3"
                title={
                  step === FIRST_STEP
                    ? dictionary?.telegram_step_1
                    : dictionary?.telegram_step_2
                }
              />
              {step === FIRST_STEP && (
                <FirstStep
                  token={token}
                  channelName={channelName}
                  receivedSites={sites}
                  isSubmited={isSubmited}
                />
              )}
              {step === SECOND_STEP && (
                <SecondStep
                  operators={operators}
                  setOperators={setOperators}
                  employeesList={employeesList}
                  newEmployeesList={newEmployeesList}
                  employeesSuccess={employeesSuccess}
                  setSearch={setSearch}
                  setSortByWithoutQuery={setSortByWithoutQuery}
                  setSortOrderWithoutQuery={setSortOrderWithoutQuery}
                />
              )}
              <MyButton
                title={
                  step === FIRST_STEP
                    ? dictionary?.cancel_form
                    : dictionary?.back
                }
                buttonClasses={'btn--primary btn--cancel'}
                buttonHandler={() => decrementStepHandler()}
                sx={{ position: 'absolute', bottom: 0, left: 0 }}
              />
            </>
          )}
          {step === THIRD_STEP && content === 'createChannel' && (
            <Congratulations leaveForm={() => navigate('/channels')} />
          )}
        </Box>
      </FormContainer>
    </>
  );
};

export default ChannelForm;
