import ReactDOMServer from 'react-dom/server';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { ReactComponent as ItalicIcon } from '../../assets/icons/italic.svg';
import { ReactComponent as BoldIcon } from '../../assets/icons/bold.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/icons/underline.svg';
import { ReactComponent as BulletListIcon } from '../../assets/icons/bullet-list.svg';
import { ReactComponent as OrderedListIcon } from '../../assets/icons/ordered-list.svg';
import { ReactComponent as AlignLeftIcon } from '../../assets/icons/align-left.svg';
import { ReactComponent as AlignCenterIcon } from '../../assets/icons/align-center.svg';
import { ReactComponent as AlignRightIcon } from '../../assets/icons/align-right.svg';

import './text-editor.scss';

var icons = Quill.import('ui/icons');
var size = Quill.import('formats/size');

size.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px'];

Quill.register(size, true);

const boldIcon = ReactDOMServer.renderToStaticMarkup(<BoldIcon />);
const italicIcon = ReactDOMServer.renderToStaticMarkup(<ItalicIcon />);
const underlineIcon = ReactDOMServer.renderToStaticMarkup(<UnderlineIcon />);
const bulletListIcon = ReactDOMServer.renderToStaticMarkup(<BulletListIcon />);
const orderedListIcon = ReactDOMServer.renderToStaticMarkup(
  <OrderedListIcon />
);
const alignLeftIcon = ReactDOMServer.renderToStaticMarkup(<AlignLeftIcon />);
const alignCenterIcon = ReactDOMServer.renderToStaticMarkup(
  <AlignCenterIcon />
);
const alignRightIcon = ReactDOMServer.renderToStaticMarkup(<AlignRightIcon />);

icons['bold'] = boldIcon;
icons['italic'] = italicIcon;
icons['underline'] = underlineIcon;
icons.list['bullet'] = bulletListIcon;
icons.list['ordered'] = orderedListIcon;
icons.align[''] = alignLeftIcon;
icons.align['center'] = alignCenterIcon;
icons.align['right'] = alignRightIcon;

export const CustomToolbar = ({ id = 'toolbar', sx = {} }) => {
  return (
    <Box sx={sx} id={id}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-list" value="bullet" />
      <button className="ql-list" value="ordered" />
      <button className="ql-align" value="" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <select className="ql-size" defaultValue="14px">
        {size.whitelist.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </Box>
  );
};

const modules = (toolbarId) => ({
  toolbar: {
    container: '#' + toolbarId,
  },
});

const formats = ['bold', 'italic', 'underline', 'list', 'align', 'size'];

export const TextEditor = ({
  placeholder,
  toolbarSx,
  editorSx,
  onChange = () => {},
  fieldName = 'toolbar',
  withToolbar = false,
  isFullHeight = false,
  ...rest
}) => {
  const quillRef = useRef();

  useEffect(() => {
    if (quillRef.current != null && withToolbar) {
      let quill = quillRef.current.getEditor();
      let keyboard = quill.getModule('keyboard');
      keyboard.addBinding({ key: 13, ctrlKey: true }, (range) => {
        quill.insertText(range.index, '\n');
      });
      keyboard.bindings[13].unshift(keyboard.bindings[13].pop());
      keyboard.addBinding({ key: 13, shiftKey: true }, (range) => {
        quill.insertText(range.index, '\n');
      });
      keyboard.bindings[13].unshift(keyboard.bindings[13].pop());
      keyboard.bindings[13].unshift(keyboard.bindings[13].pop());
    }
  }, [withToolbar]);

  return (
    <Box
      key={placeholder}
      sx={{ width: '100%', height: isFullHeight ? isFullHeight : 'initial' }}
    >
      {withToolbar && (
        <CustomToolbar id={fieldName || 'toolbar'} sx={toolbarSx} />
      )}

      <Box
        component={ReactQuill}
        ref={quillRef}
        modules={modules(fieldName)}
        formats={formats}
        onChange={onChange}
        theme="snow"
        placeholder={placeholder}
        sx={editorSx}
        {...rest}
      />
    </Box>
  );
};
