import { useEffect, useContext, useState } from 'react';

import classNames from 'classnames';

import { PhoneNumberUtil } from 'google-libphonenumber';

import { Box } from '@mui/material';

import { PageContext } from '../../../../pages/Page';
import PhoneSelect from './PhoneSelect';

import 'react-international-phone/style.css';
import './phoneSelect.scss';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const MultiplePhoneSelect = ({
    fieldName,
    dropdownWidth,
    setPhones,
    phones,
    index,
    phonesValidationErrors,
    setPhonesValidationErrors,
    showErrors,
    receivedValue,
    isSubmited,
}) => {
    const { serverValidationError, setServerValidationError } = useContext(PageContext);

    const [phone, setPhone] = useState('');
    const [show, setShow] = useState(false);

    let isValid;
    const activeClass = classNames({ show });

    useEffect(() => {
        if (receivedValue) {
            validationCheck(receivedValue);
        }
    }, [receivedValue]);

    useEffect(() => {
        if (phone) {
            validationCheck(phone);
        }
    }, [phone]);

    const validationCheck = (phone) => {
        isValid = isPhoneValid(phone);

        if (isValid) {
            setPhonesValidationErrors({
                ...phonesValidationErrors,
                [fieldName]: false,
            });
        } else {
            setPhonesValidationErrors({
                ...phonesValidationErrors,
                [fieldName]: true,
            });
        }
    };

    const removeError = () => {
        if (serverValidationError?.length) {
            setServerValidationError([]);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: dropdownWidth,
                width: '100%',
                '& .react-international-phone-country-selector-dropdown': {
                    width: dropdownWidth,
                },
                '& .react-international-phone-input': {
                    borderColor:
                        phonesValidationErrors[fieldName] && showErrors && isSubmited
                            ? 'colorAccent.second'
                            : 'basicColor.color700',
                },
            }}
            onClick={(e) => {
                const elementValue = e.target?.value;
                if (elementValue === undefined) {
                    setShow(!show);
                } else {
                    setShow(false);
                }
            }}
        >
            <PhoneSelect
                fieldName={fieldName}
                className={activeClass}
                // value={receivedValue}
                receivedValue={receivedValue}
                onChange={(phone, meta) => {
                    setPhone(phone);

                    setPhones({
                        ...phones,
                        [index]: {
                            phone_data: {
                                number: `${phone.replace(`+${meta.country.dialCode}`, '')}`,
                                country: {
                                    iso2: `${meta.country.iso2.toUpperCase()}`,
                                    name: meta.country.name,
                                    dialCode: `+${meta.country.dialCode}`,
                                },
                            },
                            phone,
                        },
                    });
                }}
                removeError={removeError}
                show={show}
                setShow={setShow}
            />
        </Box>
    );
};

export default MultiplePhoneSelect;
