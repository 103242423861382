import { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice } from '../../../api/apiSlice';

import {
  useGetDialoguesQuery,
  useGetDialogueQuery,
  useGetDialogueVisitorDataQuery,
  useConnectToDialogueMutation,
  useChangeDialogueStatusMutation,
  usePassDialogueMutation,
  useChangeCurrentDialogueTagsMutation,
  useVisitorContactsRequestMutation,
  useClearDialoguesViewsMutation,
} from '../../../api/clientPartApi/apiDialoguesSlice';
import { useChangeVisitorStatusMutation } from '../../../api/clientPartApi/apiVisitosSlice';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import { Tabs, Tab, Box } from '@mui/material';

import Section from '../../../layouts/Section/Section';
import DialoguesList from './DialoguesList';
import Chat from './Chat/Chat';
import DialogueVisitorData from './DialogueVisitorData';
import Spinner from '../../../components/ui/Spinner/Spinner';

import { CustomTabPanel, a11yProps } from '../../../components/Tabs/TabPanel';

import { socket } from '../../Page';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

import { excludeDialogHistory } from '../../../reducers/messagesSlice';
import { dateConverter } from '../../../helpers/functions';

const ACTIVE_TAB_VALUE = 0;
const WAITING_TAB_VALUE = 1;

const Dialogues = () => {
  const {
    setAlertMessage,
    alertMessageContent,
    navigate,
    pathname,
    dictionary,
  } = useContext(PageContext);
  const {
    changeContentHandler,
    skip,
    content,
    hash,
    dialogueMessagesArray,
    selectedDialogueWithRedirection,
    setSelectedDialogueWithRedirection,
  } = useContext(PageContainerContext);

  const [tabValue, setTabValue] = useState(
    pathname === '/dialogues/active' ? ACTIVE_TAB_VALUE : WAITING_TAB_VALUE
  );
  const [dialogueId, setDialogueId] = useState('');
  const [idOfUpdatedDialogue, setIdOfUpdatedDialogue] = useState('');
  const [refetchExistingDialogue, setRefetchExistingDialogue] = useState(false);
  const [showVisitorData, setShowVisitorData] = useState(false);
  const [skipVisitorData, setSkipVisitorData] = useState(true);
  const [copiedText, copy] = useCopyToClipboard();
  const [activeDialogueList, setActiveDialogueList] = useState([]);
  const [waitingDialogueList, setWaitingDialogueList] = useState([]);
  const [skipActiveDialogues, setSkipActiveDialogues] = useState(false);
  const [skipWaitingDialogues, setSkipWaitingDialogues] = useState(false);

  const dispatch = useDispatch();
  const chatMessages = useSelector((state) => state.messagesSlice);

  const {
    data: activeDialogues,
    isLoading: activeDialoguesLoading,
    isSuccess: activeDialoguesSuccess,
    isError: activeDialoguesFetchingError,
    error: activeDialoguesError,
  } = useGetDialoguesQuery({ status: 'active' }, { skip });

  const {
    data: waitingDialogues,
    isLoading: waitingDialoguesLoading,
    isSuccess: waitingDialoguesSuccess,
    isError: waitingDialoguesFetchingError,
    error: waitingDialoguesError,
  } = useGetDialoguesQuery({ status: 'waiting' }, { skip });

  const {
    data: selectedDialogue,
    isLoading: selectedDialogueLoading,
    isSuccess: selectedDialogueSuccess,
    isError: selectedDialogueFetchingError,
  } = useGetDialogueQuery(dialogueId, { skip: !dialogueId });

  const {
    data: updatedDialogue,
    isLoading: updatedDialogueLoading,
    isFetching: updatedDialogueFetching,
    refetch: refetchUpdatedDialogue,
  } = useGetDialogueQuery(idOfUpdatedDialogue, { skip: !idOfUpdatedDialogue });

  const {
    data: visitorData,
    isSuccess: visitorDataSuccess,
    isFetching: visitorDataFetching,
    isLoading: visitorDataLoading,
    isError: visitorDataFetchingError,
    refetch: fetchVisitorData,
  } = useGetDialogueVisitorDataQuery(dialogueId, { skip: skipVisitorData });

  const [
    connectToDialog,
    { isLoading: connectToDialogLoading, isFetching: connectToDialogFetching },
  ] = useConnectToDialogueMutation();

  const [
    changeDialogueStatus,
    {
      isLoading: changeDialogueStatusLoading,
      isFetching: changeDialogueStatusFetching,
    },
  ] = useChangeDialogueStatusMutation();

  const [
    passDialog,
    { isLoading: passedDialogLoading, isFetching: passedDialogFetching },
  ] = usePassDialogueMutation();

  const [
    changeVisitorStatus,
    {
      isLoading: changeVisitorStatusLoading,
      isFetching: changeVisitorStatusFetching,
    },
  ] = useChangeVisitorStatusMutation();

  const [
    changeTags,
    { isFetching: changedTagsFetching, isLoading: changedTagsLoading },
  ] = useChangeCurrentDialogueTagsMutation();

  const [
    visitorContactsRequest,
    {
      isFetching: visitorContactsRequestFetching,
      isLoading: visitorContactsRequestLoading,
    },
  ] = useVisitorContactsRequestMutation();

  const [clearDialoguesViews] = useClearDialoguesViewsMutation();

  const activeList = activeDialogues?.data?.dialogs?.data || [];
  const waitingList = waitingDialogues?.data?.dialogs?.data || [];
  const blackList = activeDialogues?.data?.dialogs?.blackList;
  const dialogueStatus = selectedDialogue?.data?.status || '';
  const siteId = selectedDialogue?.data?.site_id || '';
  const sendForm = selectedDialogue?.data?.send_form;
  const visitor = visitorData?.data || '';
  const visitorId = selectedDialogue?.data?.visitor?.id || '';
  const visitorStatus = selectedDialogue?.data?.visitor?.status || '';
  const visitorNewMessagesCount =
    selectedDialogue?.data?.new_messages_count || '';

  useLayoutEffect(() => {
    if (!dialogueId) {
      if (pathname === '/dialogues/active' && content !== 'activeDialogues') {
        changeContentHandler('activeDialogues', '', false, 'dialogues');
      } else if (
        pathname === '/dialogues/waiting' &&
        content !== 'waitingDialogues'
      ) {
        changeContentHandler('waitingDialogues', '', false, 'dialogues');
      }

      if (hash.includes('#dialogue')) {
        const id = hash.slice(hash.indexOf('_') + 1);
        setDialogueId(id);

        if (
          selectedDialogueWithRedirection.isClicked &&
          selectedDialogueWithRedirection.redirectedFromActiveOrWaitingDialoguesSection
        ) {
          clearViews(
            id,
            activeDialogueList,
            waitingDialogueList,
            selectedDialogueWithRedirection.status
          );
        }
      }
    } else if (dialogueId) {
      const id = hash.slice(hash.indexOf('_') + 1);

      if (!hash.includes('#dialogue')) {
        setDialogueId('');
      } else if (
        selectedDialogueWithRedirection.isClicked &&
        id !== dialogueId
      ) {
        setDialogueId(id);
        if (pathname === '/dialogues/active' && content !== 'activeDialogues') {
          changeContentHandler('activeDialogues', '', false, 'dialogues');
        }
        clearViews(
          id,
          activeDialogueList,
          waitingDialogueList,
          selectedDialogueWithRedirection.status
        );
      }
    }
  }, [
    hash,
    pathname,
    dialogueId,
    selectedDialogueWithRedirection,
    activeDialogueList,
    waitingDialogueList,
  ]);

  useLayoutEffect(() => {
    // when click on the back/forward browser button, refresh the page or click on link in navBar
    if (pathname === '/dialogues/active' && tabValue !== ACTIVE_TAB_VALUE) {
      setTabValue(ACTIVE_TAB_VALUE);
    } else if (
      pathname === '/dialogues/waiting' &&
      tabValue !== WAITING_TAB_VALUE
    ) {
      setTabValue(WAITING_TAB_VALUE);
    }
  }, [pathname, tabValue]);

  useEffect(() => {
    if (activeDialoguesSuccess && !skipActiveDialogues) {
      setSkipActiveDialogues(true);
      setActiveDialogueList(activeList);
    }
  }, [activeDialoguesSuccess, skipActiveDialogues]);

  useEffect(() => {
    if (waitingDialoguesSuccess && !skipWaitingDialogues) {
      setSkipWaitingDialogues(true);
      setWaitingDialogueList(waitingList);
    }
  }, [waitingDialoguesSuccess, skipWaitingDialogues]);

  useEffect(() => {
    if (
      waitingDialoguesSuccess &&
      activeDialoguesSuccess &&
      !selectedDialogueWithRedirection.redirectedFromActiveOrWaitingDialoguesSection
    ) {
      const id = hash.slice(hash.indexOf('_') + 1);
      if (selectedDialogueWithRedirection.status === 'unresolved') {
        clearViews(id, activeList);
      } else if (selectedDialogueWithRedirection.status === 'waiting') {
        clearViews(
          id,
          activeList,
          waitingList,
          selectedDialogueWithRedirection.status
        );
      } else if (
        selectedDialogueWithRedirection.status === 'free' ||
        selectedDialogueWithRedirection.status === 'someonesUnresolved'
      ) {
        setIdOfUpdatedDialogue(id);
        setSelectedDialogueWithRedirection({
          isClicked: false,
          status: '',
          redirectedFromActiveOrWaitingDialoguesSection: false,
        });
      }
    }
  }, [
    waitingDialoguesSuccess,
    activeDialoguesSuccess,
    selectedDialogueWithRedirection,
    hash,
  ]);

  useEffect(() => {
    if (!dialogueId && showVisitorData) {
      setShowVisitorData(false);
    }
  }, [dialogueId, showVisitorData]);

  useEffect(() => {
    if (selectedDialogueFetchingError) {
      alertMessageContent(dictionary?.dialogue_error, 'error');
    }
  }, [selectedDialogueFetchingError]);

  useEffect(() => {
    const updateDialogueList = (data) => {
      if (data?.status === 'resolved') {
        if (data?.id === dialogueId) {
          returnAcitveDialogueListToInitialState();
        }

        const filteredDialogueList = filterDialogueList(
          activeDialogueList,
          data?.id
        );
        setActiveDialogueList([...filteredDialogueList]);

        // dispatch(apiSlice.util.invalidateTags(['Dialogues']));
      }
    };

    const updateVisitorData = (data) => {
      if (dialogueId && content === 'activeDialogues') {
        const dialogueIsExist = activeDialogueList.some(
          ({ id }) => id === data?.id
        );

        if (dialogueIsExist) {
          setRefetchExistingDialogue(true);
        }

        setIdOfUpdatedDialogue(data?.id);

        if (!skipVisitorData) {
          fetchVisitorData();
        } else {
          dispatch(apiSlice.util.invalidateTags(['Dialogues']));
        }
      }
    };

    socket.on('dialog.status', updateDialogueList);
    socket.on('dialog.contacts.send', updateVisitorData);

    return () => {
      socket.off('dialog.status', updateDialogueList);
      socket.off('dialog.contacts.send', updateVisitorData);
    };
  }, [dialogueId, skipVisitorData, dialogueMessagesArray, activeDialogueList]);

  useEffect(() => {
    const updateDialogue = (data) => {
      let dialogueIsExist;

      if (data.status === 'waiting') {
        dialogueIsExist = waitingDialogueList.some(
          ({ id }) => id === data?.dialogId
        );
      } else if (data.status === 'unresolved') {
        dialogueIsExist = activeDialogueList.some(
          ({ id }) => id === data?.dialogId
        );
      }

      if (dialogueIsExist) {
        setRefetchExistingDialogue(true);
      }
      setIdOfUpdatedDialogue(data?.dialogId);
    };

    const renderPassedDialogue = (data) => {
      setIdOfUpdatedDialogue(data?.id);
    };

    socket.on('dialog.pass', renderPassedDialogue);
    socket.on('dialog.message.send', updateDialogue);

    return () => {
      socket.off('dialog.pass', renderPassedDialogue);
      socket.off('dialog.message.send', updateDialogue);
    };
  }, [idOfUpdatedDialogue, activeDialogueList, waitingDialogueList]);

  useEffect(() => {
    if (idOfUpdatedDialogue && refetchExistingDialogue) {
      setRefetchExistingDialogue(false);
      refetchUpdatedDialogue();
    }
  }, [idOfUpdatedDialogue, refetchExistingDialogue]);

  useEffect(() => {
    if (
      !updatedDialogueLoading &&
      !updatedDialogueFetching &&
      updatedDialogue &&
      idOfUpdatedDialogue &&
      !refetchExistingDialogue
    ) {
      const dialogue = {
        id: updatedDialogue?.data?.id,
        fullname: updatedDialogue?.data?.visitor?.fullname,
        site: { domain: updatedDialogue?.data?.domain },
        new_messages_count: updatedDialogue?.data?.new_messages_count,
        code: updatedDialogue?.data?.visitor?.code,
        city: updatedDialogue?.data?.visitor?.city,
        last_message_at: updatedDialogue?.data?.last_message_at,
        last_message_text: updatedDialogue?.data?.last_message_text,
        last_message_is_file: updatedDialogue?.data?.last_message_is_file,
        created_at: updatedDialogue?.data?.created_at,
      };

      setIdOfUpdatedDialogue('');

      const filteredDialogueList = filterDialogueList(
        updatedDialogue?.data?.status === 'unresolved'
          ? activeDialogueList
          : waitingDialogueList,
        idOfUpdatedDialogue
      );
      if (updatedDialogue?.data?.status === 'unresolved') {
        setActiveDialogueList([dialogue, ...filteredDialogueList]);
      } else {
        setWaitingDialogueList([dialogue, ...filteredDialogueList]);
      }
    }
  }, [
    updatedDialogueLoading,
    updatedDialogueFetching,
    updatedDialogue,
    idOfUpdatedDialogue,
    activeDialogueList,
    waitingDialogueList,
  ]);

  useEffect(() => {
    const { dialogsHistory } = chatMessages;
    const filteredDialogsHistory = dialogsHistory?.filter((dialog) => {
      if (content === 'activeDialogues') {
        return activeDialogueList.some((dialogue) => dialogue.id === dialog.id);
      } else {
        return waitingDialogueList.some(
          (dialogue) => dialogue.id === dialog.id
        );
      }
    });

    // console.log(filteredDialogsHistory);

    dispatch(
      excludeDialogHistory({
        dialogsHistory: filteredDialogsHistory || [],
      })
    );
  }, [
    activeDialogueList.length,
    waitingDialogueList.length,
    content,
    chatMessages.dialogsHistory.length,
  ]);

  const filterDialogueList = (dialogueList, dialogueId) => {
    return dialogueList.filter(({ id }) => id !== dialogueId);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabHandler = (path, content) => {
    setDialogueId('');
    navigate(path);
    changeContentHandler(content, '', false, 'dialogues');
  };

  const returnAcitveDialogueListToInitialState = () => {
    setDialogueId('');

    // needed to remove the hash from PopupMessage useEffect. Otherwise, it will contain information about
    // the dialogue that was clicked
    navigate('/dialogues/active');
  };

  const updateDialogueListsAfterConnecting = (id, activeList, waitingList) => {
    const filteredWaitingDialogueList = filterDialogueList(waitingList, id);
    setWaitingDialogueList([...filteredWaitingDialogueList]);

    let newActiveDialogue = [
      waitingList.find((dialogue) => dialogue.id === id),
    ];

    newActiveDialogue = newActiveDialogue.map((dialogue) => {
      return { ...dialogue, new_messages_count: 0 };
    });

    const newActiveDialogueList = [...activeList, ...newActiveDialogue].sort(
      (a, b) =>
        dateConverter(b.last_message_at, 'timestamp') -
        dateConverter(a.last_message_at, 'timestamp')
    );

    setActiveDialogueList([...newActiveDialogueList]);
  };

  const connectToDialogHandler = (id, activeList, waitingList) => {
    setAlertMessage({});

    connectToDialog(id)
      .unwrap()
      .then((res) => {
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname
        );
        navigate(`/dialogues/active#dialogue_${id}`);
        changeContentHandler('activeDialogues', '', false, 'dialogues');
        setDialogueId(id);
        updateDialogueListsAfterConnecting(id, activeList, waitingList);
      })
      .catch((error) => {
        alertMessageContent(dictionary?.connect_error, 'error');
      });
  };

  const changeDialogueStatusHandler = () => {
    changeDialogueStatus(dialogueId)
      .unwrap()
      .then((res) => {
        alertMessageContent(dictionary?.resolved, 'success');
        returnAcitveDialogueListToInitialState();
      })
      .catch((error) => {
        alertMessageContent(dictionary?.something_go_wrong, 'error');
      });
  };

  const passDialogueHandler = (userId) => {
    setAlertMessage({});

    passDialog({ user: userId, dialogue: dialogueId })
      .unwrap()
      .then((res) => {
        const filteredDialogueList = filterDialogueList(
          activeDialogueList,
          dialogueId
        );
        setActiveDialogueList([...filteredDialogueList]);

        returnAcitveDialogueListToInitialState();
        alertMessageContent(dictionary?.pass_success, 'success');
      })
      .catch((error) => {
        alertMessageContent(dictionary?.pass_error, 'error');
      });
  };

  const changeVisitorStatusHandler = (action, period, value) => {
    setAlertMessage({});

    const payload =
      action === 'block'
        ? `?site_id=${siteId}&period=${period}&value=${value}`
        : '';
    changeVisitorStatus({ id: visitorId, action, payload })
      .unwrap()
      .then((res) => {
        if (action === 'block') {
          alertMessageContent(dictionary?.visitor_blocked, 'success');
        } else {
          alertMessageContent(dictionary?.visitor_unblocked, 'success');
        }
      })
      .catch((error) => {
        if (action === 'block') {
          alertMessageContent(dictionary?.visitor_block_error, 'error');
        } else {
          alertMessageContent(dictionary?.visitor_unblock_error, 'error');
        }
      });
  };

  const clearViews = (id, activeList, waitingList, status) => {
    setSelectedDialogueWithRedirection({
      isClicked: false,
      status: '',
      redirectedFromActiveOrWaitingDialoguesSection: false,
    });

    clearDialoguesViews(id)
      .unwrap()
      .then((res) => {
        if (status === 'waiting') {
          updateDialogueListsAfterConnecting(id, activeList, waitingList);
        } else {
          const dialogueListWithUpdatedMessagesCount = activeList.map(
            (dialogue) => {
              if (dialogue.id === id) {
                return { ...dialogue, new_messages_count: 0 };
              } else {
                return dialogue;
              }
            }
          );

          setActiveDialogueList([...dialogueListWithUpdatedMessagesCount]);
        }
      })
      .catch((error) => console.log(error));
  };

  const boxStyles = {
    position: 'relative',
    width: '100%',
    borderRadius: 2.5,
    backgroundColor: 'basicColor.colorWhite',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
  };

  const titleWithIcon = (title, icon = true) => {
    return (
      <span>
        {icon && (
          <span
            style={{
              fontSize: '22px',
              lineHeight: 1.2,
              verticalAlign: 'text-top',
            }}
            className={`fi fi-${
              !selectedDialogue?.data?.isIncognito &&
              selectedDialogue?.data?.visitor?.code?.toLowerCase()
            }`}
          ></span>
        )}{' '}
        {title}
      </span>
    );
  };

  console.log(selectedDialogue?.data);

  return (
    <>
      <Section
        title={
          pathname === '/dialogues/active' && dialogueId
            ? (selectedDialogue?.data?.visitor?.country ||
                selectedDialogue?.data?.isIncognito) &&
              titleWithIcon(
                `${dictionary?.dialogue} ${
                  selectedDialogue?.data?.visitor?.country
                    ? selectedDialogue?.data?.visitor?.country
                    : `Incognito #${selectedDialogue?.data?.id}`
                }`,
                !selectedDialogue?.data?.isIncognito
              )
            : pathname === '/dialogues/active' && !dialogueId
            ? dictionary?.active_dialogues_title
            : dictionary?.waiting_dialogues_title
        }
        isLoading={
          activeDialoguesLoading ||
          waitingDialoguesLoading ||
          selectedDialogueLoading ||
          connectToDialogLoading ||
          changeDialogueStatusLoading ||
          passedDialogLoading ||
          changedTagsLoading ||
          changeVisitorStatusLoading
        }
        isFetching={
          connectToDialogFetching ||
          changeDialogueStatusFetching ||
          passedDialogFetching ||
          changedTagsFetching ||
          changeVisitorStatusFetching
        }
        isError={activeDialoguesFetchingError || waitingDialoguesFetchingError}
        error={activeDialoguesError || waitingDialoguesError}
        sx={{
          maxWidth: 1920,
          height: '77.8vh',
          display: 'flex',
          gap: 3.75,
          boxShadow: 'none',
          padding: 0,
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={{ maxWidth: 540, ...boxStyles }}>
          <Box sx={{ borderColor: 'transparent' }}>
            <Tabs
              className="tabs-clients"
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                padding: '30px 30px 14px 30px',
                borderBottom: '1px solid #DAECF9',
                marginBottom: '0!important',
              }}
            >
              <Tab
                label={dictionary?.my}
                {...a11yProps(ACTIVE_TAB_VALUE)}
                onClick={() =>
                  tabHandler('/dialogues/active', 'activeDialogues')
                }
              />
              <Tab
                label={dictionary?.expect}
                {...a11yProps(WAITING_TAB_VALUE)}
                onClick={() =>
                  tabHandler('/dialogues/waiting', 'waitingDialogues')
                }
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              padding: '14px 30px 30px 30px',
              height: 'calc(100% - 85px)',
              overflow: 'auto',
            }}
          >
            <CustomTabPanel value={tabValue} index={ACTIVE_TAB_VALUE}>
              <DialoguesList
                dialogues={activeDialogueList}
                dialogueId={dialogueId}
                setDialogueId={setDialogueId}
                changeContentHandler={changeContentHandler}
                setSkipVisitorData={setSkipVisitorData}
                visitorNewMessagesCount={visitorNewMessagesCount}
                clearViews={clearViews}
                type="active"
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={WAITING_TAB_VALUE}>
              <DialoguesList
                dialogues={waitingDialogueList}
                activeDialogueList={activeDialogueList}
                dialogueId={dialogueId}
                setDialogueId={setDialogueId}
                changeContentHandler={changeContentHandler}
                connectToDialogHandler={connectToDialogHandler}
                type="waiting"
              />
            </CustomTabPanel>
          </Box>
        </Box>
        <Box sx={{ padding: 3.75, ...boxStyles }}>
          {(visitorContactsRequestLoading ||
            visitorContactsRequestFetching) && <Spinner />}
          <Chat
            blackList={blackList}
            dialogueId={dialogueId}
            dialogueStatus={dialogueStatus}
            visitorStatus={visitorStatus}
            changeTags={changeTags}
            alertMessageContent={alertMessageContent}
            showVisitorData={showVisitorData}
            setShowVisitorData={setShowVisitorData}
            skipVisitorData={skipVisitorData}
            setSkipVisitorData={setSkipVisitorData}
            connectToDialogHandler={connectToDialogHandler}
            passDialogueHandler={passDialogueHandler}
            changeDialogueStatusHandler={changeDialogueStatusHandler}
            changeVisitorStatusHandler={changeVisitorStatusHandler}
            selectedDialogueSuccess={selectedDialogueSuccess}
            visitorContactsRequest={visitorContactsRequest}
            sendForm={sendForm}
          />
        </Box>
      </Section>
      {dialogueId && (
        <DialogueVisitorData
          visitor={visitor}
          dialogueId={dialogueId}
          copiedText={copiedText}
          copy={copy}
          showVisitorData={showVisitorData}
          setShowVisitorData={setShowVisitorData}
          setSkipVisitorData={setSkipVisitorData}
          isLoading={visitorDataLoading}
          isFetching={visitorDataFetching}
          isSuccess={visitorDataSuccess}
          isError={visitorDataFetchingError}
        />
      )}
    </>
  );
};

export default Dialogues;
