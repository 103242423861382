import { useState, useContext, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { FlexBox } from '../../../../mui/theme';

import ExpandedSwitcher from '../../../../components/ui/Switcher/ExpandedSwitcher';
import TimeInput from '../../../../components/Form/FormInputs/TimeInput';

import { ReactComponent as InformationIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as NotConfirmedIcon } from '../../../../assets/icons/not-confirmed.svg';

import { PageContext } from '../../../Page';
import { FormContainerContext } from '../../../../components/Form/FormContainer';

const setScheduleData = (dictionary) => {
    return [
        {
            id: 'mon',
            label: dictionary?.monday,
        },
        {
            id: 'tue',
            label: dictionary?.tuesday,
        },
        {
            id: 'wed',
            label: dictionary?.wednesday,
        },
        {
            id: 'thu',
            label: dictionary?.thursday,
        },
        {
            id: 'fri',
            label: dictionary?.friday,
        },
        {
            id: 'sat',
            label: dictionary?.saturday,
        },
        {
            id: 'sun',
            label: dictionary?.sunday,
        },
    ];
};

const ScheduleForm = ({ schedule, setBlockSubmitButton, hideInformationText = false }) => {
    const { dictionary } = useContext(PageContext);
    const { usedFormValidationErrors } = useContext(FormContainerContext);

    const [startTimeIsLarger, setStartTimeIsLarger] = useState(false);

    const [daySwitcherValue, setDaySwitcherValue] = useState({
        mon_switcher: schedule?.mon?.active !== undefined ? schedule?.mon?.active : false,
        tue_switcher: schedule?.tue?.active !== undefined ? schedule?.tue?.active : false,
        wed_switcher: schedule?.wed?.active !== undefined ? schedule?.wed?.active : false,
        thu_switcher: schedule?.thu?.active !== undefined ? schedule?.thu?.active : false,
        fri_switcher: schedule?.fri?.active !== undefined ? schedule?.fri?.active : false,
        sat_switcher: schedule?.sat?.active !== undefined ? schedule?.sat?.active : false,
        sun_switcher: schedule?.sun?.active !== undefined ? schedule?.sun?.active : false,
    });

    useEffect(() => {
        if (usedFormValidationErrors !== '{}') {
            const arrOfErrorsTypes = [];

            for (const key in usedFormValidationErrors) {
                arrOfErrorsTypes.push(usedFormValidationErrors[key].type);
            }

            setStartTimeIsLarger(arrOfErrorsTypes.some((error) => error === 'isLarger'));
        }
    }, [usedFormValidationErrors]);

    useEffect(() => {
        const allSwitchersPosition = Object.values(daySwitcherValue).every((value) => !value);

        setBlockSubmitButton(allSwitchersPosition);
    }, [daySwitcherValue]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.25 }}>
            {setScheduleData(dictionary).map(({ id, label }) => {
                return (
                    <FlexBox
                        className="col-18"
                        key={id}
                        sx={{
                            alignItems: 'center',
                            maxWidth: 480,
                            '& .MuiFormControl-root:first-of-type': {
                                maxWidth: 80,
                            },
                        }}
                    >
                        <ExpandedSwitcher
                            fieldName={id + '_switcher'}
                            label={label}
                            checked={daySwitcherValue}
                            setChecked={setDaySwitcherValue}
                            receivedValue={daySwitcherValue[id + '_switcher']}
                        />
                        <TimeInput
                            fieldName={id + '_start'}
                            label={dictionary?.start}
                            disabled={!daySwitcherValue[id + '_switcher']}
                            receivedValue={schedule?.[`${id}`]?.start || ''}
                        />
                        <TimeInput
                            fieldName={id + '_end'}
                            label={dictionary?.end}
                            disabled={!daySwitcherValue[id + '_switcher']}
                            receivedValue={schedule?.[`${id}`]?.end || ''}
                        />
                    </FlexBox>
                );
            })}
            {startTimeIsLarger && (
                <FlexBox className="row-10" alignItems="center" sx={{ maxWidth: 480 }}>
                    <NotConfirmedIcon />
                    <Typography variant="body2" color="colorAccent.second">
                        {dictionary?.schedule_message_2}
                    </Typography>
                </FlexBox>
            )}
            {!hideInformationText && (
                <FlexBox className="row-10" alignItems="center" sx={{ maxWidth: 480 }}>
                    <InformationIcon />
                    <Typography variant="body2">{dictionary?.schedule_message_1}</Typography>
                </FlexBox>
            )}
        </Box>
    );
};

export default ScheduleForm;
